import React, {ReactElement, useEffect} from "react";
import {GridInitialStatePremium} from "@mui/x-data-grid-premium/models/gridStatePremium";
import {DataGridPremium, GridColDef, GridRenderCellParams} from "@mui/x-data-grid-premium";
import {DividendCurrencyConfig} from "../domain/assetmanager/DividendCurrencyConfig";
import {
    useCreateDividendCurrencyDefaultConfiguration,
    useGetDividendCurrencyConfigurations
} from "../application/DividendConfigService";
import {GetDataApi} from "./types/GetDataApi";
import {Link, useNavigate} from "react-router-dom";
import Grid from "@mui/material/Grid";
import CreateDividendCurrencyConfigurationDialog from "./CreateDividendCurrencyConfigurationDialog";
import {ResponseDataFunction} from "./types/ResponseDataFunction";
import Title from "./Title";
import {useAutoSizeGrid} from "./AutoSizeGridHook";

export default function DividendCurrencyConfigurationsComponent(): ReactElement {
    const navigation = useNavigate();
    const getFunction: GetDataApi<DividendCurrencyConfig[]> = useGetDividendCurrencyConfigurations();
    const createFunction: ResponseDataFunction<string, DividendCurrencyConfig> = useCreateDividendCurrencyDefaultConfiguration();
    const apiRef = useAutoSizeGrid<DividendCurrencyConfig>({
        data: getFunction.data,
        loading: getFunction.loading,
        columns: [
            'currency',
            'netCurrency',
            'holdMatch'
        ]
    });
    const initialState: GridInitialStatePremium =
        {
            columns: {
                dimensions: {
                    currency: {width: 150},
                    netCurrency: {width: 300},
                    holdMatch: {width: 300},
                }
            }
        }

    useEffect(() => {
        if (createFunction.error) {
            navigation("dividends/config");
        } else if (createFunction.data.id > 0) {
            navigation("currency/" + createFunction.data.currency);
        }    }, [ createFunction.error, createFunction.data ]
    );

    const columns: GridColDef<DividendCurrencyConfig>[] = [
        { field: 'currency', headerName: 'Currency',
            renderCell: (params: GridRenderCellParams<DividendCurrencyConfig>) => <Link to={ "currency/" + params.row.currency }>{params.row.currency}</Link>
        },
        {field: 'netCurrency', headerName: 'Net Income Rate Match'},
        {field: 'holdMatch', headerName: "Hold Auto Approve"},
    ];

    return (
            <Grid container spacing={2}>
                <Grid item xs={12} md={8} lg={8} padding={1}>
                    <Title>Currency Specific Configuration</Title>
                </Grid>
                <Grid item xs={12} md={3} lg={3} padding={1}>
                    <CreateDividendCurrencyConfigurationDialog createFunction={createFunction}/>
                </Grid>
                <Grid item xs={12} md={12} lg={12} padding={1}>
                    <DataGridPremium
                        apiRef={apiRef}
                        initialState={initialState}
                        loading={getFunction.loading}
                        rows={getFunction.data}
                        columns={columns}
                        density={"compact"}
                        getRowId={(row) => row.id}
                        hideFooter={true}
                        autoHeight={true}
                    />
                </Grid>
            </Grid>
    );

}