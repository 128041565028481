import React, {ReactElement} from "react";
import {formatDayjsDateString} from "./TimeFormat";
import {Chip} from "@mui/material";
import {CashDividendImportStatus} from "../domain/dividend/CashDividendImportStatus";
import {RefreshDataFunction} from "./types/RefreshDataFunction";
import {useParams} from "react-router-dom";
import {getRouteDate} from "../application/CashDividendService";

export type CashDividendImportStatusComponentParam = {
    api: RefreshDataFunction<CashDividendImportStatus>
}
export default function CashDividendImportStatusComponent(params: Readonly<CashDividendImportStatusComponentParam>): ReactElement {
    const { routeDate } = useParams();

    function getColor(status: CashDividendImportStatus): "warning" | "success" | "info" {
        if (status.dpDividends === 0 && status.vpbDividends === 0) {
            return "warning";
        }
        if ((status.dpDividends === 0 && status.vpbDividends > 0)
            ||(status.dpDividends > 0 && status.vpbDividends === 0)) {
            return "info";
        }
        return "success";
    }

    function getValue(): string {
        return "Imported "
        + formatDayjsDateString(getRouteDate(routeDate))
        + " Bony = "
        + params.api.data.vpbDividends
        + " Bloomberg = "
        + params.api.data.dpDividends;
    }

    return (
        <Chip label={ getValue() } color={ getColor(params.api.data)}/>
    )
}