import axios from "axios";
import {useParams} from "react-router-dom";
import {useCallback, useEffect, useState} from "react";
import {GetDataApi} from "../components/types/GetDataApi";
import {emptyEquityTransaction, EquityTransaction} from "../domain/transaction/EquityTransaction";
import {RefreshDataFunction} from "../components/types/RefreshDataFunction";
import {emptyPageDto, PageDto} from "../domain/PageDto";
import {defaultTransactionSearchTerm, TransactionSearchTerm} from "../domain/transaction/TransactionSearchTerm";
import {SearchDataFunction} from "../components/types/SearchDataFunction";
import {getGatewayUrl} from "../components/EnvironmentConfiguration";
import {convertTransactionSearchTerm} from "./TransactionSearchTermDto";

export function useFindEquityTransactions(): RefreshDataFunction<PageDto<EquityTransaction>> {
    const [transactions, setTransactions] = useState<PageDto<EquityTransaction>>(emptyPageDto());
    const { transactionId, assetManager } = useParams();
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const apiFunction = useCallback(
        async () => {
            setLoading(true);
            if (transactionId && assetManager) {
                const body = JSON.stringify({
                    "externalTransactionId": transactionId,
                    "assetManager": assetManager
                });
                const config = {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                };
                axios.post(getGatewayUrl() + '/transaction/v1/equity/findByExternalId', body, config)
                    .then((response) => {
                        setTransactions(response.data as PageDto<EquityTransaction>)
                    })
                    .catch((err) => setError(err.message))
                    .finally(() => setLoading(false));
            }
            else {
                axios.get(getGatewayUrl() + '/transaction/v1/equity')
                    .then((response) => {
                        setTransactions(response.data as PageDto<EquityTransaction>)
                    })
                    .catch((err) => setError(err.message))
                    .finally(() => setLoading(false));
            }
        },
        [ transactionId, assetManager ]
    );

    useEffect(() => {
            apiFunction();
        },
        [ transactionId, assetManager ]
    );

    return { data: transactions, loading, error, apiFunction }
}

export function useSearchEquityTransactions(initialSearchTerm: TransactionSearchTerm | null): SearchDataFunction<TransactionSearchTerm, PageDto<EquityTransaction>> {
    const defaultSearchTerm: TransactionSearchTerm = initialSearchTerm ?? defaultTransactionSearchTerm();
    const [transactions, setTransactions] = useState<PageDto<EquityTransaction>>(emptyPageDto());
    const [searchTerm, setSearchTerm] = useState<TransactionSearchTerm>(defaultSearchTerm);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const apiFunction = useCallback(
        async (searchTerm: TransactionSearchTerm) => {
            setLoading(true);
            const dto = convertTransactionSearchTerm(searchTerm);
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            axios.post(getGatewayUrl() + '/transaction/v1/equity/search', dto, config)
                .then((response) => {
                    setTransactions(response.data as PageDto<EquityTransaction>)
                })
                .catch((err) => setError(err.message))
                .finally(() => setLoading(false));
        },
        [ searchTerm ]
    );

    useEffect(() => { apiFunction(searchTerm); },
        [ searchTerm ]
    );

    return { data: transactions, searchTerm, loading, error, apiFunction, setSearchTermFunction: setSearchTerm }
}

export function useFindEquityByTransactionId(): GetDataApi<EquityTransaction> {
    const { transactionId } = useParams();
    const [transaction, setTransaction] = useState<EquityTransaction>(emptyEquityTransaction());
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        setLoading(true);
        axios.get(getGatewayUrl() + "/transaction/v1/equity/" + transactionId)
            .then((response) => {
                setTransaction(response.data as EquityTransaction)
            })
            .catch((err) => setError(err.message))
            .finally(() => setLoading(false));
    }, [ ]);

    return { data: transaction, loading, error }
}