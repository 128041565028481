import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {Link} from 'react-router-dom';
import {
    Handshake,
    ShowChart,
    Paid,
    BusinessCenter,
    ExpandMore,
    ExpandLess,
    ArrowForward,
    RequestPageRounded,
    UpdateRounded,
    CurrencyExchangeRounded,
    ShareRounded,
    ImportContacts,
    SyncProblemRounded,
    NorthEastRounded,
    SouthEastRounded,
    SettingsRounded,
    ErrorOutlineRounded,
    FolderCopyRounded,
    ViewTimelineRounded,
    EventRounded,
    EventNoteRounded, CheckBoxRounded, SearchRounded
} from "@mui/icons-material";
import {Collapse} from "@mui/material";
import List from "@mui/material/List";
import {ReactElement} from "react";
import Divider from "@mui/material/Divider";
import RolesRetriever from "../authorization/RolesRetreiver";

type MenuItemState = {
    transactions: boolean,
    static: boolean,
    issues: boolean,
    dividends: boolean,
    configuration: boolean,
    audit: boolean
}

const defaultMenuItemState: MenuItemState = {
    transactions: false,
    static: false,
    issues: false,
    dividends: false,
    configuration: false,
    audit: false
};

export default function MenuItems(): ReactElement {
    const [openMenus, setOpenMenus] = React.useState<MenuItemState>(defaultMenuItemState);

    const handleStaticClick = () => {
        const newMenus = {...openMenus};
        newMenus.static = ! openMenus.static;
        setOpenMenus(newMenus);
    };

    const handleTransClick = () => {
        const newMenus = {...openMenus};
        newMenus.transactions = ! openMenus.transactions;
        setOpenMenus(newMenus);
    };

    const handleIssueClick = () => {
        const newMenus = {...openMenus};
        newMenus.issues = ! openMenus.issues;
        setOpenMenus(newMenus);
    };

    const handleDividendClick = () => {
        const newMenus = {...openMenus};
        newMenus.dividends = ! openMenus.dividends;
        setOpenMenus(newMenus);
    };

    const handleConfigurationManagerClick = () => {
        const newMenus = {...openMenus};
        newMenus.configuration = ! openMenus.configuration;
        setOpenMenus(newMenus);
    };

    const handleAuditClick = () => {
        const newMenus = {...openMenus};
        newMenus.audit = ! openMenus.audit;
        setOpenMenus(newMenus);
    };

    return (
        <React.Fragment>

            <ListItemButton onClick={handleIssueClick}>
                <ListItemIcon>
                    <ErrorOutlineRounded/>
                </ListItemIcon>
                <ListItemText primary="Monitoring"/>
                {openMenus.issues ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openMenus.issues} timeout="auto" unmountOnExit>
                <List component="div">
                    <ListItemButton sx={{ pl: 4 }}  component={Link} to="/issues/ttrade">
                        <ListItemIcon>
                            <NorthEastRounded/>
                        </ListItemIcon>
                        <ListItemText primary="Simcorp Dimension"/>
                    </ListItemButton>
                    <ListItemButton sx={{ pl: 4 }}  component={Link} to="/issues/inbound">
                        <ListItemIcon>
                            <SouthEastRounded/>
                        </ListItemIcon>
                        <ListItemText primary="Asset Manager"/>
                    </ListItemButton>
                    <ListItemButton sx={{ pl: 4 }}  component={Link} to="/transaction/eq/active">
                        <ListItemIcon>
                            <SyncProblemRounded />
                        </ListItemIcon>
                        <ListItemText primary="Active Equity Trade"/>
                    </ListItemButton>
                    <ListItemButton sx={{ pl: 4 }}  component={Link} to="/transaction/fu/active">
                        <ListItemIcon>
                            <SyncProblemRounded />
                        </ListItemIcon>
                        <ListItemText primary="Active Future Trade"/>
                    </ListItemButton>
                    <ListItemButton sx={{ pl: 4 }}  component={Link} to="/transaction/fx/active">
                        <ListItemIcon>
                            <SyncProblemRounded />
                        </ListItemIcon>
                        <ListItemText primary="Active FX Trade"/>
                    </ListItemButton>

                </List>
            </Collapse>

            <ListItemButton onClick={handleTransClick}>
                <ListItemIcon>
                    <ArrowForward/>
                </ListItemIcon>
                <ListItemText primary="Transactions"/>
                {openMenus.transactions ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openMenus.transactions} timeout="auto" unmountOnExit>
                <List component="div">
                    <ListItemButton sx={{ pl: 4 }} component={Link} to="/transaction/eq">
                        <ListItemIcon>
                            <RequestPageRounded/>
                        </ListItemIcon>
                        <ListItemText primary="Equities"/>
                    </ListItemButton>

                    <ListItemButton sx={{ pl: 4 }} component={Link} to="/transaction/fu">
                        <ListItemIcon>
                            <UpdateRounded/>
                        </ListItemIcon>
                        <ListItemText primary="Futures"/>
                    </ListItemButton>

                    <ListItemButton sx={{ pl: 4 }} component={Link} to="/transaction/fx">
                        <ListItemIcon>
                            <CurrencyExchangeRounded/>
                        </ListItemIcon>
                        <ListItemText primary="FX"/>
                    </ListItemButton>
                </List>
            </Collapse>

            <ListItemButton onClick={handleStaticClick}>
                <ListItemIcon>
                    <ArrowForward/>
                </ListItemIcon>
                <ListItemText primary="Static Data"/>
                {openMenus.static ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openMenus.static} timeout="auto" unmountOnExit>
                <List component="div">
                    <ListItemButton sx={{ pl: 4 }} component={Link} to="/currencies">
                        <ListItemIcon>
                            <Paid/>
                        </ListItemIcon>
                        <ListItemText primary="Currencies"/>
                    </ListItemButton>
                    <ListItemButton sx={{ pl: 4 }} component={Link} to="/currencyCrosses">
                        <ListItemIcon>
                            <Paid/>
                        </ListItemIcon>
                        <ListItemText primary="Currency Crosses"/>
                    </ListItemButton>
                    <ListItemButton sx={{ pl: 4 }} component={Link} to="/counterparts">
                        <ListItemIcon>
                            <Handshake/>
                        </ListItemIcon>
                        <ListItemText primary="Counterparts"/>
                    </ListItemButton>

                    <ListItemButton sx={{ pl: 4 }} component={Link} to="/brokers">
                        <ListItemIcon>
                            <Handshake/>
                        </ListItemIcon>
                        <ListItemText primary="Brokers"/>
                    </ListItemButton>

                    <ListItemButton sx={{ pl: 4 }} component={Link} to="/equities">
                        <ListItemIcon>
                            <ShowChart/>
                        </ListItemIcon>
                        <ListItemText primary="Equities"/>
                    </ListItemButton>

                    <ListItemButton sx={{ pl: 4 }} component={Link} to="/futures">
                        <ListItemIcon>
                            <ShowChart/>
                        </ListItemIcon>
                        <ListItemText primary="Futures"/>
                    </ListItemButton>

                    <ListItemButton sx={{ pl: 4 }} component={Link} to="/mandates">
                        <ListItemIcon>
                            <BusinessCenter/>
                        </ListItemIcon>
                        <ListItemText primary="Mandates"/>
                    </ListItemButton>

                    <ListItemButton sx={{ pl: 4 }} component={Link} to="/portfolios">
                        <ListItemIcon>
                            <BusinessCenter/>
                        </ListItemIcon>
                        <ListItemText primary="Portfolios"/>
                    </ListItemButton>

                    <ListItemButton sx={{ pl: 4 }} component={Link} to="/modelPortfolios">
                        <ListItemIcon>
                            <BusinessCenter/>
                        </ListItemIcon>
                        <ListItemText primary="Model Portfolios"/>
                    </ListItemButton>
                    <Divider/>
                    <RolesRetriever groups={ [ "admin", "super" ]}>
                        <ListItemButton sx={{ pl: 4 }} component={Link} to="/instrumentImport">
                            <ListItemIcon>
                                <ImportContacts/>
                            </ListItemIcon>
                            <ListItemText primary="Import Instruments"/>
                        </ListItemButton>
                    </RolesRetriever>
                </List>
            </Collapse>
            <ListItemButton onClick={handleDividendClick}>
                <ListItemIcon>
                    <ArrowForward/>
                </ListItemIcon>
                <ListItemText primary="Dividends"/>
                {openMenus.dividends ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openMenus.dividends} timeout="auto" unmountOnExit>
                <List component="div">
                    <ListItemButton sx={{ pl: 4 }}  component={Link} to="/dividends/monitoring">
                        <ListItemIcon>
                            <ShareRounded/>
                        </ListItemIcon>
                        <ListItemText primary="Simcorp Dimension"/>
                    </ListItemButton>
                    <ListItemButton sx={{ pl: 4 }}  component={Link} to="/dividends/pending">
                        <ListItemIcon>
                            <NorthEastRounded/>
                        </ListItemIcon>
                        <ListItemText primary="Pending"/>
                    </ListItemButton>
                    <ListItemButton sx={{ pl: 4 }}  component={Link} to="/dividends/verified">
                        <ListItemIcon>
                            <CheckBoxRounded/>
                        </ListItemIcon>
                        <ListItemText primary="Verified"/>
                    </ListItemButton>
                    <ListItemButton sx={{ pl: 4 }}  component={Link} to="/dividends/all">
                        <ListItemIcon>
                            <SearchRounded/>
                        </ListItemIcon>
                        <ListItemText primary="All"/>
                    </ListItemButton>
                </List>
            </Collapse>

            <RolesRetriever groups={ [ 'super', 'admin' ] }>
                <div>
                    <ListItemButton onClick={ handleConfigurationManagerClick }>
                        <ListItemIcon>
                            <SettingsRounded/>
                        </ListItemIcon>
                        <ListItemText primary="Configuration"/>
                        {openMenus.configuration ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={openMenus.configuration} timeout="auto" unmountOnExit>
                        <List component="div">
                            <ListItemButton sx={{ pl: 4 }}  component={Link} to="/assetmanagers">
                                <ListItemIcon>
                                    <FolderCopyRounded/>
                                </ListItemIcon>
                                <ListItemText primary="Asset Managers"/>
                            </ListItemButton>
                            <ListItemButton sx={{ pl: 4 }}  component={Link} to="/dividends/config">
                                <ListItemIcon>
                                    <FolderCopyRounded/>
                                </ListItemIcon>
                                <ListItemText primary="Dividends"/>
                            </ListItemButton>
                        </List>
                    </Collapse>
                </div>
            </RolesRetriever>
            <RolesRetriever groups={ [ 'super' ] }>
                <div>
                    <ListItemButton onClick={ handleAuditClick }>
                        <ListItemIcon>
                            <ViewTimelineRounded/>
                        </ListItemIcon>
                        <ListItemText primary="Audit"/>
                        {openMenus.audit ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={openMenus.audit} timeout="auto" unmountOnExit>
                        <List component="div">
                            <ListItemButton sx={{ pl: 4 }}  component={Link} to="/audit/events">
                                <ListItemIcon>
                                    <EventRounded/>
                                </ListItemIcon>
                                <ListItemText primary="Events"/>
                            </ListItemButton>
                            <ListItemButton sx={{ pl: 4 }}  component={Link} to="/audit/requests">
                                <ListItemIcon>
                                    <EventNoteRounded/>
                                </ListItemIcon>
                                <ListItemText primary="Requests"/>
                            </ListItemButton>
                        </List>
                    </Collapse>
                </div>
            </RolesRetriever>

        </React.Fragment>
    );
}

