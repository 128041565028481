import React, {ReactElement, } from "react";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import Title from "../../components/Title";
import {PageDto} from "../../domain/PageDto";
import {SearchDataFunction} from "../../components/types/SearchDataFunction";
import {useSearchFutureTransactions} from "../../application/FutureTransactionService";
import {FutureTransaction} from "../../domain/transaction/FutureTransaction";
import {TransactionSearchTerm} from "../../domain/transaction/TransactionSearchTerm";
import {Action} from "../../domain/transaction/Action";
import FutureTransactionComponent from "../../components/FutureTransactionComponent";

export default function ActiveFutureTransactionsPage(): ReactElement {

    const searchFn: SearchDataFunction<TransactionSearchTerm, PageDto<FutureTransaction>> = useSearchFutureTransactions({
        assetManager: "",
        counterpart: "",
        fromDate: null,
        instrument: "",
        toDate: null,
        transaction: "",
        states: [Action.ADD, Action.PENDING_STATIC]
    });

    return (
            <Container maxWidth={ false } sx={{ mt: 4, mb: 4 }}>
                { <Toolbar/> }
                <Grid container spacing={2}>
                    <Grid item xs={12} md={11} lg={11}>
                        <Title>Future Transactions in Progress</Title>
                    </Grid>
                    <FutureTransactionComponent searchFn={searchFn} />
                </Grid>
            </Container>
    );
}