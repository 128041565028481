import React, {ReactElement} from "react";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import Title from "../../components/Title";
import IconButton from "@mui/material/IconButton";
import {DownloadForOfflineRounded} from "@mui/icons-material";
import formatDateTime from "../../components/TimeFormat";
import {extractFileOrigin, ProducerServiceProps, useProducerService} from "../../application/ProducerService";
import { GetDataApi } from "../../components/types/GetDataApi";
import {TransactionProcessingResult} from "../../domain/transaction/TransactionProcessingResult";
import {useGetRefusedTransactionById} from "../../application/RefusedTransactionService";
import {RequestDataFunction} from "../../components/types/RequestDataFunction";
import {Tooltip} from "@mui/material";

export default function InboundTransactionPage(): ReactElement {
    const transaction: GetDataApi<TransactionProcessingResult> = useGetRefusedTransactionById();
    const fileService: RequestDataFunction<ProducerServiceProps> = useProducerService();

    if (transaction.loading) {
        return (<></>);
    } else {
        return (
            <Container maxWidth={false} sx={{mt: 4, mb: 4}}>
                {<Toolbar/>}
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={12}>
                        <Title>Blocked Inbound Transaction</Title>
                    </Grid>
                    <Grid container spacing={2} border={1} margin={1}>
                        <Grid item xs={4} md={4} lg={4}>eventTime</Grid>
                        <Grid item xs={8} md={8} lg={8}>{formatDateTime(transaction.data.eventTime)}</Grid>
                        <Grid item xs={4} md={4} lg={4}>Action</Grid>
                        <Grid item xs={8} md={8} lg={8}>{transaction.data.action}</Grid>
                        <Grid item xs={4} md={4} lg={4}>Fault Code</Grid>
                        <Grid item xs={8} md={8} lg={8}>{transaction.data.code}</Grid>
                        <Grid item xs={4} md={4} lg={4}>Transaction Id</Grid>
                        <Grid item xs={8} md={8} lg={8}>{transaction.data.transactionId}</Grid>
                        <Grid item xs={4} md={4} lg={4}>Transaction Type</Grid>
                        <Grid item xs={8} md={8} lg={8}>{transaction.data.transactionType}</Grid>
                        <Grid item xs={4} md={4} lg={4}>Message</Grid>
                        <Grid item xs={8} md={8} lg={8}>{transaction.data.message}</Grid>
                        <Grid item xs={4} md={4} lg={4}>File Name</Grid>
                        <Grid item xs={8} md={8} lg={8}>
                            <Tooltip title={"Download file"} placement={"top"}>
                                <IconButton color="primary" onClick={() => Promise.resolve(fileService.apiFunction({
                                    fileOrigin: extractFileOrigin(transaction.data),
                                    fileName: transaction.data.originalFile
                                }))}>
                                    <DownloadForOfflineRounded/>
                                </IconButton>
                            </Tooltip>
                            {transaction.data.originalFile.substring(transaction.data.originalFile.lastIndexOf('/') + 1)}
                        </Grid>
                        <Grid item xs={4} md={4} lg={4}>File Line</Grid>
                        <Grid item xs={8} md={8} lg={8}>{transaction.data.originalFileLine}</Grid>
                    </Grid>
                </Grid>
            </Container>);
    }
}