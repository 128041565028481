import { RefreshDataFunction } from "./types/RefreshDataFunction";
import {Statistics} from "../domain/staticdata/Statistics";
import Grid from "@mui/material/Grid";
import {Button, Tooltip} from "@mui/material";
import {RefreshRounded} from "@mui/icons-material";
import RolesRetriever from "../authorization/RolesRetreiver";
import React, {useEffect, useState} from "react";
import {SnackbarState} from "./SnackbarComponent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";

export type RefreshStaticComponentParams = {
    refreshFunction: RefreshDataFunction<Statistics>
    dataFunction: () => Promise<void>;
    setSnackbarState: React.Dispatch<React.SetStateAction<SnackbarState>>
    notifyFunction?: null | (() => void);
};


export default function RefreshStaticComponent(params: Readonly<RefreshStaticComponentParams>){
    const [syncActive, setSyncActive] = useState<boolean>(true);
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
            if (params.refreshFunction.data.duration > 0) {
                params.dataFunction().then(() => {
                    params.setSnackbarState({
                        severity: 'info',
                        open: true,
                        message: "Successfully synced " + params.refreshFunction.data.brokers + " brokers " + params.refreshFunction.data.removedBrokers + " obsolete brokers was removed"
                    });
                    setSyncActive(true);
                });
            }
        }
        , [ params.refreshFunction.data ] );

    function syncData() {
        setOpen(true);
    }

    function handleCancel() {
        setOpen(false);
    }

    function handleOk() {
        setSyncActive(false);
        if (params.notifyFunction) {
            params.notifyFunction();
        }
        params.refreshFunction.apiFunction()
            .then(() => setOpen(false));
    }

    return (
        <RolesRetriever groups={ [ "super", "admin"] }>
            <Grid item xs={12} md={2} lg={2}>
                <Tooltip title={"Refresh static data in T-Trade from SCD"} placement={"top"}>
                    <span>
                        <Button variant='outlined'
                                disabled={ ! syncActive }
                                startIcon={ <RefreshRounded/> }
                                sx={{ "whiteSpace": "nowrap" }}
                                onClick={ syncData }>
                            Sync with SCD
                        </Button>
                    </span>
                </Tooltip>
                <Dialog
                    open={open}
                    onClose={handleCancel}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Wait, are you really sure you want to do this, read CAREFULLY below first
                    </DialogTitle>
                    <DialogContent>
                            <p>This function is <strong>NOT</strong>{' '} for checking if data exists or have been imported to to SCD.</p>
                            <p>Using this function to do that will have a performance hit on Simcorp Dimension, so <strong>please do not do that.</strong></p>
                            <p>First check if your static exist in SCD, and if it <strong>does</strong>{}, and you can <strong>NOT</strong>{' '}
                                find it in T-Trade, <strong>Then, and ONLY then, and if you are in a hurry</strong>{' '} you can use this function, since normally a sync will happen in less than
                                six hours anyway.
                            </p>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCancel} autoFocus>Cancel</Button>
                        <Button onClick={handleOk}>OK</Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        </RolesRetriever>
    );
}
