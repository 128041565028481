import * as React from "react";
import RolesRetriever from "../authorization/RolesRetreiver";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Tooltip
} from "@mui/material";
import {useLoadFromDp} from "../application/CashDividendService";
import {SnackbarState} from "./SnackbarComponent";
import dayjs, {Dayjs} from "dayjs";
import {DesktopDatePicker} from "@mui/x-date-pickers";
import {formatDayjsDateString} from "./TimeFormat";

export type DividendDpSyncDialogParam = {
    setSnackbarState: React.Dispatch<React.SetStateAction<SnackbarState>>
}
export default function DividendDpSyncDialog(param: Readonly<DividendDpSyncDialogParam>) {
    const [open, setOpen] = React.useState(false);
    const [exDate, setExDate] = React.useState(dayjs());
    const syncFunction = useLoadFromDp();

    const syncCompleted = (): void => {
        param.setSnackbarState({ open: true, severity: "success", message: "Sent request to Bloomberg for new dividends, any new information should be visible in Pending Dividends in a few minutes"});
    }

    const executeSync = (): void => {
        syncFunction.apiFunction({ completedFunction: syncCompleted, date: exDate });
    }
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleResponseCancel = () => {
        setOpen(false);
    };

    const handleResponseOk = () => {
        setOpen(false);
        executeSync();
    };

    function onChangeToDate(value: Dayjs | null): void {
        if (value) {
            setExDate(value);
        }
    }


    return (
        <div>
            <RolesRetriever groups={ [ "super" ]}>
                <Tooltip title={ "Force Load dividends from Bloomberg now." } placement={"top"}>
                    <Button variant="outlined" onClick={handleClickOpen}>
                        Force Load Bloomberg
                    </Button>
                </Tooltip>
            </RolesRetriever>
            <Dialog open={open} onClose={handleResponseCancel}>
                <DialogTitle>Force Load dividends from Bloomberg</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        This will trigger a request to bloomberg for dividends with exDate on the selected date, and
                        90 days back from that time. This will auto trigger every day at 10:22. Triggering this is only required
                        if the 10:22 import has failed for some reason.
                    </DialogContentText>
                    <Tooltip title={ "Select exDate, inclusive" } placement={ "top" }>
                        <span>
                            <DesktopDatePicker
                                label="To Date"
                                    value={exDate}
                                    format="YYYY-MM-DD"
                                    onChange={onChangeToDate}
                                />
                        </span>
                    </Tooltip>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleResponseCancel}>Cancel</Button>
                    <Button disabled={ formatDayjsDateString(exDate) === null } onClick={ handleResponseOk }>Request Dividends Now</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}