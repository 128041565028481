import {InstrumentBulkResponse} from "./InstrumentBulkResponse";
import {InstrumentType} from "./InstrumentType";

export type DpInstrumentBulkResponse = {
        response: InstrumentBulkResponse[]
        instrumentType: InstrumentType
}

export function emptyDpInstrumentBulkResponse(): DpInstrumentBulkResponse {
        return {
                instrumentType: InstrumentType.EQUITY,
                response: []
        }
}