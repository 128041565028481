
import React, {ReactElement} from "react";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import Title from "../../components/Title";
import {PageDto} from "../../domain/PageDto";
import {SearchDataFunction} from "../../components/types/SearchDataFunction";
import {useSearchAuditRequests} from "../../application/AuditService";
import {AuditRequestSearchTerm} from "../../domain/audit/AuditRequestSearchTerm";
import AuditRequestSearchBar from "./AuditRequestSearchBar";
import {AuditRequest} from "../../domain/audit/AuditRequest";
import AuditRequestComponent from "../../components/AuditRequestComponent";

export default function AuditRequestsPage(): ReactElement {
    const searchFn: SearchDataFunction<AuditRequestSearchTerm, PageDto<AuditRequest>> = useSearchAuditRequests(null);

    return (
        <Container maxWidth={ false } sx={{ mt: 4, mb: 4 }}>
            { <Toolbar/> }
            <Grid container spacing={2}>
                <Grid item xs={12} md={11} lg={11}>
                    <Title>Audit Requests</Title>
                </Grid>
                <AuditRequestSearchBar searchFn={ searchFn }/>
                <AuditRequestComponent searchFn={ searchFn }/>
            </Grid>
        </Container>
    );
}