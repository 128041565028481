import {RefusedTransactionStatus} from "./RefusedTransactionStatus";
import {Dayjs} from 'dayjs';

export type RefusedTransactionSearchTerm = {
    assetManager: string,
    identifier: string,
    transaction: string,
    status: RefusedTransactionStatus | null,
    fromDate: Dayjs | null,
    toDate: Dayjs | null
}

export function defaultRefusedTransactionSearchTerm(): RefusedTransactionSearchTerm {
    return {
        assetManager: '',
        identifier: '',
        transaction: '',
        status: RefusedTransactionStatus.ACTIVE,
        fromDate: null,
        toDate: null,
    } as RefusedTransactionSearchTerm;
}