import axios from "axios";
import {useCallback, useEffect, useState} from "react";
import {AssetManagerConfig, emptyAssetManagerConfig} from "../domain/assetmanager/AssetManagerConfig";
import {GetDataApi} from "../components/types/GetDataApi";
import {useParams} from "react-router-dom";
import {ResponseDataFunction} from "../components/types/ResponseDataFunction";
import {FormatValidationResponse} from "../domain/assetmanager/FormatValidationResponse";
import {FormatValidationRequest} from "../domain/assetmanager/FormatValidationRequest";
import {getGatewayUrl} from "../components/EnvironmentConfiguration";
import {TradeReportItem} from "../domain/assetmanager/TradeReportItem";
import {Dayjs} from "dayjs";
import { RefreshDataFunction } from "../components/types/RefreshDataFunction";
import {RequestDataFunction} from "../components/types/RequestDataFunction";

export type FileUploadData = {
    file: File | null,
    assetManager: string | null
}

export type ReportData = {
    assetManager: string,
    date: Dayjs
}

export function useGetAssetManagerConfigurations(): GetDataApi<AssetManagerConfig[]> {
    const [responseData, setResponseData] = useState<AssetManagerConfig[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
            setLoading(true);
            axios.get(getGatewayUrl() + '/assetmanager/v1')
                .then((response) => {
                    setResponseData(response.data as AssetManagerConfig[])
                })
                .catch((err) => setError(err.message))
                .finally(() => setLoading(false));
        },
        []
    );

    return { data: responseData, loading, error };
}

export function useGetAssetManagerConfiguration(): RefreshDataFunction<AssetManagerConfig> {
    const { assetManagerId } = useParams();
    const [responseData, setResponseData] = useState<AssetManagerConfig>(emptyAssetManagerConfig());
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const getFunction = useCallback(
        async () => {
            setLoading(true);
            axios.get(getGatewayUrl() + '/assetmanager/v1/' + assetManagerId)
                .then((response) => {
                    setResponseData(response.data as AssetManagerConfig)
                })
                .catch((err) => setError(err.message))
                .finally(() => setLoading(false));
        },
        [ assetManagerId ]
    );

    useEffect(() => { getFunction(); },
        [ ]
    );

    return { data: responseData, loading, error, apiFunction: getFunction };
}

export function useCreateAssetManagerDefaultConfiguration(): ResponseDataFunction<string, AssetManagerConfig> {
    const [responseData, setResponseData] = useState<AssetManagerConfig>(emptyAssetManagerConfig());
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const getFunction = useCallback(
        async (name: string) => {
            setLoading(true);
            await axios.get(getGatewayUrl() + '/assetmanager/v1/default/' + name)
                .then((response) => {
                    setResponseData(response.data as AssetManagerConfig)
                })
                .catch((err) => setError(err.message))
                .finally(() => setLoading(false));
        },
        []
    );

    return { data: responseData, loading, error, apiFunction: getFunction };
}

export function useSaveAssetManagerConfiguration(): ResponseDataFunction<AssetManagerConfig, AssetManagerConfig> {
    const [responseData, setResponseData] = useState<AssetManagerConfig>(emptyAssetManagerConfig());
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const getFunction = useCallback(
        async (data: AssetManagerConfig) => {
            setLoading(true);
            const body = JSON.stringify(data);
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            await axios.post(getGatewayUrl() + '/assetmanager/v1', body, config)
                .then((response) => {
                    setResponseData(response.data as AssetManagerConfig)
                })
                .catch((err) => setError(err.message))
                .finally(() => setLoading(false));
        },
        []
    );

    return { data: responseData, loading, error, apiFunction: getFunction };
}

export function useValidateConfiguration(): ResponseDataFunction<FormatValidationRequest, FormatValidationResponse[]> {

    const [responseData, setResponseData] = useState<FormatValidationResponse[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const apiFunction = useCallback(
        async (data: FormatValidationRequest) => {
            setLoading(true);
            const bodyFormData = new FormData();
            bodyFormData.append('file', data.file);
            await axios.post(getGatewayUrl() + '/assetmanager/v1/validate/' + data.assetManager,
                                    bodyFormData,
                                    { headers: { "Content-Type": "multipart/form-data" }})
                .then((response) => {
                    setResponseData(response.data as FormatValidationResponse[])
                })
                .catch((err) => setError(err.message))
                .finally(() => setLoading(false));
        },
        []
    );

    return { data: responseData, loading, error, apiFunction };
}

export function useManualFileUpload(): ResponseDataFunction<FileUploadData, string> {

    const [responseData, setResponseData] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const apiFunction = useCallback(
        async (data: FileUploadData) => {
            setLoading(true);
            const bodyFormData = new FormData();
            bodyFormData.append('file', data.file as File);
            await axios.post(getGatewayUrl() + '/transaction/v1/' + data.assetManager + "/upload",
                bodyFormData,
                { headers: { "Content-Type": "multipart/form-data" }})
                .then(() => {
                    setResponseData('Upload Successful');
                })
                .catch((err) => setError(err.message))
                .finally(() => setLoading(false));
        },
        []
    );

    return { data: responseData, loading, error, apiFunction };
}

export function useDownloadReport(): ResponseDataFunction<ReportData, TradeReportItem[]> {
    const [responseData, setResponseData] = useState<TradeReportItem[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const apiFunction = useCallback(
        async (data: ReportData) => {
            setLoading(true);
            await axios.get(getGatewayUrl() + '/assetmanager/v1/report/' + data.assetManager + "/" + data.date.format("YYYY-MM-DD"))
                .then((response) => {
                    setResponseData(response.data as TradeReportItem[]);
                })
                .catch((err) => setError(err.message))
                .finally(() => setLoading(false));
        },
        []
    );

    return { data: responseData, loading, error, apiFunction };
}

export function useUploadReport(): RequestDataFunction<ReportData> {

    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const apiFunction = useCallback(
        async (data: ReportData) => {
            setLoading(true);
            await axios.get(getGatewayUrl() + '/assetmanager/v1/report/upload/' + data.assetManager + "/" + data.date.format("YYYY-MM-DD"))
                .catch((err) => setError(err.message))
                .finally(() => setLoading(false));
        },
        []
    );

    return { loading, error, apiFunction };
}


