
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Tooltip
} from "@mui/material";
import * as React from "react";
import {
  TtradeTransactionUpdateRequest,
  useTransactionDequeue
} from "../application/TransactionUpdateService";
import {RequestDataFunction} from "./types/RequestDataFunction";
import {TransactionType} from "../domain/transaction/TransactionType";
import IconButton from "@mui/material/IconButton";
import {CancelRounded} from "@mui/icons-material";
import {SnackbarState} from "./SnackbarComponent";

export type DequeueTransactionDialogProps = {
  transaction: string,
  type: TransactionType,
  setSnackbarState: React.Dispatch<React.SetStateAction<SnackbarState>>,
  completedFunction: () => void
}
export default function DequeueTransactionDialog(props: Readonly<DequeueTransactionDialogProps>) {
  const [open, setOpen] = React.useState(false);
  const [text, setText] = React.useState('');
  const dequeueFunction: RequestDataFunction<TtradeTransactionUpdateRequest> = useTransactionDequeue();

  const dequeueTransaction = async (message: string): Promise<void> => {
    await dequeueFunction.apiFunction({type: props.type, transaction: props.transaction, message: {message: message}, completedFunction: props.completedFunction})
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleResponseCancel = () => {
    setOpen(false);
  };

  const handleResponseOk = () => {
    dequeueTransaction(text)
        .then(() => {
          setOpen(false);
          props.setSnackbarState({ message: "Updates will be visible after a few seconds when you refresh this page", open: true, severity: "success" });
        })
        .catch(() => props.setSnackbarState({ message: "Refresh failed", open: true, severity: 'error'}));
  };

  function handleText(e: React.ChangeEvent<HTMLInputElement>) {
     setText(e.target.value);
  }

  return (
    <React.Fragment>
      <Tooltip title={"Ignore all QUEUED updates for this transaction, i.e. do not send them to SCD"} placement={"top"}>
        <IconButton color="warning" onClick={ handleClickOpen } disabled= { dequeueFunction.loading } >
          <CancelRounded/>
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleResponseCancel}>
        <DialogTitle>Skip all Transaction updates in QUEUED state</DialogTitle>
        <DialogContent>
            <p> This will skip all Transaction updates in QUEUED state for T-Trade transaction {props.transaction}.</p>
            <p> This means that T-Trade will mark these as COMPLETED/CANCELLED and not monitor state in SCD anymore for these updates,
              and the updates not yet sent do MsgQueue will never be sent there by T-Trade. </p>

            <p>These updates will remain in the T-Trade UI, but in state CANCELLED, to mark that these operations were manually skipped.</p>

            <p>The asset manager can still send updates/fixes after you have done this. This is just to force T-Trade to skip
            these actions.</p>

            <strong> Please enter the reason why you cancel these actions, text entered will be visible in T-Trade UI. </strong>
          <TextField
            autoFocus
            margin="dense"
            id="reason"
            label="Reason for Ignoring QUEUED Transaction Updates"
            fullWidth
            variant="standard"
            value={ text }
            onChange={ handleText }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleResponseCancel}>Cancel</Button>
          <Button disabled={ ! text || text.length === 0 || text.length > 200 } onClick={handleResponseOk}>Skip Transaction Updates</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}