import React, {ReactElement} from "react";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import CashDividendPage from "./CashDividendPage";

export default function InstrumentCashDividend(): ReactElement {

    return (
            <Container maxWidth={ false } sx={{ mt: 4, mb: 4 }}>
                { <Toolbar/> }
                <CashDividendPage/>
            </Container>
    );
}
