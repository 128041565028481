import {useGridApiRef} from "@mui/x-data-grid";
import {GridApiPremium} from "@mui/x-data-grid-premium/models/gridApiPremium";
import {GridAutosizeOptions} from "@mui/x-data-grid-premium";
import {useEffect} from "react";

export type AutoSizeGridParams<T> = {
    data: T[],
    loading: boolean,
    outliersFactor?: number
    includeOutliers?: boolean
    expand?: boolean
    columns: string[]
};

export function useAutoSizeGrid<T>(params: AutoSizeGridParams<T>) {

    const apiRef = useGridApiRef<GridApiPremium>();

    const autoSizeOptions:GridAutosizeOptions = {
        columns: params.columns,
        includeOutliers: params.includeOutliers ?? false,
        includeHeaders: true,
        expand: params.expand ?? true,
        outliersFactor: params.outliersFactor ?? 2.0
    };

    useEffect(() => {
        if (! params.loading && params.data && params.data.length > 0) {
            apiRef.current.autosizeColumns(autoSizeOptions);
        }
    }, [ params.loading, params.data ] )

    return apiRef;
}

