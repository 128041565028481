import {Dayjs} from "dayjs";


export default function formatDateTime(dateTime: string) {
    if (dateTime) {
        const date = new Date(dateTime + "Z");
        return new Intl.DateTimeFormat("sv-SE", {dateStyle: 'short', timeStyle: 'medium'}).format(date);
    }
    return '';
}

export function formatDate(dateTime: string) {
    if (dateTime) {
        const date = new Date(dateTime + "Z");
        return new Intl.DateTimeFormat("sv-SE", {dateStyle: 'short'}).format(date);
    }
    return '';
}

export function formatSwedishDateTime(dateTime: string) {
    if (dateTime) {
        const date = new Date(dateTime);
        return new Intl.DateTimeFormat("sv-SE", {dateStyle: 'short', timeStyle: 'medium'}).format(date);
    }
    return '';
}


export function formatDateTimeShort(dateTime: string) {
    if (dateTime) {
        const date = new Date(dateTime + "Z");
        return new Intl.DateTimeFormat("sv-SE", {dateStyle: 'short', timeStyle: 'short'}).format(date);
    }
    return '';
}

export function formatDayjsDateTimeString(date: Dayjs| null): string | null {
    if (!date) {
        return null;
    }
    const format = date.format("YYYY-MM-DDTHH:mm:ss");
    if (format.startsWith("Invalid")) {
        return null;
    }
    return format;
}

export function formatDayjsDateString(date: Dayjs| null): string | null {
    if (!date) {
        return null;
    }
    const format = date.format("YYYY-MM-DD");
    if (format.startsWith("Invalid")) {
        return null;
    }
    return format;
}

