
import React, {ReactElement, useState} from "react";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import Title from "../../components/Title";
import {Button, Tooltip} from "@mui/material";
import {UploadRounded} from "@mui/icons-material";
import RolesRetriever from "../../authorization/RolesRetreiver";
import {
    ReportData,
    useUploadReport
} from "../../application/AssetManagerService";
import dayjs, {Dayjs} from "dayjs";
import {DatePicker} from "@mui/x-date-pickers";
import {RequestDataFunction} from "../../components/types/RequestDataFunction";

export type AssetManagerReportProps = {
    assetManager: string
}

export default function AssetManagerReport(params: Readonly<AssetManagerReportProps>): ReactElement {
    const uploadReportApi:RequestDataFunction<ReportData> = useUploadReport();
    const [reportDate, setReportDate] = useState<Dayjs | null>(null);

    const handleReportDate = (value: Dayjs | null) => {
        setReportDate(value);
    }

    const isReportNotAvailable = () => {
        if (reportDate) {
            const today = dayjs().hour(23).minute(59).second(59).millisecond(999);
            if (reportDate.isBefore(today)) {
                return false;
            }
        }
        return true;
    }

    const uploadReport = () => {
        if (reportDate) {
            uploadReportApi.apiFunction({
                assetManager: params.assetManager,
                date: reportDate
            });
        }
    }

    return (
        <RolesRetriever groups={["super"]}>
            <div>
                {<Toolbar/>}
                <Grid container
                      spacing={2}
                      paddingLeft={2}
                      display="flex"
                      alignItems={"center"}
                      justifyItems={"center"}
                      sx={{
                          borderRadius: '12px',
                          border: 1,
                          borderColor: "primary.main",
                          padding: 1,
                          marginTop: "12px",
                          marginLeft: "2px"
                      }}
                >
                    <Grid item xs={12} md={12} lg={12}>
                        <Title>Report Generation</Title>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <DatePicker
                            label="Report Date"
                            value={reportDate}
                            onChange={(newValue) => handleReportDate(newValue)}
                            format="YYYY-MM-DD"
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <Tooltip title={"Will send report to Asset Manager"} placement={"top"}>
                            <span>
                                <Button variant="outlined"
                                        startIcon={<UploadRounded/>}
                                        disabled={isReportNotAvailable()}
                                        onClick={() => {
                                            uploadReport();
                                        }}>
                                    Generate External Report
                                </Button>
                            </span>
                        </Tooltip>
                    </Grid>
                </Grid>
            </div>
        </RolesRetriever>
    );
}
