import {RefusedTransactionStatus} from "../domain/transaction/RefusedTransactionStatus";
import {RefusedTransactionSearchTerm} from "../domain/transaction/RefusedTransactionSearchTerm";
import {formatDayjsDateString} from "../components/TimeFormat";

export type RefusedTransactionSearchTermDto = {
    assetManager: string,
    identifier: string,
    transaction: string,
    status: RefusedTransactionStatus | null,
    fromDate: string | null,
    toDate: string | null
}

export function convertRefusedTransactionSearchTerm(searchTerm: RefusedTransactionSearchTerm): RefusedTransactionSearchTermDto {
    return {
        assetManager: searchTerm.assetManager,
        identifier: searchTerm.identifier,
        transaction: searchTerm.transaction,
        status: searchTerm.status,
        fromDate: formatDayjsDateString(searchTerm.fromDate),
        toDate: formatDayjsDateString(searchTerm.toDate),
    }
}

