import axios from "axios";
import {ImsTransactionImportStatus} from "../domain/transaction/ImsTransactionImportStatus";
import {useCallback, useEffect, useState} from "react";
import {TransactionProgressProps} from "../pages/transaction/TransactionProgressPage";
import {RequestDataFunction} from "../components/types/RequestDataFunction";
import {RefreshDataFunction} from "../components/types/RefreshDataFunction";
import {emptyPageDto, PageDto} from "../domain/PageDto";
import {SearchDataFunction} from "../components/types/SearchDataFunction";
import {allUiTtradeStates, UiStateInfo} from "../components/types/UiStateInfo";
import {TransactionType} from "../domain/transaction/TransactionType";
import {getGatewayUrl} from "../components/EnvironmentConfiguration";
import {ImsTransactionUpdateRequest} from "../domain/transaction/ImsTransactionUpdateRequest";
import {ImsUpdateState} from "../domain/transaction/ImsUpdateState";
import {DelayedTransactionSearchTerm} from "../domain/transaction/DelayedTransactionSearchTerm";
import {DelayedTransaction} from "../domain/transaction/DelayedTransaction";

export type TtradeTransactionState = {
    state: string,
    title: string
}

export type TtradeTransctionReactivateRequest = {
    transaction: string,
    type: TransactionType
    completedFunction: () => void
}

export type TtradeTransactionUpdateRequest = {
    transaction: string,
    type: TransactionType,
    message: ImsTransactionUpdateRequest
    completedFunction: () => void
}

export type TtradeTransactionResetRequest = {
    ids: string[],
    completedFunction: () => void
}

export function useGetTransactionUpdates(props: TransactionProgressProps): RefreshDataFunction<PageDto<ImsTransactionImportStatus>> {
    const [progress, setProgress] = useState<PageDto<ImsTransactionImportStatus>>(emptyPageDto());
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const apiFunction = useCallback(async () => {
        setLoading(true);
        axios.get(getGatewayUrl() + '/transaction/v1/update/' + props.transactionType + '/' + props.transactionId)
            .then((response) => {
                setProgress(response.data as PageDto<ImsTransactionImportStatus>);
            })
            .catch((err) => setError(err.message))
            .finally(() => setLoading(false));
    }, [])

    useEffect(() => {
        apiFunction();
    }, [  props ]);

    return { data: progress, loading, error, apiFunction }
}

export function useGetTransactionUpdatesByTTradeState(): SearchDataFunction<UiStateInfo[], PageDto<ImsTransactionImportStatus>> {
    const [progress, setProgress] = useState<PageDto<ImsTransactionImportStatus>>(emptyPageDto);
    const [tStates, setTStates] = useState<UiStateInfo[]> ([allUiTtradeStates[3], allUiTtradeStates[4]]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const setupLocalFlags = (data: PageDto<ImsTransactionImportStatus>): void => {
        data.data.forEach((s) => {
            if (s.imsState === ImsUpdateState.TIMEOUT || s.imsState === ImsUpdateState.ERROR || s.imsState === ImsUpdateState.QUEUED) {
                if (!data.data.find((i) =>
                    i.transactionId === s.transactionId
                    && i.transactionType === s.transactionType
                    && i.imsState === s.imsState
                    && i.id < s.id)) {
                    s.isFirstIssue = true;
                }
            }
        });
    }

    const apiFunction = useCallback(async () => {
        setLoading(true);
        const body = JSON.stringify(tStates.map(s => s.state));
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        await axios.post(getGatewayUrl() + '/transaction/v1/update/ttradeState', body, config)
            .then((response) => {
                setupLocalFlags(response.data);
                setProgress(response.data as PageDto<ImsTransactionImportStatus>);
            })
            .catch((err) => setError(err.message))
            .finally(() => setLoading(false));
    }, [ tStates ]);

    useEffect(() => {
        apiFunction();
    }, [ tStates ]);

    return { data: progress, searchTerm: tStates, loading, error, apiFunction, setSearchTermFunction: setTStates }
}

export function useTransactionResetTimeoutStates(): RequestDataFunction<TtradeTransactionResetRequest> {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const apiFunction = useCallback(
        async (data: TtradeTransactionResetRequest) => {
            setLoading(true);
            const body = JSON.stringify(data.ids);
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            axios.post(getGatewayUrl() + '/transaction/v1/update/clearTimeout', body, config)
                .then(() => {
                    data.completedFunction();
                })
                .catch((err) => setError(err.message))
                .finally(() => setLoading(false));
        },
        []
    );

    return {loading, error, apiFunction};
}

export function useTransactionReactivate(): RequestDataFunction<TtradeTransctionReactivateRequest> {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const apiFunction = useCallback(
        async (data: TtradeTransctionReactivateRequest) => {
            setLoading(true);
            const body = JSON.stringify([ data.transaction ] );
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            axios.post(getGatewayUrl() + '/transaction/v1/update/reactivate/' + data.type.toString(), body, config)
                .then(() => {
                    data.completedFunction();
                })
                .catch((err) => setError(err.message))
                .finally(() => setLoading(false));
        },
        []
    );

    return {loading, error, apiFunction};
}

export function useTransactionCancel(): RequestDataFunction<TtradeTransactionUpdateRequest> {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const apiFunction = useCallback(
        async (data: TtradeTransactionUpdateRequest) => {
            setLoading(true);
            const body = JSON.stringify(data.message);
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            axios.post(getGatewayUrl() + '/transaction/v1/update/cancel/' + data.type.toString() + "/" + data.transaction, body, config)
                .then(() => {
                    data.completedFunction();
                })
                .catch((err) => setError(err.message))
                .finally(() => setLoading(false));
        },
        []
    );
    return {loading, error, apiFunction};
}

export function useTransactionDequeue(): RequestDataFunction<TtradeTransactionUpdateRequest> {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const apiFunction = useCallback(
        async (data: TtradeTransactionUpdateRequest) => {
            setLoading(true);
            const body = JSON.stringify(data.message);
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            axios.post(getGatewayUrl() + '/transaction/v1/update/dequeue/' + data.type.toString() + "/" + data.transaction, body, config)
                .then(() => {
                    data.completedFunction();
                })
                .catch((err) => setError(err.message))
                .finally(() => setLoading(false));
        },
        []
    );

    return {loading, error, apiFunction};
}

export function useTransactionResend(): RequestDataFunction<TtradeTransactionUpdateRequest> {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const apiFunction = useCallback(
        async (data: TtradeTransactionUpdateRequest) => {
            setLoading(true);
            const body = JSON.stringify(data.message);
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            axios.post(getGatewayUrl() + '/transaction/v1/update/resend/' + data.type.toString() + "/" + data.transaction, body, config)
                .then(() => {
                    data.completedFunction();
                })
                .catch((err) => setError(err.message))
                .finally(() => setLoading(false));
        },
        []
    );

    return {loading, error, apiFunction};
}

export function useSearchDelayedTransactions(initial: DelayedTransactionSearchTerm): SearchDataFunction<DelayedTransactionSearchTerm, PageDto<DelayedTransaction>> {
    const [data, setData] = useState<PageDto<DelayedTransaction>>(emptyPageDto);
    const [searchTerm, setSearchTerm] = useState<DelayedTransactionSearchTerm> (initial);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const apiFunction = useCallback(async () => {
        setLoading(true);
        const body = JSON.stringify(searchTerm);
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        await axios.post(getGatewayUrl() + '/transaction/v1/update/delayed', body, config)
            .then((response) => {
                setData(response.data as PageDto<DelayedTransaction>);
            })
            .catch((err) => setError(err.message))
            .finally(() => setLoading(false));
    }, [ searchTerm ]);

    useEffect(() => {
        apiFunction();
    }, [ searchTerm ]);

    return { data: data, searchTerm: searchTerm, loading, error, apiFunction, setSearchTermFunction: setSearchTerm }
}