import React, {ReactElement} from "react";
import {
    Button,
    FormControl,
    Menu,
    MenuItem
} from "@mui/material";
import {optionalSupportedFields, SupportedFields} from "../domain/assetmanager/SupportedFields";

export type FieldSelectComponentParams = {
    action: string,
    apiFunction: (a: SupportedFields) => void
}

export default function FieldSelectComponent(params: Readonly<FieldSelectComponentParams>): ReactElement {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    }

    const handleChange = (event: SupportedFields) => {
        params.apiFunction(event);
        handleClose();
    };

    return (
        <FormControl sx={{ m: 1, minWidth: 200 }}>
                <Button
                    variant='outlined'
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                >
                    { params.action }
                </Button>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    { optionalSupportedFields().map((e: SupportedFields) =>
                        <MenuItem
                            key={e}
                            value={e}
                            onClick={ () => handleChange(e) }>
                            {e}
                        </MenuItem>
                    )}
                </Menu>
        </FormControl>
    );
}