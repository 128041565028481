
export type FormatValidationRequest = {
    assetManager: string,
    file: File
}

export function toFormatValidationRequest(assetManager: string, file: File): FormatValidationRequest {
    return {
        file: file,
        assetManager: assetManager,
    } as FormatValidationRequest;
}