import React, {ReactElement} from "react";
import {CashDividend} from "../domain/dividend/CashDividend";
import {PageDto} from "../domain/PageDto";
import { SearchDataFunction } from "./types/SearchDataFunction";
import CashDividendHistoryComponent from "./CashDividendHistoryComponent";

export type CashDividendsSearchComponentParams<T> = {
    apiFunction: SearchDataFunction<T, PageDto<CashDividend>>
}


export default function CashDividendsSearchComponent<T>(params: Readonly<CashDividendsSearchComponentParams<T>>): ReactElement {

    return (
            <CashDividendHistoryComponent
                dividends={ params.apiFunction.data }
                loading={ params.apiFunction.loading }
                searchFunction={ () => params.apiFunction.apiFunction(params.apiFunction.searchTerm) }/>
    )
}