import React, {ReactElement, useState} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {Checkbox, FormControlLabel, TextField} from "@mui/material";
import {AssetManagerFileConfig} from "../../domain/assetmanager/AssetManagerFileConfig";

export type AssetManagerValidationRuleProps = {
    configuration: AssetManagerFileConfig,
    index: number,
}
export default function AssetManagerValidationRule(props: Readonly<AssetManagerValidationRuleProps>): ReactElement {
    const [active, setActive] = useState(props.configuration.validationRules[props.index].active);
    const [validationData, setValidationData] = useState(props.configuration.validationRules[props.index].validationData);

    function setConfigActive(): void {
        props.configuration.validationRules[props.index].active = ! props.configuration.validationRules[props.index].active;
        setActive(props.configuration.validationRules[props.index].active);
    }

    function setConfigValidationData(event: React.ChangeEvent<HTMLInputElement>): void {
        props.configuration.validationRules[props.index].validationData = event.target.value;
        setValidationData(props.configuration.validationRules[props.index].validationData);
    }

    return (
            <Grid container
                  spacing={2}
                  display="flex"
                  alignItems="center"
                  sx = {{ borderRadius: '12px', border: 1, borderColor:"primary.main", padding: 1, marginTop: "12px", marginLeft: "2px" }}>
                <Grid item xs={12} md={4} lg={4}>
                    <Typography component="span" variant="body1" color="black">
                        { props.configuration.validationRules[props.index].rule }
                    </Typography>
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                    <FormControlLabel control={
                        <Checkbox checked={ active } onChange={ setConfigActive }></Checkbox>
                    } label="Enabled"/>
                </Grid>
                <Grid item xs={12} md={5} lg={5}>
                    <TextField
                        label="Validation Data, rule specific"
                        id="outlined-select-extension"
                        value={ validationData }
                        onChange={ setConfigValidationData }
                    />
                </Grid>
            </Grid>
    );
}