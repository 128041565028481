import React, {ReactElement, useEffect, useState} from "react";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import Title from "../../components/Title";
import Box from "@mui/material/Box";
import TabList from "@mui/lab/TabList";
import {Button, Checkbox, FormControlLabel, Tab} from "@mui/material";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import { SaveRounded } from "@mui/icons-material";
import RolesRetriever from "../../authorization/RolesRetreiver";
import AssetManagerTxConfiguration from "./AssetManagerTxConfiguration";
import {
    useGetAssetManagerConfiguration,
    useSaveAssetManagerConfiguration,
} from "../../application/AssetManagerService";
import {AssetManagerConfig} from "../../domain/assetmanager/AssetManagerConfig";
import {ResponseDataFunction} from "../../components/types/ResponseDataFunction";
import {TransactionType} from "../../domain/transaction/TransactionType";
import SnackbarComponent, {defaultSnackbarState, SnackbarState} from "../../components/SnackbarComponent";
import {LocalizationProvider, TimeField} from "@mui/x-date-pickers";
import dayjs, {Dayjs} from "dayjs";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {RefreshDataFunction} from "../../components/types/RefreshDataFunction";

export default function AssetManagerConfiguration(): ReactElement {
    const assetManagerConfig: RefreshDataFunction<AssetManagerConfig> = useGetAssetManagerConfiguration();
    const saveAssetManagerFunction: ResponseDataFunction<AssetManagerConfig, AssetManagerConfig> = useSaveAssetManagerConfiguration();
    const [snackbarState, setSnackbarState] = useState<SnackbarState>(defaultSnackbarState);
    const [selectedTab, setSelectedTab] = useState<string>('1');
    const [logicApp, setLogicApp] = useState<boolean>(false);
    const [generateNackFile, setGenerateNackFile] = useState<boolean>(false);
    const [reportDateOffset, setReportDateOffset] = useState<boolean>(false);
    const [reportTime, setReportTime] = useState<Dayjs|null>(null);
    const [delayTime, setDelayTime] = useState<Dayjs|null>(null);


    let saveInProgress = false;

    useEffect(() => {
        setLogicApp(assetManagerConfig.data.logicAppEnabled);
        setGenerateNackFile(assetManagerConfig.data.addNackFile);
        if (assetManagerConfig.data.reportTime) {
            const day = dayjs(assetManagerConfig.data.reportTime, "HH:mm");
            setReportTime(day);
        }
        if (assetManagerConfig.data.delayTime) {
            const day = dayjs(assetManagerConfig.data.delayTime, "HH:mm");
            setDelayTime(day);
        }
        setReportDateOffset(assetManagerConfig.data.reportDateOffset === -1);
    }, [ assetManagerConfig.data ]);

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setSelectedTab(newValue);
    };

    function updateReportTime(reportTime: Dayjs | null) {
        if (reportTime) {
            const time = reportTime.format("HH:mm");
            if (time.startsWith("Invalid")) {
                assetManagerConfig.data.reportTime = null;
                setReportTime(null);
            } else {
                assetManagerConfig.data.reportTime = time;
                setReportTime(reportTime);
            }
        } else {
            assetManagerConfig.data.reportTime = null;
        }
    }

    function updateDelayTime(delayTime: Dayjs | null) {
        if (delayTime) {
            const time = delayTime.format("HH:mm");
            if (! time.startsWith("Invalid")) {
                assetManagerConfig.data.delayTime = time;
                setDelayTime(delayTime);
            }
        }
    }

    const saveConfiguration = () => {
        saveInProgress = true;
        saveAssetManagerFunction.apiFunction(assetManagerConfig.data)
            .then(() => {
                if (saveAssetManagerFunction.error) {
                    setSnackbarState({ open: true, message: "Save failed: " + saveAssetManagerFunction.error, severity: 'error' });
                } else {
                    setSnackbarState({ open: true, message: "Save successful", severity: "success" } );
                }
            })
            .finally(() => {
                saveInProgress = false;
                assetManagerConfig.apiFunction();
            });
    }

    const handleLogicApp = (event: React.ChangeEvent<HTMLInputElement>) => {
        assetManagerConfig.data.logicAppEnabled = event.target.checked;
        setLogicApp(event.target.checked);
    }

    const handleGenerateNackFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        assetManagerConfig.data.addNackFile = event.target.checked;
        setGenerateNackFile(event.target.checked);
    }

    const handleReportDateOffset = (event: React.ChangeEvent<HTMLInputElement>) => {
        assetManagerConfig.data.reportDateOffset = event.target.checked ? -1 : 0;
        setReportDateOffset(event.target.checked);
    }

    if (assetManagerConfig.loading) {
        return (
            <Container maxWidth={false} sx={{mt: 4, mb: 4}}>
                {<Toolbar/>}
                <Title>Loading ....</Title>
            </Container>);
    } else {
        return (
            <RolesRetriever groups = {[ "super" ] }>
                <Container maxWidth={false} sx={{mt: 4, mb: 4}}>
                    {<Toolbar/>}
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={9} lg={9}>
                            <Title>Asset Manager: {assetManagerConfig.data.name} Configuration</Title>
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                            <RolesRetriever groups={ [ "super" ]}>
                                <Button variant='outlined'
                                        startIcon={<SaveRounded/>}
                                        disabled={saveInProgress}
                                        onClick={() => { saveConfiguration(); }}>
                                    Save Configuration
                                </Button>
                            </RolesRetriever>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <FormControlLabel control={
                                <Checkbox
                                    checked={ logicApp }
                                    onChange={ handleLogicApp }
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            } label="Enable SFTP Area (Logic App)" />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <FormControlLabel control={
                                <Checkbox
                                    checked={ generateNackFile }
                                    onChange={ handleGenerateNackFile }
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            } label="Generate NACK File" />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <FormControlLabel control={
                                    <TimeField
                                        label="UTC Time"
                                        value={reportTime}
                                        onChange={(newValue) => updateReportTime(newValue)}
                                        format="HH:mm"
                                        sx={{ paddingRight: '6px' }}
                                    />
                                } label="Trade Report Generate Time" />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <FormControlLabel control={
                                <Checkbox
                                    checked={ reportDateOffset }
                                    onChange={ handleReportDateOffset }
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            } label="Trade Report For Yesterday" />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <FormControlLabel control={
                                    <TimeField
                                        label="Delay Time"
                                        value={delayTime}
                                        onChange={(newValue) => updateDelayTime(newValue)}
                                        format="HH:mm"
                                        sx={{ paddingRight: '6px' }}
                                    />
                                } label="Trade Delayed Transaction Time" />
                            </LocalizationProvider>
                        </Grid>

                        <Grid item xs={12} md={12} lg={12}>
                            <TabContext value={selectedTab}>
                                <Box sx={{borderBottom: 1, borderColor: 'primary.main'}}>
                                    <TabList onChange={handleTabChange} aria-label="tabs for configs">
                                        <Tab label="Equities" value="1"/>
                                        <Tab label="Futures" value="2"/>
                                        <Tab label="FX" value="3"/>
                                    </TabList>
                                </Box>
                                <TabPanel value="1">
                                    <AssetManagerTxConfiguration title={'Equities'}
                                                                 type={TransactionType.EQ}
                                                                 configuration={ assetManagerConfig.data.assetConfiguration.EQ }
                                                                 assetManager={ assetManagerConfig.data.name }
                                    />
                                </TabPanel>
                                <TabPanel value="2">
                                    <AssetManagerTxConfiguration title={'Futures'}
                                                                 type={TransactionType.FU}
                                                                 configuration={ assetManagerConfig.data.assetConfiguration.FU }
                                                                 assetManager={ assetManagerConfig.data.name }
                                    />
                                </TabPanel>
                                <TabPanel value="3">
                                    <AssetManagerTxConfiguration title={'FX'}
                                                                 type={TransactionType.FX}
                                                                 configuration={ assetManagerConfig.data.assetConfiguration.FX }
                                                                 assetManager={ assetManagerConfig.data.name }
                                    />
                                </TabPanel>
                            </TabContext>
                        </Grid>
                    </Grid>
                    <SnackbarComponent state={ snackbarState } setState={ setSnackbarState }/>
                </Container>
            </RolesRetriever>
        );
    }
}
