
import { Dayjs } from 'dayjs';
import {Action} from "./Action";


export type TransactionSearchTerm = {
    assetManager: string,
    counterpart: string,
    instrument: string,
    transaction: string,
    states: Action[],
    fromDate: Dayjs | null,
    toDate: Dayjs | null
}

export function defaultTransactionSearchTerm(): TransactionSearchTerm {
    return {
        assetManager: '',
        counterpart: '',
        instrument: '',
        transaction: '',
        states: [],
        fromDate: null,
        toDate: null,
    } as TransactionSearchTerm;
}