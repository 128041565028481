import React, {ReactElement, useState} from "react";
import {DataGridPremium, GridColDef, GridRenderCellParams, GridToolbar} from "@mui/x-data-grid-premium";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import Title from "../../components/Title";
import {useUploadInstruments} from "../../application/DataProviderService";
import {DpInstrumentBulkRequest, toDpInstrumentImportRequest} from "../../domain/dataprovider/DpInstrumentBulkRequest";
import {DpInstrumentBulkResponse} from "../../domain/dataprovider/DpInstrumentBulkResponse";
import {ResponseDataFunction} from "../../components/types/ResponseDataFunction";
import {InstrumentBulkResponse} from "../../domain/dataprovider/InstrumentBulkResponse";
import {Button, MenuItem, TextField} from "@mui/material";
import {InstrumentType} from "../../domain/dataprovider/InstrumentType";
import {IdentifierType} from "../../domain/dataprovider/IdentifierType";
import {UploadFileRounded} from "@mui/icons-material";
import SnackbarComponent, {defaultSnackbarState, SnackbarState} from "../../components/SnackbarComponent";
import RolesRetriever from "../../authorization/RolesRetreiver";
import {GridInitialStatePremium} from "@mui/x-data-grid-premium/models/gridStatePremium";
import {useAutoSizeGrid} from "../../components/AutoSizeGridHook";

export default function InstrumentImport(): ReactElement {
    const importFunction: ResponseDataFunction<DpInstrumentBulkRequest, DpInstrumentBulkResponse> = useUploadInstruments();
    const [snackbarState, setSnackbarState] = useState<SnackbarState>(defaultSnackbarState);
    const [ instrumentType, setInstrumentType ] = useState<InstrumentType>(InstrumentType.EQUITY);
    const [ identifierType, setIdentifierType ] = useState<IdentifierType>(IdentifierType.SEDOL);
    const [ file, setFile] = useState<File | null>(null);
    const apiRef = useAutoSizeGrid<InstrumentBulkResponse>({
        data: importFunction.data.response,
        loading: importFunction.loading,
        columns: [
            'instrumentId',
            'identifierType',
            'response',
            'message'
        ]
    });

    const initialState: GridInitialStatePremium =
        {
            columns: {
                columnVisibilityModel: {
                },
                dimensions: {
                    instrumentId: { width: 200 },
                    identifierType: { width: 120 },
                    response: { width: 200 },
                    message: { flex: 1 }
                }
            },
            pagination: {
                paginationModel: {
                    pageSize: 100
                }
            }
        }

    const columns: GridColDef<InstrumentBulkResponse>[] = [
        { field: 'instrumentId', headerName: 'Instrument Id', renderCell: (params: GridRenderCellParams<InstrumentBulkResponse>) =>
            <>{ params.row.identifier.instrumentId }</>
        },
        { field: 'identifierType', headerName: 'Identifier Type', renderCell: (params: GridRenderCellParams<InstrumentBulkResponse>) =>
            <>{ params.row.identifier.identifierType }</>
        },
        { field: 'response', headerName: 'Response', renderCell: (params: GridRenderCellParams<InstrumentBulkResponse>) =>
            <>{ params.row.state }</>
        },
        { field: 'message', headerName: 'Message', renderCell: (params: GridRenderCellParams<InstrumentBulkResponse>) =>
            <>{ params.row.message }</>
        }
    ];

    function setConfigInstrumentType(event: React.ChangeEvent<HTMLInputElement>) {
        setInstrumentType(event.target.value as InstrumentType);
    }

    function setConfigIdentifierType(event: React.ChangeEvent<HTMLInputElement>) {
        setIdentifierType(event.target.value as IdentifierType);
    }

    const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            setFile(e.target.files[0]);
        }
    }

    const doImportInstruments = () => {
        if (file) {
            const dpInstrumentImportRequest = toDpInstrumentImportRequest(instrumentType, identifierType, file);
            importFunction.apiFunction(dpInstrumentImportRequest)
                .then(() => {
                    if (importFunction.error) {
                        setSnackbarState({ message: "Import failed: " + importFunction.error, open: true , severity: 'error' });
                    }
                });
        }
    };

    return (
        <React.Fragment>
            <Container maxWidth={false} sx={{mt: 4, mb: 4}}>
                {<Toolbar/>}
                <Grid container spacing={2} alignItems={ 'center' } justifyItems={ 'center' }>
                    <Grid item xs={12} md={12} lg={12}>
                        <Title>Instrument Bulk Import</Title>
                        <p>
                            Here you import instruments:
                        </p>
                        <ul>
                            <li>select type of instrument</li>
                            <li> select what type of identifier you are using in the file, only one type allowed per file</li>
                            <li> Then upload a file containing one identifier per row, easiest is to create a one column excel and export to csv.</li>
                        </ul>
                        <strong>NOTE:</strong> if you choose TICKER as type, Yellowkey is mandatory:
                        <ul>
                            <li>i.e. <strong>SHEL LN</strong> needs to be in the file as <strong>SHEL LN Equity</strong></li>
                        </ul>
                        <p>
                            Once you upload a file here:
                        </p>
                        <ul>
                            <li>ACCEPTED status means that the identifier is valid and an
                                attempt to import it will be performed, it does NOT mean that it has been imported.
                            </li>
                            <li>Any other status means that it will be ignored, and T-Trade will not try to import it.</li>
                            <li>it will take a few minutes before the instruments have been imported to SCD.</li>
                            <li>T-Trade currently has no way to monitor the import to SCD, so you will have to verify
                                the import in SCD.</li>
                            <li>Once in SCD it will take up to two hours before they are visible
                                in T-Trade static data.</li>
                        </ul>
                    </Grid>
                    <Grid item xs={12} md={2} lg={2}>
                        <TextField
                            select
                            label="Instrument"
                            id="outlined-select-separator"
                            value={ instrumentType }
                            onChange={ setConfigInstrumentType} >
                            { Object.values(InstrumentType).map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} md={2} lg={2}>
                    <TextField
                            select
                            label="Identifier"
                            value={ identifierType }
                            onChange={ setConfigIdentifierType} >
                            { Object.values(IdentifierType).map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} md={5} lg={5}>
                    <TextField
                            autoFocus
                            id="importFile"
                            fullWidth
                            type='file'
                            inputProps={{accept:".tsv,.csv,text/csv,text/tsv"}}
                            onChange={ handleFile }
                        />
                    </Grid>
                    <Grid item xs={12} md={3} lg={3} justifyContent={'center'}>
                        <RolesRetriever groups={ ["admin", "super" ] }>
                            <Button variant='outlined' startIcon={<UploadFileRounded/> } disabled={ ! file || file.size === 0 } onClick={ doImportInstruments }> Import Instruments </Button>
                        </RolesRetriever>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} height={1000}>
                        <DataGridPremium
                            apiRef={apiRef}
                            initialState={initialState}
                            slots={{toolbar: GridToolbar}}
                            loading={importFunction.loading}
                            rows={importFunction.data.response}
                            columns={columns}
                            density={"compact"}
                            getRowId={(row) => row.identifier.instrumentId }
                            pagination={true}
                            pageSizeOptions={[100, 1000]}
                        />
                    </Grid>
                </Grid>
            </Container>
            <SnackbarComponent state={ snackbarState } setState={ setSnackbarState }/>
        </React.Fragment>
);
}