import * as React from 'react';
import Typography from '@mui/material/Typography';
import {PropsWithChildren, ReactElement} from "react";


export type RolesProps = {
    groups?: string[]
}

function Roles(props: PropsWithChildren<RolesProps>): ReactElement {
    return (
        <Typography component="h2" variant="h6" color="primary" gutterBottom>
            Assigned Access Level: {props.groups}
        </Typography>
    );
}

export default Roles;