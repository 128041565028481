
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Tooltip
} from "@mui/material";
import * as React from "react";
import {RequestDataFunction} from "./types/RequestDataFunction";
import IconButton from "@mui/material/IconButton";
import {CancelRounded} from "@mui/icons-material";
import {SnackbarState} from "./SnackbarComponent";
import {TtradeDividendUpdateRequest, useDividendCancel} from "../application/CashDividendService";

export type CancelDividendDialogProps = {
  dividendId: number,
  dividendShortName: string,
  exDate: string,
  setSnackbarState: React.Dispatch<React.SetStateAction<SnackbarState>>
  refreshFunction: () => void
}
export default function CancelDividendDialog(props: Readonly<CancelDividendDialogProps>) {
  const [open, setOpen] = React.useState(false);
  const [text, setText] = React.useState('');
  const cancelFunction: RequestDataFunction<TtradeDividendUpdateRequest> = useDividendCancel();

  const cancelTransaction = async (message: string): Promise<void> => {
    await cancelFunction.apiFunction({dividendId: props.dividendId, message: {message: message}, completedFunction: props.refreshFunction})
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleResponseCancel = () => {
    setOpen(false);
  };

  const handleResponseOk = () => {
    cancelTransaction(text)
        .then(() => {
          setOpen(false);
          props.setSnackbarState({ message: "Updates will be visible after a few seconds when you refresh this page", open: true, severity: "success" });
        })
        .catch(() => props.setSnackbarState({ message: "Refresh failed", open: true, severity: 'error'}));
  };

  function handleText(e: React.ChangeEvent<HTMLInputElement>) {
     setText(e.target.value);
  }

  return (
    <React.Fragment>
      <Tooltip title={"Mark this problem as resolved outside T-Trade."} placement={"top"}>
        <IconButton color="warning" onClick={ handleClickOpen } disabled= { ! cancelFunction.loading } >
          <CancelRounded/>
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleResponseCancel}>
        <DialogTitle>Mark Dividend problem as resolved outside T-Trade</DialogTitle>
        <DialogContent>
            <p> This will set state to CANCELLED on dividend {props.dividendShortName} on exDate {props.exDate}.</p>
            <p> This means that T-Trade will not monitor dividend state in SCD anymore. It also means that all issues
              have been resolved outside T-Trade, i.e. most probably in SCD. </p>
            <p>The dividend will remain in the T-Trade UI, but in state CANCELLED, to mark that these operations were manually skipped.</p>

            <strong> Please enter the reason why you cancel this dividend, text entered will be visible in T-Trade UI. </strong>
          <TextField
            autoFocus
            margin="dense"
            id="reason"
            label="Reason for Cancelling Dividend Update"
            fullWidth
            variant="standard"
            value={ text }
            onChange={ handleText }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleResponseCancel}>Cancel</Button>
          <Button disabled={ ! text || text.length === 0 || text.length > 200 } onClick={handleResponseOk}>Mark Resolved</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}