import * as React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { LicenseInfo } from '@mui/x-license-pro';
import {msalConfig} from "./authorization/authConfig";

LicenseInfo.setLicenseKey('aaff131e8251f96de56cb74b53643e26Tz03MzE4MyxFPTE3MjQzMjUzMzQwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y');

const msalInstance = new PublicClientApplication(msalConfig);
const rootElement = document.getElementById('root') as HTMLElement;
const root = createRoot(rootElement);

root.render(
    <React.StrictMode>
        <MsalProvider instance={msalInstance}>
            <App />
        </MsalProvider>
    </React.StrictMode>
);
