import {AuditEntityType} from "./AuditEntityType";
import {AuditEventType} from "./AuditEventType";
import {Dayjs} from "dayjs";

export type AuditEventSearchTerm = {
    from: Dayjs | null,
    to: Dayjs | null,
    entityId: string,
    entityType: AuditEntityType | null,
    eventType: AuditEventType | null
}

export function defaultAuditEventSearchTerm(): AuditEventSearchTerm {
    return {
        from: null,
        to: null,
        entityId: '',
        entityType: null,
        eventType: null
    } as AuditEventSearchTerm;
}