import React, {ReactElement} from "react";
import {
    DataGridPremium,
    GridColDef,
    GridRenderCellParams,
    GridToolbar
} from "@mui/x-data-grid-premium";
import Grid from "@mui/material/Grid";
import {formatDateTimeShort} from "./TimeFormat";
import {AuditEventDto} from "../domain/audit/AuditEventDto";
import {GridInitialStatePremium} from "@mui/x-data-grid-premium/models/gridStatePremium";
import {useAutoSizeGrid} from "./AutoSizeGridHook";

export type AuditEventComponentParams = {
    data: AuditEventDto[],
    loading: boolean
};
export default function AuditEventDtoComponent(params: Readonly<AuditEventComponentParams>): ReactElement {

    const apiRef = useAutoSizeGrid<AuditEventDto>({
        data: params.data,
        loading: params.loading,
        columns: [
            'eventTime',
            'eventType',
            'user',
            'externalId',
            'source',
            'url'
        ]
    });

    const initialState: GridInitialStatePremium = {
        columns: {
            columnVisibilityModel: {
            },
            dimensions: {
                eventTime: { width: 150 },
                eventType: { width: 220 },
                user: { width: 220 },
                externalId: { width: 220 },
                source: { width: 150 },
                url: { flex: 1}
            }
        },
        pagination: {
            paginationModel: {
                pageSize: 100
            }
        }
    }

    const columns: GridColDef<AuditEventDto>[] = [
        {
            field: 'eventTime',
            headerName: 'Event Time',
            renderCell: (params: GridRenderCellParams<AuditEventDto>) => formatDateTimeShort(params.row.eventTime)
        },
        {
            field: 'eventType',
            headerName: 'Event Type'
        },
        {
            field: 'user',
            headerName: 'User'
        },
        {
            field: 'externalId',
            headerName: 'External ID'
        },
        {
            field: 'source',
            headerName: 'Source'
        },
        {
            field: 'url',
            flex: 1,
            headerName: 'URL'
        }
    ];

    return (
        <Grid item xs={12} md={12} lg={12} height={ 1000 }>
            <DataGridPremium
                apiRef={apiRef}
                initialState={initialState}
                slots={{ toolbar: GridToolbar }}
                loading={params.loading}
                rows={params.data}
                columns={columns}
                density={"compact"}
                getRowId={(row) => row.eventTime }
                pagination={true}
                pageSizeOptions={[20,25,50,100,500,1000]}
            />
        </Grid>
    );
}