import {Link} from "react-router-dom";
import React, {ReactElement, useState} from "react";
import {DataGridPremium, GridColDef, GridRenderCellParams, GridToolbar} from "@mui/x-data-grid-premium";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import Title from "../../components/Title";
import formatDateTime from "../../components/TimeFormat";
import {
    AcknowledgeData,
    useSearchRefusedTransactions,
    useSetStatusFunction
} from "../../application/RefusedTransactionService";
import {TransactionProcessingResult} from "../../domain/transaction/TransactionProcessingResult";
import {PageDto} from "../../domain/PageDto";
import IconButton from "@mui/material/IconButton";
import {
    DownloadForOfflineRounded,
    VisibilityOffRounded,
    VisibilityRounded
} from "@mui/icons-material";
import {RequestDataFunction} from "../../components/types/RequestDataFunction";
import {extractFileOrigin, ProducerServiceProps, useProducerService} from "../../application/ProducerService";
import InboundTransactionSearchBar from "./InboundTransactionSearchBar";
import {RefusedTransactionSearchTerm} from "../../domain/transaction/RefusedTransactionSearchTerm";
import {SearchDataFunction} from "../../components/types/SearchDataFunction";
import {RefusedTransactionStatus} from "../../domain/transaction/RefusedTransactionStatus";
import {Chip, Tooltip} from "@mui/material";
import {GridInitialStatePremium} from "@mui/x-data-grid-premium/models/gridStatePremium";
import {useAutoSizeGrid} from "../../components/AutoSizeGridHook";

export default function InboundTransactionsPage(): ReactElement {
    const searchFn: SearchDataFunction<RefusedTransactionSearchTerm, PageDto<TransactionProcessingResult>> = useSearchRefusedTransactions();
    const fileService: RequestDataFunction<ProducerServiceProps> = useProducerService();
    const acknowledgeFunction: RequestDataFunction<AcknowledgeData> = useSetStatusFunction();
    const [ buttonDisabled, setButtonDisabled ] = useState<boolean>(false);
    const apiRef = useAutoSizeGrid<TransactionProcessingResult>({
        data: searchFn.data.data,
        loading: searchFn.loading,
        expand: false,
        columns: [
            'id',
            'assetManager',
            'action',
            'transactionType',
            'identifier',
            'code',
            'message',
            'fileOrigin',
            'originalFile',
            'originalFileLine',
            'transactionId'
        ]
    })
    function renderDownloadButton(params: TransactionProcessingResult) {
        return (
            <React.Fragment>
                <Tooltip title={"Download file"} placement={"top"}>
                    <IconButton color="primary" onClick={ () => {
                        Promise.resolve(fileService.apiFunction({ fileOrigin: extractFileOrigin(params), fileName: params.originalFile }));
                    }}>
                        <DownloadForOfflineRounded/>
                    </IconButton>
                </Tooltip>
                { params.originalFile }
            </React.Fragment>);
    }

    function reloadData() {
        const searchTerm = {...searchFn.searchTerm};
        searchFn.setSearchTermFunction(searchTerm);
        setButtonDisabled(false);
    }

    function renderStatusButton(params: TransactionProcessingResult) {
        if (params.status === RefusedTransactionStatus.ACTIVE) {
            return (
                <React.Fragment>
                    <Tooltip title="Mark as manually solved problem" placement={"top"}>
                        <IconButton disabled={ buttonDisabled } color="success" onClick={ () => {
                            setButtonDisabled(true);
                            acknowledgeFunction.apiFunction({ status: RefusedTransactionStatus.ACKNOWLEDGED, transactions: [params], completedFunction: reloadData})
                        }}>
                            <VisibilityOffRounded/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Active problem sent to asset manager" placement={"top"}>
                        <Chip size="small" label={ params.status } color={ "error" }/>
                    </Tooltip>
                </React.Fragment>);
        }
        if (params.status === RefusedTransactionStatus.ACKNOWLEDGED) {
            return (
                <React.Fragment>
                    <Tooltip title="Mark as an active problem" placement={"top"}>
                        <IconButton disabled={ buttonDisabled } color="error" onClick={ () => {
                            setButtonDisabled(true);
                            acknowledgeFunction.apiFunction({ status: RefusedTransactionStatus.ACTIVE, transactions: [ params ], completedFunction: reloadData});
                        }}>
                            <VisibilityRounded/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Problem marked as manually solved" placement={"top"}>
                        <Chip size="small" label={params.status} color={ "warning" }/>
                    </Tooltip>
                </React.Fragment>);
        }
        return (
                <Tooltip title="Problem solved by asset manager via new file upload" placement={"top"}>
                    <Chip size="small" label={params.status} color={ "success" }/>
                </Tooltip>
        );
    }

    const initialState: GridInitialStatePremium =
        {
            columns: {
                columnVisibilityModel:  {
                    code: false
                },
                dimensions: {
                    status: { width: 200 },
                    eventTime: { width: 600 }
                }
            },
            pagination: {
                paginationModel: {
                    pageSize: 1000
                }
            }
        }

    const columns: GridColDef<TransactionProcessingResult>[] = [
        { field: 'id', headerName: 'Id', width: 70, renderCell: (params: GridRenderCellParams<TransactionProcessingResult>) =>
                <Link to={"/issues/inbound/" + params.row.id }> {params.row.id} </Link> },
        { field: 'assetManager', headerName: 'Asset Mgr',
            renderCell: (params: GridRenderCellParams<TransactionProcessingResult>) =>
                 <Link to={"/counterparts/" + params.row.assetManager}> {params.row.assetManager} </Link>
        },
        { field: 'eventTime', width: 140, headerName: 'Event Time', renderCell: (params: GridRenderCellParams<TransactionProcessingResult>) =>
                formatDateTime(params.row.eventTime)
        },
        { field: 'status', width: 120, headerName: "Status",
            renderCell: (params: GridRenderCellParams<TransactionProcessingResult>) => renderStatusButton(params.row)
        },
        { field: 'action', headerName: 'Action', },
        { field: 'transactionType', headerName: 'Type' },
        { field: 'identifier', headerName: 'Identifier' },
        { field: 'code', headerName: 'Code'},
        { field: 'message', headerName: 'message', flex: 0.7 },
        { field: 'fileOrigin', headerName: 'File Origin'},
        { field: 'originalFile', headerName: 'File', flex: 0.3,
            renderCell: (params) => renderDownloadButton(params.row)
        },
        { field: 'originalFileLine', headerName: 'Line' },
        { field: 'transactionId', headerName: 'Transaction',
            renderCell: (params: GridRenderCellParams<TransactionProcessingResult>) => {
                if (params.row.transactionType) {
                    return (
                        <Tooltip title={ "Link to transaction, can be empty if it does not exist yet in T-Trade" } placement={"top"}>
                            <Link to={"/transaction/"
                                + params.row.transactionType.toLowerCase()
                                + "/"
                                + params.row.assetManager
                                + "/"
                                + params.row.transactionId}> {params.row.transactionId}
                            </Link>
                        </Tooltip>);
                } else {
                    return params.row.transactionId;
                }
            }
        }

    ];

    return (
            <Container maxWidth={ false } sx={{ mt: 4, mb: 4 }}>
                { <Toolbar/> }
                <Grid container spacing={2}>
                    <Grid item xs={12} md={11} lg={11}>
                        <Title>Blocked Inbound Transactions</Title>
                    </Grid>
                    <InboundTransactionSearchBar searchFn={ searchFn }/>
                    <Grid item xs={12} md={12} lg={12} height={ 1000 }>
                        <DataGridPremium
                            apiRef={ apiRef }
                            initialState={initialState}
                            slots={{ toolbar: GridToolbar }}
                            loading={searchFn.loading}
                            rows={searchFn.data.data}
                            columns={columns}
                            density={"compact"}
                            getRowId={(row) => row.id}
                            pagination={true}
                            pageSizeOptions={[100, 1000]}
                        />
                    </Grid>
                </Grid>
            </Container>
    );
}