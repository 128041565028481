
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Tooltip
} from "@mui/material";
import * as React from "react";
import {ResponseDataFunction} from "./types/ResponseDataFunction";
import RolesRetriever from "../authorization/RolesRetreiver";
import {DividendCurrencyConfig} from "../domain/assetmanager/DividendCurrencyConfig";

export type CreateDividendCurrencyConfigurationDialogParams = {
  createFunction: ResponseDataFunction<string, DividendCurrencyConfig>;
}

export default function CreateDividendCurrencyConfigurationDialog(params: Readonly<CreateDividendCurrencyConfigurationDialogParams>) {
  const [open, setOpen] = React.useState(false);
  const [text, setText] = React.useState('');

  const createDividendCurrencyConfiguration = (name: string): void => {
    params.createFunction.apiFunction(name);
  }
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleResponseCancel = () => {
    setOpen(false);
  };

  const handleResponseOk = () => {
    setOpen(false);
    createDividendCurrencyConfiguration(text);
  };

  function handleText(e: React.ChangeEvent<HTMLInputElement>) {
     setText(e.target.value);
  }

  const isButtonDisabled = (text: string): boolean => {
    return ! text
        || text.length !== 3
        || ! /[A-Z]/.test(text)
  }

  return (
    <div>
      <RolesRetriever groups={ [ "super" ]}>
        <Tooltip title={ "Currency specific configuration, on how to match and approve dividends." } placement={"top"}>
          <Button variant="outlined" onClick={handleClickOpen}>
            Add Currency Configuration
          </Button>
        </Tooltip>
      </RolesRetriever>
      <Dialog open={open} onClose={handleResponseCancel}>
        <DialogTitle>Create New Dividend Currency Configuration</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This will create a new Dividend Currency configuration. These are ONLY required if you need
            special behaviour for the currency.{ /* no space here */ }
            <b>Matching on Bloomberg Net Income Rate, instead of standard Gross Income Rate.</b>
            <b>Do not automatically Approve Matched Dividends for this currency.</b>{ /* no space here */ }
            You need to enter the 3 letter language code, in CAPTIALS.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="currencyId"
            label="Currency ISO"
            fullWidth
            variant="standard"
            value={ text }
            onChange={ handleText }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleResponseCancel}>Cancel</Button>
          <Button disabled={ isButtonDisabled(text) } onClick={handleResponseOk}>Create</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}