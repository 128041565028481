import {ResponseDataFunction} from "./types/ResponseDataFunction";
import {FileUploadData, useManualFileUpload} from "../application/AssetManagerService";
import {useEffect, useState} from "react";
import * as React from "react";

import {
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    TextField, Tooltip
} from "@mui/material";
import {UploadRounded} from "@mui/icons-material";
import RolesRetriever from "../authorization/RolesRetreiver";
import {SnackbarState} from "./SnackbarComponent";

export type UploadAssetManagerFileDialogParams = {
    assetManager: string,
    responseFunction: (message: SnackbarState) => void

}

export default function UploadAssetManagerFileDialog(params: Readonly<UploadAssetManagerFileDialogParams>) {
    const fileUploadFunction: ResponseDataFunction<FileUploadData, string> = useManualFileUpload();
    const [uploadOpen, setUploadOpen] = useState<boolean>(false);
    const [file, setFile] = React.useState<File | null>(null);

    useEffect(() => {
        if (fileUploadFunction.data && fileUploadFunction.data.length > 0) {
            params.responseFunction({ open: true, message: fileUploadFunction.data, severity: 'success'})
        } else if (fileUploadFunction.error) {
                const msg = fileUploadFunction.error ? fileUploadFunction.error : "Upload failed";
                params.responseFunction({open: true, message: msg, severity: 'error'});
        }
    }, [ fileUploadFunction.data, fileUploadFunction.error ]);
    
    const triggerUpload = () => {
        setUploadOpen(true);
    };

    const handleUploadCancel = () => {
        setUploadOpen(false);
    };

    const doCallUpload = () => {
        if (file) {
            const uploadRequest = { assetManager: params.assetManager, file: file } as FileUploadData;
            fileUploadFunction.apiFunction(uploadRequest)
                .finally(() => setUploadOpen(false));
        }
    };

    const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            setFile(e.target.files[0]);
        }
    }

    const isFileSet = () => {
        return file !== undefined && file !== null && file.size > 0
    }

    return (
        <RolesRetriever groups={ [ "admin", "super" ]}>
            <div>
                <Tooltip title={"Manual Upload of Transactions as this assetmanager, will behave exactly like when asset manager uploads the file."} placement={"top"}>
                    <Button variant='outlined' startIcon={<UploadRounded/>} disabled={ isFileSet() } onClick={() => {
                        triggerUpload();
                    }}>
                        Manual File Upload
                    </Button>
                </Tooltip>
                <Dialog open={uploadOpen} onClose={handleUploadCancel}>
                    <DialogTitle>Manual Upload of Asset Manager file</DialogTitle>
                    <DialogContent>
                            It is utterly important that you select a file that actually represents transaction from the asset manager you
                            are using now, i.e. <strong>{ params.assetManager }</strong>.
                            <br/>Make sure that filename is unique, so it does not collide with previous files.
                            Adding a date/time in the filename is a <strong>good</strong> idea.
                            Do <strong>not</strong> use whitespace or swedish characters in the filenames.<br/>
                            <strong><p>Be careful !!! This can potentially upload broken transactions to SCD.
                            </p></strong>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="assetManagerId"
                            fullWidth
                            type='file'
                            inputProps={{accept:".tsv,text/tsv"}}
                            onChange={handleFile}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleUploadCancel}>Cancel</Button>
                        <Button disabled={!file || file.size === 0} onClick={doCallUpload}>Upload and Import</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </RolesRetriever>
    );
}