import * as React from 'react';
import {
    DragDropContext,
    OnDragEndResponder
} from 'react-beautiful-dnd';
import {Item} from "./types/Item";
import DraggableListItem from "./DraggableListItem";
import { StrictModeDroppable } from "./StrictModeDroppable";

export type DraggableListProps = {
    items: Item[];
    onDragEnd: OnDragEndResponder;
};

const DraggableList = React.memo(({ items, onDragEnd }: DraggableListProps) => {
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <StrictModeDroppable droppableId="droppable-list">
                {provided => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                        {items.map((item, index) => (
                            <DraggableListItem item={item} index={index} key={ item.id } />
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </StrictModeDroppable>
        </DragDropContext>
    );
});

DraggableList.displayName = 'DraggableList';

export default DraggableList;
