import { AuditEntityType } from "../domain/audit/AuditEntityType";
import {AuditEventType} from "../domain/audit/AuditEventType";
import {AuditEventSearchTerm} from "../domain/audit/AuditEventSearchTerm";
import {formatDayjsDateTimeString} from "../components/TimeFormat";

export type AuditEventSearchTermDto = {
    from: string | null,
    to: string | null,
    entityId: string,
    entityType: AuditEntityType | null,
    eventType: AuditEventType | null
}

export function convertAuditEventSearchTerm(searchTerm: AuditEventSearchTerm): AuditEventSearchTermDto {
    return {
        from: formatDayjsDateTimeString(searchTerm.from),
        to: formatDayjsDateTimeString(searchTerm.to),
        entityId: searchTerm.entityId,
        entityType: searchTerm.entityType,
        eventType: searchTerm.eventType
    };
}