import {CashDividendSource} from "../dividend/CashDividendSource";


export type DividendFilterConfig = {
    id: number,
    version: number,
    accountNumber: string | null,
    instrumentPrefix: string | null,
    source: CashDividendSource
}

export function emptyDividendFilterConfig(): DividendFilterConfig {
    return {
        id: 0,
        version: 0,
        accountNumber: null,
        instrumentPrefix: null,
        source: CashDividendSource.VPB
    }
}