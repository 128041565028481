import {PageDto} from "../domain/PageDto";
import React, {ReactElement} from "react";
import {useAutoSizeGrid} from "./AutoSizeGridHook";
import {GridInitialStatePremium} from "@mui/x-data-grid-premium/models/gridStatePremium";
import {DataGridPremium, GridColDef, GridRenderCellParams, GridToolbar} from "@mui/x-data-grid-premium";
import {Link} from "react-router-dom";
import Grid from "@mui/material/Grid";
import {DelayedTransaction} from "../domain/transaction/DelayedTransaction";
import {formatDateTimeShort} from "./TimeFormat";

export type EquityTransactionComponentParams = {
    page: PageDto<DelayedTransaction>
    loading: boolean
};

export default function DelayedTransactionDataPanel(params: Readonly<EquityTransactionComponentParams>): ReactElement {

    const apiRef = useAutoSizeGrid<DelayedTransaction>({
        data: params.page?.data,
        loading: params.loading,
        columns: [
            'assetManager',
            'transactionId',
            'transactionType',
            'accepted',
            'tradeDate',
            'requestedState',
        ]
    });

    const initialState: GridInitialStatePremium =
        {
            columns: {
                columnVisibilityModel: {
                },
                dimensions: {
                    assetManager: {width: 70},
                    transactionId: {width: 150},
                    transactionType: {width: 70},
                    accepted: {width:110},
                    tradeDate: {width: 110},
                    requestedState: { flex: 1 }
                }
            },
            pagination: {
                paginationModel: {
                    pageSize: 1000
                }
            }
        }

    const columns: GridColDef<DelayedTransaction>[] = [
        {field: 'assetManager', headerName: 'AssetMgr'},
        {
            field: 'transactionId',
            headerName: 'TransactionId',
            renderCell: (params: GridRenderCellParams<DelayedTransaction>) =>
                <Link to={"/transaction/" + params.row.transactionType.toLowerCase() + "/"+ params.row.transactionId}> {params.row.transactionId} </Link>
        },
        {field: 'transactionType', headerName: "TX Type" },
        {field: 'accepted', headerName: 'Accepted At', renderCell: (p) => formatDateTimeShort(p.row.accepted)},
        {field: 'tradeDate', headerName: 'Trade Date'},
        {field: 'requestedState', headerName: 'Action'},
    ];

    return (
        <Grid item xs={12} md={12} lg={12} height={1000} padding={1}>
            <DataGridPremium
                apiRef={apiRef}
                initialState={ initialState}
                slots={{toolbar: GridToolbar }}
                loading={params.loading}
                rows={params.page?.data}
                columns={columns}
                density={"compact"}
                getRowId={(row) => row.accepted }
                pagination={true}
                pageSizeOptions={[25,50,100,500,1000]}
            />
        </Grid>
    );
}