import React, {ReactElement, useEffect, useState} from "react";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import {
    useDeleteDividendCurrencyConfiguration,
    useGetDividendCurrencyConfiguration,
    useSaveDividendCurrencyConfiguration
} from "../../application/DividendConfigService";
import {DeleteForeverRounded, SaveRounded} from "@mui/icons-material";
import RolesRetriever from "../../authorization/RolesRetreiver";
import {Button, Checkbox, FormControlLabel} from "@mui/material";
import SnackbarComponent, {defaultSnackbarState, SnackbarState} from "../../components/SnackbarComponent";
import Title from "../../components/Title";
import {useNavigate} from "react-router-dom";

export default function DividendCurrencyConfigurationPage(): ReactElement {
    const navigate = useNavigate();
    const [snackbarState, setSnackbarState] = useState<SnackbarState>(defaultSnackbarState);
    const getFunction = useGetDividendCurrencyConfiguration();
    const deleteFunction = useDeleteDividendCurrencyConfiguration();
    const saveFunction = useSaveDividendCurrencyConfiguration();
    const [netCurrency, setNetCurrency] = useState<boolean>(false);
    const [holdMatch, setHoldMatch] = useState<boolean>(false);
    let saveInProgress = false;

    useEffect(() => {
        setNetCurrency(getFunction.data.netCurrency);
        setHoldMatch(getFunction.data.holdMatch);
    }, [ getFunction.data ]);


    useEffect(() => {
        saveInProgress = false;
        if (saveFunction.data.id > 0) {
            setSnackbarState({ open: true, message: "Save successful", severity: "success" } );
            navigate("/dividends/config")
        }
    }, [ saveFunction.data ]);

    useEffect(() => {
        saveInProgress = false;
        if (saveFunction.error) {
            setSnackbarState({ open: true, message: "Save failed: " + saveFunction.error, severity: 'error' });
        }
    }, [ saveFunction.error ]);

    const saveConfiguration = () => {
        saveInProgress = true;
        saveFunction.apiFunction(getFunction.data)
    }

    const deleteConfiguration = () => {
        saveInProgress = true;
        deleteFunction.apiFunction(getFunction.data.id)
            .finally(() => {
                saveInProgress = false
                navigate("/dividends/config");
            });
    }


    const handleNetCurrency = (event: React.ChangeEvent<HTMLInputElement>) => {
        getFunction.data.netCurrency = event.target.checked;
        setNetCurrency(event.target.checked);
    }

    const handleHoldMatch = (event: React.ChangeEvent<HTMLInputElement>) => {
        getFunction.data.holdMatch = event.target.checked;
        setHoldMatch(event.target.checked);
    }

    if (getFunction.loading) {
        return (<></>);
    } else {
        return (
            <React.Fragment>
                <Container maxWidth={ false } sx={{ mt: 4, mb: 4 }}>
                    { <Toolbar/> }
                    <Grid container spacing={2} justifyItems={ "center" } alignItems={ "center" }>
                        <Grid item xs={12} md={8} lg={8} >
                            <Title>Dividend Currency Configuration {getFunction.data.currency} </Title>
                        </Grid>
                        <Grid item xs={12} md={2} lg={2}>
                            <RolesRetriever groups={ [ "super" ]}>
                                <Button variant='outlined'
                                        startIcon={<DeleteForeverRounded/>}
                                        disabled={saveInProgress}
                                        onClick={() => { deleteConfiguration() }}>
                                    Delete
                                </Button>
                            </RolesRetriever>
                        </Grid>
                        <Grid item xs={12} md={2} lg={2}>
                            <RolesRetriever groups={ [ "super" ]}>
                                <Button variant='outlined'
                                        startIcon={<SaveRounded/>}
                                        disabled={saveInProgress}
                                        onClick={() => { saveConfiguration(); }}>
                                    Save
                                </Button>
                            </RolesRetriever>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <FormControlLabel control={
                                <Checkbox
                                    checked={ netCurrency }
                                    onChange={ handleNetCurrency }
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            } label="Use NET Income Rate rather than Gross Income Rate(default) from Bloomberg." />
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <FormControlLabel control={
                                <Checkbox
                                    checked={ holdMatch }
                                    onChange={ handleHoldMatch }
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            } label="If Auto Approval is enabled, do NOT Approve Matches in this currency." />
                        </Grid>
                    </Grid>
                </Container>
                <SnackbarComponent state={ snackbarState } setState={ setSnackbarState }/>
            </React.Fragment>
        )}
}
