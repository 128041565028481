import React, {ReactElement, useState} from "react";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import Title from "../../components/Title";
import {Button, Tooltip} from "@mui/material";
import {HowToRegRounded, RuleRounded, SettingsRounded} from "@mui/icons-material";
import RolesRetriever from "../../authorization/RolesRetreiver";
import {
    useGetAssetManagerConfiguration
} from "../../application/AssetManagerService";
import {AssetManagerConfig} from "../../domain/assetmanager/AssetManagerConfig";
import {GetDataApi} from "../../components/types/GetDataApi";
import UploadAssetManagerFileDialog from "../../components/UploadAssetManagerFileDialog";
import SnackbarComponent, {defaultSnackbarState, SnackbarState} from "../../components/SnackbarComponent";
import {Link} from "react-router-dom";
import AssetManagerReport from "./AssetManagerReport";

export default function AssetManager(): ReactElement {
    const assetManagerConfig: GetDataApi<AssetManagerConfig> = useGetAssetManagerConfiguration();
    const [snackbarState, setSnackbarState] = useState<SnackbarState>(defaultSnackbarState);

    if (assetManagerConfig.loading) {
        return (
            <Container maxWidth={false} sx={{mt: 4, mb: 4}}>
                {<Toolbar/>}
                <Title>Loading ....</Title>
            </Container>);
    } else {
        return (
                <Container maxWidth={false} sx={{mt: 4, mb: 4}}>
                    {<Toolbar/>}
                    <Grid container
                          spacing={2}
                          display="flex"
                          alignItems="center">
                        <Grid item xs={12} md={9} lg={9}>
                            <Title>Asset Manager: {assetManagerConfig.data.name}</Title>
                        </Grid>
                        <RolesRetriever groups={ [ "super" ] }>
                            <Grid item xs={12} md={12} lg={12}>
                                <Button component={Link} to="edit" variant="outlined" startIcon={<SettingsRounded/>}>
                                    Edit Configuration
                                </Button>
                            </Grid>
                        </RolesRetriever>
                        <Grid item xs={12} md={3} lg={3}>
                            <Tooltip title={"Show Counterparties accepted by T-Trade for this Asset Manager."} placement={"top"}>
                                <Button component={Link} to= { "/counterparts/" + assetManagerConfig.data.name } variant="outlined"
                                    startIcon={<HowToRegRounded/>}>
                                Accepted Counterparties
                                </Button>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                            <UploadAssetManagerFileDialog assetManager={ assetManagerConfig.data.name } responseFunction={setSnackbarState}/>
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                            <Tooltip title={"Validate Transaction file contents without actually loading it into T-Trade."} placement={"top"}>
                                <Button component={Link} to="validate" variant="outlined" startIcon={<RuleRounded/>}>
                                    Validate File
                                </Button>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                            <Tooltip title={"Open Transaction Delivery Report."} placement={"top"}>
                                <Button component={Link} to="late-transactions" variant="outlined" startIcon={<RuleRounded/>}>
                                    Late Transactions Report
                                </Button>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <RolesRetriever groups={ [ "super" ]}>
                        <AssetManagerReport assetManager={ assetManagerConfig.data.name }/>
                    </RolesRetriever>
                    <SnackbarComponent state={ snackbarState } setState={ setSnackbarState }/>
                </Container>
        );
    }
}
