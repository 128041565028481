import {Transaction} from "./Transaction";
import {FxType} from "./FxType";
import {Action} from "./Action";
import {TransactionCode} from "./TransactionCode";

// AggregateFxTransaction
type FxTransactionInternal = {
    counterpartBicCode: string,
    // Deal information
    fxType: FxType,
    cls: string,
    forwardRate: 0,
    spotRate: 0,
    baseAmount: 0,
    quoteAmount: 0,
    baseCurrency: string,
    quoteCurrency: string
}

export type FxTransaction = Transaction & FxTransactionInternal;

export function emptyFxTransaction(): FxTransaction {
    return {
        transactionId: '',
        // Audit
        lastProcessed: '',
        requestedState: Action.ADD,
        // Portfolio
        assetManager: '',
        mandate: '',
        fund: '',
        fundShort: '',
        portfolio: '',
        // Counterparty
        counterpartShort: '',
        counterpartName: '',
        counterpartId: '',
        // Deal information
        externalTransactionId: '',
        transactionCode: TransactionCode.BUY,
        tradeDate: '',
        valueDate: '',
        originalFileName: '',
        originalFileRow: 0,
        counterpartBicCode: '',
        // Deal information
        fxType: FxType.SPOT,
        cls: '',
        forwardRate: 0,
        spotRate: 0,
        baseAmount: 0,
        quoteAmount: 0,
        baseCurrency: '',
        quoteCurrency: '',
    }
}
