import React, {ReactElement} from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Title from "../../components/Title";
import CashDividendDataPage from "./CashDividendDataPage";
import CashDividendHeaderPage from "./CashDividendHeaderPage";
import {CashDividendUpdateDto} from "../../domain/dividend/CashDividendUpdateDto";
import {GetDataApi} from "../../components/types/GetDataApi";
import {PageDto} from "../../domain/PageDto";
import {useGetDividendsById, useGetRawDividendsById} from "../../application/CashDividendService";
import Toolbar from "@mui/material/Toolbar";
import CashDividendDetailsComponent from "../../components/CashDividendDetailsComponent";
import {CashDividend} from "../../domain/dividend/CashDividend";
import {Button, Tooltip} from "@mui/material";


export default function CashDividendPage(): ReactElement {
    const rawDividends: GetDataApi<PageDto<CashDividendUpdateDto>> = useGetRawDividendsById();
    const dividends: GetDataApi<PageDto<CashDividend>> = useGetDividendsById();

    if (rawDividends.loading) {
        return (
            <Container maxWidth={false} sx={{mt: 4, mb: 4}}>
                {<Toolbar/>}
                <Title>Loading ....</Title>
            </Container>);
    } else {
        return (
                <Container maxWidth={ false } sx={{ mt: 4, mb: 4 }}>
                    <Grid container>
                        <Grid container spacing={2} margin={1}>
                            <Grid item xs={12}>
                                <Title> Instrument </Title>
                            </Grid>
                            <Grid item xs={12}>
                                <CashDividendHeaderPage dividend={ rawDividends.data.data[0] } />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} margin={1}>
                            <Grid item xs={12}>
                                <Tooltip
                                    title={"BLO/BNY Event ID decides whether approving a dividend will become a new Dividend (new/discarded id), or replace an existing Dividend in SCD (approved id)"}
                                    placement={"top"}>
                                    <Button>
                                        <Title>Dividend Matching Details</Title>
                                    </Button>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={12}>
                                <CashDividendDetailsComponent dividends={ dividends.data } loading={ dividends.loading }/>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} margin={1}>
                            <Grid item xs={12}>
                                <Title>Raw Dividend Input From BNY/BLO</Title>
                            </Grid>
                            <Grid item xs={12}>
                                <CashDividendDataPage dividends={ rawDividends.data.data } loading={rawDividends.loading}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
        );
    }
}
