import React, {ReactElement, useState} from "react";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Title from "../../components/Title";
import {DataGridPremium, GridColDef, GridToolbar} from "@mui/x-data-grid-premium";
import {useGetCurrencyCross, useSyncCurrencyCross} from "../../application/CurrencyCrossService";
import SnackbarComponent, {defaultSnackbarState, SnackbarState} from "../../components/SnackbarComponent";
import {RefreshDataFunction} from "../../components/types/RefreshDataFunction";
import {Statistics} from "../../domain/staticdata/Statistics";
import {GridInitialStatePremium} from "@mui/x-data-grid-premium/models/gridStatePremium";
import {useAutoSizeGrid} from "../../components/AutoSizeGridHook";
import {CurrencyCross} from "../../domain/staticdata/CurrencyCross";
import RefreshStaticComponent from "../../components/RefreshStaticComponent";

export default function CurrencyCrosses(): ReactElement {
    const { data, loading , apiFunction} = useGetCurrencyCross();
    const [ snackbarState, setSnackbarState ] = useState<SnackbarState>(defaultSnackbarState);
    const syncCurrencyCross: RefreshDataFunction<Statistics> = useSyncCurrencyCross();
    const apiRef = useAutoSizeGrid<CurrencyCross>({
        data: data,
        loading: loading,
        columns: [
            'id',
            'decimals',
            'invert'
        ]
    })

    const initialState: GridInitialStatePremium =
        {
            columns: {
                columnVisibilityModel: {
                },
                dimensions: {
                    id: { width: 250 },
                    decimals: { width: 240 },
                    invert: { flex: 1 },
                }
            }
        }

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'Id'},
        { field: 'decimals', headerName: 'Decimals' },
        { field: 'invert', headerName: 'Invert'}
    ];

    return (
            <Container maxWidth={ false } sx={{mt: 4, mb: 4}}>
                {<Toolbar/>}
                <Grid container spacing={2} alignItems={"center"} justifyItems={"center"}>
                    <Grid item xs={12} md={10} lg={10}>
                        <Title>Currency Cross</Title>
                    </Grid>
                    <RefreshStaticComponent refreshFunction={ syncCurrencyCross } dataFunction={ apiFunction } setSnackbarState={ setSnackbarState }/>
                    <Grid item xs={12} md={12} lg={12} height={ 1000 }>
                        <DataGridPremium
                            apiRef={apiRef}
                            initialState={initialState}
                            slots={{ toolbar: GridToolbar }}
                            loading={loading}
                            rows={data}
                            columns={columns}
                            density={"compact"}
                        />
                    </Grid>
                </Grid>
                <SnackbarComponent setState={ setSnackbarState} state={ snackbarState}/>
            </Container>
    );
}