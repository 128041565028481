


export type DividendCurrencyConfig = {
    id: number,
    version: number,
    netCurrency: boolean,
    holdMatch: boolean,
    currency: string
}

export function emptyDividendCurrencyConfig(): DividendCurrencyConfig {
    return {
        id: 0,
        version: 0,
        netCurrency: false,
        holdMatch: false,
        currency: ""
    }
}