import React, {ReactElement} from "react";
import {DataGridPremium, GridColDef, GridRenderCellParams, GridToolbar} from "@mui/x-data-grid-premium";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import Title from "../../components/Title";
import {Link} from "react-router-dom";
import CreateAssetManagerDialog from "../../components/CreateAssetManagerDialog";
import {
    useGetAssetManagerConfigurations
} from "../../application/AssetManagerService";
import {GetDataApi} from "../../components/types/GetDataApi";
import {AssetManagerConfig} from "../../domain/assetmanager/AssetManagerConfig";
import {GridInitialStatePremium} from "@mui/x-data-grid-premium/models/gridStatePremium";
import {useAutoSizeGrid} from "../../components/AutoSizeGridHook";


export default function AssetManagers(): ReactElement {
    const assetManagers: GetDataApi<AssetManagerConfig[]> = useGetAssetManagerConfigurations();
    const apiRef = useAutoSizeGrid<AssetManagerConfig>({
        data: assetManagers.data,
        loading: assetManagers.loading,
        columns: [
            'name',
            'eq',
            'fu',
            'fx'
        ]
    });

    function booleanToString(value: boolean) {
        if (value) {
            return 'Yes';
        }
        return 'No';
    }

    const initialState: GridInitialStatePremium =
        {
            columns: {
                columnVisibilityModel: {
                },
                dimensions: {
                    name: { flex: 1 },
                    eq: { width: 120 },
                    fu: { width: 120 },
                    fx: { width: 120 }
                }
            }
        }

    const columns: GridColDef<AssetManagerConfig>[] = [
        { field: 'name', headerName: 'Name', flex: 1, renderCell: (params: GridRenderCellParams<AssetManagerConfig>) =>
            <Link to={ params.row.name }> {params.row.name} </Link>
        },
        { field: 'eq', headerName: 'Equities Enabled', width: 120, renderCell: (params: GridRenderCellParams<AssetManagerConfig>) =>
                <> { booleanToString(params.row.assetConfiguration?.EQ?.active) } </>
        },
        { field: 'fu', headerName: 'Futures Enabled', width: 120, renderCell: (params: GridRenderCellParams<AssetManagerConfig>) =>
                <> { booleanToString(params.row.assetConfiguration?.FU?.active) } </>
        },
        { field: 'fx', headerName: 'FX Enabled', width: 120, renderCell: (params: GridRenderCellParams<AssetManagerConfig>) =>
                <> { booleanToString(params.row.assetConfiguration?.FX?.active) } </>
        },
    ];

    return (
        <Container maxWidth={ false } sx={{ mt: 4, mb: 4 }}>
            { <Toolbar/> }
            <Grid container spacing={2}>
                <Grid item xs={12} md={8} lg={10}>
                    <Title>Asset Managers</Title>
                </Grid>
                <Grid item xs={12} md={2} lg={1}>
                    <CreateAssetManagerDialog/>
                </Grid>
                <Grid item xs={12} md={12} lg={12} height={ 1000 }>
                    <DataGridPremium
                        apiRef={apiRef}
                        initialState={initialState}
                        slots={{ toolbar: GridToolbar }}
                        loading={assetManagers.loading}
                        rows={assetManagers.data}
                        columns={columns}
                        density={"compact"}
                        getRowId={(row) => row.name}
                    />
                </Grid>
            </Grid>
        </Container>
    );
}