import React, {ReactElement, useState} from "react";
import {SearchDataFunction} from "../../components/types/SearchDataFunction";
import {PageDto} from "../../domain/PageDto";
import Grid from "@mui/material/Grid";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import {ExpandLess, ExpandMore, SearchRounded} from "@mui/icons-material";
import {Collapse, MenuItem, TextField} from "@mui/material";
import {DesktopDatePicker} from "@mui/x-date-pickers";
import {Dayjs} from 'dayjs';
import {AuditEventSearchTerm} from "../../domain/audit/AuditEventSearchTerm";
import {AuditEvent} from "../../domain/audit/AuditEvent";
import {AuditEntityType} from "../../domain/audit/AuditEntityType";
import {AuditEventType} from "../../domain/audit/AuditEventType";


const entityTypes = [
    {
        value: AuditEntityType.EQUITY_TRANSACTION,
        label: 'Equity Transaction'
    },
    {
        value: AuditEntityType.FUTURE_TRANSACTION,
        label: 'Future Transaction'
    },
    {
        value: AuditEntityType.FX_TRANSACTION,
        label: 'FX Transaction'
    },
    {
        value: AuditEntityType.REFUSED_TRANSACTION,
        label: 'Rejected Transaction'
    },
    {
        value: AuditEntityType.EQUITY,
        label: 'Equity Static Data'
    },
    {
        value: AuditEntityType.FUTURE,
        label: 'Future Static Data'
    },
    {
        value: AuditEntityType.BOND,
        label: 'Bond Static Data'
    },
    {
        value: AuditEntityType.DIVIDEND,
        label: "Dividend"
    },
    {
        value: AuditEntityType.ASSETMANAGER,
        label: 'Asset Manager Configuration'
    },
    {
        value: AuditEntityType.BROKER,
        label: "Broker"
    },
    {
        value: AuditEntityType.COUNTERPART,
        label: "Counterpart"
    },
    {
        value: AuditEntityType.CURRENCY,
        label: "Currency"
    },
    {
        value: AuditEntityType.CURRENCYCROSS,
        label: "Currency Cross"
    },
    {
        value: AuditEntityType.MANDATE,
        label: "Mandate"
    },
    {
        value: AuditEntityType.MODELPORTFOLIO,
        label: "Model Portfolio"
    },
    {
        value: AuditEntityType.PORTFOLIO,
        label: "Portfolio"
    },
    {
        value: AuditEntityType.ANY,
        label: "ANY"
    }
];

const eventTypes = [
    {
        value: AuditEventType.TRANSACTION_IMPORT,
        label: 'Transaction Import'
    },
    {
        value: AuditEventType.INSTRUMENT_IMPORT,
        label: 'Instrument Import'
    },
    {
        value: AuditEventType.DELETE_ASSETMANAGER,
        label: 'Assetmanager Delete'
    },
    {
        value: AuditEventType.CREATE_ASSETMANAGER,
        label: 'Asset Manager Create'
    },
    {
        value: AuditEventType.SAVE_ASSETMANAGER,
        label: 'Asset Manager Save'
    },
    {
        value: AuditEventType.TRANSACTION_REACTIVATE,
        label: 'Transaction Reactivate in MsgQueue'
    },
    {
        value: AuditEventType.TRANSACTION_REFRESH,
        label: 'Transaction Refresh from MsgQueue'
    },
    {
        value: AuditEventType.TRANSACTION_REPORT,
        label: 'Transaction Report'
    },
    {
        value: AuditEventType.REFUSED_TRANSACTION_STATUS,
        label: 'Asset Manager Problem Status'
    },
    {
        value: AuditEventType.TRANSACTION_RESEND,
        label: "Transaction Resend"
    },
    {
        value: AuditEventType.TRANSACTION_CANCEL,
        label: "Transaction Cancel"
    },
    {
        value: AuditEventType.TRANSACTION_COMPLETE,
        label: "Transaction Complete"
    },
    {
        value: AuditEventType.DIVIDEND_IMPORT,
        label: "Dividend Import"
    },
    {
        value: AuditEventType.DIVIDEND_RESEND,
        label: "Dividend Re-Send"
    },
    {
        value: AuditEventType.DIVIDEND_CANCEL,
        label: "Dividend Cancel"
    },
    {
        value: AuditEventType.DIVIDEND_UPDATE,
        label: "Dividend Update"
    },
    {
        value: AuditEventType.DIVIDEND_CREATE,
        label: "Dividend Create"
    },
    {
        value: AuditEventType.DIVIDEND_REACTIVATE,
        label: "Dividend Reactivate"
    },
    {
        value: AuditEventType.DIVIDEND_APPROVE,
        label: "Dividend Approve"
    },
    {
        value: AuditEventType.DIVIDEND_DISCARD,
        label: "Dividend Discard"
    },
    {
        value: AuditEventType.STATIC_DATA_REFRESH,
        label: "Static Data Refresh"
    },
    {
        value: AuditEventType.ANY,
        label: "ANY"
    }
];


export type AuditEventSearchBarParams = {
    searchFn: SearchDataFunction<AuditEventSearchTerm, PageDto<AuditEvent>>;
}

export default function AuditEventSearchBar(params: Readonly<AuditEventSearchBarParams>): ReactElement {
    const [state, setState] = useState<boolean>(false);


    function onChangeEntityId(event: React.ChangeEvent<HTMLInputElement>) {
        const merged = {...params.searchFn.searchTerm, ...{entityId: event.target.value}};
        params.searchFn.setSearchTermFunction(merged);
    }

    function onChangeFromDate(value: Dayjs | null): void {
        const merged = {...params.searchFn.searchTerm, ...{from: value}};
        params.searchFn.setSearchTermFunction(merged);
    }

    function onChangeToDate(value: Dayjs | null): void {
        const merged = {...params.searchFn.searchTerm, ...{to: value}};
        params.searchFn.setSearchTermFunction(merged);
    }

    function onChangeEntityType(event: React.ChangeEvent<HTMLInputElement>) {
        const value = event.target.value as AuditEntityType;
        if (value === AuditEntityType.ANY) {
            const merged = {...params.searchFn.searchTerm, ...{entityType: null }};
            params.searchFn.setSearchTermFunction(merged);
        } else {
            const merged = {...params.searchFn.searchTerm, ...{entityType: value }};
            params.searchFn.setSearchTermFunction(merged);
        }
    }

    function onChangeEventType(event: React.ChangeEvent<HTMLInputElement>) {
        const value = event.target.value as AuditEventType;
        if (value === AuditEventType.ANY) {
            const merged = {...params.searchFn.searchTerm, ...{eventType: null }};
            params.searchFn.setSearchTermFunction(merged);
        } else {
            const merged = {...params.searchFn.searchTerm, ...{eventType: value }};
            params.searchFn.setSearchTermFunction(merged);
        }
    }

    function handleSearchClick(): void {
        setState(!state);
    }

    return (
        <React.Fragment>
                <Grid item xs={1} md={1} lg={1}>
                    <ListItemButton onClick={handleSearchClick}>
                        <ListItemIcon>
                            <SearchRounded/>
                        </ListItemIcon>
                        {state ? <ExpandLess/> : <ExpandMore/>}
                    </ListItemButton>
                </Grid>
                <Collapse in={state} timeout="auto" unmountOnExit>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} lg={2}>
                            <DesktopDatePicker
                                label="From Date"
                                format="YYYY-MM-DD"
                                value={params.searchFn.searchTerm.from}
                                onChange={ (value) => {
                                    return onChangeFromDate(value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={2}>
                            <DesktopDatePicker
                                label="To Date"
                                value={params.searchFn.searchTerm.to}
                                format="YYYY-MM-DD"
                                onChange={onChangeToDate}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={2}>
                            <TextField
                                fullWidth={true}
                                select
                                label="Entity Type"
                                id="outlined-select-separator"
                                value={ params.searchFn.searchTerm.entityType }
                                onChange={ onChangeEntityType } >
                                { entityTypes.map((option) => (
                                    <MenuItem key={option.label } value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={6} lg={2}>
                            <TextField
                                label="Entity ID"
                                value={params.searchFn.searchTerm.entityId}
                                onChange={onChangeEntityId}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={2}>
                            <TextField
                                fullWidth={true}
                                select
                                label="Event Type"
                                id="outlined-select-separator"
                                value={ params.searchFn.searchTerm.eventType }
                                onChange={ onChangeEventType } >
                                { eventTypes.map((option) => (
                                    <MenuItem key={option.label } value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>

                    </Grid>
                </Collapse>
        </React.Fragment>
    );
}