import React, {ReactElement} from "react";

import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import Title from "../../components/Title";
import TransactionProgress from "./TransactionProgressPage";
import formatDateTime from "../../components/TimeFormat";
import {GetDataApi} from "../../components/types/GetDataApi";
import {useFindEquityByTransactionId} from "../../application/EquityTransactionService";
import {EquityTransaction} from "../../domain/transaction/EquityTransaction";
import {TransactionType} from "../../domain/transaction/TransactionType";
import RolesRetriever from "../../authorization/RolesRetreiver";
import {Link} from "react-router-dom";
import {ErrorOutlineRounded, HistoryRounded} from "@mui/icons-material";
import {Button, Tooltip} from "@mui/material";
import formatNumber from "../../components/NumberFormat";

export default function EquityTransactionPage(): ReactElement {
    const transaction: GetDataApi<EquityTransaction> = useFindEquityByTransactionId();
    if (transaction.loading) {
        return (<></>);
    } else {
        return (
            <Container maxWidth={ false } sx={{ mt: 4, mb: 4 }}>
                { <Toolbar/> }
                <Grid container spacing={2} justifyItems={ "center" } alignItems={ "center" }>
                    <Grid item xs={12} md={9} lg={9} >
                        <Title>Equity Transaction { transaction.data.transactionId } </Title>
                    </Grid>
                    <Grid item xs={12} md={2} lg={2} >
                        <RolesRetriever groups={ ["super", "admin"] }>
                            <Tooltip title={ "Related Issue History" } placement={"top"}>
                                <Button variant='outlined'
                                        startIcon={ <ErrorOutlineRounded/> }
                                        sx={{ "whiteSpace": "nowrap" }}
                                        component={Link} to={ "/issues/inbound/transaction/" + transaction.data.assetManager + "/" + transaction.data.externalTransactionId }>
                                    Related Issues
                                </Button>
                            </Tooltip>
                        </RolesRetriever>
                    </Grid>
                    <Grid item xs={12} md={1} lg={1} >
                        <RolesRetriever groups={ ["super"] }>
                            <Button variant='outlined'
                                    startIcon={ <HistoryRounded/> }
                                    component={Link} to={ "/audit/events/EQ/" + transaction.data.transactionId }>
                                History
                            </Button>
                        </RolesRetriever>
                    </Grid>
                    <Grid container border={ 2 } borderRadius="6px" borderColor={ "primary.main" } margin={ 1 }>
                        <TransactionProgress assetManager={ transaction.data.assetManager } transactionType={ TransactionType.EQ } transactionId={ transaction.data.transactionId }/>
                    </Grid>
                    <Grid container border={ 2 } borderRadius="6px" borderColor={ "primary.main" } margin={ 1 } >
                        <Grid item xs={4} md={4} lg={4} padding={1}>TransactionId</Grid>
                        <Grid item xs={8} md={8} lg={8} padding={1}>{ transaction.data.transactionId }</Grid>
                        <Grid item xs={4} md={4} lg={4} padding={1}>Last Processed</Grid>
                        <Grid item xs={8} md={8} lg={8} padding={1}> { formatDateTime(transaction.data.lastProcessed) }</Grid>
                    </Grid>
                    <Grid container border={ 2 } borderRadius="6px" borderColor={ "primary.main" }  margin={ 1 }>
                        <Grid item xs={4} md={4} lg={4} padding={1}>Asset Manager Transaction</Grid>
                        <Grid item xs={8} md={8} lg={8} padding={1}>{ transaction.data.externalTransactionId }</Grid>
                        <Grid item xs={4} md={4} lg={4} padding={1}>Asset Manager</Grid>
                        <Grid item xs={8} md={8} lg={8} padding={1}>{ transaction.data.assetManager }</Grid>
                        <Grid item xs={4} md={4} lg={4} padding={1}>Mandate</Grid>
                        <Grid item xs={8} md={8} lg={8} padding={1}>{ transaction.data.mandate }</Grid>
                        <Grid item xs={4} md={4} lg={4} padding={1}>Fund </Grid>
                        <Grid item xs={8} md={8} lg={8} padding={1}>{ transaction.data.fundShort }</Grid>
                        <Grid item xs={4} md={4} lg={4} padding={1}>Fund Full</Grid>
                        <Grid item xs={8} md={8} lg={8} padding={1}>{ transaction.data.fund }</Grid>
                        <Grid item xs={4} md={4} lg={4} padding={1}>Portfolio</Grid>
                        <Grid item xs={8} md={8} lg={8} padding={1}>{ transaction.data.portfolio }</Grid>
                    </Grid>
                    <Grid container border={ 2 } borderRadius="6px" borderColor={ "primary.main" } margin={ 1 }>
                        <Grid item xs={4} md={4} lg={4} padding={1}>Counterpart</Grid>
                        <Grid item xs={8} md={8} lg={8} padding={1}>{ transaction.data.counterpartShort }</Grid>
                        <Grid item xs={4} md={4} lg={4} padding={1}>Counterpart Full</Grid>
                        <Grid item xs={8} md={8} lg={8 } padding={1}>{ transaction.data.counterpartName }</Grid>
                        <Grid item xs={4} md={4} lg={4 } padding={1}>Counterpart Id</Grid>
                        <Grid item xs={8} md={8} lg={8 } padding={1}>{ transaction.data.counterpartId }</Grid>
                        <Grid item xs={4} md={4} lg={4 } padding={1}>Swift Code</Grid>
                        <Grid item xs={8} md={8} lg={8 } padding={1}>{ transaction.data.swiftCode }</Grid>
                    </Grid>
                    <Grid container border={ 2 } borderRadius="6px" borderColor={ "primary.main" } margin={ 1 }>
                        <Grid item xs={4} md={4} lg={4} padding={1}>Asset Manager Instrument</Grid>
                        <Grid item xs={8} md={8} lg={8} padding={1}>{ transaction.data.securityId }</Grid>
                        <Grid item xs={4} md={4} lg={4} padding={1}>ISIN</Grid>
                        <Grid item xs={8} md={8} lg={8} padding={1}>{ transaction.data.isin }</Grid>
                        <Grid item xs={4} md={4} lg={4} padding={1}>SCD Instrument</Grid>
                        <Grid item xs={8} md={8} lg={8} padding={1}>{ transaction.data.instrumentShort }</Grid>
                        <Grid item xs={4} md={4} lg={4} padding={1}>Instrument Name</Grid>
                        <Grid item xs={8} md={8} lg={8} padding={1}>{ transaction.data.instrumentName }</Grid>
                        <Grid item xs={4} md={4} lg={4} padding={1}>Instrument Type</Grid>
                        <Grid item xs={8} md={8} lg={8} padding={1}>{ transaction.data.instrumentType }</Grid>
                        <Grid item xs={4} md={4} lg={4} padding={1}>RIC</Grid>
                        <Grid item xs={8} md={8} lg={8} padding={1}>{ transaction.data.ric }</Grid>
                        <Grid item xs={4} md={4} lg={4} padding={1}>SEDOL</Grid>
                        <Grid item xs={8} md={8} lg={8} padding={1}>{ transaction.data.sedol }</Grid>
                        <Grid item xs={4} md={4} lg={4} padding={1}>Bloomberg Code</Grid>
                        <Grid item xs={8} md={8} lg={8} padding={1}>{ transaction.data.bloombergId }</Grid>
                    </Grid>
                    <Grid container border={ 2 } borderRadius="6px" borderColor={ "primary.main" } margin={ 1 }>
                        <Grid item xs={4} md={4} lg={4} padding={1}>Currency</Grid>
                        <Grid item xs={8} md={8} lg={8} padding={1}>{ transaction.data.currency }</Grid>
                        <Grid item xs={4} md={4} lg={4} padding={1}>Transaction Code</Grid>
                        <Grid item xs={8} md={8} lg={8} padding={1}>{ transaction.data.transactionCode }</Grid>
                        <Grid item xs={4} md={4} lg={4} padding={1}>Quantity</Grid>
                        <Grid item xs={8} md={8} lg={8} padding={1}>{ formatNumber(transaction.data.quantity) }</Grid>
                        <Grid item xs={4} md={4} lg={4} padding={1}>Price</Grid>
                        <Grid item xs={8} md={8} lg={8} padding={1}>{ formatNumber(transaction.data.price) }</Grid>
                        <Grid item xs={4} md={4} lg={4} padding={1}>Quote Factor</Grid>
                        <Grid item xs={8} md={8} lg={8} padding={1}>{ transaction.data.quoteFactor }</Grid>
                        <Grid item xs={4} md={4} lg={4} padding={1}>Trade Date</Grid>
                        <Grid item xs={8} md={8} lg={8} padding={1}>{ transaction.data.tradeDate }</Grid>
                        <Grid item xs={4} md={4} lg={4} padding={1}>Value Date</Grid>
                        <Grid item xs={8} md={8} lg={8} padding={1}>{ transaction.data.valueDate }</Grid>
                        <Grid item xs={4} md={4} lg={4} padding={1}>Fee 1</Grid>
                        <Grid item xs={8} md={8} lg={8} padding={1}>{ formatNumber(transaction.data.fee1) }</Grid>
                        <Grid item xs={4} md={4} lg={4} padding={1}>Fee 2</Grid>
                        <Grid item xs={8} md={8} lg={8} padding={1}>{ formatNumber(transaction.data.fee2) }</Grid>
                        <Grid item xs={4} md={4} lg={4} padding={1}>Payment Amount</Grid>
                        <Grid item xs={8} md={8} lg={8} padding={1}>{ formatNumber(transaction.data.paymentAmount) }</Grid>
                    </Grid>
                </Grid>
            </Container>
    )}
}
