import {DataGridPremium, GridColDef} from "@mui/x-data-grid-premium";
import React, {ReactElement} from "react";
import {GridInitialStatePremium} from "@mui/x-data-grid-premium/models/gridStatePremium";
import {CashDividendUpdateDto} from "../../domain/dividend/CashDividendUpdateDto";
import {useAutoSizeGrid} from "../../components/AutoSizeGridHook";

export type CashDividendHeaderPageProps = {
    dividend: CashDividendUpdateDto
}

export default function CashDividendHeaderPage(params: Readonly<CashDividendHeaderPageProps>): ReactElement {
    const apiRef = useAutoSizeGrid<CashDividendUpdateDto>({
        data: [ params.dividend ],
        loading: false,
        columns: [
            'name',
            'shortName',
            'isin',
            'bloombergCode'
        ]

    });

    const initialState: GridInitialStatePremium =
        {
            columns: {
                columnVisibilityModel: {
                },
                dimensions: {
                    name: { flex: 1 },
                    shortName: { width: 200 },
                    isin: { width: 200 },
                    bloombergCode: { width: 200 },
                }
            }
        }

    const columns: GridColDef<CashDividendUpdateDto>[] = [
        { field: 'name', headerName: 'Name' },
        { field: 'shortName', headerName: 'Short Name' },
        { field: 'isin', headerName: 'ISIN' },
        { field: 'bloombergCode', headerName: 'BloombergCode' },
    ];

    return (
            <DataGridPremium
                apiRef={apiRef}
                rows={ [ params.dividend ] }
                initialState={initialState}
                columns={columns}
                getRowId={ (row) => row.id }
                autoHeight={true}
hideFooter={true}
            />
    );

}
