import {Dayjs} from "dayjs";
import {CashDividendState} from "./CashDividendState";

export type CashDividendSearchTerm = {
    instrument: string,
    fromExDate: Dayjs | null,
    toExDate: Dayjs | null,
    states: CashDividendState[],
}

export function defaultCashDividendSearchTerm(): CashDividendSearchTerm {
    return {
        instrument: '',
        fromExDate: null,
        toExDate: null,
        states: []
    } as CashDividendSearchTerm;
}