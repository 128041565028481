import React, {ReactElement, useEffect} from "react";
import {GridInitialStatePremium} from "@mui/x-data-grid-premium/models/gridStatePremium";
import {
    DataGridPremium,
    GridColDef,
    GridRenderCellParams,
    GridValueGetterParams
} from "@mui/x-data-grid-premium";
import {DividendFilterConfig} from "../domain/assetmanager/DividendFilterConfig";
import {
    useCreateDividendFilterDefaultConfiguration,
    useGetDividendFilterConfigurations
} from "../application/DividendConfigService";
import {GetDataApi} from "./types/GetDataApi";
import {Link, useNavigate} from "react-router-dom";
import Grid from "@mui/material/Grid";
import CreateDividendAnyConfigurationDialog from "./CreateDividendAnyConfigurationDialog";
import {CashDividendSource, toHumanReadableFormat} from "../domain/dividend/CashDividendSource";
import {ResponseDataFunction} from "./types/ResponseDataFunction";
import Title from "./Title";
import {useAutoSizeGrid} from "./AutoSizeGridHook";

export default function DividendFilterConfigurationsComponent(): ReactElement {
    const navigation = useNavigate();
    const getFunction: GetDataApi<DividendFilterConfig[]> = useGetDividendFilterConfigurations();
    const createFunction: ResponseDataFunction<CashDividendSource, DividendFilterConfig> = useCreateDividendFilterDefaultConfiguration();
    const apiRef = useAutoSizeGrid<DividendFilterConfig>({
        data: getFunction.data,
        loading: getFunction.loading,
        columns: [
            'id',
            'source',
            'instrumentPrefix',
            'accountNumber'
        ]
    });
    const initialState: GridInitialStatePremium =
        {
            columns: {
                dimensions: {
                    id: { width: 100},
                    source: {width: 150},
                    instrumentPrefix: {width: 300},
                    accountNumber: {width: 300},
                }
            }
        }

    useEffect(() => {
        if (createFunction.error) {
            navigation("dividends/config");
        } else if (createFunction.data.id > 0) {
            navigation("filter/" + createFunction.data.id);
        }
    }, [ createFunction.data, createFunction.error ]);

    const columns: GridColDef<DividendFilterConfig>[] = [
        {
            field: 'id', headerName: 'ID',
            renderCell: (params: GridRenderCellParams<DividendFilterConfig>) => <Link to={ "filter/" + params.row.id.toString() }>{params.row.id}</Link>
        },
        { field: 'source', headerName: 'Dividend Source', valueGetter: (params: GridValueGetterParams<DividendFilterConfig>) => { return toHumanReadableFormat(params.row.source)}},
        { field: 'accountNumber', headerName: 'Account Number'},
        { field: 'instrumentPrefix', headerName: "Instrument Prefix"}
    ];

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={8} lg={8} padding={1}>
                <Title>Filter Configuration</Title>
            </Grid>
            <Grid item xs={12} md={3} lg={3} padding={1}>
                <CreateDividendAnyConfigurationDialog
                    title={ "Filter"}
                    createFunction={ createFunction }
                    initialSource={ CashDividendSource.VPB }
                    urlPath={ "filter" }
                    toolTip={ "Rules for filtering out dividends that we do not want imported." }
                    usage={ "This creates filters that omits data read from the dividend reports when filter matches." }
                />
            </Grid>
            <Grid item xs={12} md={12} lg={12} padding={1}>
                <DataGridPremium
                    apiRef={apiRef}
                    initialState={initialState}
                    loading={getFunction.loading}
                    rows={getFunction.data}
                    columns={columns}
                    density={"compact"}
                    getRowId={(row) => row.id}
                    autoHeight={true}
                    hideFooter={true}
                />
            </Grid>
        </Grid>
    );

}