import React, {ReactElement, useEffect, useState} from "react";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import {
    useDeleteDividendPaymentDateConfiguration,
    useGetDividendPaymentDateConfiguration,
    useSaveDividendPaymentDateConfiguration
} from "../../application/DividendConfigService";
import {DeleteForeverRounded, SaveRounded} from "@mui/icons-material";
import RolesRetriever from "../../authorization/RolesRetreiver";
import {Button, Checkbox, FormControlLabel, TextField} from "@mui/material";
import SnackbarComponent, {defaultSnackbarState, SnackbarState} from "../../components/SnackbarComponent";
import Title from "../../components/Title";
import {useNavigate} from "react-router-dom";
import {ResponseDataFunction} from "../../components/types/ResponseDataFunction";
import {DividendPaymentDateConfig} from "../../domain/assetmanager/DividendPaymentDateConfig";
import {RequestDataFunction} from "../../components/types/RequestDataFunction";
import {toHumanReadableFormat} from "../../domain/dividend/CashDividendSource";

export default function DividendPaymentDateConfigurationPage(): ReactElement {
    const navigate = useNavigate();
    const [snackbarState, setSnackbarState] = useState<SnackbarState>(defaultSnackbarState);
    const getFunction = useGetDividendPaymentDateConfiguration();
    const deleteFunction: RequestDataFunction<number> = useDeleteDividendPaymentDateConfiguration();
    const saveFunction: ResponseDataFunction<DividendPaymentDateConfig, DividendPaymentDateConfig> = useSaveDividendPaymentDateConfiguration();
    const [isinPrefix, setIsinPrefix] = useState<string | null>("");
    const [instrumentCurrency, setInstrumentCurrency] = useState<string | null>("");
    const [defaultRule, setDefaultRule] = useState<boolean>(false);
    const [daysAfterExdate, setDaysAfterExdate] = useState<number>(30);

    let saveInProgress = false;

    useEffect(() => {
        setIsinPrefix(getFunction.data.isinPrefix);
        setInstrumentCurrency(getFunction.data.instrumentCurrency);
        setDefaultRule(getFunction.data.defaultRule);
        setDaysAfterExdate(getFunction.data.daysAfterExdate);
    }, [ getFunction.data ]);

    useEffect(() => {
        if (getFunction.error) {
            navigate("/dividends/config");
            setSnackbarState({message: "Failed to load item", open: true, severity: "error"})
        }
    }, [ getFunction.error ])

    useEffect(() => {
        saveInProgress = false;
        if (saveFunction.data.id > 0) {
            setSnackbarState({ open: true, message: "Save successful", severity: "success" } );
            navigate("/dividends/config")
        }
    }, [ saveFunction.data ]);

    useEffect(() => {
        saveInProgress = false;
        if (saveFunction.error) {
            setSnackbarState({ open: true, message: "Save failed: " + saveFunction.error, severity: 'error' });
        }
    }, [ saveFunction.error ]);


    const saveConfiguration = () => {
        saveInProgress = true;
        saveFunction.apiFunction(getFunction.data);
    }

    const deleteConfiguration = () => {
        saveInProgress = true;
        deleteFunction.apiFunction(getFunction.data.id)
            .finally(() => {
                saveInProgress = false
                navigate("/dividends/config");
            });
    }


    const handleInstrumentCurrency = (event: React.ChangeEvent<HTMLInputElement>) => {
        getFunction.data.instrumentCurrency = event.target.value;
        setInstrumentCurrency(event.target.value);
    }

    const handleIsinPrefix = (event: React.ChangeEvent<HTMLInputElement>) => {
        getFunction.data.isinPrefix = event.target.value;
        setIsinPrefix(event.target.value);
    }

    const handleDefaultRule = (event: React.ChangeEvent<HTMLInputElement>) => {
        getFunction.data.defaultRule = event.target.checked;
        setDefaultRule(event.target.checked);
    }

    const handleDaysAfterExdate = (event: React.ChangeEvent<HTMLInputElement>) => {
        getFunction.data.daysAfterExdate = parseInt(event.target.value);
        setDaysAfterExdate(parseInt(event.target.value));
    }

    if (getFunction.loading) {
        return (<></>);
    } else {
        return (
            <React.Fragment>
                <Container maxWidth={ false } sx={{ mt: 4, mb: 4 }}>
                    { <Toolbar/> }
                    <Grid container spacing={2} justifyItems={ "center" } alignItems={ "center" }>
                        <Grid item xs={12} md={8} lg={8} >
                            <Title>Dividend PaymentDate Configuration {getFunction.data.id} { toHumanReadableFormat(getFunction.data.source) }</Title>
                        </Grid>
                        <Grid item xs={12} md={2} lg={2}>
                            <RolesRetriever groups={ [ "super" ]}>
                                <Button variant='outlined'
                                        startIcon={<DeleteForeverRounded/>}
                                        disabled={saveInProgress}
                                        onClick={() => { deleteConfiguration() }}>
                                    Delete
                                </Button>
                            </RolesRetriever>
                        </Grid>
                        <Grid item xs={12} md={2} lg={2}>
                            <RolesRetriever groups={ [ "super" ]}>
                                <Button variant='outlined'
                                        startIcon={<SaveRounded/>}
                                        disabled={saveInProgress}
                                        onClick={() => { saveConfiguration(); }}>
                                    Save
                                </Button>
                            </RolesRetriever>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <FormControlLabel control={
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="isinPrefix"
                                    label="ISIN Prefix"
                                    fullWidth
                                    variant="standard"
                                    value={ isinPrefix }
                                    onChange={ handleIsinPrefix }
                                />
                            } label="ISIN prefix to apply this rule to" />
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <FormControlLabel control={
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="instrumentCurrency"
                                    label="Instrument Currency"
                                    fullWidth
                                    variant="standard"
                                    value={ instrumentCurrency }
                                    onChange={ handleInstrumentCurrency }
                                />
                            } label="Instrument Currency to apply this rule to" />
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <FormControlLabel control={
                                <Checkbox
                                    checked={ defaultRule }
                                    onChange={ handleDefaultRule }
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            } label="Default Rule, when checked, this will match anything not matching the other rules." />
                        </Grid>

                        <Grid item xs={12} md={12} lg={12}>
                            <FormControlLabel control={
                                <TextField inputProps={{ inputMode: 'numeric', pattern: '[0-9]+' }} autoFocus
                                    margin="dense"
                                    id="daysAfterExdate"
                                    label="Days After ExDate"
                                    fullWidth
                                    variant="standard"
                                    value={ daysAfterExdate }
                                    onChange={ handleDaysAfterExdate }
                                />
                            } label="Days after ExDate when we estimate paydate when not provided." />
                        </Grid>


                    </Grid>
                </Container>
                <SnackbarComponent state={ snackbarState } setState={ setSnackbarState }/>
            </React.Fragment>
        )}
}
