import React, {ReactElement} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import AssetManagerValidationRule from "./AssetManagerValidationRule";
import {AssetManagerFileConfig} from "../../domain/assetmanager/AssetManagerFileConfig";

export type AssetManagerValidationConfigurationProps = {
    configuration: AssetManagerFileConfig
}

export default function AssetManagerValidationConfiguration(props: Readonly<AssetManagerValidationConfigurationProps>): ReactElement {
    return (
            <Grid container
                  spacing={2}
                  sx = {{ borderRadius: '12px', border:2 , borderColor:'primary.main', padding: 2, marginTop: "12px" }}>
                <Grid item xs={12} md={12} lg={12}>
                    <Typography component="h4" variant="h6" color="primary" gutterBottom>
                        Validation Rules
                    </Typography>
                </Grid>
                { props.configuration.validationRules.map((i, index) => (
                    <AssetManagerValidationRule configuration={ props.configuration } index={ index } key={i.id}/>
                )) }
            </Grid>
    );
}