import React, {ReactElement, useState} from "react";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Title from "../../components/Title";
import {DataGridPremium, GridColDef, GridToolbar} from "@mui/x-data-grid-premium";
import {formatSwedishDateTime} from "../../components/TimeFormat";
import {useGetPortfolio, useSyncPortfolio} from "../../application/PortfolioService";
import SnackbarComponent, {defaultSnackbarState, SnackbarState} from "../../components/SnackbarComponent";
import {RefreshDataFunction} from "../../components/types/RefreshDataFunction";
import {Statistics} from "../../domain/staticdata/Statistics";
import {GridInitialStatePremium} from "@mui/x-data-grid-premium/models/gridStatePremium";
import {useAutoSizeGrid} from "../../components/AutoSizeGridHook";
import {Portfolio} from "../../domain/staticdata/Portfolio";
import RefreshStaticComponent from "../../components/RefreshStaticComponent";


export default function Portfolios(): ReactElement {
    const { data, loading, apiFunction } = useGetPortfolio();
    const [ snackbarState, setSnackbarState ] = useState<SnackbarState>(defaultSnackbarState);
    const syncPortfolio: RefreshDataFunction<Statistics> = useSyncPortfolio();
    const apiRef = useAutoSizeGrid<Portfolio>({
        data: data,
        loading: loading,
        columns: [
            'shortName',
            'profile',
            'reference',
            'changedTimestamp',
            'fullName'
        ]
    })

    const initialState: GridInitialStatePremium =
        {
            columns: {
                columnVisibilityModel: {
                    reference: false
                },
                dimensions: {
                    shortName: { width: 200 },
                    profile: { width: 100 },
                    reference: { width: 100 },
                    changedTimestamp: { width: 200 },
                    fullName: { flex: 1 }
                }
            }
        }

    const columns: GridColDef[] = [
        { field: 'shortName', headerName: 'SCD ID' },
        { field: 'profile', headerName: 'Profile' },
        { field: 'reference', headerName: 'Reference' },
        { field: 'changedTimestamp', headerName: 'changedTimestamp', renderCell: (params) =>
                formatSwedishDateTime(params.row.changedTimestamp)
        },
        { field: 'fullName', headerName: 'Name', flex: 1 },

    ];

    return (
            <Container maxWidth={ false } sx={{mt: 4, mb: 4}}>
                {<Toolbar/>}
                <Grid container spacing={2} alignItems={"center"} justifyItems={"center"}>
                    <Grid item xs={12} md={10} lg={10}>
                        <Title>Portfolios</Title>
                    </Grid>
                    <RefreshStaticComponent
                        refreshFunction={ syncPortfolio }
                        dataFunction={ apiFunction }
                        setSnackbarState={ setSnackbarState }
                    />
                    <Grid item xs={12} md={12} lg={12} height={ 1000 }>
                        <DataGridPremium
                            apiRef={apiRef}
                            initialState={initialState}
                            slots={{ toolbar: GridToolbar }}
                            loading={loading}
                            rows={data}
                            columns={columns}
                            density={"compact"}
                            getRowId={(row) => row.shortName}
                        />
                    </Grid>
                </Grid>
                <SnackbarComponent setState={ setSnackbarState} state={ snackbarState}/>
            </Container>
    );
}