import React, {ReactElement} from "react";
import {CashDividend} from "../domain/dividend/CashDividend";
import {RequestDataFunction} from "./types/RequestDataFunction";
import {
    ManyDividendsIdUpdate,
    useDiscardManyDividend,
    useTransferManyDividendToIms
} from "../application/CashDividendService";
import Grid from "@mui/material/Grid";
import DividenBulkActionComponent, {TransferableState} from "./DividenBulkActionComponent";
import {CashDividendState} from "../domain/dividend/CashDividendState";
import {PageDto} from "../domain/PageDto";
import {Button, Tooltip} from "@mui/material";
import {RefreshRounded} from "@mui/icons-material";

export type DividendBulkUpdateParams = {
    data: PageDto<CashDividend>,
    refreshFunction: () => void;
};

export default function DividendBulkUppdateComponent(params: Readonly<DividendBulkUpdateParams>): ReactElement {
    const approveFunction: RequestDataFunction<ManyDividendsIdUpdate> = useTransferManyDividendToIms();
    const discardFunction: RequestDataFunction<ManyDividendsIdUpdate> = useDiscardManyDividend();

    const stateFilter = (d: CashDividend, state: TransferableState): boolean => {
        if ((state === TransferableState.DIFF && d.state === CashDividendState.DIFF)
           || (state === TransferableState.MATCH && d.state === CashDividendState.MATCH)
           || (state === TransferableState.UPDATE && d.state === CashDividendState.UPDATE)) {
            return true;
        }
        return false;
    }

    const updateCompleted = () => {
        params.refreshFunction();
    }

    const discardAll = (state: TransferableState) => {
        const toDiscard = params.data.data
            .filter(d => stateFilter(d, state))
            .map(d => d.id);
        discardFunction.apiFunction({ dividendIds: toDiscard, completedFunction: updateCompleted });
    }

    const transferAll = (state: TransferableState) => {
        const toApprove = params.data.data
            .filter(d => stateFilter(d, state))
            .map(d => d.id);
        approveFunction.apiFunction({ dividendIds: toApprove, completedFunction: updateCompleted });
    }


    function isLoading() {
        return discardFunction.loading || approveFunction.loading;
    }

    return (
        <React.Fragment>
            <Grid item xs={12} md={4} lg={4}>
                <Tooltip title={ "Refresh data in table" } placement={ "top" }>
                    <Button variant='outlined' color="secondary" startIcon={<RefreshRounded/>} onClick={ params.refreshFunction } disabled={ isLoading() }>
                        Refresh
                    </Button>
                </Tooltip>
            </Grid>
            <Grid item xs={12} md={1} lg={1}>
                <DividenBulkActionComponent action={ 'Approve' } apiFunction={ transferAll } loading = { discardFunction.loading || approveFunction.loading }/>
            </Grid>
            <Grid item xs={12} md={1} lg={1}>
                <DividenBulkActionComponent action={ 'Discard' } apiFunction={ discardAll } loading = { discardFunction.loading || approveFunction.loading }/>
            </Grid>
        </React.Fragment>
    );
}


