import React, {ReactElement, useState} from "react";
import {SearchDataFunction} from "../../components/types/SearchDataFunction";
import {PageDto} from "../../domain/PageDto";
import Grid from "@mui/material/Grid";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import {ExpandLess, ExpandMore, SearchRounded} from "@mui/icons-material";
import {Collapse, MenuItem, TextField, Tooltip} from "@mui/material";
import {DesktopDatePicker} from "@mui/x-date-pickers";
import {TransactionProcessingResult} from "../../domain/transaction/TransactionProcessingResult";
import {RefusedTransactionSearchTerm} from "../../domain/transaction/RefusedTransactionSearchTerm";
import {RefusedTransactionStatus} from "../../domain/transaction/RefusedTransactionStatus";
import {Dayjs} from "dayjs";


const statuses = [
    {
        value: RefusedTransactionStatus.ACTIVE,
        label: 'ACTIVE'
    },
    {
        value: RefusedTransactionStatus.SOLVED,
        label: 'SOLVED'
    },
    {
        value: RefusedTransactionStatus.ACKNOWLEDGED,
        label: 'ACKNOWLEDGED'
    },
    {
        value: RefusedTransactionStatus.ANY,
        label: "ANY"
    }
];

export type InboundTransactionSearchBarParams = {
    searchFn: SearchDataFunction<RefusedTransactionSearchTerm, PageDto<TransactionProcessingResult>>;
}

export default function InboundTransactionSearchBar(params: Readonly<InboundTransactionSearchBarParams>): ReactElement {
    const [state, setState] = useState<boolean>(false);

    function onChangeTransaction(event: React.ChangeEvent<HTMLInputElement>) {
        const merged = {...params.searchFn.searchTerm, ...{transaction: event.target.value}};
        params.searchFn.setSearchTermFunction(merged);
    }

    function onChangeIdentifier(event: React.ChangeEvent<HTMLInputElement>) {
        const merged = {...params.searchFn.searchTerm, ...{identifier: event.target.value}};
        params.searchFn.setSearchTermFunction(merged);
    }

    function onChangeAssetManager(event: React.ChangeEvent<HTMLInputElement>) {
        const merged = {...params.searchFn.searchTerm, ...{assetManager: event.target.value}};
        params.searchFn.setSearchTermFunction(merged);
    }

    function onChangeFromDate(value: Dayjs | null) {
        const merged = {...params.searchFn.searchTerm, ...{fromDate: value }};
        params.searchFn.setSearchTermFunction(merged);
    }

    function onChangeToDate(value: Dayjs | null) {
        const merged = {...params.searchFn.searchTerm, ...{toDate: value }};
        params.searchFn.setSearchTermFunction(merged);
    }

    function onChangeStatus(event: React.ChangeEvent<HTMLInputElement>) {
        const value = event.target.value as RefusedTransactionStatus;
        if (value === RefusedTransactionStatus.ANY) {
            const merged = {...params.searchFn.searchTerm, ...{status: null }};
            params.searchFn.setSearchTermFunction(merged);
        } else {
            const merged = {...params.searchFn.searchTerm, ...{status: value }};
            params.searchFn.setSearchTermFunction(merged);
        }
    }

    function handleSearchClick() {
        setState(!state);
    }

    return (
        <React.Fragment>
                <Grid item xs={1} md={1} lg={1}>
                    <Tooltip title={"Search"} placement={ "top" }>
                            <ListItemButton onClick={handleSearchClick}>
                                <ListItemIcon>
                                    <SearchRounded/>
                                </ListItemIcon>
                                {state ? <ExpandLess/> : <ExpandMore/>}
                            </ListItemButton>
                    </Tooltip>
                </Grid>
                <Collapse in={state} timeout="auto" unmountOnExit>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} lg={2}>
                            <Tooltip title={"Event Time, inclusive" } placement={ "top" }>
                                <span>
                                    <DesktopDatePicker
                                        label="From Date"
                                        format="YYYY-MM-DD"
                                        value={params.searchFn.searchTerm.fromDate}
                                        onChange={ (value) => { onChangeFromDate(value) }}
                                    />
                                </span>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12} md={6} lg={2}>
                            <Tooltip title={"Event Time, inclusive"} placement="top">
                                <span>
                                    <DesktopDatePicker
                                        label="To Date"
                                        value={params.searchFn.searchTerm.toDate}
                                        format="YYYY-MM-DD"
                                        onChange={ (value) => { onChangeToDate(value) }}
                                    />
                                </span>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12} md={6} lg={2}>
                            <Tooltip title={"State, SOLVED is when assetmanger uploaded new version of trade, ACKNOWLEDGE means manually confirmed fix"} placement={ "top" }>
                                <TextField
                                    fullWidth={true}
                                    select
                                    label="Status"
                                    id="outlined-select-separator"
                                    value={ params.searchFn.searchTerm.status }
                                    onChange={ onChangeStatus } >
                                    { statuses.map((option) => (
                                        <MenuItem key={option.label } value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12} md={6} lg={2}>
                            <Tooltip title={ "Asset Manager, min 2 characters required" } placement={ "top" }>
                                <TextField
                                    label="Asset Manager"
                                    id="assetmanager-search"
                                    value={params.searchFn.searchTerm.assetManager}
                                    onChange={onChangeAssetManager}
                                />
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12} md={6} lg={2}>
                            <Tooltip title={"External Transaction ID, i.e. Asset Managers Transaction ID, min 6 characters"} placement={ "top" }>
                                <TextField
                                    label="Transaction ID"
                                    id="transaction-id-search"
                                    value={params.searchFn.searchTerm.transaction}
                                    onChange={onChangeTransaction}
                                />
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12} md={6} lg={2}>
                            <Tooltip title={"Identifier, Stock Identifier or Future Identifier or Currency pair, min 3 characters"} placement={ "top" }>
                                <TextField
                                    label="Identifier"
                                    id="identifier-id-search"
                                    value={params.searchFn.searchTerm.identifier}
                                    onChange={onChangeIdentifier}
                                />
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Collapse>
        </React.Fragment>
    );
}