import React, {Dispatch, ReactElement, SetStateAction} from "react";
import {Dayjs} from "dayjs";
import Grid from "@mui/material/Grid";
import {Tooltip} from "@mui/material";
import {DesktopDatePicker} from "@mui/x-date-pickers";
import {DelayedTransactionSearchTerm} from "../domain/transaction/DelayedTransactionSearchTerm";
import Title from "./Title";

export type TransactionReportControlPanelParams = {
    searchTerm: DelayedTransactionSearchTerm
    searchFn: Dispatch<SetStateAction<DelayedTransactionSearchTerm>>;
    assetManager: string;
}

export default function DelayedTransactionControlPanel(params: Readonly<TransactionReportControlPanelParams>): ReactElement {

    function onChangeFromDate(value: Dayjs): void {
        const merged = {...params.searchTerm, ...{fromDate: value, assetManager: params.assetManager}};
        params.searchFn(merged);
    }

    function onChangeToDate(value: Dayjs): void {
        const merged = {...params.searchTerm, ...{toDate: value}};
        params.searchFn(merged);
    }

    return (
        <Grid container spacing={2} justifyItems={'center'} alignItems={'center'}>
            <Grid item xs={12} md={12} lg={8}>
                <Title>Late Transactions for { params.assetManager} between dates </Title>
            </Grid>
            <Grid item xs={12} md={6} lg={2}>
                <Tooltip title={"tradeDate, inclusive"} placement={"top"}>
                            <span>
                                <DesktopDatePicker
                                    label="From Date"
                                    format="YYYY-MM-DD"
                                    value={params.searchTerm.fromDate}
                                    onChange={(value) => {
                                        if (value !== null) {
                                            onChangeFromDate(value);
                                        }
                                    }}
                                />
                            </span>
                </Tooltip>
            </Grid>
            <Grid item xs={12} md={6} lg={2}>
                <Tooltip title={"tradeDate, inclusive"} placement={"top"}>
                            <span>
                                <DesktopDatePicker
                                    label="To Date"
                                    value={params.searchTerm.toDate}
                                    format="YYYY-MM-DD"
                                    onChange={(value) => {
                                        if (value !== null)
                                            onChangeToDate(value);
                                        }
                                    }
                                />
                            </span>
                </Tooltip>
            </Grid>
        </Grid>
    );
}