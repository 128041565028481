import {useCallback, useState} from "react";
import axios, {AxiosRequestConfig} from "axios";
import {RequestDataFunction} from "../components/types/RequestDataFunction";
import {getGatewayUrl} from "../components/EnvironmentConfiguration";

export type ProducerServiceProps = {
    fileOrigin: string,
    fileName: string
}

export type FileOriginResolver = {
    fileOrigin: string,
    assetManager: string
}

export function extractFileOrigin(o: FileOriginResolver): string {
    if (o.fileOrigin) {
        return o.fileOrigin;
    }
    return o.assetManager;
}

export function useProducerService(): RequestDataFunction<ProducerServiceProps> {
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const apiFunction = useCallback(
        async (data: ProducerServiceProps) => {
            setLoading(true);
            const body = JSON.stringify({
                "fileName": data.fileName
            });
            const config: AxiosRequestConfig = {
                responseType: 'blob',
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            axios.post(getGatewayUrl() + "/transaction/v1/" + data.fileOrigin + '/findFileByName', body, config)
                .then((response) => {
                    // create file link in browser's memory
                    const href = URL.createObjectURL(response.data);

                    // create "a" HTML element with href to file & click
                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute('download', data.fileName); //or any other extension
                    document.body.appendChild(link);
                    link.click();

                    // clean up "a" element & remove ObjectURL
                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);
                })
                .catch((err) => setError(err.message))
                .finally(() => setLoading(false));
        },
        []
    );

    return { loading, error, apiFunction };

}