
export enum AuditEntityType {
    EQUITY_TRANSACTION = "EQUITY_TRANSACTION",
    FUTURE_TRANSACTION = "FUTURE_TRANSACTION",
    FX_TRANSACTION = "FX_TRANSACTION",
    ASSETMANAGER = "ASSETMANAGER",
    EQUITY = "EQUITY",
    FUTURE = "FUTURE",
    REFUSED_TRANSACTION = "REFUSED_TRANSACTION",
    BOND = "BOND",
    DIVIDEND = "DIVIDEND",
    COUNTERPART = "COUNTERPART",
    BROKER = "BROKER",
    CURRENCY = "CURRENCY",
    CURRENCYCROSS = "CURRENCYCROSS",
    PORTFOLIO = "PORTFOLIO",
    MODELPORTFOLIO = "MODELPORTFOLIO",
    MANDATE = "MANDATE",
    ANY = "ANY"
}