import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import {Button} from "@mui/material";
import {IPublicClientApplication} from "@azure/msal-browser";

function handleLogin(instance: IPublicClientApplication) {
    instance.loginRedirect(loginRequest).catch(e => {
        console.error(e);
    });
}

/**
 * Renders a button which, when selected, will redirect the page to the login prompt
 */
export const SignInButton = () => {
    const { instance } = useMsal();

    return (
        <Button color="secondary" className="ml-auto" onClick={() => handleLogin(instance)}>Sign In</Button>
    );
}