import React, {ReactElement, useState} from "react";
import Grid from "@mui/material/Grid";
import {Box} from "@mui/material";
import DraggableList from "../../components/DraggableList";
import {Item} from "../../components/types/Item";
import Typography from "@mui/material/Typography";
import {AssetManagerFileConfig} from "../../domain/assetmanager/AssetManagerFileConfig";
import {AssetManagerFieldConfig} from "../../domain/assetmanager/AssetManagerFieldConfig";
import {SupportedFields} from "../../domain/assetmanager/SupportedFields";
import {DropResult} from "react-beautiful-dnd";
import FieldSelectComponent from "../../components/FieldSelectComponent";

export type AssetManagerFieldConfigurationProps = {
    configuration: AssetManagerFileConfig
}
export default function AssetManagerFieldConfiguration(props: Readonly<AssetManagerFieldConfigurationProps>): ReactElement {
    const [items, setItems] = useState<Item[]>(toRenderingItems(props.configuration.fieldConfig));

    function lowestId(items: AssetManagerFieldConfig[]): AssetManagerFieldConfig {
        return items.reduce((a, b) => {
            if (a.id && b.id === null) {
                return a
            }
            if (b.id && a.id === null) {
                return b
            }
            if ((a.id || 0) < (b.id || 0)) {
                return a;
            }
            return b
        });
    }


    function toRenderingItems(items: AssetManagerFieldConfig[]): Item[] {
        if (items && items.length > 0) {
            const leastItem = lowestId(items);
            let leastId = leastItem?.id ?? 0;
            return items.map(i => {
                if (i.id) {
                    return {
                        id: i.id.toString(),
                        field: i.field,
                        column: i.column
                    }
                } else {
                    leastId --;
                    return {
                        id: leastId.toString(),
                        field: i.field,
                        column: i.column
                    }
                }

            })
        }
        return [];
    }
    const onDragEnd = ({ destination, source }: DropResult) => {
        // dropped outside the list
        if (!destination) return;

        const newItems: AssetManagerFieldConfig[] = reorder(props.configuration.fieldConfig, source.index, destination.index);
        reconfigureColumns(newItems);
        props.configuration.fieldConfig = newItems;
        setItems(toRenderingItems(newItems));
    };

    const reconfigureColumns = (newItems: AssetManagerFieldConfig[]): void => {
        let index = 0;
        newItems.forEach(i => {
            i.column = index;
            index++;
        });
    }

    function reorder<T>(list: T[], startIndex: number, endIndex: number): T[] {
        const result: T[] = Array.from(list) as T[];
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    }

    function removeField(removedField: SupportedFields) {
        const find = props.configuration.fieldConfig.find(i => i.field === removedField);
        if (find) {
            const result = Array.from(props.configuration.fieldConfig) as AssetManagerFieldConfig[];
            result.splice(props.configuration.fieldConfig.indexOf(find), 1);
            reconfigureColumns(result);
            props.configuration.fieldConfig = result;
            setItems(toRenderingItems(result));
        }
    }

    function addField(newField: SupportedFields): void {
        const result = Array.from(props.configuration.fieldConfig) as AssetManagerFieldConfig[];
        result.push({
            id: null,
            field: newField,
            column: props.configuration.fieldConfig.length
        });
        reconfigureColumns(result);
        props.configuration.fieldConfig = result;
        setItems(toRenderingItems(result));
    }

    return (
            <Grid container spacing={2} sx = {{ borderRadius: '12px', border:2, borderColor:'primary.main', padding: 2, marginTop: '12px' }}>
                <Grid item xs={12} md={3} lg={3}>
                    <Typography component="h4" variant="h6" color="primary" gutterBottom>
                        File Field Order
                    </Typography>
                </Grid>
                <Grid item xs={12} md={3} lg={3} alignItems={ 'right' }>
                    <Box display="flex" justifyContent="flex-end">
                        <FieldSelectComponent action={ 'Add Field' } apiFunction={ addField }/>
                    </Box>
                </Grid>
                <Grid item xs={12} md={3} lg={3} alignItems={ 'right' }>
                    <Box display="flex" justifyContent="flex-end">
                        <FieldSelectComponent action={ 'Remove Field' } apiFunction={ removeField }/>
                    </Box>
                </Grid>
                <Grid item xs={12} sx = {{ borderRadius: '12px', border:1 , borderColor:'primary.main', padding: 1, marginLeft: "2px" }}>
                    <DraggableList items={ items } onDragEnd={ onDragEnd }/>
                </Grid>
            </Grid>
    );
}