import {Transaction} from "./Transaction";
import {Action} from "./Action";
import {TransactionCode} from "./TransactionCode";

// AggregateFutureTransaction
type FutureTransactionInternal = {
    counterpartBicCode: string,
    // Broker
    brokerShort: string,
    brokerName: string,
    brokerId: string,
    brokerBicCode: string,
    // Instrument
    securityId: string,
    instrumentShort: string,
    instrumentName: string,
    instrumentType: string,
    bloombergId: string,
    // Deal information
    currency: string,
    quantity: number,
    price: number,
    fee1: number,
    quoteFactor: number
}

export type FutureTransaction = Transaction & FutureTransactionInternal;

export function emptyFutureTransaction() {
    return {
        transactionId: '',
        // Audit
        lastProcessed: '',
        requestedState: Action.ADD,
        // Portfolio
        assetManager: '',
        mandate: '',
        fund: '',
        fundShort: '',
        portfolio: '',
        // Counterparty
        counterpartShort: '',
        counterpartName: '',
        counterpartId: '',
        // Deal information
        externalTransactionId: '',
        transactionCode: TransactionCode.BUY_FUTURE,
        tradeDate: '',
        valueDate: '',
        originalFileName: '',
        originalFileRow: 0,
        counterpartBicCode: '',
        // Broker
        brokerShort: '',
        brokerName: '',
        brokerId: '',
        brokerBicCode: '',
        // Instrument
        securityId: '',
        instrumentShort: '',
        instrumentName: '',
        instrumentType: '',
        bloombergId: '',
        currency: '',
        quantity: 0,
        price: 0,
        fee1: 0,
        quoteFactor: 0
    }
}
