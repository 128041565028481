import * as React from 'react';
import { Draggable } from 'react-beautiful-dnd';

import {Avatar, ListItem, ListItemAvatar, ListItemText} from "@mui/material";
import {FormatLineSpacing} from "@mui/icons-material";
import {Item} from "./types/Item";

export type DraggableListItemProps = {
  item: Item;
  index: number;
};

const DraggableListItem = ({ item, index }: DraggableListItemProps) => {


  return (
    <Draggable draggableId={ item.id } index={index}>
      {(provided) => (
        <ListItem sx={{ height: 48 }}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <ListItemAvatar>
            <Avatar sx={{ width: 38, height: 38 }}>
              <FormatLineSpacing />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={ item.field } secondary = {item.column} />
        </ListItem>
      )}
    </Draggable>
  );
};

export default DraggableListItem;