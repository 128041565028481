
import React, {Dispatch} from "react";
import {
    TtradeTransactionResetRequest,
    useTransactionResetTimeoutStates
} from "../application/TransactionUpdateService";
import RolesRetriever from "../authorization/RolesRetreiver";
import {Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {RefreshRounded, SyncRounded} from "@mui/icons-material";
import {
    TtradeDividendReactivateRequest,
    useDividendReactivate
} from "../application/CashDividendService";
import {RequestDataFunction} from "./types/RequestDataFunction";
import {CashDividend} from "../domain/dividend/CashDividend";
import {CashDividendState} from "../domain/dividend/CashDividendState";
import CancelDividendDialog from "./CancelDividendDialog";
import {SnackbarState} from "./SnackbarComponent";

export type CashDividendStateRenderComponentParams = {
    dividend: CashDividend
    setButtonActive: Dispatch<React.SetStateAction<boolean>>
    setSnackbarState: Dispatch<React.SetStateAction<SnackbarState>>
    buttonActive: boolean
    refreshFunction: () => void
}

export function CashDividendStateRenderComponent(params: Readonly<CashDividendStateRenderComponentParams>) {

    const resetFunction: RequestDataFunction<TtradeTransactionResetRequest> = useTransactionResetTimeoutStates();
    const reactivateFunction: RequestDataFunction<TtradeDividendReactivateRequest> = useDividendReactivate();

    function reactivateDividend() {
        reactivateFunction.apiFunction({ dividendId: params.dividend.id.id, completedFunction: params.refreshFunction });
    }

    function updateDividend() {
        resetFunction.apiFunction({ids: [ params.dividend.requestId ], completedFunction: params.refreshFunction });
    }

    if ( params.dividend.state === CashDividendState.TIMEOUT) {
        return (<React.Fragment>
            <RolesRetriever groups={["admin", "super"]}>
                <Tooltip title={"Refresh dividend by reading state from SCD"} placement={"top"}>
                    <IconButton color="secondary" onClick={updateDividend} disabled={!params.buttonActive || resetFunction.loading || reactivateFunction.loading }>
                        <SyncRounded/>
                    </IconButton>
                </Tooltip>
            </RolesRetriever>
            {params.dividend.state}
            <RolesRetriever groups={["admin", "super"]}>
                <CancelDividendDialog setSnackbarState={params.setSnackbarState}
                                      dividendId={params.dividend.id.id}
                                      dividendShortName={params.dividend.shortName}
                                      exDate={params.dividend.dividends[0].exDate}
                                      refreshFunction={params.refreshFunction}
                />
            </RolesRetriever>
        </React.Fragment>);
    } else if ( params.dividend.state === CashDividendState.FAILED && params.dividend.transferredBy) {
        return (<React.Fragment>
            <RolesRetriever groups={["admin", "super"]}>
                <Tooltip title={"Make SCD try to import dividend again, and/or read state from SCD"}
                         placement={"top"}>
                    <IconButton color="warning" onClick={ reactivateDividend } disabled={reactivateFunction.loading || resetFunction.loading }>
                        <RefreshRounded/>
                    </IconButton>
                </Tooltip>
            </RolesRetriever>
            { params.dividend.state}
            <RolesRetriever groups={["admin", "super"]}>
                <CancelDividendDialog setSnackbarState={ params.setSnackbarState }
                                      dividendId={  params.dividend.id.id }
                                      dividendShortName={  params.dividend.shortName }
                                      exDate={  params.dividend.dividends[0].exDate }
                                      refreshFunction={params.refreshFunction}/>
            </RolesRetriever>
        </React.Fragment>);
    } else if ( params.dividend.state === CashDividendState.FAILED && ! params.dividend.transferredBy) {
        return (<React.Fragment>
            { params.dividend.state}
            <RolesRetriever groups={["admin", "super"]}>
                <CancelDividendDialog setSnackbarState={ params.setSnackbarState }
                                      dividendId={  params.dividend.id.id }
                                      dividendShortName={  params.dividend.shortName }
                                      exDate={  params.dividend.dividends[0].exDate }
                                      refreshFunction={params.refreshFunction}/>
            </RolesRetriever>
        </React.Fragment>);
    }
    return (<> { params.dividend.state} </>);
}