import axios from "axios";
import {useCallback, useState} from "react";

import {ResponseDataFunction} from "../components/types/ResponseDataFunction";
import {DpInstrumentBulkRequest} from "../domain/dataprovider/DpInstrumentBulkRequest";
import {DpInstrumentBulkResponse, emptyDpInstrumentBulkResponse} from "../domain/dataprovider/DpInstrumentBulkResponse";
import {getGatewayUrl} from "../components/EnvironmentConfiguration";

export function useUploadInstruments(): ResponseDataFunction<DpInstrumentBulkRequest, DpInstrumentBulkResponse> {
    const [responseData, setResponseData] = useState<DpInstrumentBulkResponse>(emptyDpInstrumentBulkResponse());
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const getFunction = useCallback(
        async (request: DpInstrumentBulkRequest) => {
            setLoading(true);
            const bodyFormData = new FormData();
            bodyFormData.append('file', request.file);
            await axios.post(getGatewayUrl() + '/instrument/v1/bulk/' +request.instrumentType + '/' + request.identifierType,
                bodyFormData,
                { headers: { "Content-Type": "multipart/form-data" }})
                .then((response) => {
                    setResponseData(response.data as DpInstrumentBulkResponse);
                })
                .catch((err) => setError(err.message))
                .finally(() => setLoading(false));
        },
        []
    );

    return { data: responseData, loading, error, apiFunction: getFunction };
}