import React, {useState} from "react";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import SnackbarComponent, {SnackbarState} from "./SnackbarComponent";

// hook to check the app version on route change

export default function VersionCheck() {
    const location = useLocation();
    const [ state, setState ] = useState<SnackbarState>({ message: '', severity: 'info', open: false});

    React.useLayoutEffect(() => {
        // if there is an update available and no state passed to route
        const date = window.localStorage.getItem('ttrade-last-reload-1')
        if (date === null) {
            window.localStorage.setItem('ttrade-last-reload-1', dayjs().toISOString());
            setState({ message: "Reloaded T-Trade to ensure that you have the latest version" , severity: "success", open: true } );
            return;
        }
        if (!location.state && dayjs(date).isBefore(dayjs().subtract(1, 'day'))) {
            window.localStorage.removeItem('ttrade-last-reload-1'); // remove the storage object
            window.location.reload(); // refresh the browser
        }
    }, [location]);

    return (
        <SnackbarComponent setState={ setState } state={ state }/>
    );
}