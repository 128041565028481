
export type DividendConfig = {
    id: number,
    version: number,
    dpActiveDownload: boolean,
    dpActive: boolean,
    vpbActive: boolean,
    autoApproveMatch: boolean,
    holdCurrencyMatch: boolean,
    holdNetMatch: boolean
};

export function emptyDividendConfig(): DividendConfig {
    return {
        id: 0,
        version: 0,
        dpActive: false,
        dpActiveDownload: false,
        vpbActive: false,
        autoApproveMatch: false,
        holdCurrencyMatch: false,
        holdNetMatch: false
    }
}