import React, {ReactElement, useState} from 'react';
import Toolbar from "@mui/material/Toolbar";
import {DataGridPremium } from '@mui/x-data-grid-premium';
import { GridColDef, GridToolbar } from '@mui/x-data-grid';
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Title from "../../components/Title";
import {formatSwedishDateTime} from "../../components/TimeFormat";
import {useGetCounterpart, useSyncCounterpart} from "../../application/CounterpartService";
import {RefreshDataFunction} from "../../components/types/RefreshDataFunction";
import {Statistics} from "../../domain/staticdata/Statistics";
import SnackbarComponent, {defaultSnackbarState, SnackbarState} from "../../components/SnackbarComponent";
import {GridInitialStatePremium} from "@mui/x-data-grid-premium/models/gridStatePremium";
import {useAutoSizeGrid} from "../../components/AutoSizeGridHook";
import {Counterpart} from "../../domain/staticdata/Counterpart";
import RefreshStaticComponent from "../../components/RefreshStaticComponent";

export default function Counterparts(): ReactElement {

    const { data, loading, apiFunction } = useGetCounterpart();
    const [ snackbarState, setSnackbarState ] = useState<SnackbarState>(defaultSnackbarState);
    const syncCounterpart: RefreshDataFunction<Statistics> = useSyncCounterpart();
    const apiRef = useAutoSizeGrid<Counterpart>({
        data: data,
        loading: loading,
        columns: [
            'shortName',
            'swiftAddress',
            'fullName',
            'country',
            'parik',
            'assetManagers',
            'changedTimestamp'
        ]
    });
    const initialState: GridInitialStatePremium =
        {
            columns: {
                columnVisibilityModel: {
                },
                dimensions: {
                    shortName: { width: 200 },
                    fullName: { flex: 1 },
                    country: { width: 100 },
                    parik: { width: 150 },
                    assetManagers: { width: 250},
                    changedTimestamp: { width: 400 },
                    swiftAddress: { width: 150 }
                }
            }
        }

    const columns: GridColDef[] = [
        { field: 'shortName', headerName: 'Short Name' },
        { field: 'swiftAddress', headerName: 'Swift' },
        { field: 'fullName', headerName: 'Full Name'},
        { field: 'country', headerName: 'Country'},
        { field: 'parik', headerName: 'parik' },
        { field: 'assetManagers', headerName: 'Asset Managers'},
        { field: 'changedTimestamp', headerName: 'Changed Timestamp', renderCell: (params) =>
                formatSwedishDateTime(params.row.changedTimestamp)
        }
    ];
    return (
            <Container maxWidth={ false } sx={{ mt: 4, mb: 4 }}>
                { <Toolbar/> }
                <Grid container spacing={2} alignItems={"center"} justifyItems={"center"}>
                    <Grid item xs={12} md={10} lg={10}>
                        <Title>Counterparts</Title>
                    </Grid>
                    <RefreshStaticComponent refreshFunction={ syncCounterpart } dataFunction={ apiFunction } setSnackbarState={ setSnackbarState }/>
                    <Grid item xs={12} md={12} lg={12}  height={1000}>
                        <DataGridPremium
                            apiRef={apiRef}
                            initialState={initialState}
                            slots={{ toolbar: GridToolbar }}
                            loading={loading}
                            rows={data}
                            columns={columns}
                            density={"compact"}
                            getRowId={(row) => row.shortName}
                        />
                    </Grid>
                </Grid>
                <SnackbarComponent setState={ setSnackbarState} state={ snackbarState}/>
            </Container>
    );
}