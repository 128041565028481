export enum CashDividendState {
    DIFF = 'DIFF',
    MATCH = 'MATCH',
    UPDATE = 'UPDATE',
    APPROVED = 'APPROVED',
    DISCARDED = 'DISCARDED',
    TRANSFERRED = 'TRANSFERRED',
    CANCELLED = 'CANCELLED',
    OK = 'OK',
    FAILED = 'FAILED',
    TIMEOUT = 'TIMEOUT',
    ANY = 'ANY'
}