import React, {ReactElement} from "react";
import {AlertColor} from "@mui/material/Alert/Alert";
import {Alert, Snackbar} from "@mui/material";

export type SnackbarState = {
    open: boolean,
    message: string,
    severity: AlertColor
}

export const defaultSnackbarState = { open: false, message: '', severity: 'success'} as SnackbarState;

export type SnackbarComponentParams = {
    state: SnackbarState,
    setState: React.Dispatch<React.SetStateAction<SnackbarState>>
}

export default function SnackbarComponent(params: Readonly<SnackbarComponentParams>): ReactElement {

    const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        params.setState(defaultSnackbarState);
    };

    return (
        <Snackbar
            open={params.state.open}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}>
            <Alert onClose={handleSnackbarClose} severity={params.state.severity} sx={{ width: '100%' }}>
                {params.state.message}
            </Alert>
        </Snackbar>
    );
}