export enum SupportedFields {

    SECURITYID = 'SECURITYID',
    COUNTERPARTID = 'COUNTERPARTID',
    BROKER = 'BROKER',
    QUANTITY = 'QUANTITY',
    PRICE = 'PRICE',
    FEE1 = 'FEE1',
    FEE2 = 'FEE2',
    PAYMENTAMOUNT = 'PAYMENTAMOUNT',
    BASECURRENCY = 'BASECURRENCY',
    QUOTECURRENCY = 'QUOTECURRENCY',
    TRADEDATE = 'TRADEDATE',
    VALUEDATE = 'VALUEDATE',
    RATE = 'RATE',
    BASEAMOUNT = 'BASEAMOUNT',
    QUOTEAMOUNT = 'QUOTEAMOUNT',
    PORTFOLIO = 'PORTFOLIO',
    MANDATE = 'MANDATE',
    CURRENCY = 'CURRENCY',
    CLS = 'CLS',
    TRADER = 'TRADER',
    EXTERNALTRADEID = 'EXTERNALTRADEID',
    ACTION = 'ACTION',
    TRANSACTIONTYPE = 'TRANSACTIONTYPE',
    TRANSACTIONCODE = 'TRANSACTIONCODE',
    ASSETMANAGER = 'ASSETMANAGER',
    IGNORE = 'IGNORE'

}

export function optionalSupportedFields(): SupportedFields[] {
    return [ SupportedFields.ASSETMANAGER, SupportedFields.IGNORE ]
}