import React, {ReactElement, useState} from "react";
import Grid from "@mui/material/Grid";
import Title from "../../components/Title";
import {Checkbox, FormControlLabel, MenuItem, TextField} from "@mui/material";
import Divider from "@mui/material/Divider";
import AssetManagerFieldConfiguration from "./AssetManagerFieldConfiguration";
import AssetManagerValidationConfiguration from "./AssetManagerValidationConfiguration";
import ValidateFieldOrderDialog from "../../components/ValidateFieldOrderDialog";
import {AssetManagerFileConfig} from "../../domain/assetmanager/AssetManagerFileConfig";
import {FileSeparator} from "../../domain/assetmanager/FileSeparator";
import {TransactionType} from "../../domain/transaction/TransactionType";

export type AssetManagerConfigurationProps = {
    configuration: AssetManagerFileConfig,
    title: string,
    type: TransactionType,
    assetManager: string
}

export default function AssetManagerTxConfiguration(props: Readonly<AssetManagerConfigurationProps>): ReactElement {

    const [separator, setSeparator ] = useState(props.configuration.separator);
    const [extension, setExtension ] = useState(props.configuration.extension);
    const [automaticConfirm, setAutomaticConfirm] = useState<boolean>(props.configuration.automaticConfirm);

    const separators = [
        {
            value: FileSeparator.TAB,
            label: 'TAB'
        },
        {
            value: FileSeparator.COMMA,
            label: 'COMMA'
        },
        {
            value: FileSeparator.SEMICOLON,
            label: 'SEMICOLON'
        },
        {
            value: FileSeparator.HIGHBAR,
            label: 'HIGHBAR'
        },
    ];

    function setConfigSeparator(event: React.ChangeEvent<HTMLInputElement>) {
        props.configuration.separator = event.target.value as FileSeparator;
        setSeparator(props.configuration.separator);
    }

    function setConfigExtension(event: React.ChangeEvent<HTMLInputElement>) {
        props.configuration.extension = event.target.value;
        setExtension(props.configuration.extension);
    }

    const handleAutomaticConfirm = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.configuration.automaticConfirm = event.target.checked;
        setAutomaticConfirm(event.target.checked);
    }

    return (
        <React.Fragment>
            <Grid container spacing={2} >
                <Grid item xs={12}>
                    <Title>{props.title}</Title>
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                    <ValidateFieldOrderDialog assetManager={props.assetManager} configuration={props.configuration} type={ props.type }/>
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                    <TextField
                        select
                        label="Separator"
                        id="outlined-select-separator"
                        value={ separator }
                        onChange={setConfigSeparator} >
                        {separators.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                    <TextField
                        label="File Extension"
                        id="outlined-select-extension"
                        value={ extension }
                        onChange={setConfigExtension}
                    />
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                    <FormControlLabel control={
                        <Checkbox
                            checked={ automaticConfirm }
                            onChange={ handleAutomaticConfirm }
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    } label="Send Automatic CON" />
                </Grid>
                <Grid item xs={12} >
                    <Divider variant="middle"/>
                </Grid>
            </Grid>
            <AssetManagerValidationConfiguration configuration={ props.configuration }/>
            <AssetManagerFieldConfiguration configuration={ props.configuration } />

        </React.Fragment>
    );
}