import clsx from "clsx";
import {CashDividendState} from "../domain/dividend/CashDividendState";

export const StateSx =
{
    '& .ttrade-dividend.negative': {
        backgroundColor: 'rgba(247, 104, 130, 0.3)',
            color: '#1a3e72',
            fontWeight: '600',
    },
    '& .ttrade-dividend.positive': {
        backgroundColor: 'rgba(161, 240, 184, 0.3)',
            color: '#1a3e72',
            fontWeight: '600',
    },
    '& .ttrade-dividend.update': {
        backgroundColor: 'rgba(252, 214, 151, 0.3)',
            color: '#1a3e72',
            fontWeight: '600',
    },
    '& .ttrade-dividend.applied': {
        backgroundColor: 'rgba(161, 240, 184, 0.6)',
            color: '#1a3e72',
            fontWeight: '600',
    },
    '& .ttrade-dividend.discarded': {
        backgroundColor: 'rgba(152, 214, 250, 0.3)',
            color: '#1a3e72',
            fontWeight: '600',
    },
    '& .ttrade-dividend.failed': {
        backgroundColor: 'rgba(247, 104, 130, 0.6)',
            color: '#1a3e72',
            fontWeight: '600',
}
} as const;

export function getStateClass(state: CashDividendState) {
    return clsx('ttrade-dividend', {
        negative: state === CashDividendState.DIFF,
        positive: state === CashDividendState.MATCH,
        discarded: state === CashDividendState.DISCARDED,
        applied: [ CashDividendState.OK, CashDividendState.APPROVED, CashDividendState.TRANSFERRED ].find(v => state === v),
        failed: state === CashDividendState.FAILED || state === CashDividendState.TIMEOUT || state === CashDividendState.CANCELLED,
        update: state === CashDividendState.UPDATE
    })
}
