import {Action} from "../domain/transaction/Action";
import {TransactionSearchTerm} from "../domain/transaction/TransactionSearchTerm";
import {formatDayjsDateString} from "../components/TimeFormat";

export type TransactionSearchTermDto = {
    assetManager: string,
    counterpart: string,
    instrument: string,
    transaction: string,
    states: Action[],
    fromDate: string | null,
    toDate: string | null
}

export function convertTransactionSearchTerm(searchTerm: TransactionSearchTerm): TransactionSearchTermDto {
    return {
        assetManager: searchTerm.assetManager,
        counterpart: searchTerm.counterpart,
        instrument: searchTerm.instrument,
        transaction: searchTerm.transaction,
        states: searchTerm.states,
        fromDate: formatDayjsDateString(searchTerm.fromDate),
        toDate: formatDayjsDateString(searchTerm.toDate),
    }
}