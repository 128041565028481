import {Configuration, RedirectRequest} from "@azure/msal-browser";
import {getAppClientId, getAppTenantId, getAppUri, getRedirectUri} from "../components/EnvironmentConfiguration";

export const msalConfig: Configuration = {
    auth: {
        clientId: getAppClientId() || '',
        authority: "https://login.microsoftonline.com/" + getAppTenantId(),
        redirectUri: getRedirectUri()
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest: RedirectRequest = {
    scopes: [
        "User.Read"
    ]
};


export const tokenRequest = {
    scopes: [
        getAppUri() + "/.default",
    ]
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};