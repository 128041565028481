import {TTradeProcessingState} from "../../domain/transaction/TTradeProcessingState";
import {CashDividendState} from "../../domain/dividend/CashDividendState";

export type UiStateInfo = {
    state: TTradeProcessingState,
    title: string,
}

export type DividendStateInfo = {
    state: CashDividendState,
    title: string
}

export const allUiTtradeStates: UiStateInfo[] = [
    {
        "state": TTradeProcessingState.TRANSFERRED,
        "title": 'TRANSFERRED'
    },
    {
        "state": TTradeProcessingState.OK,
        "title": 'OK'
    },
    {
        "state": TTradeProcessingState.QUEUED,
        "title": 'QUEUED'
    },
    {
        "state": TTradeProcessingState.TIMEOUT,
        "title": 'TIMEOUT'
    },
    {
        "state": TTradeProcessingState.FAILED,
        "title": 'FAILED'
    },
    {
        "state": TTradeProcessingState.PROCESSING,
        "title": 'PROCESSING'
    }
];

export const allDividendStates: DividendStateInfo[] = [
    {
        "state": CashDividendState.UPDATE,
        "title": 'UPDATE'
    },
    {
        "state": CashDividendState.DIFF,
        "title": 'DIFF'
    },
    {
        "state": CashDividendState.MATCH,
        "title": 'MATCH'
    },
    {
        "state": CashDividendState.APPROVED,
        "title": 'APPROVED'
    },
    {
        "state": CashDividendState.DISCARDED,
        "title": 'DISCARDED'
    },
    {
        "state": CashDividendState.TRANSFERRED,
        "title": 'TRANSFERRED'
    },
    {
        "state": CashDividendState.OK,
        "title": 'OK'
    },
    {
        "state": CashDividendState.FAILED,
        "title": 'FAILED'
    },
    {
        "state": CashDividendState.CANCELLED,
        "title": 'CANCELLED'
    }
];