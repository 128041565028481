import {Link} from "react-router-dom";
import React, {ReactElement} from "react";
import {DataGridPremium, GridColDef, GridRenderCellParams, GridToolbar} from "@mui/x-data-grid-premium";
import Grid from "@mui/material/Grid";
import {SearchDataFunction} from "./types/SearchDataFunction";
import {TransactionSearchTerm} from "../domain/transaction/TransactionSearchTerm";
import {FxTransaction} from "../domain/transaction/FxTransaction";
import {PageDto} from "../domain/PageDto";
import {formatDateTimeShort} from "./TimeFormat";
import {GridInitialStatePremium} from "@mui/x-data-grid-premium/models/gridStatePremium";
import {useAutoSizeGrid} from "./AutoSizeGridHook";
import formatNumber from "./NumberFormat";

export type FxTransactionComponentParams = {
    searchFn: SearchDataFunction<TransactionSearchTerm, PageDto<FxTransaction>>
};

export default function FxTransactionComponent(params: Readonly<FxTransactionComponentParams>): ReactElement {

    const apiRef = useAutoSizeGrid<FxTransaction>({
        data: params.searchFn.data.data,
        loading: params.searchFn.loading,
        columns: [
            'transactionId',
            'assetManager',
            'mandate',
            'counterpartId',
            'counterpartShort',
            'tradeDate',
            'valueDate',
            'requestedState',
            'transactionCode',
            'baseCurrency',
            'quoteCurrency',
            'spotRate',
            'cls',
            'baseAmount',
            'quoteAmount',
            'lastProcessed',
            'externalTransactionId'
        ]
    });

    const initialState: GridInitialStatePremium =
        {
            columns: {
                columnVisibilityModel: {
                },
                dimensions: {
                    transactionId: { width: 150 },
                    assetManager: { width: 70 },
                    mandate: { width: 70 },
                    counterpartId: { width: 150 },
                    counterpartShort: { flex: 1},
                    tradeDate: { width: 110},
                    valueDate: { width: 110},
                    requestedState: { width: 140},
                    transactionCode: { width: 120},
                    baseCurrency: { width: 100},
                    quoteCurrency: { width: 100},
                    spotRate: { width: 100},
                    cls: { width: 100},
                    baseAmount: { width: 100},
                    quoteAmount: { width: 100},
                    lastProcessed: { width: 160 },
                    externalTransactionId: { width: 220}
                }
            },
            pagination: {
                paginationModel: {
                    pageSize: 1000
                }
            }
        }

    const columns: GridColDef<FxTransaction>[] = [
        { field: 'transactionId', headerName: 'TransactionId',
            renderCell: (params: GridRenderCellParams<FxTransaction>) => <Link to={"/transaction/fx/" + params.row.transactionId}> {params.row.transactionId} </Link>
        },
        { field: 'assetManager', headerName: 'AssetMgr' },
        { field: 'mandate', headerName: "Mandate" },
        { field: 'counterpartId', headerName: 'Counterpart' },
        { field: 'counterpartShort', headerName: 'Counterpart Short', flex: 1 },
        { field: 'tradeDate', headerName: 'Trade Date' },
        { field: 'valueDate', headerName: 'Value Date' },
        { field: 'requestedState', headerName: 'Action' },
        { field: 'transactionCode', headerName: 'Code' },
        { field: 'baseCurrency', headerName: 'Base Currency' },
        { field: 'quoteCurrency', headerName: 'Quote Currency' },
        { field: 'spotRate', headerName: 'Spot Rate', valueGetter: (params) => formatNumber(params.row.spotRate) },
        { field: 'cls', headerName: 'Cls' },
        { field: 'baseAmount', headerName: 'BaseAmount', valueGetter: (params) => formatNumber(params.row.baseAmount) },
        { field: 'quoteAmount', headerName: 'Quote Amount', valueGetter: (params) => formatNumber(params.row.quoteAmount) },
        { field: 'lastProcessed', headerName: 'Last Processed', renderCell: (params: GridRenderCellParams<FxTransaction>) =>
                formatDateTimeShort(params.row.lastProcessed)
        },
        { field: 'externalTransactionId', headerName: 'External Transaction' }
    ];

    return (
        <Grid item xs={12} md={12} lg={12} height={ 1000 } padding={1}>
            <DataGridPremium
                apiRef={apiRef}
                initialState={initialState}
                slots={{ toolbar: GridToolbar }}
                loading={params.searchFn.loading}
                rows={params.searchFn.data.data}
                columns={columns}
                density={"compact"}
                getRowId={(row) => row.transactionId}
                pagination={true}
                pageSizeOptions={[25,50,100,500,1000]}
            />
        </Grid>
    );
}