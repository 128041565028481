import axios from "axios";
import {useCallback, useEffect, useState} from "react";
import {GetDataApi} from "../components/types/GetDataApi";
import {useParams} from "react-router-dom";
import {ResponseDataFunction} from "../components/types/ResponseDataFunction";
import {getGatewayUrl} from "../components/EnvironmentConfiguration";
import {DividendConfig, emptyDividendConfig} from "../domain/assetmanager/DividendConfig";
import {DividendCurrencyConfig, emptyDividendCurrencyConfig} from "../domain/assetmanager/DividendCurrencyConfig";
import {RequestDataFunction} from "../components/types/RequestDataFunction";
import {DividendPaymentDateConfig, emptyDividendPaymentDateConfig} from "../domain/assetmanager/DividendPaymentDateConfig";
import {CashDividendSource} from "../domain/dividend/CashDividendSource";
import {DividendFilterConfig, emptyDividendFilterConfig} from "../domain/assetmanager/DividendFilterConfig";
import {RefreshDataFunction} from "../components/types/RefreshDataFunction";

export function useGetDividendConfiguration(): RefreshDataFunction<DividendConfig> {
    const [responseData, setResponseData] = useState<DividendConfig>(emptyDividendConfig());
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const apiFunction = useCallback(async() => {
            setLoading(true);
            axios.get(getGatewayUrl() + '/dividends/v1/config')
                .then((response) => {
                    setResponseData(response.data as DividendConfig)
                })
                .catch((err) => setError(err.message))
                .finally(() => setLoading(false));
        },
        []
    );

    useEffect(() => { apiFunction() }, [])

    return { data: responseData, loading, error, apiFunction: apiFunction };
}

export function useSaveDividendConfiguration(): ResponseDataFunction<DividendConfig, DividendConfig> {
    const [responseData, setResponseData] = useState<DividendConfig>(emptyDividendConfig());
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const getFunction = useCallback(
        async (data: DividendConfig) => {
            setLoading(true);
            const body = JSON.stringify(data);
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            await axios.post(getGatewayUrl() + '/dividends/v1/config', body, config)
                .then((response) => {
                    setResponseData(response.data as DividendConfig)
                })
                .catch((err) => setError(err.message))
                .finally(() => setLoading(false));
        },
        []
    );

    return { data: responseData, loading, error, apiFunction: getFunction };
}

export function useGetDividendCurrencyConfiguration(): GetDataApi<DividendCurrencyConfig> {
    const { currency } = useParams();
    const [responseData, setResponseData] = useState<DividendCurrencyConfig>(emptyDividendCurrencyConfig());
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        axios.get(getGatewayUrl() + '/dividends/v1/config/currency/' + currency)
            .then((response) => {
                setResponseData(response.data as DividendCurrencyConfig)
            })
            .catch((err) => setError(err.message))
            .finally(() => setLoading(false));
    }, []);
    return { data: responseData, loading, error };
}

export function useGetDividendCurrencyConfigurations(): GetDataApi<DividendCurrencyConfig[]> {
    const [responseData, setResponseData] = useState<DividendCurrencyConfig[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        axios.get(getGatewayUrl() + '/dividends/v1/config/currency')
            .then((response) => {
                setResponseData(response.data as DividendCurrencyConfig[])
            })
            .catch((err) => setError(err.message))
            .finally(() => setLoading(false));
    }, []);
    return { data: responseData, loading, error };
}

export function useDeleteDividendCurrencyConfiguration(): RequestDataFunction<number> {
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const  apiFunction = useCallback(async (id: number) => {
        axios.delete(getGatewayUrl() + '/dividends/v1/config/currency/' + id)
            .catch((err) => setError(err.message))
            .finally(() => setLoading(false));
    }, []);
    return { apiFunction: apiFunction, loading, error };
}

export function useCreateDividendCurrencyDefaultConfiguration(): ResponseDataFunction<string, DividendCurrencyConfig> {
    const [responseData, setResponseData] = useState<DividendCurrencyConfig>(emptyDividendCurrencyConfig());
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const getFunction = useCallback(
        async (name: string) => {
            setLoading(true);
            await axios.get(getGatewayUrl() + '/dividends/v1/config/currency/default/' + name)
                .then((response) => {
                    setResponseData(response.data as DividendCurrencyConfig)
                })
                .catch((err) => setError(err.message))
                .finally(() => setLoading(false));
        },
        []
    );

    return { data: responseData, loading, error, apiFunction: getFunction };
}

export function useSaveDividendCurrencyConfiguration(): ResponseDataFunction<DividendCurrencyConfig, DividendCurrencyConfig> {
    const [responseData, setResponseData] = useState<DividendCurrencyConfig>(emptyDividendCurrencyConfig());
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const getFunction = useCallback(
        async (data: DividendCurrencyConfig) => {
            setLoading(true);
            const body = JSON.stringify(data);
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            await axios.post(getGatewayUrl() + '/dividends/v1/config/currency', body, config)
                .then((response) => {
                    setResponseData(response.data as DividendCurrencyConfig)
                })
                .catch((err) => setError(err.message))
                .finally(() => setLoading(false));
        },
        []
    );

    return { data: responseData, loading, error, apiFunction: getFunction };
}

export function useGetDividendPaymentDateConfigurations(): GetDataApi<DividendPaymentDateConfig[]> {
    const [responseData, setResponseData] = useState<DividendPaymentDateConfig[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        axios.get(getGatewayUrl() + '/dividends/v1/config/payment/date')
            .then((response) => {
                setResponseData(response.data as DividendPaymentDateConfig[])
            })
            .catch((err) => setError(err.message))
            .finally(() => setLoading(false));
    }, []);
    return { data: responseData, loading, error };
}

export function useGetDividendPaymentDateConfiguration(): GetDataApi<DividendPaymentDateConfig> {
    const { id } = useParams();
    const [responseData, setResponseData] = useState<DividendPaymentDateConfig>(emptyDividendPaymentDateConfig());
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        axios.get(getGatewayUrl() + '/dividends/v1/config/payment/date/' + id)
            .then((response) => {
                setResponseData(response.data as DividendPaymentDateConfig)
            })
            .catch((err) => setError(err.message))
            .finally(() => setLoading(false));
    }, []);
    return { data: responseData, loading, error };
}

export function useDeleteDividendPaymentDateConfiguration(): RequestDataFunction<number> {
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const  apiFunction = useCallback(async (id: number) => {
        axios.delete(getGatewayUrl() + '/dividends/v1/config/payment/date/' + id)
            .catch((err) => setError(err.message))
            .finally(() => setLoading(false));
    }, []);
    return { apiFunction: apiFunction, loading, error };
}

export function useCreateDividendPaymentDateDefaultConfiguration(): ResponseDataFunction<CashDividendSource, DividendPaymentDateConfig> {
    const [responseData, setResponseData] = useState<DividendPaymentDateConfig>(emptyDividendPaymentDateConfig());
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const getFunction = useCallback(
        async (name: CashDividendSource) => {
            setLoading(true);
            await axios.get(getGatewayUrl() + '/dividends/v1/config/payment/date/default/' + name)
                .then((response) => {
                    setResponseData(response.data as DividendPaymentDateConfig)
                })
                .catch((err) => setError(err.message))
                .finally(() => setLoading(false));
        },
        []
    );

    return { data: responseData, loading, error, apiFunction: getFunction };
}

export function useSaveDividendPaymentDateConfiguration(): ResponseDataFunction<DividendPaymentDateConfig, DividendPaymentDateConfig> {
    const [responseData, setResponseData] = useState<DividendPaymentDateConfig>(emptyDividendPaymentDateConfig());
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const getFunction = useCallback(
        async (data: DividendPaymentDateConfig) => {
            setLoading(true);
            const body = JSON.stringify(data);
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            setError(null);
            await axios.post(getGatewayUrl() + '/dividends/v1/config/payment/date', body, config)
                .then((response) => {
                    setResponseData(response.data as DividendPaymentDateConfig)
                })
                .catch((err) => setError(err.response.data))
                .finally(() => setLoading(false));
        },
        []
    );

    return { data: responseData, loading, error, apiFunction: getFunction };
}

export function useGetDividendFilterConfigurations(): GetDataApi<DividendFilterConfig[]> {
    const [responseData, setResponseData] = useState<DividendFilterConfig[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        axios.get(getGatewayUrl() + '/dividends/v1/config/filter')
            .then((response) => {
                setResponseData(response.data as DividendFilterConfig[])
            })
            .catch((err) => setError(err.message))
            .finally(() => setLoading(false));
    }, []);
    return { data: responseData, loading, error };
}

export function useGetDividendFilterConfiguration(): GetDataApi<DividendFilterConfig> {
    const { id } = useParams();
    const [responseData, setResponseData] = useState<DividendFilterConfig>(emptyDividendFilterConfig());
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        axios.get(getGatewayUrl() + '/dividends/v1/config/filter/' + id)
            .then((response) => {
                setResponseData(response.data as DividendFilterConfig)
            })
            .catch((err) => setError(err.message))
            .finally(() => setLoading(false));
    }, []);
    return { data: responseData, loading, error };
}
export function useDeleteDividendFilterConfiguration(): RequestDataFunction<number> {
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const  apiFunction = useCallback(async (id: number) => {
        axios.delete(getGatewayUrl() + '/dividends/v1/config/filter/' + id)
            .catch((err) => setError(err.message))
            .finally(() => setLoading(false));
    }, []);
    return { apiFunction: apiFunction, loading, error };
}

export function useCreateDividendFilterDefaultConfiguration(): ResponseDataFunction<CashDividendSource, DividendFilterConfig> {
    const [responseData, setResponseData] = useState<DividendFilterConfig>(emptyDividendFilterConfig());
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const getFunction = useCallback(
        async (name: CashDividendSource) => {
            setLoading(true);
            await axios.get(getGatewayUrl() + '/dividends/v1/config/filter/default/' + name)
                .then((response) => {
                    setResponseData(response.data as DividendFilterConfig)
                })
                .catch((err) => setError(err.message))
                .finally(() => setLoading(false));
        },
        []
    );

    return { data: responseData, loading, error, apiFunction: getFunction };
}

export function useSaveDividendFilterConfiguration(): ResponseDataFunction<DividendFilterConfig, DividendFilterConfig> {
    const [responseData, setResponseData] = useState<DividendFilterConfig>(emptyDividendFilterConfig());
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const getFunction = useCallback(
        async (data: DividendFilterConfig) => {
            setLoading(true);
            const body = JSON.stringify(data);
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            await axios.post(getGatewayUrl() + '/dividends/v1/config/filter', body, config)
                .then((response) => {
                    setResponseData(response.data as DividendFilterConfig)
                })
                .catch((err) => setError(err.response.data))
                .finally(() => setLoading(false));
        },
        []
    );

    return { data: responseData, loading, error, apiFunction: getFunction };
}


