import {loginRequest} from "../authorization/authConfig";
import {useMsal} from "@azure/msal-react";
import React, {ReactElement, useEffect, useState} from "react";
import {callMsGraph} from "../authorization/graph";
import Title from "../components/Title";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import RolesRetriever from "../authorization/RolesRetreiver";
import Roles from "../components/Roles";

export default function ProfileContent(): ReactElement {
    const { instance, accounts } = useMsal();
    const [graphData, setGraphData] = useState({ displayName: ""} );

    useEffect(() => {
        requestProfileData();
    }, []);

    function requestProfileData() {
        const request = {
            ...loginRequest,
            account: accounts[0]
        };

        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        instance.acquireTokenSilent(request).then((response) => {
            callMsGraph(response.accessToken)
                .then(graphResponse => setGraphData(graphResponse));
        }).catch(() => {
            instance.acquireTokenPopup(request).then((response) => {
                callMsGraph(response.accessToken)
                    .then(graphResponse => setGraphData(graphResponse));
            });
        });
    }

    return (
            <Container sx={{ mt: 4, mb: 4 }}>
                { <Toolbar/> }
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={12}>
                        <Title> Welcome to T-Trade { graphData.displayName } </Title>
                    </Grid>
                </Grid>
                <RolesRetriever groups={ [ "user", "admin", "super" ]}>
                    <Roles/>
                </RolesRetriever>
            </Container>
    );
}