import React, {ReactElement} from "react";
import {
    DataGridPremium,
    GridColDef,
    GridRenderCellParams,
    GridToolbar
} from "@mui/x-data-grid-premium";
import Grid from "@mui/material/Grid";
import {formatDateTimeShort} from "./TimeFormat";
import {AuditEvent} from "../domain/audit/AuditEvent";
import {Link} from "react-router-dom";
import {GridInitialStatePremium} from "@mui/x-data-grid-premium/models/gridStatePremium";
import {useAutoSizeGrid} from "./AutoSizeGridHook";

export type AuditEventComponentParams = {
    data: AuditEvent[],
    loading: boolean
};

export default function AuditEventComponent(params: Readonly<AuditEventComponentParams>): ReactElement {

    const apiRef = useAutoSizeGrid<AuditEvent>({
        data: params.data,
        loading: params.loading,
        columns: [
            'eventTime',
            'entityType',
            'entityId',
            'eventType',
            'requestId'
        ]
    });

    const initialState: GridInitialStatePremium = {
        columns: {
            columnVisibilityModel: {
            },
            dimensions: {
                eventTime: { width: 150 },
                entityType: { width: 220 },
                entityId: { width: 220 },
                eventType: { width: 220 },
                requestId: { flex: 1 }
            }
        },
        pagination: {
            paginationModel: {
                pageSize: 1000
            }
        }
    }

    const columns: GridColDef<AuditEvent>[] = [
        {
            field: 'eventTime',
            headerName: 'Event Time',
            renderCell: (params: GridRenderCellParams<AuditEvent>) => formatDateTimeShort(params.row.eventTime)
        },
        {
            field: 'entityType',
            headerName: 'Entity Type'
        },
        {
            field: 'entityId',
            headerName: 'Entity ID'
        },
        {
            field: 'eventType',
            headerName: 'Event Type'
        },
        {
            field: 'requestId',
            flex: 1,
            headerName: 'Request ID',
            renderCell: (params: GridRenderCellParams<AuditEvent>) =>
                <Link to={ "/audit/requests/" + params.row.requestId}> { params.row.requestId} </Link>
        },
    ];

    return (
        <Grid item xs={12} md={12} lg={12} height={ 800 } padding={1}>
            <DataGridPremium
                apiRef={apiRef}
                initialState={initialState}
                slots={{ toolbar: GridToolbar }}
                loading={params.loading}
                rows={params.data}
                columns={columns}
                density={"compact"}
                getRowId={(row) => row.id }
                pagination={true}
                pageSizeOptions={[20,25,50,100,500,1000]}
            />
        </Grid>
    );
}