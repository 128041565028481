import React, {ReactElement, useEffect, useState} from "react";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import {
    useDeleteDividendFilterConfiguration,
    useGetDividendFilterConfiguration,
    useSaveDividendFilterConfiguration
} from "../../application/DividendConfigService";
import {DeleteForeverRounded, SaveRounded} from "@mui/icons-material";
import RolesRetriever from "../../authorization/RolesRetreiver";
import {Button, FormControlLabel, TextField} from "@mui/material";
import SnackbarComponent, {defaultSnackbarState, SnackbarState} from "../../components/SnackbarComponent";
import Title from "../../components/Title";
import {useNavigate} from "react-router-dom";
import {ResponseDataFunction} from "../../components/types/ResponseDataFunction";
import {DividendFilterConfig} from "../../domain/assetmanager/DividendFilterConfig";
import {RequestDataFunction} from "../../components/types/RequestDataFunction";
import {toHumanReadableFormat} from "../../domain/dividend/CashDividendSource";

export default function DividendFilterConfigurationPage(): ReactElement {
    const navigate = useNavigate();
    const [snackbarState, setSnackbarState] = useState<SnackbarState>(defaultSnackbarState);
    const getFunction = useGetDividendFilterConfiguration();
    const deleteFunction: RequestDataFunction<number> = useDeleteDividendFilterConfiguration();
    const saveFunction: ResponseDataFunction<DividendFilterConfig, DividendFilterConfig> = useSaveDividendFilterConfiguration();
    const [instrumentPrefix, setInstrumentPrefix] = useState<string | null>("");
    const [accountNumber, setAccountNumber] = useState<string | null>("");
    let saveInProgress = false;

    useEffect(() => {
        setInstrumentPrefix(getFunction.data.instrumentPrefix);
        setAccountNumber(getFunction.data.accountNumber);
    }, [ getFunction.data ]);

    useEffect(() => {
        saveInProgress = false;
        if (saveFunction.data.id > 0) {
            setSnackbarState({ open: true, message: "Save successful", severity: "success" } );
            navigate("/dividends/config")
        }
    }, [ saveFunction.data ]);

    useEffect(() => {
        saveInProgress = false;
        if (saveFunction.error) {
            setSnackbarState({ open: true, message: "Save failed: " + saveFunction.error, severity: 'error' });
        }
    }, [ saveFunction.error ]);

    const saveConfiguration = () => {
        saveInProgress = true;
        saveFunction.apiFunction(getFunction.data);
    }

    const deleteConfiguration = () => {
        saveInProgress = true;
        deleteFunction.apiFunction(getFunction.data.id)
            .finally(() => {
                saveInProgress = false
                navigate("/dividends/config");
            });
    }


    const handleAccountNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
        getFunction.data.accountNumber = event.target.value;
        setAccountNumber(event.target.value);
    }

    const handleInstrumentPrefix = (event: React.ChangeEvent<HTMLInputElement>) => {
        getFunction.data.instrumentPrefix = event.target.value;
        setInstrumentPrefix(event.target.value);
    }

    if (getFunction.loading) {
        return (<></>);
    } else {
        return (
            <React.Fragment>
                <Container maxWidth={ false } sx={{ mt: 4, mb: 4 }}>
                    { <Toolbar/> }
                    <Grid container spacing={2} justifyItems={ "center" } alignItems={ "center" }>
                        <Grid item xs={12} md={8} lg={8} >
                            <Title>Dividend Filter Configuration {getFunction.data.id} { toHumanReadableFormat(getFunction.data.source) }</Title>
                        </Grid>
                        <Grid item xs={12} md={2} lg={2}>
                            <RolesRetriever groups={ [ "super" ]}>
                                <Button variant='outlined'
                                        startIcon={<DeleteForeverRounded/>}
                                        disabled={saveInProgress}
                                        onClick={() => { deleteConfiguration() }}>
                                    Delete
                                </Button>
                            </RolesRetriever>
                        </Grid>
                        <Grid item xs={12} md={2} lg={2}>
                            <RolesRetriever groups={ [ "super" ]}>
                                <Button variant='outlined'
                                        startIcon={<SaveRounded/>}
                                        disabled={saveInProgress}
                                        onClick={() => { saveConfiguration(); }}>
                                    Save
                                </Button>
                            </RolesRetriever>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <FormControlLabel control={
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="accountNumber"
                                    label="Account Number"
                                    fullWidth
                                    variant="standard"
                                    value={ accountNumber }
                                    onChange={ handleAccountNumber }
                                />
                            } label="Account Number to Ignore (Only works for BONY)" />
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <FormControlLabel control={
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="instrumentPrefix"
                                    label="Instrument Prefix"
                                    fullWidth
                                    variant="standard"
                                    value={ instrumentPrefix }
                                    onChange={ handleInstrumentPrefix }
                                />
                            } label="Instrument Prefix to ignore (ISIN for BONY, Bloomberg Code for Bloomberg)" />
                        </Grid>
                    </Grid>
                </Container>
                <SnackbarComponent state={ snackbarState } setState={ setSnackbarState }/>
            </React.Fragment>
        )}
}
