import React, {ReactElement, useState} from "react";
import {PageDto} from "../domain/PageDto";
import {CashDividend, getCashDividendPaymentDate, hasCashDividendEstimatedPaymentDate} from "../domain/dividend/CashDividend";
import {Link} from "react-router-dom";
import {
    DataGridPremium,
    GridCellParams,
    GridColDef,
    GridRenderCellParams,
    GridToolbar,
    GridValueGetterParams
} from "@mui/x-data-grid-premium";
import {GridInitialStatePremium} from "@mui/x-data-grid-premium/models/gridStatePremium";
import {formatDate} from "./TimeFormat";
import Grid from "@mui/material/Grid";
import clsx from 'clsx';
import {CashDividendStateRenderComponent} from "./CashDividendStateRenderComponent";
import SnackbarComponent, {defaultSnackbarState, SnackbarState} from "./SnackbarComponent";
import {StateSx, getStateClass} from "./CashDividendStateRenderingComponent";
import {useAutoSizeGrid} from "./AutoSizeGridHook";
import formatNumber from "./NumberFormat";

export type CashDividendsComponentParams = {
    dividends: PageDto<CashDividend>
    loading: boolean
    searchFunction: () => void
}

export default function CashDividendHistoryComponent(params: Readonly<CashDividendsComponentParams>): ReactElement {
    const [ buttonActive, setButtonActive] = useState<boolean>(true);
    const [ snackbarState, setSnackbarState ] = useState<SnackbarState>(defaultSnackbarState);

    const apiRef = useAutoSizeGrid<CashDividend>({
        data: params.dividends.data,
        loading: params.loading,
        includeOutliers: false,
        expand: true,
        outliersFactor: 2.0,
        columns: [
            'state',
            'exDate',
            'paymentDate',
            'isin',
            'bloombergCode',
            'shortName',
            'incomeRate',
            'currency',
            'id1',
            'id2',
            'sequence',
            'verifiedDate',
            'message'
        ]}
    );

    function renderLink(path: string, name: string) {
        if (name) {
            return (<Link to={path + name}> {name} </Link>);
        }
        return '';
    }

    const initialState: GridInitialStatePremium =
        {
            columns: {
                columnVisibilityModel: {
                    requestId: false
                },
                dimensions: {
                    state: {width: 170},
                    exDate: {width: 100},
                    paymentDate: {width: 110},
                    isin: {width: 120},
                    bloombergCode: {width: 140},
                    shortName: {width: 170},
                    incomeRate: {width: 100},
                    currency: {width: 100},
                    id1: {width: 100},
                    id2: {width: 100},
                    sequence: {maxWidth: 80},
                    verifiedDate: {width: 170},
                    message: { flex: 1 }
                }
            },
            pagination: {
                paginationModel: {
                    pageSize: 1000
                }
            }
        }

    function renderState(row: GridRenderCellParams<CashDividend>) {
        return (<CashDividendStateRenderComponent
            dividend={ row.row }
            setButtonActive={ setButtonActive }
            setSnackbarState={ setSnackbarState }
            buttonActive={ buttonActive}
            refreshFunction={params.searchFunction}
        />)
    }

    const columns: GridColDef<CashDividend>[] = [
        { field: 'state', headerName: 'State',
            cellClassName: (params: GridCellParams<CashDividend>) => {
                return getStateClass(params.row.state);
            },
            renderCell: (params: GridRenderCellParams<CashDividend>) => renderState(params),
            maxWidth: 140,
            minWidth: 100
        },
        { field: 'exDate', headerName: 'Ex Date',
            valueGetter: (params: GridValueGetterParams<CashDividend>) => params.row.dividends[0].exDate,
            maxWidth: 100
        },
        { field: 'paymentDate', headerName: 'Payment Date',
            valueGetter: (params: GridValueGetterParams<CashDividend>) => getCashDividendPaymentDate(params.row),
            cellClassName: (params: GridCellParams<CashDividend>) => {
                return clsx('ttrade-dividend', {
                    update: hasCashDividendEstimatedPaymentDate(params.row)
                })
            },
            maxWidth: 100
        },
        { field: 'isin', headerName: 'ISIN',
            renderCell: (params: GridRenderCellParams<CashDividend>) => renderLink('/dividends/isin/', params.row.isin)
        },
        { field: 'bloombergCode', headerName: 'Bloomberg',
            renderCell: (params: GridRenderCellParams<CashDividend>) => renderLink('/dividends/bloomberg/', params.row.bloombergCode)
        },
        { field: 'shortName', headerName: 'Instrument',
            renderCell: (params: GridRenderCellParams<CashDividend>) => renderLink('/dividends/shortName/', params.row.shortName)
        },
        { field: 'incomeRate', headerName: 'Income Rate',
            valueGetter: (params: GridValueGetterParams<CashDividend>) => formatNumber(params.row.dividends[0].matchedIncomeRate),
        },
        { field: 'currency',
            headerName: 'Currency',
            valueGetter: (params: GridValueGetterParams<CashDividend>) => params.row.dividends[0].currency,
            cellClassName: (params: GridCellParams<CashDividend>) => {
                return clsx('ttrade-dividend', {
                    negative: params.row.dividends.find(d => d.currency !== d.instrumentCurrency)
                })
            },
            maxWidth: 90
        },
        { field: 'id1', headerName: 'DIV ID 1',
            valueGetter : (params: GridValueGetterParams<CashDividend>) => params.row.transferredBy?.id,
            maxWidth: 120
        },
        { field: 'id2', headerName: 'DIV ID 2',
            valueGetter : (params: GridValueGetterParams<CashDividend>) => params.row.transferredBy?.serialNumber,
            maxWidth: 120
        },
        { field: 'sequence', headerName: 'Sequence No',
            valueGetter : (params: GridValueGetterParams<CashDividend>) => params.row.transferredBy?.serialNumber,
            maxWidth: 100
        },
        { field: 'verifiedDate', headerName: 'Verified Date',
            renderCell: (params: GridRenderCellParams<CashDividend>) => formatDate(params.row.verifiedDate),
            maxWidth: 100
        },
        {
            field: 'message',
            headerName: 'Message',
            flex: 1,
            valueGetter : (params: GridValueGetterParams<CashDividend>) => params.row.message
        }

    ];

    return (
        <React.Fragment>
            <Grid item xs={12} md={12} lg={12} height={ 1000 }>
                <DataGridPremium
                    apiRef={apiRef}
                    sx={ StateSx }
                    initialState={initialState}
                    slots={{ toolbar: GridToolbar }}
                    loading={params.loading}
                    rows={params.dividends.data }
                    columns={columns}
                    density={'compact'}
                    getRowId={(row: CashDividend) => row.id.id}
                    pagination={true}
                    pageSizeOptions={[100,1000]}
                />
            </Grid>
            <SnackbarComponent state={ snackbarState } setState={ setSnackbarState }/>
        </React.Fragment>
    );
}