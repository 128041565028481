
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@mui/material";
import * as React from "react";
import {ResponseDataFunction} from "./types/ResponseDataFunction";
import {AssetManagerConfig} from "../domain/assetmanager/AssetManagerConfig";
import {
  useCreateAssetManagerDefaultConfiguration,
} from "../application/AssetManagerService";
import {useNavigate} from "react-router-dom";
import RolesRetriever from "../authorization/RolesRetreiver";


export default function CreateAssetManagerDialog() {
  const navigation = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [text, setText] = React.useState('');
  const createFunction: ResponseDataFunction<string, AssetManagerConfig> = useCreateAssetManagerDefaultConfiguration();

  const createAssetManager = (name: string): void => {
    createFunction.apiFunction(name)
        .then(() => navigation(name));
  }
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleResponseCancel = () => {
    setOpen(false);
  };

  const handleResponseOk = () => {
    setOpen(false);
    createAssetManager(text);
  };

  function handleText(e: React.ChangeEvent<HTMLInputElement>) {
     setText(e.target.value);
  }

  return (
    <div>
      <RolesRetriever groups={ [ "super" ]}>
        <Button variant="outlined" onClick={handleClickOpen}>
          New
        </Button>
      </RolesRetriever>
      <Dialog open={open} onClose={handleResponseCancel}>
        <DialogTitle>Create New Asset Manager Configuration</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This will create a new default disabled Asset Manager configuration with the name you supply.
            NOTE, that this name must match the name in IMS system. If a configuration with the supplied
            name already exists this action will fail.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="assetManagerId"
            label="Asset Manager ID"
            fullWidth
            variant="standard"
            value={ text }
            onChange={ handleText }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleResponseCancel}>Cancel</Button>
          <Button disabled={ ! text || text.length === 0 } onClick={handleResponseOk}>Create</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}