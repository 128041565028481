import {Link} from "react-router-dom";
import React, {ReactElement, useState} from "react";
import {DataGridPremium, GridColDef, GridRenderCellParams, GridToolbar} from "@mui/x-data-grid-premium";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import Title from "../../components/Title";
import formatDateTime from "../../components/TimeFormat";
import {Autocomplete, Button, TextField, Tooltip} from "@mui/material";
import RolesRetriever from "../../authorization/RolesRetreiver";
import IconButton from "@mui/material/IconButton";
import {RefreshRounded, SyncRounded} from "@mui/icons-material";
import {ImsTransactionImportStatus} from "../../domain/transaction/ImsTransactionImportStatus";
import {
    TtradeTransactionResetRequest,
    TtradeTransactionState,
    TtradeTransctionReactivateRequest,
    useGetTransactionUpdatesByTTradeState,
    useTransactionReactivate,
    useTransactionResetTimeoutStates
} from "../../application/TransactionUpdateService";
import {RequestDataFunction} from "../../components/types/RequestDataFunction";
import {ImsUpdateState} from "../../domain/transaction/ImsUpdateState";
import {PageDto} from "../../domain/PageDto";
import {allUiTtradeStates, UiStateInfo} from "../../components/types/UiStateInfo";
import {SearchDataFunction} from "../../components/types/SearchDataFunction";
import SnackbarComponent, {defaultSnackbarState, SnackbarState} from "../../components/SnackbarComponent";
import {GridInitialStatePremium} from "@mui/x-data-grid-premium/models/gridStatePremium";
import CancelTransactionDialog from "../../components/CancelTransactionDialog";
import {TTradeProcessingState} from "../../domain/transaction/TTradeProcessingState";
import DequeueTransactionDialog from "../../components/DequeueTransactionDialog";
import ResendTransactionDialog from "../../components/ResendTransactionDialog";

export default function TtradeStatusesPage(): ReactElement {
    const searchFunction: SearchDataFunction<UiStateInfo[], PageDto<ImsTransactionImportStatus>> = useGetTransactionUpdatesByTTradeState();
    const resetFunction: RequestDataFunction<TtradeTransactionResetRequest> = useTransactionResetTimeoutStates();
    const reactivateFunction: RequestDataFunction<TtradeTransctionReactivateRequest> = useTransactionReactivate();
    const [ snackbarState, setSnackbarState ] = useState<SnackbarState>(defaultSnackbarState);

    function refreshData(): void {
        searchFunction.setSearchTermFunction(Object.assign([], searchFunction.searchTerm));
    }

    function renderImsState(params: GridRenderCellParams<ImsTransactionImportStatus>): ReactElement {

        function refreshTransaction() {
            resetFunction.apiFunction({
                ids: [ params.row.requestId ],
                completedFunction: refreshData } as TtradeTransactionResetRequest)
                .then(() => setSnackbarState({ message: "Updates will be visible after a few seconds when you refresh this page", open: true, severity: "success" } ))
                .catch(() => setSnackbarState({ message: "Refresh failed", open: true, severity: 'error'}));
        }

        function reactivateTransaction() {
            reactivateFunction.apiFunction(
                {
                    transaction: params.row.transactionId,
                    type: params.row.transactionType,
                    completedFunction: refreshData
                } as TtradeTransctionReactivateRequest)
                .then(() => setSnackbarState({ message: "Updates will be visible after a few seconds when you refresh this page", open: true, severity: "success" } ))
                .catch(() => setSnackbarState({ message: "Reactivate failed", open: true, severity: 'error'}));
        }

        if (! params.row.isFirstIssue) {
            return (<> {params.row.imsState} </>);
        } else if (params.row.imsState === ImsUpdateState.TIMEOUT) {
            return (<React.Fragment>
                <RolesRetriever groups={["admin", "super"]}>
                    <Tooltip title={"Refresh transaction by reading state from SCD"} placement={"top"}>
                        <div>
                            <IconButton color="secondary" onClick={refreshTransaction} disabled={resetFunction.loading || reactivateFunction.loading}>
                                <SyncRounded/>
                            </IconButton>
                        </div>
                    </Tooltip>
                </RolesRetriever>
                {params.row.imsState}
            </React.Fragment>);
        } else if (params.row.imsState === ImsUpdateState.ERROR) {
            return (<React.Fragment>
                <RolesRetriever groups={["admin", "super"]}>
                    <Tooltip title={"Make SCD try to import transaction again, and/or read state from SCD"}
                             placement={"top"}>
                        <div>
                            <IconButton color="warning" onClick={reactivateTransaction} disabled={resetFunction.loading || reactivateFunction.loading}>
                                <RefreshRounded/>
                            </IconButton>
                        </div>
                    </Tooltip>
                </RolesRetriever>
                {params.row.imsState}
            </React.Fragment>);
        }
        // Not reachable code
        return (<> {params.row.imsState} </>);
    }

    function renderTTradeState(params: GridRenderCellParams<ImsTransactionImportStatus>): ReactElement {

        if (! params.row.isFirstIssue) {
            return (<> {params.row.state} </>);
        } else if (params.row.state === TTradeProcessingState.FAILED && params.row.imsState === ImsUpdateState.ERROR) {
            return (
                <React.Fragment>
                    <RolesRetriever groups={["admin", "super"]}>
                        <CancelTransactionDialog setSnackbarState={ setSnackbarState }
                                                 transaction={ params.row.transactionId}
                                                 type={ params.row.transactionType}
                                                 completedFunction={refreshData}/>
                    </RolesRetriever>
                    { params.row.state}
                </React.Fragment>);
        } else if (params.row.state === TTradeProcessingState.QUEUED && params.row.imsState === ImsUpdateState.QUEUED) {
            return (
                <React.Fragment>
                    <RolesRetriever groups={[ "super"]}>
                        <DequeueTransactionDialog setSnackbarState={ setSnackbarState }
                                                  transaction={ params.row.transactionId}
                                                  type={ params.row.transactionType}
                                                  completedFunction={refreshData}/>
                    </RolesRetriever>
                    { params.row.state}
                    <RolesRetriever groups={[ "super" ]}>
                        <ResendTransactionDialog setSnackbarState={ setSnackbarState }
                                                 transaction={ params.row.transactionId}
                                                 type={ params.row.transactionType}
                                                 completedFunction={refreshData}
                                                  />
                    </RolesRetriever>
                </React.Fragment>);
        }
        // Not reachable code
        return (<> {params.row.state} </>);
    }


    function renderTransactionType(params: GridRenderCellParams<ImsTransactionImportStatus>): ReactElement {
        if (params.row.transactionType) {
            return (<Link to={"/transaction/"
                + params.row.transactionType.toLowerCase()
                + "/"
                + params.row.transactionId}> {params.row.transactionId} </Link>);
        } else {
            return (<>{ params.row.transactionId }</>);
        }
    }

    const initialState: GridInitialStatePremium =
        {
            columns: {
                columnVisibilityModel:  {
                    id: false,
                    requestId: false
                },
                dimensions: {
                    id: { width: 50 },
                    requestId: { width: 100 },
                    created: { width: 200},
                    updated: { width: 200},
                    imsState: { width: 150},
                    state: { width: 150},
                    transactionId: { width: 250 },
                    transactionType: { width: 100 },
                    action: { width: 100},
                    originalFileName: { width: 200},
                    originalFileRow: {  width: 100},
                    imsMessage: { flex: 1 }
                }
            }
        }

    const columns: GridColDef<ImsTransactionImportStatus>[] = [
        { field: 'id', headerName: 'Id', width: 50 },
        { field: 'requestId', headerName: 'Request ID', width: 100 },
        { field: 'created', headerName: 'Created', width: 200, renderCell: (params: GridRenderCellParams<ImsTransactionImportStatus>) =>
                formatDateTime(params.row.created)
        },
        { field: 'updated', headerName: 'Updated', width: 200, renderCell: (params: GridRenderCellParams<ImsTransactionImportStatus>) =>
                formatDateTime(params.row.updated)
        },
        { field: 'imsState', headerName: 'State', width: 150,
            renderCell: (params: GridRenderCellParams<ImsTransactionImportStatus>) => renderImsState(params)
        },
        { field: 'state', headerName: 'T-Trade State', width: 150,
            renderCell: (params: GridRenderCellParams<ImsTransactionImportStatus>) => renderTTradeState(params)
        },
        { field: 'transactionId', headerName: 'Transaction', width: 250, renderCell: (params: GridRenderCellParams<ImsTransactionImportStatus>) => renderTransactionType(params) },
        { field: 'transactionType', headerName: 'Trans Type', width: 100 },
        { field: 'action', headerName: 'Action', width: 100 },
        { field: 'originalFileName', headerName: 'File', width: 200 },
        { field: 'originalFileRow', headerName: 'Line', width: 100 },
        { field: 'imsMessage', headerName: 'Message', flex: 1 }
    ];

    function onSelectionChange(event: React.SyntheticEvent<Element,Event>, value: UiStateInfo[]) {
        if (value && value.length > 0) {
            searchFunction.setSearchTermFunction(value);
        }
    }

    return (
        <React.Fragment>
            <Container maxWidth={ false } sx={{ mt: 4, mb: 4 }}>
                { <Toolbar/> }
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={12}>
                        <Title>T-Trade Transaction Status in Simcorp Dimension</Title>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <Autocomplete
                            multiple
                            id="tags-outlined"
                            size="small"
                            options={ allUiTtradeStates }
                            value={ searchFunction.searchTerm || [] }
                            getOptionLabel={(option: TtradeTransactionState) => option.title}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="T-Trade States"
                                />
                            )}
                            onChange={ onSelectionChange }
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <RolesRetriever groups={ ["admin", "super"] }>
                            <Tooltip title={ "Refresh data in table" } placement={ "top" }>
                                <Button color="secondary" startIcon={<RefreshRounded/>} onClick={ refreshData }>
                                    Refresh
                                </Button>
                            </Tooltip>
                        </RolesRetriever>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} height={ 1000 }>
                        <DataGridPremium
                            initialState={initialState}
                            slots={{ toolbar: GridToolbar }}
                            loading={searchFunction.loading}
                            rows={searchFunction.data.data}
                            columns={columns}
                            density={"compact"}
                            getRowId={(row) => row.id}
                            pageSizeOptions={[100]}
                        />
                    </Grid>
                </Grid>
            </Container>
            <SnackbarComponent state={ snackbarState } setState={ setSnackbarState }/>
        </React.Fragment>
    );
}