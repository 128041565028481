import axios from "axios";
import {useCallback, useEffect, useState} from "react";
import {CurrencyCross} from "../domain/staticdata/CurrencyCross";
import {getGatewayUrl} from "../components/EnvironmentConfiguration";
import {emptyStatistics, Statistics} from "../domain/staticdata/Statistics";
import {RefreshDataFunction} from "../components/types/RefreshDataFunction";

export const useGetCurrencyCross = (): RefreshDataFunction<CurrencyCross[]> => {
    const [responseData, setResponseData] = useState<CurrencyCross[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const apiFunction = useCallback(async () => {
            setLoading(true);
            axios.get(getGatewayUrl() + '/static-data/v1/currencycross')
                .then((response) => {
                    setResponseData(response.data as CurrencyCross[])
                })
                .catch((err) => setError(err.message))
                .finally(() => setLoading(false));
        },
        []
    );

    useEffect(() => {
            apiFunction();
        },
        [ ]
    );

    return { data: responseData, loading, error, apiFunction };
};

export const useSyncCurrencyCross = (): RefreshDataFunction<Statistics> => {
    const [responseData, setResponseData] = useState<Statistics>(emptyStatistics());
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const getFunction = useCallback(async () => {
            setLoading(true);
            const uri = '/static-data/v1/sync/currencycross';
            axios.put(getGatewayUrl() + uri)
                .then((response) => {
                    setResponseData(response.data as Statistics)
                })
                .catch((err) => setError(err.message))
                .finally(() => setLoading(false));
        },
        []
    );

    return { data: responseData, loading, error, apiFunction: getFunction };
};
