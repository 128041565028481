import {TransactionType} from "./TransactionType";
import {Action} from "./Action";
import {ReasonCode} from "./ResonCode";
import {RefusedTransactionStatus} from "./RefusedTransactionStatus";

export type TransactionProcessingResult = {
    id: number,
    assetManager: string,
    eventTime: string,
    action: Action,
    code: ReasonCode,
    transactionId: string,
    transactionType: TransactionType,
    message: string,
    fileOrigin: string,
    originalFile: string,
    originalFileLine: number,
    identifier: string,
    status: RefusedTransactionStatus,

}

export function emptyTransactionProcessingResult(): TransactionProcessingResult {
    return {
        id: 0,
        assetManager: '',
        eventTime: '',
        action: Action.ADD,
        code: ReasonCode.ACCEPTED,
        fileOrigin: '',
        transactionId: '',
        transactionType: TransactionType.EQ,
        message: '',
        originalFile: '',
        originalFileLine: 0
    } as TransactionProcessingResult;
}

