import { InstrumentType } from "./InstrumentType";
import {IdentifierType} from "./IdentifierType";

export type DpInstrumentBulkRequest = {
        instrumentType: InstrumentType;
        identifierType: IdentifierType;
        file: File
}

export function toDpInstrumentImportRequest(instrumentType: InstrumentType, identifierType: IdentifierType, file: File): DpInstrumentBulkRequest {
        return {
                instrumentType: instrumentType,
                identifierType: identifierType,
                file: file
        } as DpInstrumentBulkRequest;
}