import React, {ReactElement} from "react";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import Title from "../../components/Title";
import {CashDividend} from "../../domain/dividend/CashDividend";
import {PageDto} from "../../domain/PageDto";
import CashDividendsSearchComponent from "../../components/CashDividendSearchComponent";
import {SearchDataFunction} from "../../components/types/SearchDataFunction";
import {useSearchCashDividends} from "../../application/CashDividendService";
import {CashDividendSearchTerm} from "../../domain/dividend/CashDividendSearchTerm";
import CashDividendSearchBar from "../../components/CashDividendSearchBar";


export default function SearchCashDividendsPage(): ReactElement {

    const dividends: SearchDataFunction<CashDividendSearchTerm, PageDto<CashDividend>> = useSearchCashDividends();

    return (
            <Container maxWidth={ false } sx={{ mt: 4, mb: 4 }}>
                { <Toolbar/> }
                <Grid container spacing={2} alignItems={"center"} justifyItems={"center"}>
                    <Grid item xs={12} md={10} lg={10}>
                        <Title>All Dividends</Title>
                    </Grid>
                    <CashDividendSearchBar searchFn={ dividends }/>
                    <CashDividendsSearchComponent apiFunction={ dividends }/>
                </Grid>
            </Container>
    );
}