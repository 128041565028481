import React, {ReactElement, useState} from "react";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Title from "../../components/Title";
import {DataGridPremium, GridColDef, GridToolbar} from "@mui/x-data-grid-premium";
import {formatSwedishDateTime} from "../../components/TimeFormat";
import {useGetEquity, useSyncEquity} from "../../application/EquityService";
import SnackbarComponent, {defaultSnackbarState, SnackbarState} from "../../components/SnackbarComponent";
import {RefreshDataFunction} from "../../components/types/RefreshDataFunction";
import {Statistics} from "../../domain/staticdata/Statistics";
import {GridInitialStatePremium} from "@mui/x-data-grid-premium/models/gridStatePremium";
import {useAutoSizeGrid} from "../../components/AutoSizeGridHook";
import {Equity} from "../../domain/staticdata/Equity";
import RefreshStaticComponent from "../../components/RefreshStaticComponent";

export default function Equities(): ReactElement {

    const { data, loading, apiFunction } = useGetEquity();
    const [ snackbarState, setSnackbarState ] = useState<SnackbarState>(defaultSnackbarState);
    const syncEquity: RefreshDataFunction<Statistics> = useSyncEquity();
    const apiRef = useAutoSizeGrid<Equity>({
        data: data,
        loading: loading,
        columns: [
            'shortName',
            'isin',
            'sedol',
            'bloombergCode',
            'fullName',
            'currency',
            'secType',
            'changedTimestamp'
        ]
    });

    function syncInBackground() {
        setSnackbarState({
            severity: 'info',
            open: true,
            message: "Sync will run in the background, it will take a while for Equities, you will be notified when it is done."
        });
    }

    const initialState: GridInitialStatePremium =
        {
            columns: {
                columnVisibilityModel: {
                },
                dimensions: {
                    shortName: { width: 170 },
                    isin: { width: 180 },
                    sedol: { width: 100 },
                    bloombergCode: { width: 100 },
                    fullName: { flex: 1 },
                    currency: { width: 100 },
                    secType: { width: 100 },
                    changedTimestamp: { width: 300 }
                }
            },
            pagination: {
                paginationModel: {
                    pageSize: 25000
                }
            }
        }


    const columns: GridColDef[] = [
        { field: 'shortName', headerName: 'SCD ID' },
        { field: 'isin', headerName: 'ISIN' },
         { field: 'sedol', headerName: 'Sedol' },
         { field: 'bloombergCode', headerName: 'Bloomberg' },
         { field: 'fullName', headerName: 'fullName' },
         { field: 'currency', headerName: 'Currency' },
         { field: 'secType', headerName: 'secType' },
         { field: 'changedTimestamp', headerName: 'changedTimestamp', renderCell: (params) =>
                 formatSwedishDateTime(params.row.changedTimestamp)
         }
    ];

    return (
        <Container maxWidth={ false } sx={{mt: 4, mb: 4}}>
            {<Toolbar/>}
            <Grid container spacing={2} alignItems={"center"} justifyItems={"center"}>
                <Grid item xs={12} md={10} lg={10}>
                    <Title>Equities</Title>
                </Grid>
                <RefreshStaticComponent
                    refreshFunction={ syncEquity }
                    dataFunction={ apiFunction }
                    setSnackbarState={ setSnackbarState }
                    notifyFunction={ syncInBackground }
                />
                <Grid item xs={12} md={12} lg={12} height={ 1000 }>
                    <DataGridPremium
                        apiRef={apiRef}
                        initialState={initialState}
                        slots={{ toolbar: GridToolbar }}
                        loading={loading}
                        rows={data}
                        columns={columns}
                        density={"compact"}
                        getRowId={(row) => row.shortName}
                        pagination={true}
                        pageSizeOptions={[1000, 5000, 10000, 25000]}
                    />
                </Grid>
            </Grid>
            <SnackbarComponent setState={ setSnackbarState} state={ snackbarState}/>
        </Container>
    );
}