import * as React from 'react';
import {responsiveFontSizes, ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import {AuthenticatedTemplate, UnauthenticatedTemplate} from "@azure/msal-react";
import DashboardMenu from "./DashboardMenu";
import Counterparts from "../pages/counterpart/Counterparts";
import Equities from "../pages/equity/Equities";
import EquityTransactions from "../pages/transaction/EquityTransactionsPage";
import EquityTransactionPage from "../pages/transaction/EquityTransactionPage";
import InboundTransactions from "../pages/monitoring/InboundTransactionsPage";
import ProfileContent from "../pages/Profile";
import AccessTokenRequestInterceptor from "../authorization/AccessTokenRequestInterceptor";
import ModelPortfolios from "../pages/modelPortfolio/ModelPortfolios";
import Mandates from "../pages/mandate/Mandates";
import Portfolios from "../pages/portfolio/Portfolios";
import Currencies from "../pages/currency/Currencies";
import CurrencyCrosses from "../pages/currencycross/CurrencyCrosses";
import Futures from "../pages/future/Futures";
import FxTransactions from "../pages/transaction/FxTransactionsPage";
import FxTransaction from "../pages/transaction/FxTransactionPage";
import FutureTransaction from "../pages/transaction/FutureTransactionPage";
import FutureTransactions from "../pages/transaction/FutureTransactionsPage";
import InboundTransaction from "../pages/monitoring/InboundTransactionPage";
import Title from "../components/Title";
import TtradeStatuses from "../pages/monitoring/TtradeStatusesPage";
import PendingCashDividendsPage from "../pages/cashdividend/PendingCashDividendsPage";
import InstrumentCashDividend from "../pages/cashdividend/InstrumentCashDividend";
import AssetManagers from "../pages/assetmanager/AssetManagers";
import AssetManager from "../pages/assetmanager/AssetManager";
import {ReactElement} from "react";
import Brokers from "../pages/broker/Brokers";
import InstrumentImport from '../pages/dataprovider/InstrumentImport';
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import ActiveEquityTransactionsPage from "../pages/monitoring/ActiveEquityTransactionsPage";
import ActiveFutureTransactionsPage from "../pages/monitoring/ActiveFutureTransactionsPage";
import ActiveFxTransactionsPage from "../pages/monitoring/ActiveFxTransactionsPage";
import ValidateConfiguration from "../pages/assetmanager/ValidateConfiguration";
import AssetManagerConfiguration from "../pages/assetmanager/AssetManagerConfiguration";
import AuditEventsPage from "../pages/audit/AuditEventsPage";
import AuditRequestsPage from "../pages/audit/AuditRequestsPage";
import AuditRequestPage from "../pages/audit/AuditRequestPage";
import AuditTransactionEventsPage from "../pages/audit/AuditTransactionEventPage";
import VersionCheck from "../components/VersionCheck";
import SearchCashDividendsPage from '../pages/cashdividend/SearchCashDividendsPage';
import DividendConfigPage from '../pages/assetmanager/DividendConfigPage';
import DividendCurrencyConfigurationPage from "../pages/assetmanager/DividendCurrencyConfigurationPage";
import CashDividendsMonitorPage from "../pages/cashdividend/CashDividendsMonitorPage";
import VerifiedCashDividendsPage from "../pages/cashdividend/VerifiedCashDividendsPage";
import DividendPaymentDateConfigurationPage from '../pages/assetmanager/DividendPaymentDateConfigurationPage';
import DividendFilterConfigurationPage from '../pages/assetmanager/DividendFilterConfigurationPage';
import theme from "../theme";
import DelayedTransactionsPage from "../pages/transaction/DelayedTransactionsPage";

let mdTheme = theme;
mdTheme = responsiveFontSizes(mdTheme);

export default function Dashboard(): ReactElement {

    return (
        <ThemeProvider theme={mdTheme}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box sx={{display: 'flex'}}>
                    <CssBaseline/>
                    <Router>
                        <AuthenticatedTemplate>
                            <DashboardMenu/>
                            <VersionCheck/>
                            <AccessTokenRequestInterceptor>
                                <Routes>
                                    <Route path="/" element={<ProfileContent/>}/>
                                    <Route path="/currencies" element={<Currencies/>}/>
                                    <Route path="/currencyCrosses" element={<CurrencyCrosses/>}/>
                                    <Route path="/futures" element={<Futures/>}/>
                                    <Route path="/mandates" element={<Mandates/>}/>
                                    <Route path="/portfolios" element={<Portfolios/>}/>
                                    <Route path="/modelPortfolios" element={<ModelPortfolios/>}/>
                                    <Route path="/counterparts" element={<Counterparts/>}/>
                                    <Route path="/counterparts/:assetManager" element={<Counterparts/>}/>
                                    <Route path="/brokers" element={<Brokers/>}/>
                                    <Route path="/equities" element={<Equities/>}/>
                                    <Route path="/transaction/eq/:assetManager/:transactionId"
                                           element={<EquityTransactions/>}/>
                                    <Route path="/transaction/eq/active" element={<ActiveEquityTransactionsPage/>}/>
                                    <Route path="/transaction/eq/:transactionId" element={<EquityTransactionPage/>}/>
                                    <Route path="/transaction/eq" element={<EquityTransactions/>}/>
                                    <Route path="/transaction/fx/:assetManager/:transactionId"
                                           element={<FxTransactions/>}/>
                                    <Route path="/transaction/fx/active" element={<ActiveFxTransactionsPage/>}/>
                                    <Route path="/transaction/fx/:transactionId" element={<FxTransaction/>}/>
                                    <Route path="/transaction/fx" element={<FxTransactions/>}/>
                                    <Route path="/transaction/fu/:assetManager/:transactionId"
                                           element={<FutureTransactions/>}/>
                                    <Route path="/transaction/fu/active" element={<ActiveFutureTransactionsPage/>}/>
                                    <Route path="/transaction/fu/:transactionId" element={<FutureTransaction/>}/>
                                    <Route path="/transaction/fu" element={<FutureTransactions/>}/>
                                    <Route path="/issues/inbound" element={<InboundTransactions/>}/>
                                    <Route path="/issues/inbound/:refusedId" element={<InboundTransaction/>}/>
                                    <Route path="/issues/inbound/file/:assetManager/:fileName"
                                           element={<InboundTransactions/>}/>
                                    <Route path="/issues/inbound/transaction/:assetManager/:transactionId"
                                           element={<InboundTransactions/>}/>
                                    <Route path="/issues/ttrade" element={<TtradeStatuses/>}/>
                                    <Route path="/dividends/pending" element={<PendingCashDividendsPage/>}/>
                                    <Route path="/dividends/pending/:isin" element={<PendingCashDividendsPage/>}/>
                                    <Route path="/dividends/verified" element={<VerifiedCashDividendsPage/>}/>
                                    <Route path="/dividends/verified/:routeDate" element={<VerifiedCashDividendsPage/>}/>
                                    <Route path="/dividends/all" element={<SearchCashDividendsPage/>}/>
                                    <Route path="/dividends/monitoring" element={<CashDividendsMonitorPage/>}/>
                                    <Route path="/dividends/shortName/:shortName" element={<InstrumentCashDividend/>}/>
                                    <Route path="/dividends/isin/:isin" element={<InstrumentCashDividend/>}/>
                                    <Route path="/dividends/bloomberg/:bloombergCode"
                                           element={<InstrumentCashDividend/>}/>
                                    <Route path="/dividends/config" element={<DividendConfigPage/>}/>
                                    <Route path="/dividends/config/currency/:currency" element={<DividendCurrencyConfigurationPage/>}/>
                                    <Route path="/dividends/config/payment/date/:id" element={<DividendPaymentDateConfigurationPage/>}/>
                                    <Route path="/dividends/config/filter/:id" element={<DividendFilterConfigurationPage/>}/>
                                    <Route path="/assetmanagers" element={<AssetManagers/>}/>
                                    <Route path="/assetmanagers/:assetManagerId" element={<AssetManager/>}/>
                                    <Route path="/assetmanagers/:assetManagerId/validate" element={<ValidateConfiguration/>}/>
                                    <Route path="/assetmanagers/:assetManagerId/late-transactions" element={<DelayedTransactionsPage/>}/>
                                    <Route path="/assetmanagers/:assetManagerId/edit" element={<AssetManagerConfiguration/>}/>
                                    AssetManagerTradeReport
                                    <Route path="/instrumentImport" element={<InstrumentImport/>}/>
                                    <Route path="/audit/events" element={ <AuditEventsPage/> }/>
                                    <Route path="/audit/events/:transactionType/:transactionId" element={ <AuditTransactionEventsPage/> }/>
                                    <Route path="/audit/requests" element={ <AuditRequestsPage/> }/>
                                    <Route path="/audit/requests/:requestId" element={ <AuditRequestPage/> }/>
                                </Routes>
                            </AccessTokenRequestInterceptor>
                        </AuthenticatedTemplate>
                        <UnauthenticatedTemplate>
                            <Container sx={{mt: 2, mb: 2}}>
                                {<Toolbar/>}
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <Title>This dashboard is available for signed in users only.</Title>
                                    </Grid>
                                </Grid>
                            </Container>
                        </UnauthenticatedTemplate>
                    </Router>
                </Box>
            </LocalizationProvider>
        </ThemeProvider>
    );
}
