import {GetDataApi} from "../components/types/GetDataApi";
import {emptyFutureTransaction, FutureTransaction} from "../domain/transaction/FutureTransaction";
import {useCallback, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import axios from "axios";
import {emptyPageDto, PageDto} from "../domain/PageDto";
import {RefreshDataFunction} from "../components/types/RefreshDataFunction";
import {SearchDataFunction} from "../components/types/SearchDataFunction";
import {defaultTransactionSearchTerm, TransactionSearchTerm} from "../domain/transaction/TransactionSearchTerm";
import {getGatewayUrl} from "../components/EnvironmentConfiguration";
import {convertTransactionSearchTerm} from "./TransactionSearchTermDto";

export function useFindFutureTransactions(): RefreshDataFunction<PageDto<FutureTransaction>> {
    const [transactions, setTransactions] = useState<PageDto<FutureTransaction>>(emptyPageDto());
    const { transactionId, assetManager } = useParams();
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const apiFunction = useCallback(async () => {
        setLoading(true);
        if (transactionId && assetManager) {
            const body = JSON.stringify({
                "externalTransactionId": transactionId,
                "assetManager": assetManager
            });
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            await axios.post(getGatewayUrl() + '/transaction/v1/future/findByExternalId', body, config)
                .then((response) => {
                    setTransactions(response.data as PageDto<FutureTransaction>);
                })
                .catch((err) => setError(err.message))
                .finally(() => setLoading(false));
        }
        else {
            axios.get(getGatewayUrl() + '/transaction/v1/future')
                .then((response) => {
                    setTransactions(response.data as PageDto<FutureTransaction>)
                })
                .catch((err) => setError(err.message))
                .finally(() => setLoading(false));
        }
    }, [])
    useEffect(() => {
        apiFunction();
    }, [ transactionId, assetManager ]);

    return { data: transactions, loading, error, apiFunction }
}

export function useFindByFutureTransactionId(): GetDataApi<FutureTransaction> {
    const { transactionId } = useParams();
    const [transaction, setTransaction] = useState<FutureTransaction>(emptyFutureTransaction());
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        setLoading(true);
        axios.get(getGatewayUrl() + "/transaction/v1/future/" + transactionId)
            .then((response) => {
                setTransaction(response.data as FutureTransaction)
            })
            .catch((err) => setError(err.message))
            .finally(() => setLoading(false));
    }, [ ]);

    return { data: transaction, loading, error }
}

export function useSearchFutureTransactions(initialSearchTerm: TransactionSearchTerm | null): SearchDataFunction<TransactionSearchTerm, PageDto<FutureTransaction>> {
    const defaultSearchTerm = initialSearchTerm ?? defaultTransactionSearchTerm();
    const [transactions, setTransactions] = useState<PageDto<FutureTransaction>>(emptyPageDto());
    const [searchTerm, setSearchTerm] = useState<TransactionSearchTerm>(defaultSearchTerm);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const apiFunction = useCallback(
        async (searchTerm: TransactionSearchTerm) => {
            setLoading(true);
            const dto = convertTransactionSearchTerm(searchTerm);
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            axios.post(getGatewayUrl() + '/transaction/v1/future/search', dto, config)
                .then((response) => {
                    setTransactions(response.data as PageDto<FutureTransaction>)
                })
                .catch((err) => setError(err.message))
                .finally(() => setLoading(false));
        },
        [ searchTerm ]
    );

    useEffect(() => { apiFunction(searchTerm); },
        [ searchTerm ]
    );

    return { data: transactions, searchTerm, loading, error, apiFunction, setSearchTermFunction: setSearchTerm }
}