import {DividendId} from "./DividendId";
import {CashDividendState} from "./CashDividendState";
import {CashDividendUpdate} from "./CashDividendUpdate";
import {CashDividendIdentifier} from "./CashDividendIdentifier";

export type CashDividend = {
    id: DividendId,
    eventTime: string,
    verifiedDate: string,
    checkedDate: string,
    requestId: string,
    isin: string,
    bloombergCode: string,
    shortName: string,
    name: string,
    approvedCurrency: string,
    state: CashDividendState,
    message: string,
    dividends: CashDividendUpdate[],
    transferredBy: CashDividendIdentifier
}

export function hasCashDividendEstimatedPaymentDate(dividend: CashDividend) {
    // Prefer BLO date over BONY.
    const update = dividend.dividends
        .toSorted((a, b) => a.source.localeCompare(b.source))
        .find(a => a.paymentDate);
    return update?.estimatedPaymentDate;
}

export function getCashDividendPaymentDate(dividend: CashDividend) {
    // Prefer BLO date over BONY.
    const update = dividend.dividends
        .toSorted((a, b) => a.source.localeCompare(b.source))
        .find(a => a.paymentDate);
    return update?.paymentDate;
}