import {CashDividendSource} from "../dividend/CashDividendSource";


export type DividendPaymentDateConfig = {
    id: number,
    version: number,
    isinPrefix: string | null,
    source: CashDividendSource,
    instrumentCurrency: string | null,
    defaultRule: boolean,
    daysAfterExdate: number
}

export function emptyDividendPaymentDateConfig(): DividendPaymentDateConfig {
    return {
        id: 0,
        version: 0,
        isinPrefix: null,
        source: CashDividendSource.DP,
        instrumentCurrency: null,
        defaultRule: false,
        daysAfterExdate: 30
    }
}