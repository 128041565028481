import axios from "axios";
import {useCallback, useEffect, useState} from "react";
import {Params, useParams} from "react-router-dom";
import {GetDataApi} from "../components/types/GetDataApi";
import {
    emptyTransactionProcessingResult,
    TransactionProcessingResult
} from "../domain/transaction/TransactionProcessingResult";
import {RefreshDataFunction} from "../components/types/RefreshDataFunction";
import {emptyPageDto, PageDto} from "../domain/PageDto";
import {SearchDataFunction} from "../components/types/SearchDataFunction";
import {
    defaultRefusedTransactionSearchTerm,
    RefusedTransactionSearchTerm
} from "../domain/transaction/RefusedTransactionSearchTerm";
import {getGatewayUrl} from "../components/EnvironmentConfiguration";
import {RequestDataFunction} from "../components/types/RequestDataFunction";
import {RefusedTransactionStatus} from "../domain/transaction/RefusedTransactionStatus";
import {convertRefusedTransactionSearchTerm} from "./RefusedTransactionSearchTermDto";

export type AcknowledgeData = {
    status: RefusedTransactionStatus,
    transactions: TransactionProcessingResult[]
    completedFunction: () => void
}

export function useGetRefusedTransactionById():  GetDataApi<TransactionProcessingResult> {
    const [transaction, setTransaction] = useState<TransactionProcessingResult>(emptyTransactionProcessingResult());
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const { refusedId } = useParams();

    useEffect(() => {
        setLoading(true);
        axios.get(getGatewayUrl() + '/transaction/v1/refused/' + refusedId)
            .then((response) => {
                setTransaction(response.data as TransactionProcessingResult)
            })
            .catch((err) => setError(err.message))
            .finally(() => setLoading(false));
    }, []);

    return { data: transaction, loading, error };
}

export function useGetRefusedTransactions(): RefreshDataFunction<PageDto<TransactionProcessingResult>> {

    const [transactions, setTransactions] = useState<PageDto<TransactionProcessingResult>>(emptyPageDto());
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const { transactionId, assetManager, fileName } = useParams();

    const apiFunction = useCallback(async () => {
        setLoading(true);
        if (transactionId) {
            axios.get(getGatewayUrl() + '/transaction/v1/refused/transaction/' + transactionId)
                .then((response) => {
                    setTransactions(response.data as PageDto<TransactionProcessingResult>)
                })
                .catch((err) => setError(err.message))
                .finally(() => setLoading(false));
        }
        else if (assetManager && fileName) {
            const body = JSON.stringify({
                "fileName": fileName
            });
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            axios.post(getGatewayUrl() + '/transaction/v1/refused/file/' + assetManager, body, config)
                .then((response) => {
                    setTransactions(response.data as PageDto<TransactionProcessingResult>)
                })
                .catch((err) => setError(err.message))
                .finally(() => setLoading(false));
        } else {
            axios.get(getGatewayUrl() + '/transaction/v1/refused')
                .then((response) => {
                    setTransactions(response.data as PageDto<TransactionProcessingResult>)
                })
                .catch((err) => setError(err.message))
                .finally(() => setLoading(false));
        }
    }, []);

    useEffect(() => {
        apiFunction();
    }, [ transactionId, assetManager, fileName ]);

    return { data: transactions, loading, error, apiFunction };
}

function getDefaultSearchTerm(params: Readonly<Params<string>>): RefusedTransactionSearchTerm {
    if (params.transactionId && params.assetManager) {
        return {
            assetManager: params.assetManager,
            identifier: '',
            transaction: params.transactionId,
            status: null,
            fromDate: null,
            toDate: null,
        } as RefusedTransactionSearchTerm;
    }
    return defaultRefusedTransactionSearchTerm();
}

export function useSearchRefusedTransactions(): SearchDataFunction<RefusedTransactionSearchTerm, PageDto<TransactionProcessingResult>> {
    const [transactions, setTransactions] = useState<PageDto<TransactionProcessingResult>>(emptyPageDto());
    const params = useParams();
    const [searchTerm, setSearchTerm] = useState<RefusedTransactionSearchTerm>(getDefaultSearchTerm(params));
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const apiFunction = useCallback(
        async (searchTerm: RefusedTransactionSearchTerm) => {
            setLoading(true);
            const dto = convertRefusedTransactionSearchTerm(searchTerm);
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            axios.post(getGatewayUrl() + '/transaction/v1/refused/search', dto, config)
                .then((response) => {
                    setTransactions(response.data as PageDto<TransactionProcessingResult>)
                })
                .catch((err) => setError(err.message))
                .finally(() => setLoading(false));
        },
        [ searchTerm ]
    );

    useEffect(() => { apiFunction(searchTerm); },
        [ searchTerm ]
    );

    return { data: transactions, searchTerm, loading, error, apiFunction, setSearchTermFunction: setSearchTerm }
}

export function useSetStatusFunction(): RequestDataFunction<AcknowledgeData> {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const apiFunction = useCallback(
        async (data: AcknowledgeData) => {
            setLoading(true);
            const body = JSON.stringify(data.transactions);
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            axios.post(getGatewayUrl() + '/transaction/v1/refused/setStatus/' + data.status, body, config)
                .then(() => {
                    data.completedFunction();
                })
                .catch((err) => setError(err.message))
                .finally(() => setLoading(false));
        },
        []
    );

    return {loading, error, apiFunction};
}