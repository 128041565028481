import React, {ReactElement} from "react";
import {useParams} from "react-router-dom";
import {SearchDataFunction} from "../../components/types/SearchDataFunction";
import {PageDto} from "../../domain/PageDto";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import {
    DelayedTransactionSearchTerm
} from "../../domain/transaction/DelayedTransactionSearchTerm";
import dayjs from "dayjs";
import { DelayedTransaction } from "../../domain/transaction/DelayedTransaction";
import DelayedTransactionControlPanel from "../../components/DelayedTransactionControlPanel";
import DelayedTransactionDataPanel from "../../components/DelayedTransactionDataPanel";
import {useSearchDelayedTransactions} from "../../application/TransactionUpdateService";

export default function DelayedTransactionsPage(): ReactElement {
    const params = useParams();
    const initialSearchTerm: DelayedTransactionSearchTerm = {
         assetManager: params.assetManagerId ?? '',
         fromDate: dayjs().subtract(90, 'day'),
         toDate: dayjs()
    };
    const searchFn: SearchDataFunction<DelayedTransactionSearchTerm, PageDto<DelayedTransaction>> = useSearchDelayedTransactions(initialSearchTerm);

    return (
        <Container maxWidth={ false } sx={{ mt: 4, mb: 4 }}>
            { <Toolbar/> }
            <Grid container spacing={2} justifyItems={ "center" } alignItems={ "center" }>
                <DelayedTransactionControlPanel assetManager={params.assetManagerId ?? ''} searchTerm={ searchFn.searchTerm} searchFn={ searchFn.setSearchTermFunction }/>
                <DelayedTransactionDataPanel page={ searchFn.data } loading={ searchFn.loading }/>
            </Grid>
        </Container>
    );
}