import React, {ReactElement} from "react";
import {PageDto} from "../domain/PageDto";
import {
    CashDividend,
    getCashDividendPaymentDate,
    hasCashDividendEstimatedPaymentDate
} from "../domain/dividend/CashDividend";
import {DividendIdUpdate, useDiscardDividend, useTransferDividendToIms} from "../application/CashDividendService";
import {RequestDataFunction} from "./types/RequestDataFunction";
import {DividendId} from "../domain/dividend/DividendId";
import {Link} from "react-router-dom";
import {
    DataGridPremium,
    GridCellParams,
    GridColDef,
    GridRenderCellParams,
    GridToolbar,
    GridValueGetterParams
} from "@mui/x-data-grid-premium";
import RolesRetriever from "../authorization/RolesRetreiver";
import {Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {DeleteRounded, DoneRounded} from "@mui/icons-material";
import {GridInitialStatePremium} from "@mui/x-data-grid-premium/models/gridStatePremium";
import formatDateTime from "./TimeFormat";
import Grid from "@mui/material/Grid";
import {CashDividendSource} from "../domain/dividend/CashDividendSource";
import {CashDividendUpdate} from "../domain/dividend/CashDividendUpdate";
import {CashDividendState} from "../domain/dividend/CashDividendState";
import clsx from 'clsx';
import {getStateClass, StateSx} from "./CashDividendStateRenderingComponent";
import {useAutoSizeGrid} from "./AutoSizeGridHook";
import ApproveDividendOnInstrumentCurrencyComponent from "./ApproveDividendOnInstrumentCurrencyComponent";
import formatNumber from "./NumberFormat";


export type CashDividendsComponentParams = {
    dividends: PageDto<CashDividend>
    loading: boolean
    searchFunction: () => void
}

export default function CashDividendsComponent(params: Readonly<CashDividendsComponentParams>): ReactElement {
    const approveFunction: RequestDataFunction<DividendIdUpdate> = useTransferDividendToIms();
    const discardFunction: RequestDataFunction<DividendIdUpdate> = useDiscardDividend();
    const apiRef = useAutoSizeGrid<CashDividend>({
        data: params.dividends.data,
        loading: params.loading,
        includeOutliers: true,
        outliersFactor: 1.2,
        expand: true,
        columns: [
            'state',
            'exDate',
            'paymentDate',
            'isin',
            'bloombergCode',
            'shortName',
            'name',
            'sources',
            'incomeRate',
            'currency',
            'eventTime'
        ]
    });


    function renderLink(path: string, name: string) {
        if (name) {
            return (<Link to={ path + name }> {name} </Link>);
        }
        return '';
    }

    const dividendUpdated = () => {
        params.searchFunction();
    }

    function transferDividend(id: DividendId) {
        approveFunction.apiFunction({ dividendId: id, instrumentCurrency: false, completedFunction: dividendUpdated });
    }

    function discardDividend(id: DividendId) {
         discardFunction.apiFunction({ dividendId: id, instrumentCurrency: false, completedFunction: dividendUpdated });
    }

    function isLoading() {
        return discardFunction.loading || approveFunction.loading;
    }

    const statesWithButtons = [ CashDividendState.DIFF, CashDividendState.MATCH, CashDividendState.UPDATE];

    function isApprovable(dividend: CashDividend): boolean {
        return !!(dividend.shortName
            && dividend.dividends.find(e => e.paymentDate && e.matchedIncomeRate)
            && statesWithButtons.find((e) => e === dividend.state));
    }

    function renderButtons(params: GridRenderCellParams<CashDividend>) {
        if (isApprovable(params.row)) {
            return (<React.Fragment>
                <RolesRetriever groups={ [ "admin", "super" ]}>
                    <Tooltip title={"Approve, and Transfer Dividend to SCD"} placement={'top'}>
                        <div>
                            <IconButton color="secondary" onClick={ () => transferDividend(params.row.id) } disabled={ isLoading() }>
                                <DoneRounded/>
                            </IconButton>
                        </div>
                    </Tooltip>
                </RolesRetriever>
                { params.row.state }
                <RolesRetriever groups={ [ "admin", "super" ]} >
                    <Tooltip title={"Discard Dividend, will not be transferred to SCD"} placement={'top'}>
                        <div>
                            <IconButton color="secondary" onClick={ () => discardDividend(params.row.id)} disabled={ isLoading() }>
                                <DeleteRounded/>
                            </IconButton>
                        </div>
                    </Tooltip>
                </RolesRetriever>
            </React.Fragment>);
        } else if (statesWithButtons.find((e) => e === params.row.state)) {
            return (<React.Fragment>
                { params.row.state }
                <RolesRetriever groups={ [ "admin", "super" ]}>
                    <Tooltip title={"Discard Dividend, will not be transferred to SCD"} placement={'top'}>
                        <div>
                            <IconButton color="secondary" onClick={ () => discardDividend(params.row.id)} disabled={ isLoading() }>
                                <DeleteRounded/>
                            </IconButton>
                        </div>
                    </Tooltip>
                </RolesRetriever>
            </React.Fragment>);
        } else {
            return (<> { params.row.state } </>)
        }
    }

    const initialState: GridInitialStatePremium =
        {
            columns: {
                columnVisibilityModel: {
                    requestId: false
                },
                dimensions: {
                    eventTime: { width: 170 },
                    state: { width: 170 },
                    sources: { width: 100 },
                    isin: { width: 120 },
                    bloombergCode: { width: 140},
                    shortName: { width: 170 },
                    name: { flex: 1 },
                    exDate: { width: 100 },
                    paymentDate: { width: 110 },
                    incomeRate: { width: 100 },
                    currency: { width: 100 },
                    requestId: { width: 300 }
                }
            }
        }

    function toReadableSource(source: CashDividendSource): string {
        if (source === CashDividendSource.DP) {
            return 'BLO';
        } else {
            return 'BNY'
        }
    }

    function isNetIncomeRateMatch(dividends: CashDividendUpdate[]) {
        return dividends.find(d => d.netIncomeRate === d.matchedIncomeRate && d.source === 'DP')
    }

    function renderCurrency(update: CashDividend) {
        return (<React.Fragment>
                    <ApproveDividendOnInstrumentCurrencyComponent
                        approveFunction={approveFunction}
                        isApprovable={true}
                        id={ update.id }
                        instrumentCurrency={ update.dividends[0]?.instrumentCurrency }
                        currency={ update.dividends[0]?.currency } />
                </React.Fragment>
        );
    }

    const columns: GridColDef<CashDividend>[] = [
        { field: 'state', headerName: 'State',
            renderCell: (params: GridRenderCellParams<CashDividend>) => renderButtons(params),
            cellClassName: (params: GridCellParams<CashDividend>) => {
                return getStateClass(params.row.state);
            },
            minWidth: 170
        },
        { field: 'exDate', headerName: 'Ex Date',
            valueGetter: (params: GridValueGetterParams<CashDividend>) => params.row.dividends[0].exDate
        },
        { field: 'paymentDate', headerName: 'Payment Date',
            valueGetter: (params: GridValueGetterParams<CashDividend>) => getCashDividendPaymentDate(params.row),
            cellClassName: (params: GridCellParams<CashDividend>) => {
                return clsx('ttrade-dividend', {
                    update: hasCashDividendEstimatedPaymentDate(params.row)
                })
            }
        },
        { field: 'isin', headerName: 'ISIN',
            renderCell: (params: GridRenderCellParams<CashDividend>) => renderLink('/dividends/isin/', params.row.isin)
        },
        { field: 'bloombergCode', headerName: 'Bloomberg',
            renderCell: (params: GridRenderCellParams<CashDividend>) => renderLink('/dividends/bloomberg/', params.row.bloombergCode)
        },
        { field: 'shortName', headerName: 'Instrument',
            renderCell: (params: GridRenderCellParams<CashDividend>) => renderLink('/dividends/shortName/', params.row.shortName)
        },
        { field: 'name', headerName: 'Instrument Name', flex: 1 },
        { field: 'sources', headerName: 'Source',
            valueGetter: (params: GridValueGetterParams<CashDividend>) => {
                if (params.row.state === CashDividendState.MATCH) {
                    return "";
                }
                if (params.row.state === CashDividendState.UPDATE && params.row.dividends.length > 1) {
                    return toReadableSource(params.row.dividends[0].source) + " " + toReadableSource(params.row.dividends[1].source);
                }
                return toReadableSource(params.row.dividends[0].source);
            }
        },
        { field: 'incomeRate', headerName: 'Income Rate',
            valueGetter: (params: GridValueGetterParams<CashDividend>) => formatNumber(params.row.dividends[0].matchedIncomeRate),
            cellClassName: (params: GridCellParams<CashDividend>) => {
                return clsx('ttrade-dividend', {
                    negative: isNetIncomeRateMatch(params.row.dividends)
                })
            }
        },
        { field: 'currency',
            headerName: 'Currency',
            renderCell: (params: GridRenderCellParams<CashDividend>) => renderCurrency(params.row),
            cellClassName: (params: GridCellParams<CashDividend>) => {
                return clsx('ttrade-dividend', {
                    negative: params.row.dividends.find(d => d.currency !== d.instrumentCurrency)
                })
            },
            maxWidth: 100
        },
        { field: 'eventTime', headerName: 'EventTime',
            renderCell: (params: GridRenderCellParams<CashDividend>) => formatDateTime(params.row.eventTime),
            minWidth: 130
        }
    ];

    return (
            <Grid item xs={12} md={12} lg={12} height={ 1000 }>
                <DataGridPremium
                    apiRef={apiRef}
                    sx={ StateSx }
                    initialState={initialState}
                    slots={{ toolbar: GridToolbar }}
                    loading={params.loading}
                    rows={params.dividends.data }
                    columns={columns}
                    density={'compact'}
                    getRowId={(row: CashDividend) => row.id.id}
                />
            </Grid>
    );
}