import React, {ReactElement, useState} from "react";
import {SearchDataFunction} from "../../components/types/SearchDataFunction";
import {TransactionSearchTerm} from "../../domain/transaction/TransactionSearchTerm";
import {PageDto} from "../../domain/PageDto";
import Grid from "@mui/material/Grid";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import {ExpandLess, ExpandMore, SearchRounded} from "@mui/icons-material";
import {Collapse, TextField, Tooltip} from "@mui/material";
import {DesktopDatePicker} from "@mui/x-date-pickers";
import { Dayjs } from 'dayjs';

export type TransactionSearchBarParams<T> = {
    searchFn: SearchDataFunction<TransactionSearchTerm, PageDto<T>>;
}

export default function TransactionSearchBar<T>(params: Readonly<TransactionSearchBarParams<T>>): ReactElement {
    const [state, setState] = useState<boolean>(false);


    function onChangeTransaction(event: React.ChangeEvent<HTMLInputElement>) {
        const merged = {...params.searchFn.searchTerm, ...{transaction: event.target.value}};
        params.searchFn.setSearchTermFunction(merged);
    }

    function onChangeInstrument(event: React.ChangeEvent<HTMLInputElement>) {
        const merged = {...params.searchFn.searchTerm, ...{instrument: event.target.value}};
        params.searchFn.setSearchTermFunction(merged);
    }

    function onChangeCounterpart(event: React.ChangeEvent<HTMLInputElement>) {
        const merged = {...params.searchFn.searchTerm, ...{counterpart: event.target.value}};
        params.searchFn.setSearchTermFunction(merged);
    }

    function onChangeAssetManager(event: React.ChangeEvent<HTMLInputElement>) {
        const merged = {...params.searchFn.searchTerm, ...{assetManager: event.target.value}};
        params.searchFn.setSearchTermFunction(merged);
    }

    function onChangeFromDate(value: Dayjs | null): void {
        const merged = {...params.searchFn.searchTerm, ...{fromDate: value}};
        params.searchFn.setSearchTermFunction(merged);
    }

    function onChangeToDate(value: Dayjs | null): void {
        const merged = {...params.searchFn.searchTerm, ...{toDate: value}};
        params.searchFn.setSearchTermFunction(merged);
    }

    function handleSearchClick(): void {
        setState(!state);
    }

    return (
        <React.Fragment>
                <Grid item xs={12} md={1} lg={1}>
                    <Tooltip title={"Search Transactions"}>
                        <span>
                            <ListItemButton onClick={handleSearchClick}>
                                <ListItemIcon>
                                    <SearchRounded/>
                                </ListItemIcon>
                                {state ? <ExpandLess/> : <ExpandMore/>}
                            </ListItemButton>
                        </span>
                    </Tooltip>
                </Grid>
                <Collapse in={state} timeout="auto" unmountOnExit>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} lg={2}>
                            <Tooltip title={ "tradeDate, inclusive" } placement={ "top" }>
                                <span>
                                    <DesktopDatePicker
                                        label="From Date"
                                        format="YYYY-MM-DD"
                                        value={params.searchFn.searchTerm.fromDate}
                                        onChange={ (value) => {
                                            return onChangeFromDate(value);
                                        }}
                                    />
                                </span>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12} md={6} lg={2}>
                            <Tooltip title={ "tradeDate, inclusive" } placement={ "top" }>
                                <span>
                                    <DesktopDatePicker
                                        label="To Date"
                                        value={params.searchFn.searchTerm.toDate}
                                        format="YYYY-MM-DD"
                                        onChange={ (value) => {
                                            return onChangeToDate(value);
                                        }}
                                    />
                                </span>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12} md={6} lg={2}>
                            <Tooltip title={ "Asset Manager, min 2 characters" } placement={ "top" }>
                                <TextField
                                    label="Asset Manager"
                                    id="assetmanager-search"
                                    value={params.searchFn.searchTerm.assetManager}
                                    onChange={onChangeAssetManager}
                                />
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12} md={6} lg={2}>
                            <Tooltip title={ "External or T-Trade transaction identifier, min 6 characters" } placement={ "top" }>
                                <TextField
                                    label="Transaction"
                                    id="transaction-id-search"
                                    value={params.searchFn.searchTerm.transaction}
                                    onChange={onChangeTransaction}
                                />
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12} md={6} lg={2}>
                            <Tooltip title={ "Sedol or ISIN or BB code or Name, min 3 characters" } placement={ "top" }>
                                <TextField
                                    label="Instrument"
                                    id="instrument-id-search"
                                    value={params.searchFn.searchTerm.instrument}
                                    onChange={onChangeInstrument}
                                />
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12} md={6} lg={2}>
                            <Tooltip title={ "shortCode or Name, min 3 characters" } placement={ "top" }>
                                <TextField
                                    label="Counterpart"
                                    id="counterpart-search"
                                    value={params.searchFn.searchTerm.counterpart}
                                    onChange={onChangeCounterpart}
                                />
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Collapse>
        </React.Fragment>
    );
}