
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Tooltip
} from "@mui/material";
import * as React from "react";
import {
  TtradeTransactionUpdateRequest,
  useTransactionResend
} from "../application/TransactionUpdateService";
import {RequestDataFunction} from "./types/RequestDataFunction";
import {TransactionType} from "../domain/transaction/TransactionType";
import IconButton from "@mui/material/IconButton";
import {FastForwardRounded} from "@mui/icons-material";
import {SnackbarState} from "./SnackbarComponent";

export type ResendTransactionDialogProps = {
  transaction: string,
  type: TransactionType,
  setSnackbarState: React.Dispatch<React.SetStateAction<SnackbarState>>,
  completedFunction: () => void
}
export default function ResendTransactionDialog(props: Readonly<ResendTransactionDialogProps>) {
  const [open, setOpen] = React.useState(false);
  const [text, setText] = React.useState('');
  const resendFunction: RequestDataFunction<TtradeTransactionUpdateRequest> = useTransactionResend();

  const handleUpdate = () => {
    props.completedFunction();
  }
  const resendTransaction = async (message: string): Promise<void> => {
    await resendFunction.apiFunction({type: props.type, transaction: props.transaction, message: {message: message}, completedFunction: handleUpdate })
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleResponseCancel = () => {
    setOpen(false);
  };

  const handleResponseOk = () => {
    resendTransaction(text)
        .then(() => {
          setOpen(false);
          props.setSnackbarState({ message: "Updates will be visible after a few seconds when you refresh this page", open: true, severity: "success" });
        })
        .catch(() => props.setSnackbarState({ message: "Refresh failed", open: true, severity: 'error'}));
  };

  function handleText(e: React.ChangeEvent<HTMLInputElement>) {
     setText(e.target.value);
  }

  return (
    <React.Fragment>
      <Tooltip title={"Force sending QUEUED Transaction to SCD MsgQueue"} placement={"top"}>
        <IconButton color="warning" onClick={ handleClickOpen } disabled= { resendFunction.loading } >
          <FastForwardRounded/>
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleResponseCancel}>
        <DialogTitle>Send Transaction updates in QUEUED state to SCD</DialogTitle>
        <DialogContent>
            <p> <strong>NOTE:</strong> This ACTION is performed automatically by T-Trade, this action is here ONLY
              to aid if T-Trade for some reason gets a Transaction stuck in QUEUED status.
            </p>
            <p> This will send first QUEUED T-Trade Transaction update for {props.transaction} to SCD, T-Trade
              will then handle all other QUEUED updates for that Transaction automatically.
            </p>
            <p> This means that for any Transaction, you should only have to do this ONCE</p>

            <strong> Please enter the reason why you send this transaction manually to SCD, text entered will be visible in T-Trade UI. </strong>
          <TextField
            autoFocus
            margin="dense"
            id="reason"
            label="Reason why Transaction Update needs manual send to SCD"
            fullWidth
            variant="standard"
            value={ text }
            onChange={ handleText }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleResponseCancel}>Cancel</Button>
          <Button disabled={ ! text || text.length === 0 || text.length > 200 } onClick={handleResponseOk}>Force Send to SCD</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}