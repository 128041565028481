import React, {ReactElement} from "react";
import {
    Button,
    FormControl,
    Menu,
    MenuItem
} from "@mui/material";

export enum TransferableState {
    DIFF = 'DIFF',
    MATCH = 'MATCH',
    UPDATE = 'UPDATE'
}

export type DividendBulkActionComponentParam = {
    action: string,
    apiFunction: (a: TransferableState) => void,
    loading: boolean
}

export default function DividenBulkActionComponent(params: Readonly<DividendBulkActionComponentParam>): ReactElement {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    }

    const handleMenuClick = (event: TransferableState) => {
        params.apiFunction(event);
        handleClose();
    };

    return (
        <FormControl sx={{ m: 1, minWidth: 100 }}>
                <Button
                    variant='outlined'
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    disabled={ params.loading }
                >
                    { params.action }
                </Button>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    { Object.values(TransferableState).map((e: TransferableState) =>
                        <MenuItem
                            key={e}
                            value={e}
                            onClick={ () => handleMenuClick(e) }>
                            { "All " + e }
                        </MenuItem>
                    )}
                </Menu>
        </FormControl>
    );
}