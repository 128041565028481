import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {DividendIdUpdate} from "../application/CashDividendService";
import {RequestDataFunction} from "./types/RequestDataFunction";
import {Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {DoneRounded} from "@mui/icons-material";
import RolesRetriever from "../authorization/RolesRetreiver";
import {DividendId} from "../domain/dividend/DividendId";

export type ApproveDividendOnInstrumentCurrencyComponentParams = {
    id: DividendId
    isApprovable: boolean
    instrumentCurrency: string
    currency: string
    approveFunction: RequestDataFunction<DividendIdUpdate>
}
export default function ApproveDividendOnInstrumentCurrencyComponent(params: Readonly<ApproveDividendOnInstrumentCurrencyComponentParams>) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleCancel = () => {
        setOpen(false);
    };

    const handleOk = () => {
        params.approveFunction.apiFunction({ dividendId: params.id, instrumentCurrency: true, completedFunction: () => handleCancel() });
    }

    if (params.isApprovable
        && params.instrumentCurrency
        && params.currency
        && params.instrumentCurrency !== params.currency) {
        return (
            <React.Fragment>
                <RolesRetriever groups={ [ "admin", "super" ]}>
                    <div>
                        <Tooltip title={"Approve transfer to SCD with Instrument Currency (" + params.instrumentCurrency + ")" } placement={'top'}>
                            <div>
                                <IconButton color="warning" onClick={handleClickOpen} disabled={ params.approveFunction.loading }>
                                    <DoneRounded/>
                                </IconButton>
                            </div>
                        </Tooltip>
                        <Dialog
                            open={open}
                            onClose={handleCancel}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                {"Transfer Dividend to SCD with Instrument Currency ?"}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    <p>You are now approving importing this dividend to SCD with Instrument Currency { params.instrumentCurrency } instead of Dividend Currency { params.currency }.</p>
                                    <p><strong>The Dividend Rate will not be updated automatically</strong></p>
                                    <p>This needs to be updated manually in SCD once this Dividend is imported to
                                        SCD. When you use this you will enable the possibility to update this dividend from T-Trade in the future when
                                        Bony and/or Bloomberg sends an update with the proper Currency and Rate.
                                    </p>
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCancel} autoFocus>Cancel</Button>
                                <Button onClick={handleOk}>OK</Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                </RolesRetriever>
                { params.currency }
            </React.Fragment>);
    } else {
        return (<>{params.currency}</>);
    }
}