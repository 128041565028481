export enum CashDividendSource {
    VPB = 'VPB',
    DP = 'DP'
}

export function toHumanReadableFormat(source: CashDividendSource): string {
    if (source === CashDividendSource.DP) {
        return 'BLO';
    } else {
        return 'BNY'
    }
}