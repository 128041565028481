import {formatDayjsDateString} from "../../components/TimeFormat";
import dayjs from "dayjs";

export type CashDividendImportStatus = {
    vpbDividends: number
    dpDividends: number
    date: string | null
}

export function defaultCashDividendImportStatus(): CashDividendImportStatus {
    return {
        vpbDividends: 0,
        dpDividends: 0,
        date: formatDayjsDateString(dayjs())
    };
}