import * as React from 'react';

import Dashboard from './dashboard/Dashboard';
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import {ReactElement} from "react";

export default function App(): ReactElement {
  return (
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <Dashboard/>
      </MsalAuthenticationTemplate>
  );
}
