
export enum ReasonCode {
    F_LINE_WRONG_NUMBER_OF_COLUMNS = 'F_LINE_WRONG_NUMBER_OF_COLUMNS',
    F_COLUMN_PARSE_FAILED = 'F_COLUMN_PARSE_FAILED',

    // Transaction Reasons -- When we cannot detect type of transaction / externalId.
    F_LINE_NO_EXTERNAL_TRANSACTION_ID = 'F_LINE_NO_EXTERNAL_TRANSACTION_ID',
    F_TRANSACTION_TYPE_MISSING = 'F_TRANSACTION_TYPE_MISSING',
    F_TRANSACTION_TYPE_NOT_KNOWN = 'F_TRANSACTION_TYPE_NOT_KNOWN',

    // Transaction Reasons
    T_IGNORED = 'T_IGNORED',
    T_UNSUPPORTED_TRANSACTION_CODE = 'T_UNSUPPORTED_TRANSACTION_CODE',

    T_INVALID_STATE_UNKNOWN = 'T_INVALID_STATE_UNKNOWN',
    T_INVALID_STATE_DELETE = 'T_INVALID_STATE_DELETE',
    T_INVALID_STATE_CONFIRM_BEFORE_ADD = 'T_INVALID_STATE_CONFIRM_BEFORE_ADD',
    T_INVALID_STATE_CONFIRM_ALREADY_CONFIRMED = 'T_INVALID_STATE_CONFIRM_ALREADY_CONFIRMED',
    T_INVALID_STATE_ADD_ALREADY_ADDED = 'T_INVALID_STATE_ADD_ALREADY_ADDED',

    T_SD_MISSING_EQUITY = 'T_SD_MISSING_EQUITY',
    T_DP_MISSING_EQUITY = 'T_DP_MISSING_EQUITY',
    T_SD_MISSING_PORTFOLIO = 'T_SD_MISSING_PORTFOLIO',
    T_SD_MISSING_MANDATE = 'T_SD_MISSING_MANDATE',
    T_SD_MISSING_MODELPORTFOLIO = 'T_SD_MISSING_MODELPORTFOLIO',
    T_SD_MISSING_COUNTERPART = 'T_SD_MISSING_COUNTERPART',
    T_SD_NO_CONFIGURED_COUNTERPART = 'T_SD_NO_CONFIGURED_COUNTERPART',
    T_COUNTERPART_NOT_ACCEPTED = 'T_COUNTERPART_NOT_ACCEPTED',
    T_AMOUNTS_NOT_MATCHING = 'T_AMOUNTS_NOT_MATCHING',
    T_SD_MISSING_BROKER = 'T_SD_MISSING_BROKER',
    T_SD_MISSING_FUTURE = 'T_SD_MISSING_FUTURE',
    T_DP_MISSING_FUTURE = 'T_DP_MISSING_FUTURE',
    T_SD_MISSING_CURRENCYCROSS = 'T_SD_MISSING_CURRENCYCROSS',
    T_SD_MISSING_CURRENCY = 'T_SD_MISSING_CURRENCY',
    T_CLS_VALUE_MISMATCH = 'T_CLS_VALUE_MISMATCH',
    T_FIELD_UPDATE_NOT_ALLOWED = 'T_FIELD_UPDATE_NOT_ALLOWED',

    ACCEPTED = 'ACCEPTED'
}