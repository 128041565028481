import React, {ReactElement} from "react";
import {useParams} from "react-router-dom";
import {
    AuditByTransactionIdParams,
    useGetAuditEventsByTransactionId,
} from "../../application/AuditService";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import Title from "../../components/Title";
import {GetDataApi} from "../../components/types/GetDataApi";
import {AuditEventDto} from "../../domain/audit/AuditEventDto";
import {TransactionType} from "../../domain/transaction/TransactionType";
import AuditEventDtoComponent from "../../components/AuditEventDtoComponent";
import RolesRetriever from "../../authorization/RolesRetreiver";

export default function AuditTransactionEventsPage(): ReactElement {
    const params = useParams();
    const searchTerm: AuditByTransactionIdParams = {
            transactionId: params.transactionId as string,
            transactionType: params.transactionType as TransactionType
    };
    const searchFn: GetDataApi<AuditEventDto[]> = useGetAuditEventsByTransactionId(searchTerm);

    return (
        <RolesRetriever groups={ ["super"] }>
            <Container maxWidth={ false } sx={{ mt: 4, mb: 4 }}>
                { <Toolbar/> }
                <Grid container spacing={2}>
                    <Grid item xs={12} md={11} lg={11}>
                        <Title>Audit Events For Transaction { params.transactionId} </Title>
                    </Grid>
                    <AuditEventDtoComponent data={ searchFn.data } loading={searchFn.loading}/>
                </Grid>
            </Container>
        </RolesRetriever>
    );
}