import React, {ReactElement, useEffect, useState} from "react";
import {PageDto} from "../domain/PageDto";
import {
    CashDividend,
    getCashDividendPaymentDate,
    hasCashDividendEstimatedPaymentDate
} from "../domain/dividend/CashDividend";
import {
    DataGridPremium,
    GridCellParams,
    GridColDef,
    GridRenderCellParams, GridToolbar,
    GridValueGetterParams
} from "@mui/x-data-grid-premium";
import {GridInitialStatePremium} from "@mui/x-data-grid-premium/models/gridStatePremium";
import {formatDate} from "./TimeFormat";
import clsx from 'clsx';
import {getStateClass, StateSx} from "./CashDividendStateRenderingComponent";
import {CashDividendUpdate} from "../domain/dividend/CashDividendUpdate";
import {CashDividendSource} from "../domain/dividend/CashDividendSource";
import {CashDividendState} from "../domain/dividend/CashDividendState";
import {CashDividendSourceDto} from "../domain/dividend/CashDividendSourceDto";
import {useAutoSizeGrid} from "./AutoSizeGridHook";
import formatNumber from "./NumberFormat";

export type CashDividendsComponentParams = {
    dividends: PageDto<CashDividend>
    loading: boolean
}

const idUsed: CashDividendState[] = [
    CashDividendState.TIMEOUT,
    CashDividendState.CANCELLED,
    CashDividendState.FAILED,
    CashDividendState.TRANSFERRED,
    CashDividendState.OK,
    CashDividendState.APPROVED
];

export default function CashDividendDetailsComponent(params: Readonly<CashDividendsComponentParams>): ReactElement {
    const [ sources, setSources] = useState<CashDividendSourceDto[]>([]);
    useEffect(() => {
        setSources(params.dividends.data
            .filter(a => idUsed.find(c => c === a.state))
            .map(a => a.dividends.map(d => toDto(d)))
            .flat());
    }, [ params.dividends ]);

    const apiRef = useAutoSizeGrid<CashDividendSourceDto>({
        data: sources,
        loading: params.loading,
        columns: [
            'verifiedDate',
            'state',
            'exDate',
            'paymentDate',
            'incomeRate',
            'currency',
            'id1',
            'id2',
            'sequence',
            'bnyId',
            'bloId',
            'message',
            'requestId'
        ]}
    );

    function toDto(update: CashDividendUpdate) {
        return { 'source': update.source, 'eventId': update.eventId } as CashDividendSourceDto
    }

    function renderStates(updates: CashDividendUpdate[], source: CashDividendSource) {
        const update = updates.find(u => u.source === source);
        if (update) {
            return update.eventId;
        }
        return "";
    }

    function matchesId(s: CashDividendSourceDto, dividend: CashDividend) {
        return dividend.dividends.find(d => d.source === s.source && d.eventId === s.eventId);
    }

    function idIsApproved(dividend: CashDividend) {
        return sources.find(s => matchesId(s, dividend))
    }

    const initialState: GridInitialStatePremium =
        {
            columns: {
                columnVisibilityModel: {
                    requestId: false
                },
                dimensions: {
                    verifiedDate: {width: 170},
                    state: {width: 170},
                    exDate: {width: 100},
                    paymentDate: {width: 110},
                    incomeRate: {width: 100},
                    currency: {width: 100},
                    id1: {width: 100},
                    id2: {width: 100},
                    sequence: {width: 80},
                    bnyId: {width: 180},
                    bloId: {width: 180},
                    message: {flex: 0.8},
                    requestId: {width: 300}
                }
            },
            pagination: {
                paginationModel: {
                    pageSize: 25
                }
            }
        };

    const columns: GridColDef<CashDividend>[] = [
        { field: 'state', headerName: 'State',
            cellClassName: (params: GridCellParams<CashDividend>) => {
                return getStateClass(params.row.state);
            }
        },
        { field: 'exDate', headerName: 'Ex Date',
            valueGetter: (params: GridValueGetterParams<CashDividend>) => params.row.dividends[0].exDate
        },
        { field: 'paymentDate', headerName: 'Payment Date',
            valueGetter: (params: GridValueGetterParams<CashDividend>) => getCashDividendPaymentDate(params.row),
            cellClassName: (params: GridCellParams<CashDividend>) => {
                return clsx('ttrade-dividend', {
                    update: hasCashDividendEstimatedPaymentDate(params.row)
                })
            }
        },
        { field: 'incomeRate', headerName: 'Income Rate',
            valueGetter: (params: GridValueGetterParams<CashDividend>) => formatNumber(params.row.dividends[0].matchedIncomeRate),
        },
        { field: 'currency',
            headerName: 'Currency',
            valueGetter: (params: GridValueGetterParams<CashDividend>) => params.row.approvedCurrency || params.row.dividends[0].currency,
            cellClassName: (params: GridCellParams<CashDividend>) => {
                return clsx('ttrade-dividend', {
                    negative: params.row.dividends.find(d => d.currency !== d.instrumentCurrency && params.row.approvedCurrency === d.instrumentCurrency),
                    update: params.row.dividends.find(d => d.currency !== d.instrumentCurrency && params.row.approvedCurrency !== d.instrumentCurrency)
                })
            }
        },
        { field: 'id1', headerName: 'DIV ID 1',
            valueGetter : (params: GridValueGetterParams<CashDividend>) => params.row.transferredBy?.id
        },
        { field: 'id2', headerName: 'DIV ID 2',
            valueGetter : (params: GridValueGetterParams<CashDividend>) => params.row.transferredBy?.serialNumber
        },
        { field: 'sequence', headerName: 'Sequence No',
            valueGetter : (params: GridValueGetterParams<CashDividend>) => params.row.transferredBy?.serialNumber
        },
        { field: 'verifiedDate', headerName: 'Verified Date',
            renderCell: (params: GridRenderCellParams<CashDividend>) => formatDate(params.row.verifiedDate)
        },
        {
            field: 'bnyId', headerName: 'BNY Event Id',
            valueGetter: (params: GridValueGetterParams<CashDividend>) => renderStates(params.row.dividends, CashDividendSource.VPB),
            cellClassName: (params: GridCellParams<CashDividend>) => {
                return clsx('ttrade-dividend', {
                    positive: idIsApproved(params.row),
                    negative: ! idIsApproved(params.row)
                })
            }
        },
        {
            field: 'bloId', headerName: 'BLO Event Id',
            valueGetter: (params: GridValueGetterParams<CashDividend>) => renderStates(params.row.dividends, CashDividendSource.DP),
            cellClassName: (params: GridCellParams<CashDividend>) => {
                return clsx('ttrade-dividend', {
                    positive: idIsApproved(params.row),
                    negative: ! idIsApproved(params.row)
                })
            }
        },
        { field: 'message', headerName: 'Message',
            valueGetter : (params: GridValueGetterParams<CashDividend>) => params.row.message
        }

    ];

    return (
            <DataGridPremium
                apiRef={apiRef}
                sx={ StateSx }
                slots={{ toolbar: GridToolbar }}
                initialState={initialState}
                loading={params.loading}
                rows={params.dividends.data }
                columns={columns}
                density={'compact'}
                getRowId={(row: CashDividend) => row.id.id}
                pagination={true}
                pageSizeOptions={[10, 25, 50, 100]}
                autoHeight={true}
            />
    );
}