import React, {ReactElement} from "react";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import Title from "../../components/Title";
import {PageDto} from "../../domain/PageDto";
import {SearchDataFunction} from "../../components/types/SearchDataFunction";
import {useSearchAuditEvents} from "../../application/AuditService";
import {AuditEvent} from "../../domain/audit/AuditEvent";
import {AuditEventSearchTerm} from "../../domain/audit/AuditEventSearchTerm";
import AuditEventComponent from "../../components/AuditEventComponent";
import AuditEventSearchBar from "./AuditEventSearchBar";

export default function AuditEventsPage(): ReactElement {
    const searchFn: SearchDataFunction<AuditEventSearchTerm, PageDto<AuditEvent>> = useSearchAuditEvents(null);

    return (
        <Container maxWidth={ false } sx={{ mt: 4, mb: 4 }}>
            { <Toolbar/> }
            <Grid container spacing={2} height={1000}>
                <Grid item xs={12} md={11} lg={11}>
                    <Title>Audit Events</Title>
                </Grid>
                <AuditEventSearchBar searchFn={ searchFn }/>
                <AuditEventComponent data={ searchFn.data.data } loading={searchFn.loading}/>
            </Grid>
        </Container>
    );
}