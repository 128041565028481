
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";
import {AssetManagerFileConfig} from "../domain/assetmanager/AssetManagerFileConfig";
import {TransactionType} from "../domain/transaction/TransactionType";
import {Link} from "react-router-dom";

export type ValidateFieldOrderDialogParams = {
  configuration: AssetManagerFileConfig
  assetManager: string,
  type: TransactionType
}

export default function ValidateFieldOrderDialog(params: Readonly<ValidateFieldOrderDialogParams>) {
  const [active, setActive ] = useState<boolean>(params.configuration.active);
  const [validateOpen, setValidateOpen] = useState<boolean>(false);

  useEffect(() => {
    if (active !== params.configuration.active) {
      params.configuration.active = active;
      if (active) {
        triggerValidation()
      }
    }
  }, [ active ]);

  const handleActive = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActive(event.target.checked);
  };

  const triggerValidation = () => {
    setValidateOpen(true);
  };

  const handleValidateCancel = () => {
    setValidateOpen(false);
  };

  return (
    <div>
      <FormControlLabel control={
        <Checkbox checked={ active } onChange={ handleActive }></Checkbox>
      } label="Enabled" />
      <Dialog open={validateOpen} onClose={handleValidateCancel}>
        <DialogTitle>Validation of Configuration</DialogTitle>
        <DialogContent>
            You are strongly suggested to validate that this configuration is actually working before activating it.
            <p> To verify a configuration
              <ul>
                <li> first save it, without setting the &quot;Enabled&quot; checkbox.</li>
                <li>Then go to <Link to={"validate"}>Validate Page</Link> </li>
                <li>Then upload a file containing samples from the asset manager</li>
                <li>The Validate Page will then show if file works with the configuration.</li>
                <li>Once you have a successful validation, activate it with the &quot;Enabled&quot; checkbox and save it.</li>
              </ul>
            </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleValidateCancel}>OK</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}