import {Dayjs} from "dayjs";

export type AuditRequestSearchTerm = {
    from: Dayjs | null,
    to: Dayjs | null,
    url: string,
    user: string
}

export function defaultAuditRequestSearchTerm(): AuditRequestSearchTerm {
    return {
        from: null,
        to: null,
        url: '',
        user: ''
    } as AuditRequestSearchTerm;
}