import axios from "axios";
import {useCallback, useEffect, useState} from "react";
import {Mandate} from "../domain/staticdata/Mandate";
import {getGatewayUrl} from "../components/EnvironmentConfiguration";
import {RefreshDataFunction} from "../components/types/RefreshDataFunction";
import {emptyStatistics, Statistics} from "../domain/staticdata/Statistics";

export const useGetMandate = (): RefreshDataFunction<Mandate[]> => {
    const [responseData, setResponseData] = useState<Mandate[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const apiFunction = useCallback(async () => {
            setLoading(true);
            axios.get(getGatewayUrl() + '/static-data/v1/mandate')
                .then((response) => {
                    setResponseData(response.data as Mandate[])
                })
                .catch((err) => setError(err.message))
                .finally(() => setLoading(false));
        },
        []
    );

    useEffect(() => {
            apiFunction();
        },
        [ ]
    );

    return { data: responseData, loading, error, apiFunction };
};
export const useSyncMandate = (): RefreshDataFunction<Statistics> => {
    const [responseData, setResponseData] = useState<Statistics>(emptyStatistics());
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const getFunction = useCallback(async () => {
            setLoading(true);
            const uri = '/static-data/v1/sync/mandate';
            axios.put(getGatewayUrl() + uri)
                .then((response) => {
                    setResponseData(response.data as Statistics)
                })
                .catch((err) => setError(err.message))
                .finally(() => setLoading(false));
        },
        []
    );

    return { data: responseData, loading, error, apiFunction: getFunction };
};
