import {ReactElement, useState} from "react";
import * as React from "react";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import Title from "../../components/Title";
import {useParams} from "react-router-dom";
import {Button, TextField} from "@mui/material";
import {DataGridPremium, GridColDef, GridToolbar} from "@mui/x-data-grid-premium";
import {ResponseDataFunction} from "../../components/types/ResponseDataFunction";
import {FormatValidationRequest, toFormatValidationRequest} from "../../domain/assetmanager/FormatValidationRequest";
import {FormatValidationResponse} from "../../domain/assetmanager/FormatValidationResponse";
import {useValidateConfiguration} from "../../application/AssetManagerService";
import SnackbarComponent, {
    defaultSnackbarState,
    SnackbarState
} from "../../components/SnackbarComponent";
import {GridInitialStatePremium} from "@mui/x-data-grid-premium/models/gridStatePremium";
import {useAutoSizeGrid} from "../../components/AutoSizeGridHook";

export default function ValidateConfiguration(): ReactElement {
    const { assetManagerId} = useParams();
    const [ file, setFile ] = useState<File|null>(null);
    const [ state, setState] = useState<SnackbarState>(defaultSnackbarState);
    const validationFunction: ResponseDataFunction<FormatValidationRequest, FormatValidationResponse[]> = useValidateConfiguration();
    const apiRef = useAutoSizeGrid<FormatValidationResponse>({
        data: validationFunction.data,
        loading: validationFunction.loading,
        columns: [
            'line',
            'valid',
            'message'
        ]
    });

    const doCallValidate = () => {
        if (file && assetManagerId) {
            validationFunction.apiFunction(toFormatValidationRequest(assetManagerId, file))
                .then(() => {
                    if (validationFunction.data === undefined || validationFunction.data.length === 0) {
                        setState({ message: "Validation Failed, no lines were validated", severity: "warning", open: true });
                    }
                })
                .catch(() => setState({ message: "Validation Failed", severity: "error", open: true }))
            ;

        }
    };

    const isValidateDisabled = (): boolean => {
        return ! (file && assetManagerId);
    }

    const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            setFile(e.target.files[0]);
        }
    }

    const initialState: GridInitialStatePremium =
        {
            columns: {
                columnVisibilityModel: {
                },
                dimensions: {
                    line: { width: 70 },
                    valid: { width: 120 },
                    message: { flex: 1 }
                }
            }
        }

    const columns: GridColDef<FormatValidationResponse>[] = [
        { field: 'line', headerName: 'Line' },
        { field: 'valid', headerName: 'Valid' },
        { field: 'message', headerName: 'Message' }
    ];

    return (
        <Container maxWidth={ false } sx={{ mt: 4, mb: 4 }}>
            { <Toolbar/> }
            <Grid container spacing={2} alignItems={ 'center' } justifyItems={ 'center' }>
                <Grid item xs={12} md={6} lg={6}>
                    <Title>Validate Transaction File for { assetManagerId }</Title>
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="file"
                        fullWidth
                        type='file'
                        inputProps={{accept:".tsv,text/tsv"}}
                        onChange={ handleFile }
                    />
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                    <Button variant='outlined'
                            onClick={doCallValidate}
                            disabled={ isValidateDisabled() }>
                        Validate
                    </Button>
                </Grid>
                <Grid item xs={12} md={12} lg={12} height={800}>
                    <DataGridPremium
                        apiRef={apiRef}
                        initialState={initialState}
                        slots={{toolbar: GridToolbar}}
                        loading={validationFunction.loading}
                        rows={validationFunction.data}
                        columns={columns}
                        density={"compact"}
                        getRowId={(row) => row.line}
                    />
                </Grid>
            </Grid>
            <SnackbarComponent state={ state } setState={ setState }></SnackbarComponent>
        </Container>);
}