import React, {ReactElement, useState} from "react";
import Grid from "@mui/material/Grid";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import {ExpandLess, ExpandMore, SearchRounded} from "@mui/icons-material";
import {Autocomplete, Collapse, TextField, Tooltip} from "@mui/material";
import {DesktopDatePicker} from "@mui/x-date-pickers";
import { Dayjs } from 'dayjs';
import {CashDividend} from "../domain/dividend/CashDividend";
import {CashDividendSearchTerm} from "../domain/dividend/CashDividendSearchTerm";
import {PageDto} from "../domain/PageDto";
import {SearchDataFunction} from "./types/SearchDataFunction";
import {allDividendStates, DividendStateInfo} from "./types/UiStateInfo";


export type CashDividendSearchBarParams = {
    searchFn: SearchDataFunction<CashDividendSearchTerm, PageDto<CashDividend>>;
}

export default function CashDividendSearchFunction(params: Readonly<CashDividendSearchBarParams>): ReactElement {
    const [state, setState] = useState<boolean>(false);
    const [searchStates, setSearchStates] = useState<DividendStateInfo[]>([]);

    function onChangeInstrument(event: React.ChangeEvent<HTMLInputElement>) {
        const merged = {...params.searchFn.searchTerm, ...{instrument: event.target.value}};
        params.searchFn.setSearchTermFunction(merged);
    }

    function onChangeFromDate(value: Dayjs | null): void {
        const merged = {...params.searchFn.searchTerm, ...{fromExDate: value}};
        params.searchFn.setSearchTermFunction(merged);
    }

    function onChangeToDate(value: Dayjs | null): void {
        const merged = {...params.searchFn.searchTerm, ...{toExDate: value}};
        params.searchFn.setSearchTermFunction(merged);
    }

    function handleSearchClick(): void {
        setState(!state);
    }

    function onSelectionChange(event: React.SyntheticEvent<Element,Event>, value: DividendStateInfo[]) {
        if (value) {
            setSearchStates(value);
            const merged: CashDividendSearchTerm = { ...params.searchFn.searchTerm, ...{ states: value.map(e => e.state) }};
            params.searchFn.setSearchTermFunction(merged);
        }
    }

    return (
        <React.Fragment>
                <Grid item xs={12} md={1} lg={1}>
                    <Tooltip title={"Search Dividends"}>
                        <span>
                            <ListItemButton onClick={handleSearchClick}>
                                <ListItemIcon>
                                    <SearchRounded/>
                                </ListItemIcon>
                                {state ? <ExpandLess/> : <ExpandMore/>}
                            </ListItemButton>
                        </span>
                    </Tooltip>
                </Grid>
                <Collapse in={state} timeout="auto" unmountOnExit>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} lg={2}>
                            <Tooltip title={ "exDate, inclusive" } placement={ "top" }>
                                <span>
                                    <DesktopDatePicker
                                        label="From Date"
                                        format="YYYY-MM-DD"
                                        value={params.searchFn.searchTerm.fromExDate}
                                        onChange={ (value) => {
                                            return onChangeFromDate(value);
                                        }}
                                    />
                                </span>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12} md={6} lg={2}>
                            <Tooltip title={ "exDate, inclusive" } placement={ "top" }>
                                <span>
                                <DesktopDatePicker
                                    label="To Date"
                                    value={params.searchFn.searchTerm.toExDate}
                                    format="YYYY-MM-DD"
                                    onChange={onChangeToDate}
                                />
                                </span>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <Tooltip title={ "ISIN, BB Code, Short Code or Name, min 3 characters" } placement={ "top" }>
                                <TextField
                                    label="Instrument"
                                    id="instrument-id-search"
                                    value={params.searchFn.searchTerm.instrument}
                                    onChange={onChangeInstrument}
                                />
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12} md={12} lg={5}>
                            <Autocomplete
                                multiple
                                id="tags-outlined"
                                size="small"
                                options={ allDividendStates }
                                value={ searchStates }
                                getOptionLabel={(option: DividendStateInfo) => option.title}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Dividend States"
                                    />
                                )}
                                onChange={ onSelectionChange }
                            />
                        </Grid>
                    </Grid>
                </Collapse>
        </React.Fragment>
    );
}