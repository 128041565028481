import {
    getRouteDate,
    useGetDividendImportStatus,
    useGetVerifiedCashDividends
} from "../../application/CashDividendService";
import {CashDividend} from "../../domain/dividend/CashDividend";
import {PageDto} from "../../domain/PageDto";
import React from "react";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import CashDividendHistoryComponent from "../../components/CashDividendHistoryComponent";
import Title from "../../components/Title";
import { DatePicker } from "@mui/x-date-pickers";
import {CashDividendImportStatus} from "../../domain/dividend/CashDividendImportStatus";
import CashDividendImportStatusComponent from "../../components/CashDividendImportStatusComponent";
import {useNavigate, useParams} from "react-router-dom";
import {RefreshDataFunction} from "../../components/types/RefreshDataFunction";
import {Dayjs} from "dayjs";


export default function VerifiedCashDividendsPage() {
    const { routeDate } = useParams();
    const navigateFunction = useNavigate();
    const apiFunction: RefreshDataFunction<PageDto<CashDividend>> = useGetVerifiedCashDividends();
    const importStatusFunction: RefreshDataFunction<CashDividendImportStatus> = useGetDividendImportStatus();

    function loadNewDate(date: Dayjs | null) {
        if (date) {
            navigateFunction("/dividends/verified/" + date.format("YYYY-MM-DD"));
        }
    }

    return (
            <Container maxWidth={ false } sx={{ mt: 4, mb: 4 }}>
                { <Toolbar/> }
                <Grid container spacing={2} alignItems={"center"} justifyItems={"center"}>
                    <Grid item xs={12} md={6} lg={6}>
                        <Title>Verified Dividends</Title>
                    </Grid>
                    <Grid item xs={12} md={3} lg={3}>
                        <CashDividendImportStatusComponent api={importStatusFunction}/>
                    </Grid>
                    <Grid item xs={12} md={3} lg={3}>
                        <DatePicker
                            label="Verified At"
                            value={getRouteDate(routeDate)}
                            onChange={(newValue) => loadNewDate(newValue) }
                            format={"YYYY-MM-DD"}
                        />
                    </Grid>
                    <CashDividendHistoryComponent
                            dividends={ apiFunction.data }
                            loading={ apiFunction.loading }
                            searchFunction={ () => apiFunction.apiFunction() }/>
                </Grid>
            </Container>
    )

}