import React, {ReactElement} from "react";
import {
    DataGridPremium,
    GridColDef,
    GridRenderCellParams, GridToolbar,
} from "@mui/x-data-grid-premium";
import {SearchDataFunction} from "./types/SearchDataFunction";
import {PageDto} from "../domain/PageDto";
import {formatDateTimeShort} from "./TimeFormat";
import {AuditRequestSearchTerm} from "../domain/audit/AuditRequestSearchTerm";
import {AuditRequest} from "../domain/audit/AuditRequest";
import {Link} from "react-router-dom";
import {GridInitialStatePremium} from "@mui/x-data-grid-premium/models/gridStatePremium";
import {useAutoSizeGrid} from "./AutoSizeGridHook";
import Grid from "@mui/material/Grid";


export type AuditRequestComponentParams = {
    searchFn: SearchDataFunction<AuditRequestSearchTerm, PageDto<AuditRequest>>
};

export default function AuditRequestComponent(params: Readonly<AuditRequestComponentParams>): ReactElement {

    const apiRef = useAutoSizeGrid<AuditRequest>({
        data: params.searchFn.data.data,
        loading: params.searchFn.loading,
        columns: [
            'eventTime',
            'requestId',
            'user',
            'type',
            'source',
            'url',
            'verb',
            'externalId'
        ]
    });

    const initialState: GridInitialStatePremium =
        {
            pagination: {
                paginationModel: {
                    pageSize: 1000
                }
            },
            columns: {
                columnVisibilityModel: {},
                dimensions: {
                    eventTime: {width: 150},
                    requestId: {width: 220},
                    user: {width: 200},
                    type: {width: 140},
                    source: {width: 200},
                    url: {flex: 1},
                    verb: {width: 100},
                    externalId: {width: 200}
                }
            }
        };

    const columns: GridColDef<AuditRequest>[] = [
        {
            field: 'eventTime',
            headerName: 'Event Time',
            renderCell: (params: GridRenderCellParams<AuditRequest>) => formatDateTimeShort(params.row.eventTime)
        },
        {
            field: 'requestId',
            headerName: "Request ID",
            renderCell: (params: GridRenderCellParams<AuditRequest>) =>
                <Link to={ params.row.requestId}> { params.row.requestId} </Link>
        },
        {
            field: 'user',
            headerName: 'User'
        },
        {
            field: 'type',
            headerName: 'RequestType'
        },
        {
            field: 'source',
            headerName: 'Source'
        },
        {
            field: 'url',
            flex: 1,
            headerName: 'URL'
        },
        {
            field: 'verb',
            headerName: 'Verb'
        },
        {
            field: 'externalId',
            headerName: 'ExternalId'
        }
    ];

    return (
        <Grid item xs={12} md={12} lg={12} height={ 1000 } maxWidth={"100%"}>
            <DataGridPremium
                apiRef={apiRef}
                initialState={initialState}
                slots={{ toolbar: GridToolbar }}
                loading={params.searchFn.loading}
                rows={params.searchFn.data.data}
                columns={columns}
                density={"compact"}
                getRowId={(row) => row.requestId }
                pagination={true}
                pageSizeOptions={[20,25,50,100,500,1000]}
            />
        </Grid>
    );
}