

declare global {
    interface Window {
        GATEWAY_URL: string;
        REDIRECT_URI: string;
        AD_APP_APP_URI: string;
        AD_APP_TENANT_ID: string;
        AD_APP_CLIENT_ID: string;
        APP_ENVIRONMENT: string;
    }
}

export function getGatewayUrl(): string {
    if (window.GATEWAY_URL) {
        return window.GATEWAY_URL;
    }
    return process.env.REACT_APP_GATEWAY_URL as string;
}

export function getRedirectUri(): string {
    if (window.REDIRECT_URI) {
        return window.REDIRECT_URI;
    }
    return process.env.REACT_APP_REDIRECT_URI as string;
}

export function getAppUri(): string {
    if (window.AD_APP_APP_URI) {
        return window.AD_APP_APP_URI;
    }
    return process.env.REACT_APP_AD_APP_APP_URI as string;
}

export function getAppTenantId(): string {
    if (window.AD_APP_TENANT_ID) {
        return window.AD_APP_TENANT_ID;
    }
    return process.env.REACT_APP_AD_APP_TENANTID as string;
}

export function getAppClientId(): string {
    if (window.AD_APP_CLIENT_ID) {
        return window.AD_APP_CLIENT_ID;
    }
    return process.env.REACT_APP_AD_APP_CLIENTID as string;
}

export function getAppEnvironment(): string {
    if (window.APP_ENVIRONMENT) {
        return window.APP_ENVIRONMENT;
    }
    return process.env.REACT_APP_ENVIRONMENT as string;
}