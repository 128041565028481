import {GetDataApi} from "../components/types/GetDataApi";
import {useCallback, useEffect, useState} from "react";
import axios from "axios";
import {getGatewayUrl} from "../components/EnvironmentConfiguration";
import {emptyPageDto, PageDto} from "../domain/PageDto";
import {SearchDataFunction} from "../components/types/SearchDataFunction";
import {TransactionType} from "../domain/transaction/TransactionType";
import {AuditEventDto} from "../domain/audit/AuditEventDto";
import {AuditEntityType} from "../domain/audit/AuditEntityType";
import {AuditEventSearchTerm, defaultAuditEventSearchTerm} from "../domain/audit/AuditEventSearchTerm";
import {AuditEvent} from "../domain/audit/AuditEvent";
import {AuditRequest, emptyAuditRequest} from "../domain/audit/AuditRequest";
import {AuditRequestSearchTerm, defaultAuditRequestSearchTerm} from "../domain/audit/AuditRequestSearchTerm";
import {useParams} from "react-router-dom";
import {convertAuditEventSearchTerm} from "./AuditEventSearchTermDto";
import {convertAuditRequestSearchTerm} from "./AuditRequestSearchTermDto";

export type AuditByTransactionIdParams = {
    transactionId: string
    transactionType: TransactionType
}

export type AuditRequestIdParams = {
    requestId: string
}

export function useGetAuditEventsByTransactionId(params: AuditByTransactionIdParams):  GetDataApi<AuditEventDto[]> {
    const [auditEvents, setAuditEvents] = useState<AuditEventDto[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const convert = (t: TransactionType) => {
        switch (t) {
            case TransactionType.EQ:
                return AuditEntityType.EQUITY_TRANSACTION;
            case TransactionType.FU:
                return AuditEntityType.FUTURE_TRANSACTION;
            case TransactionType.FX:
                return AuditEntityType.FX_TRANSACTION;
        }
    }

    useEffect(() => {
        setLoading(true);
        axios.get(getGatewayUrl() + '/audit/v1/' + convert(params.transactionType) + '/' + params.transactionId)
            .then((response) => {
                setAuditEvents(response.data as AuditEventDto[])
            })
            .catch((err) => setError(err.message))
            .finally(() => setLoading(false));
    }, []);

    return { data: auditEvents, loading, error };
}

export function useSearchAuditEvents(initialSearchTerm: AuditEventSearchTerm | null): SearchDataFunction<AuditEventSearchTerm, PageDto<AuditEvent>> {
    const defaultSearchTerm = initialSearchTerm ?? defaultAuditEventSearchTerm();
    const [events, setEvents] = useState<PageDto<AuditEvent>>(emptyPageDto());
    const [searchTerm, setSearchTerm] = useState<AuditEventSearchTerm>(defaultSearchTerm);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const apiFunction = useCallback(
        async (searchTerm: AuditEventSearchTerm) => {
            setLoading(true);
            const dto = convertAuditEventSearchTerm(searchTerm);
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            axios.post(getGatewayUrl() + '/audit/v1/event/search', dto, config)
                .then((response) => {
                    setEvents(response.data as PageDto<AuditEvent>)
                })
                .catch((err) => setError(err.message))
                .finally(() => setLoading(false));
        },
        [ searchTerm ]
    );

    useEffect(() => { apiFunction(searchTerm); },
        [ searchTerm ]
    );

    return { data: events, searchTerm, loading, error, apiFunction, setSearchTermFunction: setSearchTerm }
}

export function useSearchAuditRequests(initialSearchTerm: AuditRequestSearchTerm | null): SearchDataFunction<AuditRequestSearchTerm, PageDto<AuditRequest>> {
    const defaultSearchTerm = initialSearchTerm ?? defaultAuditRequestSearchTerm();
    const [requests, setRequests] = useState<PageDto<AuditRequest>>(emptyPageDto());
    const [searchTerm, setSearchTerm] = useState<AuditRequestSearchTerm>(defaultSearchTerm);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const apiFunction = useCallback(
        async (searchTerm: AuditRequestSearchTerm) => {
            setLoading(true);
            const dto = convertAuditRequestSearchTerm(searchTerm);
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            axios.post(getGatewayUrl() + '/audit/v1/request/search', dto, config)
                .then((response) => {
                    setRequests(response.data as PageDto<AuditRequest>)
                })
                .catch((err) => setError(err.message))
                .finally(() => setLoading(false));
        },
        [ searchTerm ]
    );

    useEffect(() => { apiFunction(searchTerm); },
        [ searchTerm ]
    );

    return { data: requests, searchTerm, loading, error, apiFunction, setSearchTermFunction: setSearchTerm }
}

export function useGetAuditEventsByRequestId():  GetDataApi<AuditEvent[]> {
    const { requestId } = useParams();
    const [auditEvents, setAuditEvents] = useState<AuditEvent[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        setLoading(true);
        axios.get(getGatewayUrl() + '/audit/v1/event/id/' + requestId)
            .then((response) => {
                setAuditEvents(response.data as AuditEvent[])
            })
            .catch((err) => setError(err.message))
            .finally(() => setLoading(false));
    }, []);

    return { data: auditEvents, loading, error };
}

export function useGetAuditRequestByRequestId():  GetDataApi<AuditRequest> {
    const { requestId } = useParams();
    const [auditRequest, setAuditRequest] = useState<AuditRequest>(emptyAuditRequest());
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        setLoading(true);
        axios.get(getGatewayUrl() + '/audit/v1/request/id/' + requestId)
            .then((response) => {
                setAuditRequest(response.data)
            })
            .catch((err) => setError(err.message))
            .finally(() => setLoading(false));
    }, []);

    return { data: auditRequest, loading, error };
}
