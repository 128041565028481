
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Tooltip,
} from "@mui/material";
import * as React from "react";
import {ResponseDataFunction} from "./types/ResponseDataFunction";
import RolesRetriever from "../authorization/RolesRetreiver";
import {CashDividendSource} from "../domain/dividend/CashDividendSource";
import {DividendFilterConfig} from "../domain/assetmanager/DividendFilterConfig";
import {DividendPaymentDateConfig} from "../domain/assetmanager/DividendPaymentDateConfig";

export type CreateDividendAnyConfigurationDialogParams = {
  createFunction: ResponseDataFunction<CashDividendSource, DividendPaymentDateConfig | DividendFilterConfig>,
  title: string,
  usage: string,
  urlPath: string,
  toolTip: string,
  initialSource: CashDividendSource
}

export default function CreateDividendAnyConfigurationDialog(params: Readonly<CreateDividendAnyConfigurationDialogParams>) {
  const [open, setOpen] = React.useState<boolean>(false);
  const [dividendSource, setDividendSource] = React.useState<CashDividendSource>(params.initialSource);

  const createDividendConfiguration = (source: CashDividendSource): void => {
    params.createFunction.apiFunction(source);
  }
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleResponseCancel = () => {
    setOpen(false);
  };

  const handleResponseOk = () => {
    setOpen(false);
    createDividendConfiguration(dividendSource);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setDividendSource(event.target.value as CashDividendSource);
  };

  return (
    <div>
      <RolesRetriever groups={ [ "super" ]}>
        <Tooltip title={ params.toolTip} placement={"top"}>
          <Button variant="outlined" onClick={handleClickOpen}>
            Add { params.title } Configuration
          </Button>
        </Tooltip>
      </RolesRetriever>
      <Dialog open={open} onClose={handleResponseCancel}>
        <DialogTitle>Create New Dividend { params.title } Configuration</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This will create a new Dividend { params.title } configuration. These are used to
            { params.usage }
            You need to select whether this rule is applied to BONY or BLOOMBERG files.
          </DialogContentText>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Dividend Source</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={dividendSource}
                label="Age"
                onChange={handleChange}
            >
              <MenuItem value={CashDividendSource.VPB}>BONY</MenuItem>
              <MenuItem value={CashDividendSource.DP}>BLOOMBERG</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleResponseCancel}>Cancel</Button>
          <Button onClick={handleResponseOk}>Create</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}