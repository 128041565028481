// AggregatedEquityTransaction
import {Transaction} from "./Transaction";
import {Action} from "./Action";
import {TransactionCode} from "./TransactionCode";

type EquityTransactionInternal = {
    swiftCode: string,
    // Instrument
    securityId: string,
    isin: string,
    instrumentShort: string,
    instrumentName: string,
    instrumentType: string,
    ric: string,
    sedol: string,
    bloombergId: string,
    currency: string,
    quantity: number,
    price: number,
    quoteFactor: number,
    fee1: number,
    fee2: number,
    paymentAmount: number
};

export type EquityTransaction = Transaction & EquityTransactionInternal;

export function emptyEquityTransaction() {
    return {
        transactionId: '',
        // Audit
        lastProcessed: '',
        requestedState: Action.ADD,
        // Portfolio
        assetManager: '',
        mandate: '',
        fund: '',
        fundShort: '',
        portfolio: '',
        // Counterparty
        counterpartShort: '',
        counterpartName: '',
        counterpartId: '',
        // Deal information
        externalTransactionId: '',
        transactionCode: TransactionCode.BUY,
        tradeDate: '',
        valueDate: '',
        originalFileName: '',
        originalFileRow: 0,
        swiftCode: '',
        // Instrument
        securityId: '',
        isin: '',
        instrumentShort: '',
        instrumentName: '',
        instrumentType: '',
        ric: '',
        sedol: '',
        bloombergId: '',
        currency: '',
        quantity: 0,
        price: 0,
        quoteFactor: 0,
        fee1: 0,
        fee2: 0,
        paymentAmount: 0
    }
}