export enum AuditEventType {
    TRANSACTION_IMPORT = "TRANSACTION_IMPORT",
    INSTRUMENT_IMPORT = "INSTRUMENT_IMPORT",

    DELETE_ASSETMANAGER = "DELETE_ASSETMANAGER",
    CREATE_ASSETMANAGER = "CREATE_ASSETMANAGER",
    SAVE_ASSETMANAGER = "SAVE_ASSETMANAGER",

    TRANSACTION_REACTIVATE = "TRANSACTION_REACTIVATE",
    TRANSACTION_REFRESH = "TRANSACTION_REFRESH",
    TRANSACTION_REPORT = "TRANSACTION_REPORT",
    TRANSACTION_RESEND = "TRANSACTION_RESEND",
    TRANSACTION_CANCEL = "TRANSACTION_CANCEL",
    TRANSACTION_COMPLETE = "TRANSACTION_COMPLETE",
    DIVIDEND_IMPORT = "DIVIDEND_IMPORT",
    DIVIDEND_RESEND = "DIVIDEND_RESEND",
    DIVIDEND_CANCEL = "DIVIDEND_CANCEL",
    DIVIDEND_UPDATE = "DIVIDEND_UPDATE",
    DIVIDEND_CREATE = "DIVIDEND_CREATE",
    DIVIDEND_REACTIVATE = "DIVIDEND_REACTIVATE",
    DIVIDEND_APPROVE = "DIVIDEND_APPROVE",
    DIVIDEND_DISCARD = "DIVIDEND_DISCARD",
    STATIC_DATA_REFRESH = "STATIC_DATA_REFRESH",
    REFUSED_TRANSACTION_STATUS = "REFUSED_TRANSACTION_STATUS",
    ANY = "ANY"

}