import React, {ReactElement} from "react";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import Title from "../../components/Title";
import TransactionProgress from "./TransactionProgressPage";
import {GetDataApi} from "../../components/types/GetDataApi";
import {FxTransaction} from "../../domain/transaction/FxTransaction";
import {useFindByFxTransactionId} from "../../application/FxTransactionService";
import {TransactionType} from "../../domain/transaction/TransactionType";
import {Button, Tooltip} from "@mui/material";
import {ErrorOutlineRounded, HistoryRounded} from "@mui/icons-material";
import {Link} from "react-router-dom";
import RolesRetriever from "../../authorization/RolesRetreiver";
import formatNumber from "../../components/NumberFormat";
import formatDateTime from "../../components/TimeFormat";

/*
{
    "transactionId": "22291038628052",
    "created": "1970-01-01T10:43:48.008",
    "confirmed": null,
    "deleted": null,
    "lastProcessed": "1970-01-01T10:43:48.008",
    "assetManager": "NTR",
    "mandate": "GLP",
    "fund": "AP7 Aktiefond",
    "fundShort": "EQF",
    "portfolio": "Northern Trust Global Passive",
    "counterpartShort": "CITIBANK GB",
    "counterpartName": "Citibank N.A, London (Citicorp)",
    "counterpartId": "CITIGB2L",
    "externalTransactionId": "B00306625199",
    "transactionCode": "BUY",
    "tradeDate": "2022-09-29",
    "valueDate": "2022-10-03",
    "originalFileName": "fx.tsv",
    "originalFileRow": 1,
    "counterpartBicCode": "CITIGB2L",
    "fxType": "SPOT",
    "cls": "Y",
    "forwardRate": null,
    "spotRate": 0.97666,
    "baseAmount": 5000000,
    "quoteAmount": 4883300,
    "baseCurrency": "EUR",
    "quoteCurrency": "USD"
  },
 */

export default function FxTransactionPage(): ReactElement {
    const transaction: GetDataApi<FxTransaction> = useFindByFxTransactionId();

    if (transaction.loading) {
        return (<></>);
    } else {
        return (
                <Container maxWidth={ false } sx={{ mt: 4, mb: 4 }}>
                    { <Toolbar/> }
                    <Grid container spacing={2} justifyItems={ "center" } alignItems={ "center" }>
                        <Grid item xs={12} md={8} lg={8} >
                            <Title>FX Transaction {transaction.data.transactionId} </Title>
                        </Grid>
                        <Grid item xs={12} md={2} lg={2} >
                            <RolesRetriever groups={ ["super", "admin"] }>
                                <Tooltip title={ "Related Issue History" } placement={"top"}>
                                    <Button variant='outlined'
                                        startIcon={ <ErrorOutlineRounded/> }
                                        sx={{ "whiteSpace": "nowrap" }}
                                        component={Link} to={ "/issues/inbound/transaction/" + transaction.data.assetManager + "/" + transaction.data.externalTransactionId }>
                                        Related Issues
                                    </Button>
                                </Tooltip>
                           </RolesRetriever>
                        </Grid>
                        <Grid item xs={12} md={2} lg={2} >
                            <RolesRetriever groups={ ["super"] }>
                                <Button variant='outlined'
                                        startIcon={ <HistoryRounded/> }
                                        component={Link} to={ "/audit/events/FX/" + transaction.data.transactionId }>
                                    History
                                </Button>
                            </RolesRetriever>
                        </Grid>
                        <Grid container border={ 2 } borderRadius="6px" borderColor={ "primary.main" } margin={ 1 }>
                            <TransactionProgress assetManager={ transaction.data.assetManager } transactionType={ TransactionType.FX } transactionId={ transaction.data.transactionId }/>
                        </Grid>
                        <Grid container border={ 2 } borderRadius="6px" borderColor={ "primary.main" } margin={ 1 }>
                            <Grid item xs={4} md={4} lg={4} padding={1}>TransactionId</Grid>
                            <Grid item xs={8} md={8} lg={8} padding={1}>{ transaction.data.transactionId }</Grid>
                            <Grid item xs={4} md={4} lg={4} padding={1}>Last Processed</Grid>
                            <Grid item xs={8} md={8} lg={8} padding={1}> { formatDateTime(transaction.data.lastProcessed) }</Grid>
                        </Grid>
                        <Grid container border={ 2 } borderRadius="6px" borderColor={ "primary.main" } margin={ 1 }>
                            <Grid item xs={4} md={4} lg={4} padding={1}>Asset Manager Transaction</Grid>
                            <Grid item xs={8} md={8} lg={8} padding={1}>{ transaction.data.externalTransactionId }</Grid>
                            <Grid item xs={4} md={4} lg={4} padding={1}>Asset Manager</Grid>
                            <Grid item xs={8} md={8} lg={8} padding={1}>{ transaction.data.assetManager }</Grid>
                            <Grid item xs={4} md={4} lg={4} padding={1}>Mandate</Grid>
                            <Grid item xs={8} md={8} lg={8} padding={1}>{ transaction.data.mandate }</Grid>
                            <Grid item xs={4} md={4} lg={4} padding={1}>Fund </Grid>
                            <Grid item xs={8} md={8} lg={8} padding={1}>{ transaction.data.fundShort }</Grid>
                            <Grid item xs={4} md={4} lg={4} padding={1}>Fund Full</Grid>
                            <Grid item xs={8} md={8} lg={8} padding={1}>{ transaction.data.fund }</Grid>
                            <Grid item xs={4} md={4} lg={4} padding={1}>Portfolio</Grid>
                            <Grid item xs={8} md={8} lg={8} padding={1}>{ transaction.data.portfolio }</Grid>
                        </Grid>
                        <Grid container border={ 2 } borderRadius="6px" borderColor={ "primary.main" } margin={ 1 }>
                            <Grid item xs={4} md={4} lg={4} padding={1}>Counterpart</Grid>
                            <Grid item xs={8} md={8} lg={8} padding={1}>{ transaction.data.counterpartShort }</Grid>
                            <Grid item xs={4} md={4} lg={4} padding={1}>Counterpart Full</Grid>
                            <Grid item xs={8} md={8} lg={8} padding={1}>{ transaction.data.counterpartName }</Grid>
                            <Grid item xs={4} md={4} lg={4} padding={1}>Counterpart Id</Grid>
                            <Grid item xs={8} md={8} lg={8} padding={1}>{ transaction.data.counterpartId }</Grid>
                            <Grid item xs={4} md={4} lg={4} padding={1}>Bic Code</Grid>
                            <Grid item xs={8} md={8} lg={8} padding={1}>{ transaction.data.counterpartBicCode }</Grid>
                        </Grid>
                        <Grid container border={ 2 } borderRadius="6px" borderColor={ "primary.main" } margin={ 1 }>
                            <Grid item xs={4} md={4} lg={4} padding={1}>Base Currency</Grid>
                            <Grid item xs={8} md={8} lg={8} padding={1}>{ transaction.data.baseCurrency }</Grid>
                            <Grid item xs={4} md={4} lg={4} padding={1}>Quote Currency</Grid>
                            <Grid item xs={8} md={8} lg={8} padding={1}>{ transaction.data.quoteCurrency }</Grid>
                            <Grid item xs={4} md={4} lg={4} padding={1}>Transaction Code</Grid>
                            <Grid item xs={8} md={8} lg={8} padding={1}>{ transaction.data.transactionCode }</Grid>
                            <Grid item xs={4} md={4} lg={4} padding={1}>Base Amount</Grid>
                            <Grid item xs={8} md={8} lg={8} padding={1}>{ formatNumber(transaction.data.baseAmount) }</Grid>
                            <Grid item xs={4} md={4} lg={4} padding={1}>Quote Amount</Grid>
                            <Grid item xs={8} md={8} lg={8} padding={1}>{ formatNumber(transaction.data.quoteAmount) }</Grid>
                            <Grid item xs={4} md={4} lg={4} padding={1}>Spot Rate</Grid>
                            <Grid item xs={8} md={8} lg={8} padding={1}>{ formatNumber(transaction.data.spotRate) }</Grid>
                            <Grid item xs={4} md={4} lg={4} padding={1}>CLS</Grid>
                            <Grid item xs={8} md={8} lg={8} padding={1}>{ transaction.data.cls } </Grid>
                            <Grid item xs={4} md={4} lg={4} padding={1}>Trade Date</Grid>
                            <Grid item xs={8} md={8} lg={8} padding={1}>{ transaction.data.tradeDate }</Grid>
                            <Grid item xs={4} md={4} lg={4} padding={1}>Value Date</Grid>
                            <Grid item xs={8} md={8} lg={8} padding={1}>{ transaction.data.valueDate }</Grid>
                        </Grid>
                    </Grid>
                </Container>
    )}
}
