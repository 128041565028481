import {AuditRequestSearchTerm} from "../domain/audit/AuditRequestSearchTerm";
import {formatDayjsDateTimeString} from "../components/TimeFormat";

export type AuditRequestSearchTermDto = {
    from: string | null,
    to: string | null,
    url: string,
    user: string
}

export function convertAuditRequestSearchTerm(searchTerm: AuditRequestSearchTerm): AuditRequestSearchTermDto {
    return {
        from: formatDayjsDateTimeString(searchTerm.from),
        to: formatDayjsDateTimeString(searchTerm.to),
        url: searchTerm.url,
        user: searchTerm.user,
    };
}