import React, {ReactElement} from "react";
import {PageDto} from "../../domain/PageDto";
import {CashDividend} from "../../domain/dividend/CashDividend";
import {useGetCashDividendsMonitor} from "../../application/CashDividendService";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import Title from "../../components/Title";
import {RefreshDataFunction} from "../../components/types/RefreshDataFunction";
import CashDividendHistoryComponent from "../../components/CashDividendHistoryComponent";

export default function CashDividendsMonitorPage(): ReactElement {

    const dividends: RefreshDataFunction<PageDto<CashDividend>> = useGetCashDividendsMonitor();

    return (
            <Container maxWidth={ false } sx={{ mt: 4, mb: 4 }}>
                { <Toolbar/> }
                <Grid container spacing={2} alignItems={"center"} justifyItems={"center"}>
                    <Grid item xs={12} md={10} lg={10}>
                        <Title>Simcorp Dimension Dividend Problems</Title>
                    </Grid>
                    <CashDividendHistoryComponent
                        dividends={ dividends.data }
                        loading={ dividends.loading }
                        searchFunction={ () => dividends.apiFunction() }/>
                </Grid>
            </Container>
    );
}