import React, {ReactElement, useEffect} from "react";
import {GridInitialStatePremium} from "@mui/x-data-grid-premium/models/gridStatePremium";
import {
    DataGridPremium,
    GridColDef,
    GridRenderCellParams,
    GridValueGetterParams
} from "@mui/x-data-grid-premium";
import {DividendPaymentDateConfig} from "../domain/assetmanager/DividendPaymentDateConfig";
import {
    useCreateDividendPaymentDateDefaultConfiguration,
    useGetDividendPaymentDateConfigurations
} from "../application/DividendConfigService";
import {GetDataApi} from "./types/GetDataApi";
import {Link, useNavigate} from "react-router-dom";
import Grid from "@mui/material/Grid";
import CreateDividendAnyConfigurationDialog from "./CreateDividendAnyConfigurationDialog";
import {CashDividendSource, toHumanReadableFormat} from "../domain/dividend/CashDividendSource";
import {ResponseDataFunction} from "./types/ResponseDataFunction";
import Title from "./Title";
import {useAutoSizeGrid} from "./AutoSizeGridHook";

export default function DividendPaymentDateConfigurationsComponent(): ReactElement {
    const navigation = useNavigate();
    const getFunction: GetDataApi<DividendPaymentDateConfig[]> = useGetDividendPaymentDateConfigurations();
    const createFunction: ResponseDataFunction<CashDividendSource, DividendPaymentDateConfig> = useCreateDividendPaymentDateDefaultConfiguration();
    const apiRef = useAutoSizeGrid<DividendPaymentDateConfig>({
        data: getFunction.data,
        loading: getFunction.loading,
        columns: [
            'id',
            'source',
            'isinPrefix',
            'instrumentCurrency',
            'defaultRule',
            'daysAfterExdate'
        ]
    });
    const initialState: GridInitialStatePremium =
        {
            columns: {
                dimensions: {
                    id: {width: 100},
                    source: {width: 100},
                    isinPrefix: {width: 150},
                    instrumentCurrency: {width: 150},
                    defaultRule: { width: 100},
                    daysAfterExdate: { width: 200}
                }
            }
        }

    useEffect(() => {
        if (createFunction.error) {
            navigation("dividends/config");
        } else if (createFunction.data.id > 0) {
            navigation("payment/date/" + createFunction.data.id);
        }
    }, [ createFunction.data, createFunction.error ]);

    const columns: GridColDef<DividendPaymentDateConfig>[] = [
        { field: 'id', headerName: 'ID',
            renderCell: (params: GridRenderCellParams<DividendPaymentDateConfig>) => <Link to={ "payment/date/" + params.row.id.toString() }>{params.row.id}</Link>
        },
        { field: 'source', headerName: 'Dividend Source', valueGetter: (params: GridValueGetterParams<DividendPaymentDateConfig>) => { return toHumanReadableFormat(params.row.source)}},
        { field: 'isinPrefix', headerName: "ISIN Prefix"},
        { field: 'instrumentCurrency', headerName: "Instrument Currency"},
        { field: 'defaultRule', headerName: "Default Rule"},
        { field: 'daysAfterExdate', headerName: "Days After ExDate"},

    ];

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={8} lg={8} padding={1}>
                <Title>Missing Payment Date Configuration</Title>
            </Grid>
            <Grid item xs={12} md={3} lg={3} padding={1}>
                    <CreateDividendAnyConfigurationDialog
                        title={ "Payment Date"}
                        createFunction={ createFunction }
                        initialSource={ CashDividendSource.DP }
                        urlPath={ "payment/date" }
                        toolTip={ "Rules for estimating paymentDate if missing, based on exDate. Only done if there is a RATE." }
                        usage={ "This creates rules to estimate missing payment dates when they are empty in reports." }
                    />
            </Grid>
            <Grid item xs={12} md={12} lg={12} padding={1}>
                <DataGridPremium
                    apiRef={apiRef}
                    initialState={initialState}
                    loading={getFunction.loading}
                    rows={getFunction.data}
                    columns={columns}
                    density={"compact"}
                    getRowId={(row) => row.id}
                    hideFooter={true}
                    autoHeight={true}
                />
            </Grid>
        </Grid>
    );

}