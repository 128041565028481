import React, {ReactElement} from "react";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import Title from "../../components/Title";
import {PageDto} from "../../domain/PageDto";
import {SearchDataFunction} from "../../components/types/SearchDataFunction";
import {useSearchFxTransactions} from "../../application/FxTransactionService";
import {FxTransaction} from "../../domain/transaction/FxTransaction";
import {TransactionSearchTerm} from "../../domain/transaction/TransactionSearchTerm";
import {Action} from "../../domain/transaction/Action";
import FxTransactionComponent from "../../components/FxTransactionComponent";

export default function ActiveFxTransactionsPage(): ReactElement {

    const searchFn: SearchDataFunction<TransactionSearchTerm, PageDto<FxTransaction>> = useSearchFxTransactions({
        assetManager: "",
        counterpart: "",
        fromDate: null,
        instrument: "",
        toDate: null,
        transaction: "",
        states: [Action.ADD, Action.PENDING_STATIC]
    });

    return (
            <Container maxWidth={ false } sx={{ mt: 4, mb: 4 }}>
                { <Toolbar/> }
                <Grid container spacing={2}>
                    <Grid item xs={12} md={11} lg={11}>
                        <Title>Fx Transactions in Progress</Title>
                    </Grid>
                    <FxTransactionComponent searchFn={searchFn} />
                </Grid>
            </Container>
    );
}