import React, {ReactElement} from "react";
import {DataGridPremium, GridColDef, GridRenderCellParams, GridToolbar} from "@mui/x-data-grid-premium";
import Grid from "@mui/material/Grid";
import {DownloadForOfflineRounded} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import formatDateTimeShort from "../../components/TimeFormat";
import {ImsTransactionImportStatus} from "../../domain/transaction/ImsTransactionImportStatus";
import {useGetTransactionUpdates} from "../../application/TransactionUpdateService";
import {extractFileOrigin, ProducerServiceProps, useProducerService} from "../../application/ProducerService";
import {RequestDataFunction} from "../../components/types/RequestDataFunction";
import {RefreshDataFunction} from "../../components/types/RefreshDataFunction";
import {PageDto} from "../../domain/PageDto";
import {Tooltip} from "@mui/material";
import {GridInitialStatePremium} from "@mui/x-data-grid-premium/models/gridStatePremium";
import {useAutoSizeGrid} from "../../components/AutoSizeGridHook";

export type TransactionProgressProps = {
    assetManager: string
    transactionType: string
    transactionId: string
}

export default function TransactionProgress(props: Readonly<TransactionProgressProps>): ReactElement {
    const progress: RefreshDataFunction<PageDto<ImsTransactionImportStatus>> = useGetTransactionUpdates(props);
    const fileService: RequestDataFunction<ProducerServiceProps> = useProducerService();

    const apiRef = useAutoSizeGrid<ImsTransactionImportStatus>({
        data: progress.data.data,
        loading: progress.loading,
        columns: [
            'id',
            'transactionId',
            'action',
            'created',
            'updated',
            'imsState',
            'state',
            'fileOrigin',
            'originalFileName',
            'originalFileRow',
            'imsMessage'
        ]
    });

    const fileOrigin = (props: TransactionProgressProps, params: ImsTransactionImportStatus) => {
        return { assetManager: props.assetManager, fileOrigin: params.fileOrigin };
    }

    function renderDownloadButton(params: ImsTransactionImportStatus) {
        return (
            <React.Fragment>
                <Tooltip title={"Download file"} placement={"top"}>
                    <IconButton color="primary" onClick={ () => {
                        Promise.resolve(fileService.apiFunction({ fileOrigin: extractFileOrigin(fileOrigin(props, params)), fileName: params.originalFileName}));
                    }}>
                        <DownloadForOfflineRounded/>
                    </IconButton>
                </Tooltip>
                { params.originalFileName }
            </React.Fragment>);
    }

    const initialState: GridInitialStatePremium =
        {
            columns: {
                columnVisibilityModel: {
                    id: false,
                    transactionId: false
                }
                ,
                dimensions: {
                    id: { width: 100 },
                    transactionId: { width: 220 },
                    action: { width: 100 },
                    created: { width: 150},
                    updated: { width: 150},
                    imsState: { width: 130},
                    state: { width: 130},
                    fileOrigin: { width: 100},
                    originalFileName: { width: 250},
                    originalFileRow: {  width: 50},
                    imsMessage: { flex: 1 }
                }
            },
            pagination: {
                paginationModel: {
                    pageSize: 100
                }
            }
        }

    const columns: GridColDef<ImsTransactionImportStatus>[] = [
        { field: 'id', headerName: 'Id' },
        { field: 'transactionId', headerName: 'TransactionId' },
        { field: 'action', headerName: 'Action' },
        { field: 'created', headerName: 'Created',
            renderCell: (params: GridRenderCellParams<ImsTransactionImportStatus>) => formatDateTimeShort(params.row.created)
        },
        { field: 'updated', headerName: 'Updated',
            renderCell: (params: GridRenderCellParams<ImsTransactionImportStatus>) => formatDateTimeShort(params.row.updated)
        },
        { field: 'imsState', headerName: 'State'},
        { field: 'state', headerName: 'T-Trade State' },
        { field: 'fileOrigin', headerName: 'File Origin'},
        { field: 'originalFileName', headerName: 'File',
            renderCell: (params: GridRenderCellParams<ImsTransactionImportStatus>) => renderDownloadButton(params.row)
        },
        { field: 'originalFileRow', headerName: 'Line' },
        { field: 'imsMessage', headerName: 'Message', flex: 1}
    ];
    return (
        <Grid item xs={12} md={12} lg={12} height={ 400 } padding={1}>
            <DataGridPremium
                apiRef={apiRef}
                initialState={ initialState }
                slots={{ toolbar: GridToolbar }}
                loading={progress.loading}
                rows={progress.data.data}
                columns={columns}
                density={"compact"}
                pagination={true}
                pageSizeOptions={[100, 1000]}
            />
        </Grid>
    );

}
