
import React, {ReactElement} from "react";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import Title from "../../components/Title";
import {FxTransaction} from "../../domain/transaction/FxTransaction";
import {useSearchFxTransactions} from "../../application/FxTransactionService";
import {PageDto} from "../../domain/PageDto";
import {SearchDataFunction} from "../../components/types/SearchDataFunction";
import {TransactionSearchTerm} from "../../domain/transaction/TransactionSearchTerm";
import TransactionSearchBar from "./TransactionSearchBar";
import FxTransactionComponent from "../../components/FxTransactionComponent";
import {Link, useParams} from "react-router-dom";
import {SyncProblemRounded} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import {Tooltip} from "@mui/material";

export default function FxTransactions(): ReactElement {
    const params = useParams();
    let initialSearchTerm: TransactionSearchTerm | null = null;
    if (params.assetManager && params.transactionId) {
        initialSearchTerm = {
            counterpart: '',
            fromDate: null,
            toDate: null,
            instrument: '',
            states: [],
            transaction: params.transactionId,
            assetManager: params.assetManager
        };
    }
    const searchFn: SearchDataFunction<TransactionSearchTerm, PageDto<FxTransaction>> = useSearchFxTransactions(initialSearchTerm);

    return (
            <Container maxWidth={ false } sx={{ mt: 4, mb: 4 }}>
                { <Toolbar/> }
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={6}>
                        <Title>FX Transactions</Title>
                    </Grid>
                    <Grid item xs={12} md={1} lg={1}>
                        <Tooltip title={"View not confirmed transactions"} placement={"top"}>
                            <span>
                                <IconButton component={Link} to="/transaction/fx/active">
                                    <SyncProblemRounded />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Grid>
                    <TransactionSearchBar searchFn={ searchFn }/>
                    <FxTransactionComponent searchFn={ searchFn }/>
                </Grid>
            </Container>
    );
}