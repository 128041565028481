import {Link} from "react-router-dom";
import React, {ReactElement} from "react";
import {DataGridPremium, GridColDef, GridRenderCellParams, GridToolbar} from "@mui/x-data-grid-premium";
import Grid from "@mui/material/Grid";
import {SearchDataFunction} from "./types/SearchDataFunction";
import {TransactionSearchTerm} from "../domain/transaction/TransactionSearchTerm";
import {FutureTransaction} from "../domain/transaction/FutureTransaction";
import {PageDto} from "../domain/PageDto";
import {formatDateTimeShort} from "./TimeFormat";
import {GridInitialStatePremium} from "@mui/x-data-grid-premium/models/gridStatePremium";
import {useAutoSizeGrid} from "./AutoSizeGridHook";
import formatNumber from "./NumberFormat";


export type FutureTransactionComponentParams = {
    searchFn: SearchDataFunction<TransactionSearchTerm, PageDto<FutureTransaction>>
};

export default function FutureTransactionComponent(params: Readonly<FutureTransactionComponentParams>): ReactElement {

    const apiRef = useAutoSizeGrid<FutureTransaction>({
        data: params.searchFn.data.data,
        loading: params.searchFn.loading,
        columns: [
            'transactionId',
            'assetManager',
            'mandate',
            'counterpartId',
            'counterpartShort',
            'brokerId',
            'brokerShort',
            'tradeDate',
            'valueDate',
            'requestedState',
            'transactionCode',
            'bloombergId',
            'securityId',
            'instrumentShort',
            'instrumentName',
            'quantity',
            'price',
            'currency',
            'fee1',
            'lastProcessed',
            'externalTransactionId'
        ]
    });

    const initialState: GridInitialStatePremium =
        {
            columns: {
                columnVisibilityModel: {
                    brokerShort: false,
                    instrumentShort: false,
                    counterpartShort: false
                },
                dimensions: {
                    transactionId: { width: 150 },
                    assetManager: { width: 70 },
                    mandate: { width: 70 },
                    counterpartId: { width: 130},
                    counterpartShort: { width: 150},
                    brokerId: { width: 70 },
                    brokerShort: { width: 150},
                    tradeDate: { width: 110},
                    valueDate: { width: 110},
                    requestedState: { width: 100},
                    transactionCode: { width: 120},
                    bloombergId: { width: 150},
                    securityId: { width: 200},
                    instrumentShort: { width: 200},
                    instrumentName: { flex: 1},
                    quantity: { width: 100},
                    price: { width: 100},
                    currency: { width: 70},
                    fee1: { width: 80},
                    lastProcessed: { width: 140 },
                    externalTransactionId: { width: 220}
                }
            },
            pagination: {
                paginationModel: {
                    pageSize: 1000
                }
            }
        }

    const columns: GridColDef<FutureTransaction>[] = [
        { field: 'transactionId', headerName: 'TransactionId', renderCell: (params: GridRenderCellParams<FutureTransaction>) =>
                <Link to={"/transaction/fu/" + params.row.transactionId}> {params.row.transactionId} </Link> },
        { field: 'assetManager', headerName: 'AssetMgr' },
        { field: 'mandate', headerName: "Mandate" },
        { field: 'counterpartId', headerName: 'Counterpart' },
        { field: 'counterpartShort', headerName: 'Counterpart Short' },
        { field: 'brokerId', headerName: 'Broker' },
        { field: 'brokerShort', headerName: 'BrokerShort' },
        { field: 'tradeDate', headerName: 'Trade Date' },
        { field: 'valueDate', headerName: 'Value Date' },
        { field: 'requestedState', headerName: 'Action' },
        { field: 'transactionCode', headerName: 'Code' },
        { field: 'bloombergId', headerName: 'BloombergCode' },
        { field: 'securityId', headerName: 'Instrument' },
        { field: 'instrumentShort', headerName: 'Instrument Short' },
        { field: 'instrumentName', headerName: 'Instrument Name', flex: 1 },
        { field: 'quantity', headerName: 'Quantity', valueGetter: (params) => formatNumber(params.row.quantity) },
        { field: 'price', headerName: 'Price', valueGetter: (params) => formatNumber(params.row.price) },
        { field: 'currency', headerName: 'Currency' },
        { field: 'fee1', headerName: 'Fee1', valueGetter: (params) => formatNumber(params.row.fee1) },
        { field: 'lastProcessed', headerName: 'Last Processed', renderCell: (params: GridRenderCellParams<FutureTransaction>) =>
                formatDateTimeShort(params.row.lastProcessed)
        },
        { field: 'externalTransactionId', headerName: 'External Transaction' }
    ];

    return (
        <Grid item xs={12} md={12} lg={12} height={ 1000 } padding={1}>
            <DataGridPremium
                apiRef={apiRef}
                initialState={initialState}
                slots={{ toolbar: GridToolbar }}
                loading={params.searchFn.loading}
                rows={params.searchFn.data.data}
                columns={columns}
                density={"compact"}
                getRowId={(row) => row.transactionId}
                pagination={true}
                pageSizeOptions={[25,50,100,500,1000]}
            />
        </Grid>
    );
}