import React, {ReactElement, useState} from "react";
import {SearchDataFunction} from "../../components/types/SearchDataFunction";
import {PageDto} from "../../domain/PageDto";
import Grid from "@mui/material/Grid";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import {ExpandLess, ExpandMore, SearchRounded} from "@mui/icons-material";
import {Collapse, TextField} from "@mui/material";
import {DesktopDatePicker} from "@mui/x-date-pickers";
import {Dayjs} from 'dayjs';
import {AuditRequestSearchTerm} from "../../domain/audit/AuditRequestSearchTerm";
import {AuditRequest} from "../../domain/audit/AuditRequest";

export type AuditRequestSearchBarParams = {
    searchFn: SearchDataFunction<AuditRequestSearchTerm, PageDto<AuditRequest>>;
}

export default function AuditRequestSearchBar(params: Readonly<AuditRequestSearchBarParams>): ReactElement {
    const [state, setState] = useState<boolean>(false);


    function onChangeUser(event: React.ChangeEvent<HTMLInputElement>) {
        const merged = {...params.searchFn.searchTerm, ...{user: event.target.value}};
        params.searchFn.setSearchTermFunction(merged);
    }

    function onChangeUrl(event: React.ChangeEvent<HTMLInputElement>) {
        const merged = {...params.searchFn.searchTerm, ...{url: event.target.value}};
        params.searchFn.setSearchTermFunction(merged);
    }

    function onChangeFromDate(value: Dayjs | null): void {
        const merged = {...params.searchFn.searchTerm, ...{from: value}};
        params.searchFn.setSearchTermFunction(merged);
    }

    function onChangeToDate(value: Dayjs | null): void {
        const merged = {...params.searchFn.searchTerm, ...{to: value}};
        params.searchFn.setSearchTermFunction(merged);
    }



    function handleSearchClick(): void {
        setState(!state);
    }

    return (
        <React.Fragment>
                <Grid item xs={1} md={1} lg={1}>
                    <ListItemButton onClick={handleSearchClick}>
                        <ListItemIcon>
                            <SearchRounded/>
                        </ListItemIcon>
                        {state ? <ExpandLess/> : <ExpandMore/>}
                    </ListItemButton>
                </Grid>
                <Collapse in={state} timeout="auto" unmountOnExit>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} lg={3}>
                            <DesktopDatePicker
                                label="From Date"
                                format="YYYY-MM-DD"
                                value={params.searchFn.searchTerm.from}
                                onChange={ (value) => {
                                    return onChangeFromDate(value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <DesktopDatePicker
                                label="To Date"
                                value={params.searchFn.searchTerm.to}
                                format="YYYY-MM-DD"
                                onChange={onChangeToDate}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <TextField
                                label="URL"
                                value={params.searchFn.searchTerm.url}
                                onChange={onChangeUrl}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <TextField
                                label="User"
                                value={params.searchFn.searchTerm.user}
                                onChange={onChangeUser}
                            />
                        </Grid>
                    </Grid>
                </Collapse>
        </React.Fragment>
    );
}