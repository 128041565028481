export type Statistics = {
    duration: number,
    counterparts: number,
    brokers: number,
    portfolios: number,
    modelPortfolios: number,
    equities: number,
    mandates: number,
    currency: number,
    currencyCross: number,
    futureIndex: number,
    removedCounterparts: number,
    removedBrokers: number,
    removedPortfolios: number,
    removedModelPortfolios: number,
    removedEquities: number,
    removedMandates: number,
    removedCurrency: number,
    removedCurrencyCross: number,
    removedFutureIndex: number,
}

export function emptyStatistics(): Statistics {
    return {
        duration: 0,
        counterparts: 0,
        brokers: 0,
        portfolios: 0,
        modelPortfolios: 0,
        equities: 0,
        mandates: 0,
        currency: 0,
        currencyCross: 0,
        futureIndex: 0,
        removedCounterparts: 0,
        removedBrokers: 0,
        removedPortfolios: 0,
        removedModelPortfolios: 0,
        removedEquities: 0,
        removedMandates: 0,
        removedCurrency: 0,
        removedCurrencyCross: 0,
        removedFutureIndex: 0,
    }
}