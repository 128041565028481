import {Link} from "react-router-dom";
import React, {ReactElement} from "react";
import {
    DataGridPremium,
    GridColDef,
    GridRenderCellParams,
    GridToolbar
} from "@mui/x-data-grid-premium";
import Grid from "@mui/material/Grid";
import {SearchDataFunction} from "./types/SearchDataFunction";
import {TransactionSearchTerm} from "../domain/transaction/TransactionSearchTerm";
import {EquityTransaction} from "../domain/transaction/EquityTransaction";
import {PageDto} from "../domain/PageDto";
import {formatDateTimeShort} from "./TimeFormat";
import {GridInitialStatePremium} from "@mui/x-data-grid-premium/models/gridStatePremium";
import {useAutoSizeGrid} from "./AutoSizeGridHook";
import formatNumber from "./NumberFormat";

export type EquityTransactionComponentParams = {
    searchFn: SearchDataFunction<TransactionSearchTerm, PageDto<EquityTransaction>>
};

export default function EquityTransactionComponent(params: Readonly<EquityTransactionComponentParams>): ReactElement {

    const apiRef = useAutoSizeGrid<EquityTransaction>({
        data: params.searchFn.data.data,
        loading: params.searchFn.loading,
        columns: [
            'transactionId',
            'assetManager',
            'mandate',
            'counterpartId',
            'counterpartShort',
            'tradeDate',
            'valueDate',
            'requestedState',
            'transactionCode',
            'isin',
            'sedol',
            'bloombergId',
            'securityId',
            'instrumentShort',
            'instrumentName',
            'quantity',
            'price',
            'currency',
            'fee1',
            'fee2',
            'paymentAmount',
            'lastProcessed',
            'externalTransactionId'
        ]
    });

    const initialState: GridInitialStatePremium =
    {
        columns: {
            columnVisibilityModel: {
                isin: false,
                bloombergId: false,
                instrumentShort: false,
                counterpartShort: false
            },
            dimensions: {
                transactionId: {width: 150},
                assetManager: {width: 70},
                mandate: {width: 70},
                counterpartId: {width: 130},
                counterpartShort: {width: 150},
                tradeDate: {width: 110},
                valueDate: {width: 110},
                requestedState: {width: 100},
                transactionCode: {width: 70},
                isin: {width: 110},
                sedol: {width: 80},
                bloombergId: {width: 150},
                securityId: {width: 200},
                instrumentShort: {width: 200},
                instrumentName: {flex: 1},
                quantity: {width: 100},
                price: {width: 100},
                currency: {width: 70},
                fee1: {width: 80},
                fee2: {width: 80},
                paymentAmount: {width: 150},
                lastProcessed: {width: 140},
                externalTransactionId: {width: 220}
            }
        },
        pagination: {
            paginationModel: {
                pageSize: 1000
            }
        }
    }

    const columns: GridColDef<EquityTransaction>[] = [
        {
            field: 'transactionId',
            headerName: 'TransactionId',
            renderCell: (params: GridRenderCellParams<EquityTransaction>) =>
                <Link to={"/transaction/eq/" + params.row.transactionId}> {params.row.transactionId} </Link>
        },
        {field: 'assetManager', headerName: 'AssetMgr'},
        {field: 'mandate', headerName: "Mandate" },
        {field: 'counterpartId', headerName: 'Counterpart'},
        {field: 'counterpartShort', headerName: 'Counterpart Short'},
        {field: 'tradeDate', headerName: 'Trade Date'},
        {field: 'valueDate', headerName: 'Value Date'},
        {field: 'requestedState', headerName: 'Action'},
        {field: 'transactionCode', headerName: 'Code'},
        {field: 'isin', headerName: 'ISIN'},
        {field: 'sedol', headerName: 'Sedol'},
        {field: 'bloombergId', headerName: 'BloombergCode'},
        {field: 'securityId', headerName: 'Instrument'},
        {field: 'instrumentShort', headerName: 'Instrument Short'},
        {field: 'instrumentName', headerName: 'Instrument Name'},
        {field: 'quantity', headerName: 'Quantity', valueGetter: (params) => formatNumber(params.row.quantity)},
        {field: 'price', headerName: 'Price', valueGetter: (params) => formatNumber(params.row.price)},
        {field: 'currency', headerName: 'Currency'},
        {field: 'fee1', headerName: 'Fee1', valueGetter: (params) => formatNumber(params.row.fee1)},
        {field: 'fee2', headerName: 'Fee2', valueGetter: (params) => formatNumber(params.row.fee2)},
        {field: 'paymentAmount', headerName: 'PaymentAmount', valueGetter: (params) => formatNumber(params.row.paymentAmount)},
        {
            field: 'lastProcessed',
            headerName: 'Last Processed',
            renderCell: (params: GridRenderCellParams<EquityTransaction>) =>
                formatDateTimeShort(params.row.lastProcessed)
        },
        {field: 'externalTransactionId', headerName: 'External Transaction', flex: 1 }
    ];

    return (
        <Grid item xs={12} md={12} lg={12} height={1000} padding={1}>
            <DataGridPremium
                apiRef={apiRef}
                initialState={ initialState}
                slots={{toolbar: GridToolbar }}
                loading={params.searchFn.loading}
                rows={params.searchFn.data.data}
                columns={columns}
                density={"compact"}
                getRowId={(row) => row.transactionId}
                pagination={true}
                pageSizeOptions={[25,50,100,500,1000]}
            />
        </Grid>
    );
}