import axios from "axios";
import {useCallback, useEffect, useState} from "react";
import {Equity} from "../domain/staticdata/Equity";
import {getGatewayUrl} from "../components/EnvironmentConfiguration";
import {RefreshDataFunction} from "../components/types/RefreshDataFunction";
import {emptyStatistics, Statistics} from "../domain/staticdata/Statistics";

export const useGetEquity = (): RefreshDataFunction<Equity[]> => {
    const [responseData, setResponseData] = useState<Equity[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const apiFunction = useCallback(async () => {
            setLoading(true);
            axios.get(getGatewayUrl() + '/static-data/v1/equity')
                .then((response) => {
                    setResponseData(response.data as Equity[])
                })
                .catch((err) => setError(err.message))
                .finally(() => setLoading(false));
        },
        []
    );

    useEffect(() => {
            apiFunction();
        },
        [ ]
    );

    return { data: responseData, loading, error, apiFunction };
};

export const useSyncEquity = (): RefreshDataFunction<Statistics> => {
    const [responseData, setResponseData] = useState<Statistics>(emptyStatistics());
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const getFunction = useCallback(async () => {
            setLoading(true);
            const uri = '/static-data/v1/sync/equity';
            axios.put(getGatewayUrl() + uri)
                .then((response) => {
                    setResponseData(response.data as Statistics)
                })
                .catch((err) => setError(err.message))
                .finally(() => setLoading(false));
        },
        []
    );

    return { data: responseData, loading, error, apiFunction: getFunction };
};
