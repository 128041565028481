import axios from "axios";
import {useCallback, useEffect, useState} from "react";
import {Counterpart} from "../domain/staticdata/Counterpart";
import {useParams} from "react-router-dom";
import {getGatewayUrl} from "../components/EnvironmentConfiguration";
import {emptyStatistics, Statistics} from "../domain/staticdata/Statistics";
import {RefreshDataFunction} from "../components/types/RefreshDataFunction";

export const useGetCounterpart = (): RefreshDataFunction<Counterpart[]> => {
    const { assetManager } = useParams();
    const [responseData, setResponseData] = useState<Counterpart[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);



    const apiFunction = useCallback(async () => {
            setLoading(true);
            let uri = '/static-data/v1/counterpart';
            if (assetManager) {
                uri = '/static-data/v1/counterpart/assetManager/' + assetManager;
            }
            axios.get(getGatewayUrl() + uri)
                .then((response) => {
                    setResponseData(response.data as Counterpart[])
                })
                .catch((err) => setError(err.message))
                .finally(() => setLoading(false));
        },
        []
    );

    useEffect(() => {
            apiFunction();
        },
        [ ]
    );

    return { data: responseData, loading, error, apiFunction };
};

export const useSyncCounterpart = (): RefreshDataFunction<Statistics> => {
    const [responseData, setResponseData] = useState<Statistics>(emptyStatistics());
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const getFunction = useCallback(async () => {
            setLoading(true);
            const uri = '/static-data/v1/sync/counterpart';
            axios.put(getGatewayUrl() + uri)
                .then((response) => {
                    setResponseData(response.data as Statistics)
                })
                .catch((err) => setError(err.message))
                .finally(() => setLoading(false));
        },
        []
    );

    return { data: responseData, loading, error, apiFunction: getFunction };
};
