import * as React from "react";
import RolesRetriever from "../authorization/RolesRetreiver";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Tooltip
} from "@mui/material";
import {useLoadFromVpb} from "../application/CashDividendService";
import {SnackbarState} from "./SnackbarComponent";

export type DividendVpbSyncDialogParam = {
    setSnackbarState: React.Dispatch<React.SetStateAction<SnackbarState>>
}
export default function DividendVpbSyncDialog(param: Readonly<DividendVpbSyncDialogParam>) {
    const [open, setOpen] = React.useState(false);
    const syncFunction = useLoadFromVpb();

    const syncCompleted = (): void => {
        param.setSnackbarState({ open: true, severity: "success", message: "Triggered load of dividend reports generated by Nexen, any new information should be visible in Pending Dividends shortly"});
    }

    const executeSync = (): void => {
        syncFunction.apiFunction({ completedFunction: syncCompleted, date: null });
    }
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleResponseCancel = () => {
        setOpen(false);
    };

    const handleResponseOk = () => {
        setOpen(false);
        executeSync();
    };

    return (
        <div>
            <RolesRetriever groups={ [ "super" ]}>
                <Tooltip title={ "Force Load dividends from Bony now." } placement={"top"}>
                    <Button variant="outlined" onClick={handleClickOpen}>
                        Force Load Bony
                    </Button>
                </Tooltip>
            </RolesRetriever>
            <Dialog open={open} onClose={handleResponseCancel}>
                <DialogTitle>Load dividends from BONY</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        This will trigger the load of any reports generated in NEXEN for T-Trade dividends.
                        This will auto trigger every day at 10:18, and report generation is setup in Nexen
                        to generate at 10:00. Triggering this will import any files Nexen has uploaded to T-Trade.
                        Triggering this is only required if the 10:18 import has failed for some reason.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleResponseCancel}>Cancel</Button>
                    <Button onClick={handleResponseOk}>Load Files Now</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}