import React, {ReactElement, useState} from "react";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Title from "../../components/Title";
import {DataGridPremium, GridColDef, GridToolbar} from "@mui/x-data-grid-premium";
import {useGetMandate, useSyncMandate} from "../../application/MandateService";
import SnackbarComponent, {defaultSnackbarState, SnackbarState} from "../../components/SnackbarComponent";
import {RefreshDataFunction} from "../../components/types/RefreshDataFunction";
import {Statistics} from "../../domain/staticdata/Statistics";
import {GridInitialStatePremium} from "@mui/x-data-grid-premium/models/gridStatePremium";
import {useAutoSizeGrid} from "../../components/AutoSizeGridHook";
import {Mandate} from "../../domain/staticdata/Mandate";
import RefreshStaticComponent from "../../components/RefreshStaticComponent";

export default function Mandates(): ReactElement {
    const { data, loading, apiFunction } = useGetMandate();
    const [ snackbarState, setSnackbarState ] = useState<SnackbarState>(defaultSnackbarState);
    const syncMandate: RefreshDataFunction<Statistics> = useSyncMandate();
    const apiRef = useAutoSizeGrid<Mandate>({
        data: data,
        loading: loading,
        columns: [
            'trafc1',
            'trafcglacc'
        ]
    })

    const initialState: GridInitialStatePremium =
        {
            columns: {
                columnVisibilityModel: {
                },
                dimensions: {
                    trafc1: { width: 200 },
                    trafcglacc: { width: 200 }
                }
            }
        }

    const columns: GridColDef[] = [
        { field: 'trafc1', headerName: 'trafc1', width: 170 },
        { field: 'trafcglacc', headerName: 'trafcglacc', width: 130 },
    ];

    return (
        <Container maxWidth={ false } sx={{mt: 4, mb: 4}}>
            {<Toolbar/>}
            <Grid container spacing={2} alignItems={"center"} justifyItems={"center"}>
                <Grid item xs={12} md={10} lg={10}>
                    <Title>Mandates</Title>
                </Grid>
                <RefreshStaticComponent
                    refreshFunction={ syncMandate }
                    dataFunction={ apiFunction }
                    setSnackbarState={ setSnackbarState }
                />

                <Grid item xs={12} md={12} lg={12} height={ 1000 }>
                    <DataGridPremium apiRef={apiRef}
                        slots={{ toolbar: GridToolbar }}
                        initialState={initialState}
                        loading={loading}
                        rows={data}
                        columns={columns}
                        density={"compact"}
                        getRowId={(row) => row.trafc1}
                    />
                </Grid>
                <SnackbarComponent setState={ setSnackbarState} state={ snackbarState}/>
            </Grid>
        </Container>
    );
}