import React, {ReactElement, useState} from "react";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Title from "../../components/Title";
import {DataGridPremium, GridColDef, GridToolbar} from "@mui/x-data-grid-premium";
import {formatSwedishDateTime} from "../../components/TimeFormat";
import {useGetCurrency, useSyncCurrency} from "../../application/CurrencyService";
import SnackbarComponent, {defaultSnackbarState, SnackbarState} from "../../components/SnackbarComponent";
import {RefreshDataFunction} from "../../components/types/RefreshDataFunction";
import {Statistics} from "../../domain/staticdata/Statistics";
import {GridInitialStatePremium} from "@mui/x-data-grid-premium/models/gridStatePremium";
import {useAutoSizeGrid} from "../../components/AutoSizeGridHook";
import {Currency} from "../../domain/staticdata/Currency";
import RefreshStaticComponent from "../../components/RefreshStaticComponent";

export default function Currencies(): ReactElement {

    const {data, loading, apiFunction } = useGetCurrency();
    const [ snackbarState, setSnackbarState ] = useState<SnackbarState>(defaultSnackbarState);
    const syncCurrency: RefreshDataFunction<Statistics> = useSyncCurrency();
    const apiRef = useAutoSizeGrid<Currency>({
        data: data,
        loading: loading,
        columns: [
            'id',
            'decimals',
            'curfc2',
            'curik',
            'changedTimestamp'
        ]
    })

    const initialState: GridInitialStatePremium =
        {
            columns: {
                columnVisibilityModel: {
                    curik: false
                },
                dimensions: {
                    id: { width: 250 },
                    decimals: { width: 240 },
                    curfc2: { width: 100 },
                    curik: { width: 100 },
                    changedTimestamp: { flex: 1 },
                }
            }
        }

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'Id' },
        { field: 'decimals', headerName: 'Decimals' },
        { field: 'curfc2', headerName: 'CLS' },
        { field: 'curik', headerName: 'Currency Key' },
        { field: 'changedTimestamp', headerName: 'Changed Timestamp', renderCell: (params) =>
                formatSwedishDateTime(params.row.changedTimestamp)
        }
    ];

    return (
            <Container maxWidth={ false } sx={{mt: 4, mb: 4}}>
                {<Toolbar/>}
                <Grid container spacing={2} alignItems={"center"} justifyItems={"center"}>
                    <Grid item xs={12} md={10} lg={10}>
                        <Title>Currency</Title>
                    </Grid>
                    <RefreshStaticComponent refreshFunction={ syncCurrency } dataFunction={ apiFunction } setSnackbarState={ setSnackbarState }/>
                    <Grid item xs={12} md={12} lg={12} height={ 1000 }>
                        <DataGridPremium
                            apiRef={apiRef}
                            initialState={initialState}
                            slots={{ toolbar: GridToolbar }}
                            loading={loading}
                            rows={data}
                            columns={columns}
                            density={"compact"}
                        />
                    </Grid>
                </Grid>
                <SnackbarComponent setState={ setSnackbarState} state={ snackbarState}/>
            </Container>
    );
}