import * as React from "react";
import {styled} from "@mui/material/styles";
import MuiAppBar, {AppBarProps} from "@mui/material/AppBar";
import MuiDrawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import MenuItems from "./MenuItems";
import { useIsAuthenticated } from "@azure/msal-react";
import {SignOutButton} from "../authorization/SignOutButton";
import {SignInButton} from "../authorization/SigninButton";
import ListItemText from "@mui/material/ListItemText";
import {Link} from "react-router-dom";
import Badge from "@mui/material/Badge";
import {AccountCircle} from "@mui/icons-material";
import {getAppEnvironment} from "../components/EnvironmentConfiguration";

interface AppBarExtendedProps extends AppBarProps {
    open?: boolean
}

const CustomAppBar = <C extends React.ElementType>(props: AppBarProps<C, {component?: C}> & AppBarExtendedProps ) => {
    return (<MuiAppBar {...props}>{props.children}</MuiAppBar>);
}

export default function DashboardMenu() {

    const isAuthenticated = useIsAuthenticated();

    const [ open, setOpen ] = React.useState<boolean>(true);

    const toggleDrawer = () => {
        setOpen(!open);
    };

    const drawerWidth = 240;

    const AppBar = styled(CustomAppBar, {
        shouldForwardProp: (prop) => prop !== 'open',
    })(({theme, open}) => ({
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
    }));

    const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
        ({theme, open}) => ({
            '& .MuiDrawer-paper': {
                position: 'relative',
                whiteSpace: 'nowrap',
                width: drawerWidth,
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                }),
                boxSizing: 'border-box',
                ...(!open && {
                    overflowX: 'hidden',
                    transition: theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                    }),
                    width: theme.spacing(7),
                    [theme.breakpoints.up('sm')]: {
                        width: theme.spacing(9),
                    },
                }),
            },
        }),
    );

    return (<React.Fragment>
        <Drawer variant="permanent" open={open}>
            <Toolbar
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    px: [1],
                }}
            >
                <IconButton onClick={toggleDrawer}>
                    <ChevronLeftIcon/>
                </IconButton>
            </Toolbar>
            <Divider/>
            <List component="nav">
                { isAuthenticated ? <MenuItems/> : <ListItemText sx={{ marginLeft: "5px" }}>No Access, Login Required</ListItemText> }
                <Divider sx={{my: 1}}/>
            </List>
        </Drawer>
            <AppBar position="absolute" open={open}>
                <Toolbar
                    sx={{
                        pr: '24px', // keep right padding when drawer closed
                    }}
                >
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawer}
                        sx={{
                            marginRight: '36px',
                            ...(open && {display: 'none'}),
                        }}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{flexGrow: 1}}
                    >
                        {document.title} <strong color={ 'success' }> -- { getAppEnvironment() } </strong>
                    </Typography>
                    { isAuthenticated ?
                        <div>
                            <IconButton component={Link} to="/" color="inherit">
                                <Badge color="secondary">
                                    <AccountCircle/>
                                </Badge>
                            </IconButton>
                            <SignOutButton/>
                        </div> : <SignInButton/> }
                </Toolbar>
            </AppBar>
    </React.Fragment>)
}
