import React, {ReactElement} from "react";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import Title from "../../components/Title";
import TransactionProgress from "./TransactionProgressPage";
import formatDateTime from "../../components/TimeFormat";
import {useFindByFutureTransactionId} from "../../application/FutureTransactionService";
import {GetDataApi} from "../../components/types/GetDataApi";
import {FutureTransaction} from "../../domain/transaction/FutureTransaction";
import {TransactionType} from "../../domain/transaction/TransactionType";
import {Button, Tooltip} from "@mui/material";
import {ErrorOutlineRounded, HistoryRounded} from "@mui/icons-material";
import {Link} from "react-router-dom";
import RolesRetriever from "../../authorization/RolesRetreiver";
import formatNumber from "../../components/NumberFormat";

/*
{

    "externalTransactionId": "B003066260008",
    "transactionCode": "SELL_FUTURE",
    "tradeDate": "2022-09-29",
    "valueDate": "2022-09-29",
    "originalFileName": "future.tsv",
    "originalFileRow": 1,
    "counterpartBicCode": "UBSWGB2L",
    "brokerShort": "UBS GB",
    "brokerName": "UBS Warburg (UBS AG), London",
    "brokerId": "UBSWGB2L",
    "brokerBicCode": "UBSWGB2L",
    "securityId": "CAC DEC22",
    "instrumentShort": "CAC DEC22",
    "instrumentName": "CAC40 10 EURO FUT Dec22",
    "instrumentType": "Future",
    "bloombergId": "CFZ2 Index",
    "currency": "EUR",
    "quantity": 26,
    "price": 1794,
    "fee1": 780,
    "quoteFactor": null
  }
 */
export default function FutureTransactionPage(): ReactElement {
    const transaction: GetDataApi<FutureTransaction> = useFindByFutureTransactionId();

    if (transaction.loading) {
        return (<></>);
    } else {
        return (
                <Container maxWidth={false} sx={{mt: 4, mb: 4}}>
                    {<Toolbar/>}
                    <Grid container spacing={2} justifyItems={ "center" } alignItems={ "center" }>
                        <Grid item xs={12} md={9} lg={9}>
                            <Title>Future Transaction {transaction.data.transactionId} </Title>
                        </Grid>
                        <Grid item xs={12} md={2} lg={2} >
                            <RolesRetriever groups={ ["super"] }>
                                    <Button variant='outlined'
                                        startIcon={ <ErrorOutlineRounded/> }
                                        sx={{ "whiteSpace": "nowrap" }}
                                        component={Link} to={ "/issues/inbound/transaction/" + transaction.data.assetManager + "/" + transaction.data.externalTransactionId }>
                                    Related Issues
                                </Button>
                            </RolesRetriever>
                        </Grid>
                        <Grid item xs={12} md={1} lg={1} >
                            <RolesRetriever groups={ ["super"] }>
                                    <Tooltip title={ "Related Issue History" } placement={"top"}>
                                    <Button variant='outlined'
                                            startIcon={ <HistoryRounded/> }
                                            component={Link} to={ "/audit/events/FU/" + transaction.data.transactionId }>
                                        History
                                    </Button>
                                </Tooltip>
                            </RolesRetriever>
                        </Grid>
                        <Grid container border={ 2 } borderRadius="6px" borderColor={ "primary.main" } margin={ 1 }>
                            <TransactionProgress assetManager={transaction.data.assetManager}
                                                 transactionType={TransactionType.FU}
                                                 transactionId={transaction.data.transactionId}/>
                        </Grid>
                        <Grid container border={ 2 } borderRadius="6px" borderColor={ "primary.main" } margin={ 1 }>
                            <Grid item xs={4} md={4} lg={4} padding={1}>TransactionId</Grid>
                            <Grid item xs={8} md={8} lg={8} padding={1}>{transaction.data.transactionId}</Grid>
                            <Grid item xs={4} md={4} lg={4} padding={1}>Last Processed</Grid>
                            <Grid item xs={8} md={8} lg={8} padding={1}> {formatDateTime(transaction.data.lastProcessed)}</Grid>
                        </Grid>
                        <Grid container border={ 2 } borderRadius="6px" borderColor={ "primary.main" } margin={ 1 }>
                            <Grid item xs={4} md={4} lg={4} padding={1}>Asset Manager Transaction</Grid>
                            <Grid item xs={8} md={8} lg={8} padding={1}>{transaction.data.externalTransactionId}</Grid>
                            <Grid item xs={4} md={4} lg={4} padding={1}>Asset Manager</Grid>
                            <Grid item xs={8} md={8} lg={8} padding={1}>{transaction.data.assetManager}</Grid>
                            <Grid item xs={4} md={4} lg={4} padding={1}>Mandate</Grid>
                            <Grid item xs={8} md={8} lg={8} padding={1}>{transaction.data.mandate}</Grid>
                            <Grid item xs={4} md={4} lg={4} padding={1}>Fund </Grid>
                            <Grid item xs={8} md={8} lg={8} padding={1}>{transaction.data.fundShort}</Grid>
                            <Grid item xs={4} md={4} lg={4} padding={1}>Fund Full</Grid>
                            <Grid item xs={8} md={8} lg={8} padding={1}>{transaction.data.fund}</Grid>
                            <Grid item xs={4} md={4} lg={4} padding={1}>Portfolio</Grid>
                            <Grid item xs={8} md={8} lg={8} padding={1}>{transaction.data.portfolio}</Grid>
                        </Grid>
                        <Grid container border={ 2 } borderRadius="6px" borderColor={ "primary.main" } margin={ 1 }>
                            <Grid item xs={4} md={4} lg={4} padding={1}>Counterpart</Grid>
                            <Grid item xs={8} md={8} lg={8} padding={1}>{transaction.data.counterpartShort}</Grid>
                            <Grid item xs={4} md={4} lg={4} padding={1}>Counterpart Full</Grid>
                            <Grid item xs={8} md={8} lg={8} padding={1}>{transaction.data.counterpartName}</Grid>
                            <Grid item xs={4} md={4} lg={4} padding={1}>Counterpart Id</Grid>
                            <Grid item xs={8} md={8} lg={8} padding={1}>{transaction.data.counterpartId}</Grid>
                            <Grid item xs={4} md={4} lg={4} padding={1}>Counterpart BicCode</Grid>
                            <Grid item xs={8} md={8} lg={8} padding={1}>{transaction.data.counterpartBicCode}</Grid>
                        </Grid>
                        <Grid container border={ 2 } borderRadius="6px" borderColor={ "primary.main" } margin={ 1 }>
                            <Grid item xs={4} md={4} lg={4} padding={1}>Broker</Grid>
                            <Grid item xs={8} md={8} lg={8} padding={1}>{transaction.data.brokerShort}</Grid>
                            <Grid item xs={4} md={4} lg={4} padding={1}>Broker Full</Grid>
                            <Grid item xs={8} md={8} lg={8} padding={1}>{transaction.data.brokerName}</Grid>
                            <Grid item xs={4} md={4} lg={4} padding={1}>Broker Id</Grid>
                            <Grid item xs={8} md={8} lg={8} padding={1}>{transaction.data.brokerId}</Grid>
                            <Grid item xs={4} md={4} lg={4} padding={1}>Broker BicCode</Grid>
                            <Grid item xs={8} md={8} lg={8} padding={1}>{transaction.data.brokerBicCode}</Grid>
                        </Grid>
                        <Grid container border={ 2 } borderRadius="6px" borderColor={ "primary.main" } margin={ 1 }>
                            <Grid item xs={4} md={4} lg={4} padding={1}>Asset Manager Instrument</Grid>
                            <Grid item xs={8} md={8} lg={8} padding={1}>{transaction.data.securityId}</Grid>
                            <Grid item xs={4} md={4} lg={4} padding={1}>SCD Instrument</Grid>
                            <Grid item xs={8} md={8} lg={8} padding={1}>{transaction.data.instrumentShort}</Grid>
                            <Grid item xs={4} md={4} lg={4} padding={1}>Instrument Name</Grid>
                            <Grid item xs={8} md={8} lg={8} padding={1}>{transaction.data.instrumentName}</Grid>
                            <Grid item xs={4} md={4} lg={4} padding={1}>Instrument Type</Grid>
                            <Grid item xs={8} md={8} lg={8} padding={1}>{transaction.data.instrumentType}</Grid>
                            <Grid item xs={4} md={4} lg={4} padding={1}>Bloomberg Code</Grid>
                            <Grid item xs={8} md={8} lg={8} padding={1}>{transaction.data.bloombergId}</Grid>
                        </Grid>
                        <Grid container border={ 2 } borderRadius="6px" borderColor={ "primary.main" } margin={ 1 }>
                            <Grid item xs={4} md={4} lg={4} padding={1}>Currency</Grid>
                            <Grid item xs={8} md={8} lg={8} padding={1}>{transaction.data.currency}</Grid>
                            <Grid item xs={4} md={4} lg={4} padding={1}>Transaction Code</Grid>
                            <Grid item xs={8} md={8} lg={8} padding={1}>{transaction.data.transactionCode}</Grid>
                            <Grid item xs={4} md={4} lg={4} padding={1}>Quantity</Grid>
                            <Grid item xs={8} md={8} lg={8} padding={1}>{formatNumber(transaction.data.quantity)}</Grid>
                            <Grid item xs={4} md={4} lg={4} padding={1}>Price</Grid>
                            <Grid item xs={8} md={8} lg={8} padding={1}>{formatNumber(transaction.data.price)}</Grid>
                            <Grid item xs={4} md={4} lg={4} padding={1}>Fee 1</Grid>
                            <Grid item xs={8} md={8} lg={8} padding={1}>{formatNumber(transaction.data.fee1)}</Grid>
                            <Grid item xs={4} md={4} lg={4} padding={1}>Quote Factor</Grid>
                            <Grid item xs={8} md={8} lg={8} padding={1}>{transaction.data.quoteFactor}</Grid>
                            <Grid item xs={4} md={4} lg={4} padding={1}>Trade Date</Grid>
                            <Grid item xs={8} md={8} lg={8} padding={1}>{transaction.data.tradeDate}</Grid>
                            <Grid item xs={4} md={4} lg={4} padding={1}>Value Date</Grid>
                            <Grid item xs={8} md={8} lg={8} padding={1}>{transaction.data.valueDate}</Grid>
                        </Grid>
                    </Grid>
                </Container>
        );
    }
}
