import {AuditRequestType} from "./AuditRequestType";

export type AuditRequest = {
    requestId: string,
    eventTime: string,
    type: AuditRequestType,
    user: string,
    // Only on external requests
    externalId: string,
    source: string,
    url: string,
    verb: string
}

export function emptyAuditRequest(): AuditRequest {
    return {
        requestId: '',
        eventTime: '',
        type: AuditRequestType.APPLICATION,
        user: '',
        // Only on external requests
        externalId: '',
        source: '',
        url: '',
        verb: ''
    };
}