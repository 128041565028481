import React, {PropsWithChildren, ReactElement, useEffect, useState} from "react";
import {useAccount, useMsal} from "@azure/msal-react";
import {tokenRequest} from "./authConfig";
import {jwtDecode} from "jwt-decode";
import {AccountInfo, AuthenticationResult, InteractionRequiredAuthError} from "@azure/msal-browser";
import {RolesProps} from "../components/Roles";

interface MyToken {
    roles: string[]
}

export type RolesRetrieverProps = {
    groups: string[]
}

export default function RolesRetriever(props: PropsWithChildren<RolesRetrieverProps>): ReactElement {

    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0]) as AccountInfo;
    const [ groups, setGroups ] = useState<string[]>([]);

    useEffect(() => {
        instance.acquireTokenSilent({
            ...tokenRequest,
            account
        }).then((response) => {
            setGroups(decodeTokenGroups(response))
        }).catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
                instance.acquireTokenPopup({
                    ...tokenRequest,
                    account
                }).then((response) => {
                    setGroups(decodeTokenGroups(response))
                })
                .catch(function (error) {
                    console.log(error);
                });
            }
        });
    }, []);

    function decodeTokenGroups(response: AuthenticationResult): string[] {
        const token = jwtDecode<MyToken>(response.accessToken);
        return token.roles.map(s => s.substring(s.lastIndexOf(".") + 1));
    }

    function isValidGroup(): boolean | undefined {
        if (props.groups === undefined || groups.length === 0) {
            return false;
        }
        const group = groups[0];
        const gs = props.groups as string[];
        return gs.find(g => g === group) !== undefined;
    }

    return (
        <div hidden={ ! isValidGroup() }>
            { React.cloneElement(props.children as React.ReactElement<RolesProps>, { "groups": groups })}
        </div>);
}