import React, {ReactElement} from "react";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import Title from "../../components/Title";
import {CashDividend} from "../../domain/dividend/CashDividend";
import {useGetDividendImportStatus, useGetPendingDividends} from "../../application/CashDividendService";
import {PageDto} from "../../domain/PageDto";
import {RefreshDataFunction} from "../../components/types/RefreshDataFunction";
import DividendBulkUppdateComponent from "../../components/DividendBulkUppdateComponent";
import CashDividendComponent from "../../components/CashDividendComponent";
import CashDividendImportStatusComponent from "../../components/CashDividendImportStatusComponent";


export default function PendingCashDividendsPage(): ReactElement {

    const dividends: RefreshDataFunction<PageDto<CashDividend>> = useGetPendingDividends();
    const importStatusFunction = useGetDividendImportStatus();

    return (
            <Container maxWidth={ false } sx={{ mt: 4, mb: 4 }}>
                { <Toolbar/> }
                <Grid container spacing={2} alignItems={"center"} justifyItems={"center"}>
                    <Grid item xs={12} md={3} lg={3}>
                        <Title>All Dividends That Requires Verification</Title>
                    </Grid>
                    <Grid item xs={12} md={3} lg={3}>
                        <CashDividendImportStatusComponent api={importStatusFunction}/>
                    </Grid>
                    <DividendBulkUppdateComponent data={ dividends.data } refreshFunction={ () => dividends.apiFunction() }/>
                    <CashDividendComponent dividends={ dividends.data } loading={ dividends.loading } searchFunction={ () => dividends.apiFunction() }/>
                </Grid>
            </Container>
    );
}