import React, {ReactElement, useEffect, useState} from "react";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import Title from "../../components/Title";
import {Button, Checkbox, FormControlLabel, Tooltip} from "@mui/material";
import SnackbarComponent, {defaultSnackbarState, SnackbarState} from "../../components/SnackbarComponent";
import {useGetDividendConfiguration, useSaveDividendConfiguration} from "../../application/DividendConfigService";
import {DividendConfig} from "../../domain/assetmanager/DividendConfig";
import RolesRetriever from "../../authorization/RolesRetreiver";
import DividendCurrencyConfigurationsComponent from "../../components/DividendCurrencyConfigurationsComponent";
import {SaveRounded} from "@mui/icons-material";
import DividendFilterConfigurationsComponent from "../../components/DividendFilterConfigurationsComponent";
import DividendPaymentDateConfigurationsComponent from "../../components/DividendPaymentDateConfigurationsComponent";
import DividendDpSyncDialog from "../../components/DividendDpSyncDialog";
import DividendVpbSyncDialog from "../../components/DividendVpbSyncDialog";
import {RefreshDataFunction} from "../../components/types/RefreshDataFunction";

export default function DividenConfigPage(): ReactElement {
    const dividendConfig: RefreshDataFunction<DividendConfig> = useGetDividendConfiguration();
    const saveFunction = useSaveDividendConfiguration();
    const [snackbarState, setSnackbarState] = useState<SnackbarState>(defaultSnackbarState);
    const [autoMatch, setAutoMatch] = useState<boolean>(false);
    const [dpDownloadActive, setDpDownloadActive] = useState<boolean>(false);
    const [dpActive, setDpActive] = useState<boolean>(false);
    const [vpbActive, setVpbActive] = useState<boolean>(false);
    const [holdNetMatch, setHoldNetMatch] = useState<boolean>(false);
    const [holdCurrencyMatch, setHoldCurrencyMatch] = useState<boolean>(false);

    let saveInProgress = false;

    useEffect(() => {
        setAutoMatch(dividendConfig.data.autoApproveMatch);
        setDpDownloadActive(dividendConfig.data.dpActiveDownload);
        setDpActive(dividendConfig.data.dpActive);
        setVpbActive(dividendConfig.data.vpbActive);
        setHoldNetMatch(dividendConfig.data.holdNetMatch);
        setHoldCurrencyMatch(dividendConfig.data.holdCurrencyMatch);
    }, [ dividendConfig.data ]);

    const saveConfiguration = () => {
        saveInProgress = true;
        saveFunction.apiFunction(dividendConfig.data)
            .then(() => {
                if (dividendConfig.error) {
                    setSnackbarState({ open: true, message: "Save failed: " + dividendConfig.error, severity: 'error' });
                } else {
                    setSnackbarState({ open: true, message: "Save successful", severity: "success" } );
                }
            })
            .finally(() => {
                saveInProgress = false;
                dividendConfig.apiFunction();
            });
    }

    const handleAutoMatch = (event: React.ChangeEvent<HTMLInputElement>) => {
        dividendConfig.data.autoApproveMatch = event.target.checked;
        setAutoMatch(event.target.checked);
    }

    const handleDpDownloadActive = (event: React.ChangeEvent<HTMLInputElement>) => {
        dividendConfig.data.dpActiveDownload = event.target.checked;
        setDpDownloadActive(dividendConfig.data.dpActiveDownload);
    }

    const handleDpActive = (event: React.ChangeEvent<HTMLInputElement>) => {
        dividendConfig.data.dpActive = event.target.checked;
        setDpActive(dividendConfig.data.dpActive);
    }
    const handleVpbActive = (event: React.ChangeEvent<HTMLInputElement>) => {
        dividendConfig.data.vpbActive = event.target.checked;
        setVpbActive(dividendConfig.data.vpbActive);
    }
    const handleHoldNetMatch = (event: React.ChangeEvent<HTMLInputElement>) => {
        dividendConfig.data.holdNetMatch = event.target.checked;
        setHoldNetMatch(dividendConfig.data.holdNetMatch);
    }
    const handleHoldCurrencyMatch = (event: React.ChangeEvent<HTMLInputElement>) => {
        dividendConfig.data.holdCurrencyMatch = event.target.checked;
        setHoldCurrencyMatch(dividendConfig.data.holdCurrencyMatch);
    }

    if (dividendConfig.loading) {
        return (
            <Container maxWidth={false} sx={{mt: 4, mb: 4}}>
                {<Toolbar/>}
                <Title>Loading ....</Title>
            </Container>);
    } else {
        return (
                <Container maxWidth={false} sx={{mt: 4, mb: 4}}>
                    {<Toolbar/>}
                    <Grid container
                          spacing={2}
                          display="flex"
                          alignItems="center">
                        <Grid item xs={12} md={4} lg={4}>
                            <Title>Dividend Configuration</Title>
                        </Grid>
                        <Grid item xs={12} md={2} lg={2}>
                            <DividendDpSyncDialog setSnackbarState={ setSnackbarState}></DividendDpSyncDialog>
                        </Grid>
                        <Grid item xs={12} md={2} lg={2}>
                            <DividendVpbSyncDialog setSnackbarState={ setSnackbarState}></DividendVpbSyncDialog>
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                            <RolesRetriever groups={ [ "super" ]}>
                                <Button variant='outlined'
                                        startIcon={<SaveRounded/>}
                                        disabled={saveInProgress}
                                        onClick={() => { saveConfiguration(); }}>
                                    Save Configuration
                                </Button>
                            </RolesRetriever>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                                <Tooltip title={ "Enables API calls daily to Bloomberg to fetch Dividend Data to T-Trade" }>
                                    <FormControlLabel control={
                                        <Checkbox
                                            checked={ dpDownloadActive }
                                            onChange={ handleDpDownloadActive }
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    } label="Activate Requesting Dividends Daily from Bloomberg" />
                                </Tooltip>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                                <Tooltip title={ "This will also load the files downloaded from Bloomberg into T-Trade" }>
                                    <FormControlLabel control={
                                        <Checkbox
                                            checked={ dpActive }
                                            onChange={ handleDpActive }
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    } label="Activate Importing Dividends from Bloomberg" />
                                </Tooltip>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                                <Tooltip title={ "This will enable loading daily Dividend files from Bloomberg Nexen into T-Trade" }>
                                    <FormControlLabel control={
                                        <Checkbox
                                            checked={ vpbActive }
                                            onChange={ handleVpbActive }
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    } label="Activate Importing Dividends from BONY" />
                                </Tooltip>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                                <Tooltip title={ "This will automatically approve matches overall, there are other options to limit which matches that are approved too." }>
                                    <FormControlLabel control={
                                        <Checkbox
                                            checked={ autoMatch }
                                            onChange={ handleAutoMatch }
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    } label="Auto Approve Matched Dividends" />
                                </Tooltip>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                                <Tooltip title={ "If Auto Match is enabled, this will make sure that if we matched on NET rate, it will not be automatically sent to Simcorp Dimension" }>
                                    <FormControlLabel control={
                                        <Checkbox
                                            checked={ holdNetMatch }
                                            onChange={ handleHoldNetMatch }
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    } label="Hold Matches using NET Income Rate" />
                                </Tooltip>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <Tooltip title={ "If Auto Match is enabled, this will make sure that if currencies differ, it will not be automatically sent to Simcorp Dimension" }>
                                <FormControlLabel control={
                                    <Checkbox
                                        checked={ holdCurrencyMatch }
                                        onChange={ handleHoldCurrencyMatch }
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                } label="Hold Matches With Differing Instrument and Dividend Currency"/>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <DividendCurrencyConfigurationsComponent/>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <DividendFilterConfigurationsComponent/>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <DividendPaymentDateConfigurationsComponent/>
                        </Grid>
                    </Grid>
                    <SnackbarComponent state={ snackbarState } setState={ setSnackbarState }/>
                </Container>
        );
    }
}
