import {TransactionType} from "../transaction/TransactionType";
import {AssetManagerFileConfig} from "./AssetManagerFileConfig";
import {FileSeparator} from "./FileSeparator";

export type AssetManagerConfig = {

    name: string,
    sortOnFileNames: boolean,
    addNackFile: boolean,
    logicAppEnabled: boolean,
    reportTime: string | null,
    delayTime: string | null,
    reportDateOffset: number | null,
    assetConfiguration: Record<TransactionType, AssetManagerFileConfig>

}

export function emptyAssetManagerConfig(): AssetManagerConfig {
    return {
        name: '',
        sortOnFileNames: false,
        addNackFile: false,
        logicAppEnabled: false,
        reportTime: null,
        delayTime: null,
        reportDateOffset: null,
        assetConfiguration: {
            FX: {
                id: 0,
                fieldConfig: [],
                separator: FileSeparator.TAB,
                extension: '',
                automaticConfirm: false,
                active: false,
                validationRules: []
            },
            FU: {
                id: 0,
                fieldConfig: [],
                separator: FileSeparator.TAB,
                extension: '',
                automaticConfirm: false,
                active: false,
                validationRules: []
            },
            EQ: {
                id: 0,
                fieldConfig: [],
                separator: FileSeparator.TAB,
                extension: '',
                automaticConfirm: false,
                active: false,
                validationRules: []
            }
        }
    }
}
