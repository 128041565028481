import {
    DataGridPremium,
    GridCellParams,
    GridColDef,
    GridRenderCellParams, GridToolbar,
    GridValueGetterParams
} from "@mui/x-data-grid-premium";
import React, {ReactElement} from "react";
import formatDateTime, {formatDateTimeShort} from "../../components/TimeFormat";
import {GridInitialStatePremium} from "@mui/x-data-grid-premium/models/gridStatePremium";
import {CashDividendSource} from "../../domain/dividend/CashDividendSource";
import {CashDividendUpdateDto} from "../../domain/dividend/CashDividendUpdateDto";
import clsx from "clsx";
import {CashDividendState} from "../../domain/dividend/CashDividendState";
import RolesRetriever from "../../authorization/RolesRetreiver";
import {Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {SearchRounded} from "@mui/icons-material";
import {Link} from "react-router-dom";
import {getStateClass, StateSx} from "../../components/CashDividendStateRenderingComponent";
import {useAutoSizeGrid} from "../../components/AutoSizeGridHook";
import formatNumber from "../../components/NumberFormat";

export type CashDividendDataPageProps = {
    dividends: CashDividendUpdateDto[]
    loading: boolean
}
export default function CashDividendDataPage(params: Readonly<CashDividendDataPageProps>): ReactElement {

    const apiRef = useAutoSizeGrid<CashDividendUpdateDto>({
        data: params.dividends,
        loading: params.loading,
        columns: [
            'state',
            'verifiedDate',
            'exDate',
            'importDate',
            'paymentDate',
            'currency',
            'instrumentCurrency',
            'netIncomeRate',
            'grossIncomeRate',
            'matchedIncomeRate',
            'source',
            'eventId',
            'eventTime',
            'message'
        ]
    });

    const initialState: GridInitialStatePremium =
        {
            columns: {
                columnVisibilityModel: {
                    eventTime: false,
                },
                dimensions: {
                    state: { width: 150 },
                    exDate: { width: 120 },
                    importDate: { width: 120 },
                    paymentDate: { width: 120 },
                    verifiedDate: { width: 160 },
                    currency: { width: 130 },
                    instrumentCurrency: { width: 130 },
                    netIncomeRate: { width: 110 },
                    grossIncomeRate: { width: 110 },
                    matchedIncomeRate: { width: 110 },
                    source: { width: 70 },
                    eventId: { width: 120 },
                    message: { flex: 1}
                }
            },
            pagination: {
                paginationModel: {
                    pageSize: 25
                }
            }

        };

    const statesWithLink = [ CashDividendState.DIFF, CashDividendState.MATCH, CashDividendState.UPDATE];

    function renderButtons(params: GridRenderCellParams<CashDividendUpdateDto>) {
        if (statesWithLink.find((e) => e === params.row.state)) {
            return (<React.Fragment>
                <RolesRetriever groups={ [ "admin", "super" ]}>
                    <Tooltip title={"Locate this in Pending dividends"} placement={'top'}>
                        <IconButton color="primary" component={Link} to={ "/dividends/pending/" + params.row.isin}>
                            <SearchRounded/>
                        </IconButton>
                    </Tooltip>
                </RolesRetriever>
                { params.row.state }
            </React.Fragment>);
        } else {
            return (<> { params.row.state } </>)
        }
    }

    function toReadableSource(source: CashDividendSource): string {
        if (source === CashDividendSource.DP) {
            return 'BLO';
        } else {
            return 'BNY'
        }
    }

    const columns: GridColDef<CashDividendUpdateDto>[] = [
        { field: 'state',
            headerName: 'State',
            renderCell: renderButtons,
            cellClassName: (params: GridCellParams<CashDividendUpdateDto>) => {
                return getStateClass(params.row.state);
            }},
        { field: 'verifiedDate', headerName: 'Verified Date', valueGetter: (params: GridValueGetterParams<CashDividendUpdateDto>) =>
                formatDateTimeShort(params.row.verifiedDate) },
        { field: 'exDate', headerName: 'Ex Date' },
        { field: 'importDate', headerName: 'Import Date'},
        {
            field: 'paymentDate', headerName: 'Payment Date',
            cellClassName: (params: GridCellParams<CashDividendUpdateDto>) => {
                return clsx('ttrade-dividend', {
                    update: params.row.estimatedPaymentDate
                })
            }
        },
        { field: 'currency', headerName: 'Div Currency' },
        { field: 'instrumentCurrency', headerName: 'EQ Currency' },
        { field: 'netIncomeRate', headerName: 'Net Rate',
            valueGetter: (params: GridValueGetterParams<CashDividendUpdateDto>) => formatNumber(params.row.netIncomeRate),
        },
        { field: 'grossIncomeRate', headerName: 'Gross Rate',
            valueGetter: (params: GridValueGetterParams<CashDividendUpdateDto>) => formatNumber(params.row.grossIncomeRate),
        },
        { field: 'matchedIncomeRate', headerName: 'Rate',
            valueGetter: (params: GridValueGetterParams<CashDividendUpdateDto>) => formatNumber(params.row.matchedIncomeRate),
        },
        { field: 'source', headerName: 'Source', valueGetter: (params: GridValueGetterParams<CashDividendUpdateDto>) =>
                toReadableSource(params.row.source) },
        { field: 'eventId', headerName: 'Event ID' },
        { field: 'eventTime', headerName: 'Event Time',
            valueGetter: (params: GridRenderCellParams<CashDividendUpdateDto>) => formatDateTime(params.row.eventTime)
        },
        { field: 'message', headerName: 'Message', flex: 0.8}
    ];

    return (
        <DataGridPremium
            apiRef={apiRef}
            sx={ StateSx }
            rows={ params.dividends }
            initialState={initialState}
            slots={{ toolbar: GridToolbar }}
            columns={columns}
            getRowId={ (row) => row.id }
            loading={params.loading}
            density={"compact"}
            pagination={true}
            pageSizeOptions={[10, 25, 50, 100]}
            autoHeight={true}
        />
    );

}
