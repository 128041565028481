import React, {ReactElement} from "react";
import {GetDataApi} from "../../components/types/GetDataApi";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import Title from "../../components/Title";
import formatDateTime from "../../components/TimeFormat";
import {useGetAuditEventsByRequestId, useGetAuditRequestByRequestId} from "../../application/AuditService";
import {AuditRequest} from "../../domain/audit/AuditRequest";
import AuditEventComponent from "../../components/AuditEventComponent";
import {AuditEvent} from "../../domain/audit/AuditEvent";

export default function AuditRequestPage(): ReactElement {
    const auditRequest: GetDataApi<AuditRequest> = useGetAuditRequestByRequestId();
    const auditEvent: GetDataApi<AuditEvent[]> = useGetAuditEventsByRequestId();


    const getUrlOrInfo = () => {
        if (auditRequest.data.verb) {
            return auditRequest.data.verb + " " + auditRequest.data.url;
        }
        return auditRequest.data.url;
    }

    if (auditRequest.loading) {
        return (<></>);
    } else {
        return (
            <Container maxWidth={false} sx={{mt: 4, mb: 4}}>
                {<Toolbar/>}
                <Grid container spacing={2} height={1000}>
                    <Grid item xs={12} md={12} lg={12}>
                        <Title>Audit Request {auditRequest.data.requestId} </Title>
                    </Grid>
                    <Grid container border={ 2 } borderRadius="6px" borderColor={ "primary.main" } margin={ 1 }>
                        <Grid item xs={2} md={2} lg={2} padding={1}>User</Grid>
                        <Grid item xs={4} md={4} lg={4} padding={1}>{auditRequest.data.user}</Grid>
                        <Grid item xs={2} md={2} lg={2} padding={1}>Event Time</Grid>
                        <Grid item xs={4} md={4} lg={4} padding={1}> {formatDateTime(auditRequest.data.eventTime)}</Grid>
                        <Grid item xs={2} md={2} lg={2} padding={1}>Type</Grid>
                        <Grid item xs={4} md={4} lg={4} padding={1}> { auditRequest.data.type }</Grid>
                        <Grid item xs={2} md={2} lg={2} padding={1}>URL/Info</Grid>
                        <Grid item xs={4} md={4} lg={4} padding={1}>{ getUrlOrInfo() }</Grid>
                        <Grid item xs={2} md={2} lg={2} padding={1}>Source</Grid>
                        <Grid item xs={4} md={4} lg={4} padding={1}>{ auditRequest.data.source }</Grid>
                        <Grid item xs={2} md={2} lg={2} padding={1}>External Id</Grid>
                        <Grid item xs={4} md={4} lg={4} padding={1}>{ auditRequest.data.externalId }</Grid>
                    </Grid>
                    <Grid container border={ 2 } borderRadius="6px" borderColor={ "primary.main" } margin={ 1 }>
                        <AuditEventComponent data={auditEvent.data} loading={auditEvent.loading}/>
                    </Grid>
                </Grid>
            </Container>
        );
    }
}