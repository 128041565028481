import axios from "axios";
import {useCallback, useEffect, useState} from "react";
import {RefreshDataFunction} from "../components/types/RefreshDataFunction";
import {CashDividend} from "../domain/dividend/CashDividend";
import {PageDto} from "../domain/PageDto";
import {DividendId} from "../domain/dividend/DividendId";
import {RequestDataFunction} from "../components/types/RequestDataFunction";
import {useParams} from "react-router-dom";
import {GetDataApi} from "../components/types/GetDataApi";
import {getGatewayUrl} from "../components/EnvironmentConfiguration";
import {CashDividendSearchTerm, defaultCashDividendSearchTerm} from "../domain/dividend/CashDividendSearchTerm";
import {SearchDataFunction} from "../components/types/SearchDataFunction";
import {CashDividendUpdateDto} from "../domain/dividend/CashDividendUpdateDto";
import {CashDividendState} from "../domain/dividend/CashDividendState";
import {ImsTransactionUpdateRequest} from "../domain/transaction/ImsTransactionUpdateRequest";
import dayjs, {Dayjs} from "dayjs";
import {formatDayjsDateString} from "../components/TimeFormat";
import {CashDividendImportStatus, defaultCashDividendImportStatus} from "../domain/dividend/CashDividendImportStatus";

const DEFAULT_PAGE: PageDto<CashDividend> = { page: 0, size: 0, totalElements: 0, data: []};
const DEFAULT_DTO_PAGE: PageDto<CashDividendUpdateDto> = { page: 0, size: 0, totalElements: 0, data: []};

const config = {
    headers: {
        'Content-Type': 'application/json'
    }
};

export type TtradeDividendUpdateRequest = {
    dividendId: number,
    message: ImsTransactionUpdateRequest
    completedFunction: () => void
}

export type TtradeDividendReactivateRequest = {
    dividendId: number
    completedFunction: () => void
}

export type DividendIdUpdate = {
    dividendId: DividendId,
    instrumentCurrency: boolean,
    completedFunction: () => void
}

export type ManyDividendsIdUpdate = {
    dividendIds: DividendId[],
    completedFunction: () => void
}

export type DividendSyncFunction = {
    date: Dayjs | null
    completedFunction: () => void
}

export function getRouteDate(routeDate: string | undefined) {
    if (routeDate) {
        const date = formatDayjsDateString(dayjs(routeDate, "YYYY-MM-DD"));
        return date ? dayjs(date) : dayjs();
    }
    return dayjs();
}

export function useGetPendingDividends(): RefreshDataFunction<PageDto<CashDividend>> {
    const { isin } = useParams();
    const [dividends, setDividends] = useState<PageDto<CashDividend>>(DEFAULT_PAGE);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const data = JSON.stringify({
        'isin': isin,
    });
    const getFunction = useCallback(
        async () => {
            setLoading(true);
            await axios.post(getGatewayUrl() + '/dividend/cash/v1/pending', data, config)
                .then((response) => {
                    setDividends(response.data as PageDto<CashDividend>)
                })
                .catch((err) => setError(err.error))
                .finally(() => {
                    setLoading(false)
                });
        },
        [ isin ]
    );

    useEffect(() => {
        getFunction();
    }, [ isin ]);

    return {data: dividends, loading, error, apiFunction: getFunction}
}

export function useGetVerifiedCashDividends(): RefreshDataFunction<PageDto<CashDividend>> {
    const { routeDate } = useParams();
    const [dividends, setDividends] = useState<PageDto<CashDividend>>(DEFAULT_PAGE);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const apiFunction = useCallback(
        async () => {
            setLoading(true);
            axios.get(getGatewayUrl() + '/dividend/cash/v1/verified/' + getRouteDate(routeDate).format("YYYY-MM-DD"))
                .then((response) => {
                    setDividends(response.data as PageDto<CashDividend>)
                })
                .catch((err) => setError(err.message))
                .finally(() => setLoading(false));
        },
        [ routeDate ]
    );

    useEffect(() => {
            apiFunction(); },
        [ routeDate ]
    );

    return { data: dividends, loading, error, apiFunction }
}

export function useSearchCashDividends(): SearchDataFunction<CashDividendSearchTerm, PageDto<CashDividend>> {
    const defaultSearchTerm = defaultCashDividendSearchTerm();
    const [dividends, setDividends] = useState<PageDto<CashDividend>>(DEFAULT_PAGE);
    const [searchTerm, setSearchTerm] = useState<CashDividendSearchTerm>(defaultSearchTerm);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const apiFunction = useCallback(
        async (searchTerm: CashDividendSearchTerm) => {
            setLoading(true);
            axios.post(getGatewayUrl() + '/dividend/cash/v1/search', searchTerm, config)
                .then((response) => {
                    setDividends(response.data as PageDto<CashDividend>)
                })
                .catch((err) => setError(err.message))
                .finally(() => setLoading(false));
        },
        [ searchTerm ]
    );

    useEffect(() => {
        apiFunction(searchTerm); },
        [ searchTerm ]
    );

    return { data: dividends, searchTerm, loading, error, apiFunction, setSearchTermFunction: setSearchTerm }
}

export function useGetCashDividendsMonitor(): RefreshDataFunction<PageDto<CashDividend>> {
    const searchTerm = {
        instrument: '',
        fromExDate: null,
        toExDate: null,
        states: [ CashDividendState.FAILED, CashDividendState.TRANSFERRED, CashDividendState.TIMEOUT ]
    } as CashDividendSearchTerm;
    const [dividends, setDividends] = useState<PageDto<CashDividend>>(DEFAULT_PAGE);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const apiFunction = useCallback(
        async () => {
            setLoading(true);
            axios.post(getGatewayUrl() + '/dividend/cash/v1/search', searchTerm, config)
                .then((response) => {
                    setDividends(response.data as PageDto<CashDividend>)
                })
                .catch((err) => setError(err.message))
                .finally(() => setLoading(false));
        },
        [ ]
    );

    useEffect(() => {
            apiFunction(); },
        [ ]
    );

    return { data: dividends, loading, error, apiFunction }
}

export function useGetRawDividendsById(): GetDataApi<PageDto<CashDividendUpdateDto>> {
    const { shortName, isin, bloombergCode } = useParams();
    const [dividends, setDividends] = useState<PageDto<CashDividendUpdateDto>>(DEFAULT_DTO_PAGE);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const data = JSON.stringify({
            'shortName': shortName,
            'isin': isin,
            'bloombergCode': bloombergCode
        });
        axios.post(getGatewayUrl() + '/dividend/cash/v1/instrument/search/raw', data, config)
            .then((response) => {
                setDividends(response.data as PageDto<CashDividendUpdateDto>)
            })
            .catch((err) => setError(err.error))
            .finally(() => setLoading(false));
    }, [ ]);

    return { data: dividends, loading, error };

}

export function useGetDividendsById(): GetDataApi<PageDto<CashDividend>> {
    const { shortName, isin, bloombergCode } = useParams();
    const [dividends, setDividends] = useState<PageDto<CashDividend>>(DEFAULT_PAGE);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const data = JSON.stringify({
            'shortName': shortName,
            'isin': isin,
            'bloombergCode': bloombergCode
        });
        axios.post(getGatewayUrl() + '/dividend/cash/v1/instrument/search', data, config)
            .then((response) => {
                setDividends(response.data as PageDto<CashDividend>)
            })
            .catch((err) => setError(err.error))
            .finally(() => setLoading(false));
    }, [ ]);

    return { data: dividends, loading, error };

}


export function useTransferDividendToIms(): RequestDataFunction<DividendIdUpdate> {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const getFunction = useCallback(
        async (id: DividendIdUpdate) => {
            setLoading(true);
            const cfg = { ...config, params: {
                instrumentCurrency: id.instrumentCurrency ? id.instrumentCurrency : false
            }};
            axios.post(getGatewayUrl() + '/dividend/cash/v1/approve', id.dividendId, cfg)
                .then(() => {
                    id.completedFunction();
                })
                .catch((err) => setError(err.error))
                .finally(() => setLoading(false));
        },
        []
    );

    return { loading, error, apiFunction: getFunction };
}

export function useDiscardDividend(): RequestDataFunction<DividendIdUpdate> {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const getFunction = useCallback(
        async (id: DividendIdUpdate) => {
            setLoading(true);
            axios.post(getGatewayUrl() + '/dividend/cash/v1/discard', id.dividendId, config)
                .then(() => {
                    id.completedFunction();
                })
                .catch((err) => setError(err.error))
                .finally(() => {
                    setLoading(false)
                });
        },
        []
    );

    return { loading, error, apiFunction: getFunction };
}

export function useTransferManyDividendToIms(): RequestDataFunction<ManyDividendsIdUpdate> {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const getFunction = useCallback(
async (update: ManyDividendsIdUpdate) => {
            setLoading(true);
            axios.post(getGatewayUrl() + '/dividend/cash/v1/approve/many', update.dividendIds, config)
                .then(() => {
                    update.completedFunction();
                })
                .catch((err) => setError(err.error))
                .finally(() => setLoading(false));
        },
        []
    );

    return { loading, error, apiFunction: getFunction };
}

export function useDiscardManyDividend(): RequestDataFunction<ManyDividendsIdUpdate> {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const getFunction = useCallback(
        async (update: ManyDividendsIdUpdate) => {
            setLoading(true);
            axios.post(getGatewayUrl() + '/dividend/cash/v1/discard/many', update.dividendIds, config)
                .then(() => {
                    update.completedFunction();
                })
                .catch((err) => setError(err.error))
                .finally(() => {
                    setLoading(false)
                });
        },
        []
    );

    return { loading, error, apiFunction: getFunction };
}

export function useDividendCancel(): RequestDataFunction<TtradeDividendUpdateRequest> {
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const apiFunction = useCallback(
        async (data: TtradeDividendUpdateRequest) => {
            setLoading(true);
            const body = JSON.stringify(data.message);
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            axios.post(getGatewayUrl() + '/dividend/cash/v1/update/cancel/' + data.dividendId, body, config)
                .then(() => {
                    data.completedFunction();
                })
                .catch((err) => setError(err.message))
                .finally(() => setLoading(false));
        },
        []
    );
    return {loading, error, apiFunction};
}

export function useDividendReactivate(): RequestDataFunction<TtradeDividendReactivateRequest> {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const apiFunction = useCallback(
        async (data: TtradeDividendReactivateRequest) => {
            setLoading(true);
            const body = JSON.stringify([ data.dividendId ] );
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            axios.post(getGatewayUrl() + '/dividend/cash/v1/update/reactivate', body, config)
                .then(() => {
                    data.completedFunction();
                })
                .catch((err) => setError(err.message))
                .finally(() => setLoading(false));
        },
        []
    );

    return {loading, error, apiFunction};
}

export function useLoadFromVpb(): RequestDataFunction<DividendSyncFunction> {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const getFunction = useCallback(
        async (param: DividendSyncFunction) => {
            setLoading(true);
            axios.get(getGatewayUrl() + '/dividend/cash/v1/vpb/sync')
                .then(() => {
                    param.completedFunction();
                })
                .catch((err) => setError(err.error))
                .finally(() => setLoading(false));
        },
        []
    );

    return { loading, error, apiFunction: getFunction };
}

export function useLoadFromDp(): RequestDataFunction<DividendSyncFunction> {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const getFunction = useCallback(
        async (id: DividendSyncFunction) => {
            setLoading(true);
            const params = { params: { date: formatDayjsDateString(id.date) }};
            axios.get(getGatewayUrl() + '/dividend/cash/v1/dataprovider/sync', params)
                .then(() => {
                    id.completedFunction();
                })
                .catch((err) => setError(err.error))
                .finally(() => {
                    setLoading(false)
                });
        },
        []
    );

    return { loading, error, apiFunction: getFunction };
}

export function useGetDividendImportStatus(): RefreshDataFunction<CashDividendImportStatus> {
    const { routeDate } = useParams();
    const [importStatus, setImportStatus] = useState<CashDividendImportStatus>(defaultCashDividendImportStatus());
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const getFunction = useCallback(
        async () => {
            setLoading(true);
            axios.get(getGatewayUrl() + '/dividend/cash/v1/imported/' + formatDayjsDateString(getRouteDate(routeDate)))
                .then((response) => {
                    setImportStatus(response.data);
                })
                .catch((err) => setError(err.error))
                .finally(() => {
                    setLoading(false)
                });
        },
        [ routeDate ]
    );

    useEffect(() => {
        getFunction();
    }, [ routeDate ]);

    return {
        loading,
        error,
        data: importStatus,
        apiFunction: getFunction
    };
}